import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Row, Col, Dropdown, DropdownButton, ListGroup } from 'react-bootstrap';
import { Dash, Pencil, Trash } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import ConnectionModal from '../../components/ConnectionModal';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { useDeleteConnection } from 'hooks/connectionHooks';

function ConnectionInfo({ context }) {
  const navigate = useNavigate();
  const { connection } = useContext(context);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const deleteConnection = useDeleteConnection({
    onSuccess: () => {
      navigate('/admin/connections');
    },
    onError: () => {},
  });

  const handleConfirmDeleteConnectionClick = () => {
    deleteConnection.mutate(connection);
  };

  const handleEditOrganizationClick = () => {
    setShowModal(true);
  };

  const handleDeleteConnectionClick = () => {
    setShowConfirmationDialog(true);
  };

  return (
    <>
      <Row>
        <Col>
          <div className="d-flex">
            <h2 className="flex-grow-1 fs-3 fw-normal mb-4">
              Connection details
            </h2>
            <DropdownButton title="Actions">
              <Dropdown.Item onClick={() => handleEditOrganizationClick()}>
                <Pencil /> Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={handleDeleteConnectionClick}>
                <Trash /> Delete
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListGroup>
            <ListGroup.Item>
              <span className="fw-bold">Source ID: </span>
              {connection.configuration.sourceId}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="fw-bold">Source Name: </span>
              {connection.configuration.sourceName}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="fw-bold">Destination ID: </span>
              {connection.configuration.destinationId}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="fw-bold">Destination Name: </span>
              {connection.configuration.destinationName}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="fw-bold">MRN Source ID: </span>
              {connection.configuration.medicalRecordNumberIdentifier}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="fw-bold">Clinical Summary ID: </span>
              {connection.configuration.clinicalSummarySourceId ?? <Dash />}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="fw-bold">Clinical Summary Name: </span>
              {connection.configuration.clinicalSummarySourceName ?? <Dash />}
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>

      <ConfirmationDialog
        show={showConfirmationDialog}
        title="Confirm Delete"
        bodyText="Are you sure you want to delete this connection?"
        confirmText="Delete"
        onConfirm={handleConfirmDeleteConnectionClick}
        onCancel={() => setShowConfirmationDialog(false)}
      />

      {showModal && (
        <ConnectionModal
          connection={connection}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
}

ConnectionInfo.propTypes = {
  context: PropTypes.shape({}).isRequired,
};

export default ConnectionInfo;
