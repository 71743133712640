import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { PlusCircle } from 'react-bootstrap-icons';

export default function AddButton({
  className = '',
  onClick,
  isDisabled = false,
  size = '',
  label,
}) {
  return (
    <Button
      variant=""
      onClick={onClick}
      className={`d-flex align-items-center ${className}`}
      disabled={isDisabled}
      size={size}
    >
      <PlusCircle className="me-1" /> {label}
    </Button>
  );
}

AddButton.defaultProps = {
  isDisabled: false,
  size: undefined,
  className: undefined,
};

AddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  size: PropTypes.string,
};
