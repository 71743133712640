import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

export function usePatientMedications(patientId) {
  return useQuery(patientId && ['patientMedications', patientId], () =>
    axios.get(`/api/patients/${patientId}/medications`).then((res) => res.data)
  );
}

export function useLatestEncounterMedications(patientId) {
  return useQuery(patientId && ['latestEncounterMedications', patientId], () =>
    axios
      .get(`/api/patients/${patientId}/latest-encounter-medications`)
      .then((res) => res.data)
  );
}

const createPatientMedication = async (values) => {
  const { patientId, ...medication } = values;
  const response = await axios.post(
    `/api/patients/${patientId}/medications`,
    medication
  );
  return response.data;
};

export function useCreatePatientMedication(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPatientMedication,
    onSuccess: (medication) => {
      queryClient.invalidateQueries([
        'patientMedications',
        medication.patientId,
      ]);
      queryClient.invalidateQueries(['patient', medication.patientId]);

      options?.onSuccess?.(medication);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const updatePatientMedication = async (values) => {
  const { patientId, ...medication } = values;
  const response = await axios.put(
    `/api/patients/${patientId}/medications`,
    medication
  );
  return response.data;
};

export function useUpdatePatientMedication(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updatePatientMedication,
    onSuccess: (medication) => {
      queryClient.invalidateQueries([
        'patientMedications',
        medication.patientId,
      ]);
      queryClient.invalidateQueries(['patient', medication.patientId]);

      options?.onSuccess?.(medication);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const deletePatientMedication = async (values) => {
  const { patientId, id } = values;
  await axios.delete(`/api/patients/${patientId}/medications`, {
    data: { id },
  });

  return { patientId };
};

export function useDeletePatientMedication(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePatientMedication,
    onSuccess: ({ patientId }) => {
      queryClient.invalidateQueries(['patientMedications', patientId]);
      queryClient.invalidateQueries(['patient', patientId]);

      options?.onSuccess?.({ patientId });
    },
    onError: (error) => options?.onError?.(error),
  });
}
