import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState, useCallback } from 'react';

import { Row, Col, Table, Card, Button } from 'react-bootstrap';

import { ShieldLock, Dash, Plus } from 'react-bootstrap-icons';

import DiagnosisButton from './DiagnosisButton';
import DiagnosisModal from './DiagnosisModal';
import { DiagnosisContext } from './DiagnosisProvider';
import {
  ACTION_BUTTON_TYPES,
  ActionButton,
} from 'components/Button/ActionButton';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { UserContext, Roles } from 'components/Provider/UserProvider';
import ReadonlyIcon from 'components/ReadonlyIcon';
import {
  usePatientDiagnoses,
  useDeletePatientDiagnosis,
} from 'hooks/patientDiagnosisHooks';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

const LIST_LENGTH = 10;

function DiagnosisList({ patientId }) {
  const [show, setShow] = useState(false);
  const { editDiagnosis } = useContext(DiagnosisContext);
  const { userRole } = useContext(UserContext);
  const diagnoses = usePatientDiagnoses(patientId);
  const [selectedItemId, setSelectedItemId] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [showLength, setShowLength] = useState(LIST_LENGTH);

  const deleteDiagnosis = useDeletePatientDiagnosis({
    onSuccess: () => {
      setSelectedItemId(0);
      setShowConfirm(false);
    },
  });

  const confirmDelete = (itemId) => {
    setSelectedItemId(itemId);
    setShowConfirm(true);
  };

  const deleteConfirmed = () =>
    deleteDiagnosis.mutate({ patientId, id: selectedItemId });

  const toggleShowAll = () => {
    setShowLength(showAll ? LIST_LENGTH : diagnoses.data.length);
    setShowAll(!showAll);
  };

  const isDisabled = useCallback(
    (role, item) =>
      !Roles.canModifyRecords(role) || item.external || item.patientEncounterId,
    []
  );

  useEffect(() => {
    if (!diagnoses.isLoading && diagnoses.data.length) setShow(true);
  }, [diagnoses.isLoading, diagnoses.data, setShow]);

  return (
    <>
      <div className="border-bottom border-lighter mt-2">
        <Row className="align-items-center">
          <Col className="d-flex align-items-center">
            <div className="display-3">Medical History</div>
            <Button variant="" className="ms-1" onClick={() => setShow(!show)}>
              {show ? <Dash size={24} /> : <Plus size={24} />}
            </Button>
          </Col>
          <Col
            xs={3}
            className="align-items-center text-end"
            style={{ width: '175px' }}
          >
            <DiagnosisButton className="w-100" />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            {show &&
              (diagnoses.data && diagnoses.data.length ? (
                <Table bordered hover size="sm">
                  <thead>
                    <tr>
                      <th aria-label="External" className="text-center">
                        <ShieldLock className="ms-0" />
                      </th>
                      <th className="text-uppercase">Diagnosis</th>
                      <th className="text-uppercase text-center">Start Date</th>
                      <th className="text-uppercase text-center">End Date</th>
                      <th className="text-uppercase text-center">Status</th>
                      <th className="text-uppercase">Created By</th>
                      <th className="text-uppercase text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {diagnoses.data.slice(0, showLength).map((item) => (
                      <tr key={item.id}>
                        <td
                          style={{ width: '40px' }}
                          className="text-center align-top"
                        >
                          <ReadonlyIcon
                            readonly={item.external}
                            tooltip="External"
                          />
                        </td>
                        <td className="align-top">
                          {item.diagnosisCode?.name}
                        </td>
                        <td
                          style={{ width: '200px' }}
                          className="text-center align-top"
                        >
                          {item.startDate ? (
                            formatDateDisplay(item.startDate)
                          ) : (
                            <Dash />
                          )}
                        </td>
                        <td
                          style={{ width: '200px' }}
                          className="text-center align-top"
                        >
                          {item.endDate ? (
                            formatDateDisplay(item.endDate)
                          ) : (
                            <Dash />
                          )}
                        </td>
                        <td className="text-center align-top">
                          {item.diagnosisStatus?.name ?? <Dash />}
                        </td>
                        <td>{item.createdBy?.fullName ?? <Dash />}</td>
                        <td
                          className="text-center align-top"
                          style={{ width: '100px' }}
                        >
                          <ActionButton
                            action={ACTION_BUTTON_TYPES.EDIT}
                            disabled={isDisabled(userRole, item)}
                            onClick={() => editDiagnosis(item)}
                          />

                          <ActionButton
                            action={ACTION_BUTTON_TYPES.DELETE}
                            disabled={isDisabled(userRole, item)}
                            onClick={() => confirmDelete(item.id)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <Card body className="text-muted fst-italic mb-3">
                  0 diagnoses. Click Add Diagnosis to add new records.
                </Card>
              ))}

            {diagnoses.data && diagnoses.data.length > LIST_LENGTH && (
              <Button variant="link" onClick={toggleShowAll}>
                {showAll ? 'Show less' : 'Show more'}
              </Button>
            )}

            <ConfirmationDialog
              show={showConfirm}
              title="Confirm Delete"
              bodyText="Are you sure you want to delete this diagnosis?"
              confirmText="Delete"
              onConfirm={deleteConfirmed}
              onCancel={() => setShowConfirm(false)}
            />
          </Col>
        </Row>
      </div>
      <DiagnosisModal />
    </>
  );
}

DiagnosisList.propTypes = {
  patientId: PropTypes.number.isRequired,
};

export default DiagnosisList;
