import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Col, Row, Stack } from 'react-bootstrap';
import SiteIndicationsTable from './SiteIndicationsTable';
import ConfirmationDialog from 'components/ConfirmationDialog';
import LoadingIndicator from 'components/LoadingIndicator';
import {
  useDeleteSiteIndication,
  useSiteIndications,
} from 'hooks/siteIndicationHooks';

function ConfirmationBody(indicationName) {
  return (
    <span>
      Are you sure you want to remove the{' '}
      <span className="fw-bold">{indicationName}</span> indication from the
      site?
    </span>
  );
}

export default function SiteIndicationList({ siteId }) {
  const [showConfirmRemoveIndication, setShowConfirmRemoveIndication] =
    useState(false);
  const [selectedSiteIndication, setSelectedSiteIndication] = useState();
  const [confirmationBody, setConfirmationBody] = useState('');

  const indications = useSiteIndications(siteId);

  const deleteSiteIndication = useDeleteSiteIndication({
    onSuccess: () => {
      setShowConfirmRemoveIndication(false);
    },
  });

  const confirmRemoveIndication = (indication) => {
    const newConfirmationBody = ConfirmationBody(indication.displayName);

    setConfirmationBody(newConfirmationBody);

    setSelectedSiteIndication({ siteId, indicationId: indication.id });
    setShowConfirmRemoveIndication(true);
  };

  const handleRemove = () =>
    deleteSiteIndication.mutate({
      indicationId: parseInt(selectedSiteIndication.indicationId, 10),
      siteId: parseInt(selectedSiteIndication.siteId, 10),
    });

  if (indications.isLoading) return <LoadingIndicator />;

  return (
    <Stack>
      <h5>Current Indications</h5>

      <Row className="mt-2">
        <Col xs={8}>
          {indications.data.length ? (
            <SiteIndicationsTable
              indications={indications.data}
              confirmRemoveIndication={confirmRemoveIndication}
            />
          ) : (
            <span className="fst-italic">
              Site has no assigned indications...
            </span>
          )}
        </Col>
      </Row>
      <ConfirmationDialog
        show={showConfirmRemoveIndication}
        title="Confirm Remove"
        bodyText={confirmationBody}
        confirmText="Remove"
        onConfirm={handleRemove}
        onCancel={() => setShowConfirmRemoveIndication(false)}
      />
    </Stack>
  );
}

SiteIndicationList.propTypes = {
  siteId: PropTypes.number.isRequired,
};
