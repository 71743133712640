import PropTypes from 'prop-types';
import React from 'react';
import { Alert } from 'react-bootstrap';
import './SchedulePane.scss';

export default function AppointmentDescription({
  encounterType,
  appointmentDisplay,
  errorText,
}) {
  if (errorText) {
    return <Alert variant="danger">{errorText}</Alert>;
  }

  if (encounterType && appointmentDisplay) {
    return (
      <div className="text-center text-muted my-2 mx-4">
        <p className="mb-1">{encounterType.name}</p>
        <p>{appointmentDisplay}</p>
      </div>
    );
  }
  return null;
}

AppointmentDescription.defaultProps = {
  encounterType: undefined,
  appointmentDisplay: undefined,
  errorText: undefined,
};

AppointmentDescription.propTypes = {
  encounterType: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  appointmentDisplay: PropTypes.string,
  errorText: PropTypes.string,
};
