import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';

export default function TextField({
  label,
  name,
  placeholder = undefined,
  type = 'text',
  focus = false,
  as = undefined,
  style = undefined,
  labelClassName = undefined,
  controlClassName = undefined,
  disabled = false,
  maxLength = undefined,
  additionalOnChange,
}) {
  const { handleChange, values, touched, errors } = useFormikContext();

  const innerOnChange = (e) => {
    const val = e;
    if (val.target.value === '') val.target.value = null;

    additionalOnChange?.(val);
    handleChange(val);
  };

  return (
    <Form.Group controlId={name} className={controlClassName}>
      {label && <Form.Label className={labelClassName}>{label}</Form.Label>}
      <Form.Control
        autoFocus={focus}
        type={type}
        name={name}
        value={values[name] || ''}
        onChange={innerOnChange}
        isValid={touched[name] && !errors[name]}
        isInvalid={!!errors[name]}
        placeholder={placeholder}
        as={as}
        rows="5"
        maxLength={maxLength}
        style={style}
        disabled={disabled}
      />
    </Form.Group>
  );
}

TextField.defaultProps = {
  placeholder: undefined,
  type: 'text',
  label: undefined,
  focus: false,
  maxLength: undefined,
  as: undefined,
  style: undefined,
  labelClassName: undefined,
  controlClassName: undefined,
  disabled: false,
  additionalOnChange: undefined,
};

TextField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['text', 'date', 'number']),
  focus: PropTypes.bool,
  maxLength: PropTypes.string,
  as: PropTypes.string,
  style: PropTypes.shape({}),
  labelClassName: PropTypes.string,
  controlClassName: PropTypes.string,
  disabled: PropTypes.bool,
  additionalOnChange: PropTypes.func,
};
