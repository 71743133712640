import React, { useState } from 'react';
import { Form, InputGroup, FormCheck, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AdminListLayout from '../Layouts/AdminListLayout';
import AddButton from 'components/Button/AddButton';
import TableLayout from 'components/Layouts/TableLayout';
import UserModal from 'components/UserModal';
import { usePagination } from 'hooks/tableHooks';
import { useUserMetrics } from 'hooks/userHooks';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

const COLUMNS = [
  {
    accessorKey: 'fullName',
    header: 'Name',
  },
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    accessorKey: 'createdAt',
    header: 'Date Created',
    meta: {
      headerProps: { className: 'col-1 text-center text-nowrap px-2' },
      cellProps: { className: 'text-center' },
    },
    cell: ({ getValue }) => formatDateDisplay(getValue()),
  },
];

const paginationOptions = {
  pageIndex: 0,
  pageSize: 25,
};

export default function UsersTable() {
  const navigate = useNavigate();

  const [pagination, setPagination] = usePagination(paginationOptions);
  const [query, setQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showActiveUsers, setShowActiveUsers] = useState(true);

  const userMetrics = useUserMetrics(
    pagination.pageIndex,
    pagination.pageSize,
    query,
    showActiveUsers
  );

  const getRowProps = (row) => ({
    className: 'hoverable-row',
    onClick: () => navigate(`/admin/users/${row.original.id}`),
  });

  const handleShowActiveUsers = (value) => {
    setShowActiveUsers(value);
    setPagination(paginationOptions);
    setQuery('');
  };

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  return (
    <AdminListLayout
      topRow={
        <Stack direction="horizontal" className="justify-content-between mb-2">
          <AddButton label="New User" onClick={() => setShowModal(true)} />

          <Form className="float-end">
            <InputGroup className="mb-0 h-100 flex-nowrap justify-content-end">
              <Form.Control
                placeholder="Search by name or email"
                value={query || ''}
                onChange={handleSearch}
                style={{ minWidth: '300px' }}
              />
            </InputGroup>
          </Form>
        </Stack>
      }
      modal={showModal && <UserModal onClose={() => setShowModal(false)} />}
      table={
        <Stack gap={2}>
          <Form direction="horizontal" gap={3} as={Stack}>
            <FormCheck
              id="active-users-radio"
              type="radio"
              label="Active"
              checked={showActiveUsers}
              onChange={() => handleShowActiveUsers(true)}
            />
            <FormCheck
              id="inactive-users-radio"
              className="ms-3"
              type="radio"
              label="Inactive"
              checked={!showActiveUsers}
              onChange={() => handleShowActiveUsers(false)}
            />
          </Form>
          <TableLayout
            columns={COLUMNS}
            data={userMetrics.data}
            pagination={pagination}
            setPagination={setPagination}
            noResultsText="0 users found."
            getRowProps={getRowProps}
            isLoading={userMetrics.isLoading}
          />
        </Stack>
      }
    />
  );
}
