import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Stack, FormCheck } from 'react-bootstrap';
import { getReasonTableColumns } from './constants';
import ReasonModal from './ReasonModal';
import CollapsibleSection from '../CollapsibleSection';
import TableLayout from 'components/Layouts/TableLayout';
import LoadingIndicator from 'components/LoadingIndicator';

const emptyReason = { name: '', active: true };

function ReasonSection({
  useOrgReasons,
  useCreateReason,
  useUpdateReason,
  reasonType,
  globalOnly = false,
}) {
  const [selectedReason, setSelectedReason] = useState(null);
  const [showActive, setShowActive] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const reasons = useOrgReasons(false, globalOnly);

  const resetModal = () => {
    setSelectedReason(null);
    setShowModal(false);
  };

  const createReason = useCreateReason({
    onSuccess: () => resetModal(),
    onError: (err) => setError(err?.response.data.message),
  });

  const updateReason = useUpdateReason({
    onSuccess: () => resetModal(),
    onError: (err) => setError(err?.response.data.message),
  });

  const handleCreateReason = (reasonToCreate) => {
    createReason.mutate({ ...reasonToCreate, isGlobal: globalOnly });
  };

  const handleUpdateReason = (reasonToUpdate) => {
    updateReason.mutate({ ...reasonToUpdate, isGlobal: globalOnly });
  };

  const handleAddReasonButtonOnClick = () => {
    setError(null);
    setSelectedReason(emptyReason);
    setShowModal(true);
  };

  const handleEditButtonOnClick = (reason) => {
    setError(null);
    setSelectedReason(reason);
    setShowModal(true);
  };

  if (reasons.isLoading) return <LoadingIndicator />;

  const COLUMNS = getReasonTableColumns(handleEditButtonOnClick, globalOnly);

  const tableData = reasons.data.filter(
    (reason) => reason.active === showActive
  );

  return (
    <>
      <CollapsibleSection
        title={`${reasonType}s`}
        onAddButtonClick={handleAddReasonButtonOnClick}
        collapsibleChildren={
          <Stack gap={2}>
            <Stack direction="horizontal">
              <FormCheck
                id={`${reasonType}-active`}
                type="radio"
                name={`${reasonType}-active`}
                label="Active"
                checked={showActive}
                onChange={() => setShowActive(true)}
              />

              <FormCheck
                id={`${reasonType}-inactive`}
                className="ms-3"
                type="radio"
                name={`${reasonType}-inactive`}
                label="Inactive"
                checked={!showActive}
                onChange={() => setShowActive(false)}
              />
            </Stack>
            <TableLayout columns={COLUMNS} data={tableData} />
          </Stack>
        }
      />
      {showModal && (
        <ReasonModal
          title={`${selectedReason?.id ? 'Update' : 'Create'} ${reasonType}`}
          onClose={() => resetModal()}
          initialValues={{ ...selectedReason }}
          onSubmit={
            selectedReason?.id ? handleUpdateReason : handleCreateReason
          }
          errorMsg={error}
          isSubmitting={createReason.isLoading || updateReason.isLoading}
        />
      )}
    </>
  );
}

ReasonSection.defaultProps = {
  globalOnly: false,
};

ReasonSection.propTypes = {
  useOrgReasons: PropTypes.func.isRequired,
  useCreateReason: PropTypes.func.isRequired,
  useUpdateReason: PropTypes.func.isRequired,
  reasonType: PropTypes.string.isRequired,
  globalOnly: PropTypes.bool,
};

export default ReasonSection;
