/* eslint-disable react/jsx-no-constructed-context-values */
import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

import { useConnection } from 'hooks/connectionHooks';

const ConnectionContext = createContext({});

function ConnectionProvider({ connectionId, children }) {
  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const { data: connection, isSuccess } = useConnection(connectionId);

  return (
    isSuccess && (
      <ConnectionContext.Provider
        value={{
          connection,
          showConfirmRemove,
          setShowConfirmRemove,
        }}
      >
        {children}
      </ConnectionContext.Provider>
    )
  );
}

ConnectionProvider.propTypes = {
  connectionId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default ConnectionProvider;
export { ConnectionContext, ConnectionProvider };
