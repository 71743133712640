import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

import TableLayout from 'components/Layouts/TableLayout';
import {
  renderClinicalSummaryColumn,
  renderDestinationColumn,
  renderSourceColumn,
} from 'pages/Admin/ConnectionsList';

export default function SiteConnectionsTable({
  connections,
  confirmRemoveConnection,
}) {
  const columns = useMemo(
    () => [
      {
        header: 'Source',
        cell: (props) => renderSourceColumn(props),
      },
      {
        header: 'Destination',
        cell: (props) => renderDestinationColumn(props),
      },
      {
        accessorKey: 'configuration.medicalRecordNumberIdentifier',
        header: 'MRN Identifier',
      },
      {
        header: 'Clinical Summary Source',
        cell: (props) => renderClinicalSummaryColumn(props),
      },
      {
        header: 'Actions',
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { style: { width: '60px' }, className: 'text-center' },
        },
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row }) => (
          <Button
            size="sm"
            variant="dark"
            className="btn-lg btn-block"
            onClick={() => confirmRemoveConnection(row.original)}
          >
            <Trash />
          </Button>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <TableLayout columns={columns} data={connections} />;
}

SiteConnectionsTable.propTypes = {
  connections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,

  confirmRemoveConnection: PropTypes.func.isRequired,
};
