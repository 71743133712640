import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  Dash,
  ExclamationCircleFill,
  FileEarmark,
} from 'react-bootstrap-icons';

import TableLayout from 'components/Layouts/TableLayout';
import RecentNoteIcon from 'components/RecentNoteIcon';
import StringArrayPopover from 'components/StringArrayPopover';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

const renderStudiesCell = (props) => (
  <StringArrayPopover
    displayText={props.getValue()}
    header="Studies"
    items={props.row.original.studyStatuses}
  />
);

const renderSitesCell = (props) => (
  <StringArrayPopover
    displayText={props.getValue()}
    header="Sites"
    items={props.row.original.siteNames}
  />
);

const viewPatient = (patientId) => {
  window.open(`/patients/${patientId}`);
};

const getRowProps = (row) => ({
  className: 'hoverable-row',
  onClick: () => viewPatient(row.original.id),
});

const COLUMNS = [
  {
    accessorKey: 'fullName',
    header: 'Name',
    meta: {
      headerProps: { className: 'display-4 fw-bold text-uppercase' },
    },
    cell: (props) => {
      if (!props.row.original.doNotContact) return props.getValue();

      return (
        <div className="d-flex align-items-center">
          <div className="me-2">{props.getValue()}</div>
          <OverlayTrigger
            overlay={<Tooltip>Do Not Contact</Tooltip>}
            placement="top"
          >
            <ExclamationCircleFill className="text-danger" />
          </OverlayTrigger>
        </div>
      );
    },
  },
  {
    accessorKey: 'medicalRecordNumber',
    header: 'MRN',
    meta: {
      headerProps: {
        className: 'display-4 fw-bold text-uppercase',
        style: { width: '15%' },
      },
    },
  },
  {
    id: 'siteNames',
    // It's evaluated only when if renderSitesCell is used.
    accessorFn: (row) => `${row.siteNames?.length ?? 0} Sites`,
    enableSorting: false,
    header: 'Site',
    meta: {
      headerProps: { className: 'display-4 fw-bold text-uppercase' },
    },
    cell: (props) => {
      if (props.row.original.siteNames?.length > 1) {
        return renderSitesCell(props);
      }

      if (props.row.original.siteNames?.length === 1) {
        return <span>{props.row.original.siteNames[0]}</span>;
      }

      return <Dash />;
    },
  },
  {
    accessorKey: 'nextAppointment',
    header: 'Appt',
    meta: {
      headerProps: {
        className: 'display-4 fw-bold text-uppercase',
        style: { width: '15%' },
      },
    },
    cell: (props) => {
      const { nextAppointment } = props.row.original;

      if (nextAppointment?.length) {
        const [appointmentDate, timeZone, reason] = nextAppointment;

        const formattedDate = DateTime.fromSQL(appointmentDate, {
          zone: timeZone,
        }).toFormat('M/d/yy t ZZZZ');

        return (
          <div>
            <div>{formattedDate}</div>
            <div className="text-muted">{reason}</div>
          </div>
        );
      }
      return <Dash />;
    },
  },
  {
    id: 'studyStatuses',
    // It's evaluated only when if renderStudiesCell is used.
    accessorFn: (row) => `${row.studyStatuses?.length ?? 0} Studies`,
    enableSorting: false,
    header: 'Study',
    meta: {
      headerProps: { className: 'display-4 fw-bold text-uppercase' },
    },
    cell: (props) => {
      if (props.row.original.studyStatuses?.length > 1) {
        return renderStudiesCell(props);
      }

      if (props.row.original.studyStatuses?.length === 1) {
        return <span>{props.row.original.studyStatuses[0]}</span>;
      }

      return <Dash />;
    },
  },
  {
    accessorKey: 'latest_interaction',
    header: 'Last Contacted',
    meta: {
      headerProps: {
        className: 'text-center display-4',
        style: { width: '15%' },
      },
      cellProps: { className: 'text-center' },
    },
    cell: (props) =>
      props.getValue() ? formatDateDisplay(props.getValue()) : <Dash />,
  },
  {
    accessorKey: 'remarks',
    header: () => <FileEarmark />,
    enableSorting: false,
    meta: {
      headerProps: { className: 'text-center', style: { width: '32px' } },
      cellProps: { className: 'text-center' },
    },
    cell: (props) => <RecentNoteIcon show id={props.row.original.id} />,
  },
];

export default function SearchResultsTable({
  data,
  pagination,
  setPagination,
  sorting,
  setSorting,
}) {
  if (!data?.rows) {
    return (
      <Card>
        <Card.Body>No Results found</Card.Body>
      </Card>
    );
  }

  return (
    <TableLayout
      columns={COLUMNS}
      data={data}
      pagination={pagination}
      setPagination={setPagination}
      sorting={sorting}
      setSorting={setSorting}
      getRowProps={getRowProps}
      noResultsText={data.toString()}
    />
  );
}

SearchResultsTable.propTypes = {
  data: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  setPagination: PropTypes.func.isRequired,
  setSorting: PropTypes.func.isRequired,
  pagination: PropTypes.shape({}).isRequired,
  sorting: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

SearchResultsTable.defaultProps = {
  data: undefined,
};
