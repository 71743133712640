import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import * as yup from 'yup';
import LoadingIndicator from './LoadingIndicator';
import { useOrganizationUsers } from 'hooks/organizationHooks';
import { useUserSites } from 'hooks/siteHooks';
import { useCreateUserSite } from 'hooks/userSiteHooks';

const schema = yup.object({
  fullName: yup.string().required(),
});

export default function UserAdd({ siteId, organizationId }) {
  const formRef = useRef();

  const [selectedUser, setSelectedUser] = useState(null);
  const [substring, setSubstring] = useState('');

  const orgUsers = useOrganizationUsers(
    organizationId,
    substring,
    0,
    25,
    true,
    !!substring.length
  );
  const siteUsers = useUserSites(siteId);

  const createUserSite = useCreateUserSite({
    onSuccess: () => {
      setSelectedUser(null);
    },
  });

  const handleClick = () => {
    const userId = Number(selectedUser.id);
    createUserSite.mutate({ userId, siteId });
  };

  const handleSearch = (query) => {
    setSubstring(query);
  };

  if (orgUsers.isLoading || siteUsers.isLoading) return <LoadingIndicator />;

  const filteredUsers = orgUsers.data.rows?.filter(
    (user) => !siteUsers.data.map((u) => u.id).includes(user.id)
  );

  return (
    <Formik validationSchema={schema} initialValues={{ fullName: '' }}>
      {({ setFieldValue }) => (
        <Form noValidate ref={formRef}>
          <Row>
            <Col xs={6}>
              <Form.Group controlId="formFullName">
                <AsyncTypeahead
                  id="fullName"
                  name="fullName"
                  filterBy={() => true}
                  labelKey="fullName"
                  minLength={3}
                  onSearch={handleSearch}
                  isLoading={false}
                  options={filteredUsers.map((u) => u.user)}
                  selected={selectedUser ? [selectedUser] : []}
                  placeholder="Search using name or email"
                  onChange={(users) => {
                    if (users.length === 0) {
                      setFieldValue('fullName', null);
                      setSelectedUser(null);
                    } else {
                      setFieldValue('fullName', users[0].id);
                      setSelectedUser(users[0]);
                    }
                  }}
                  renderMenuItemChildren={(option) => (
                    <section className="d-flex flex-row">
                      <div className="text-truncate w-90">
                        <span>{option.fullName}</span>
                        <span className="ms-2 text-muted">
                          ({option.email})
                        </span>
                      </div>
                    </section>
                  )}
                />
              </Form.Group>
            </Col>
            <Col xs="1">
              <Button
                variant="primary"
                className="ms-n3"
                disabled={!selectedUser || createUserSite.isLoading}
                onClick={handleClick}
              >
                Add
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

UserAdd.propTypes = {
  siteId: PropTypes.number.isRequired,
  organizationId: PropTypes.number.isRequired,
};
