import PropTypes from 'prop-types';
import React from 'react';
import { FormSelect, FormGroup, FormLabel } from 'react-bootstrap';
import { useEncounterTypes } from 'hooks/encounterHooks';
import './SchedulePane.scss';

export default function EncounterTypeSelect({
  value,
  onChange,
  disabled,
  isValid,
  isInvalid,
  label,
  name,
  className,
}) {
  const { data, isLoading } = useEncounterTypes();

  const handleChange = (event) => {
    const encounterType = data.find(
      (item) => item.id === Number(event.target.value)
    );
    onChange(event, encounterType);
  };

  return (
    <FormGroup controlId={`form-${name}`} className={className}>
      {label && <FormLabel>{label}</FormLabel>}
      <FormSelect
        name={name}
        value={value}
        onChange={handleChange}
        isValid={isValid}
        isInvalid={isInvalid}
        disabled={disabled}
      >
        <option value="">Select Visit Type...</option>
        {!isLoading &&
          data.map((x) => (
            <option key={x.id} value={x.id}>
              {x.name}
            </option>
          ))}
      </FormSelect>
    </FormGroup>
  );
}

EncounterTypeSelect.defaultProps = {
  className: undefined,
  label: undefined,
  isInvalid: undefined,
  isValid: undefined,
  disabled: undefined,
};

EncounterTypeSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};
