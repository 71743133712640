import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

export function useRoles() {
  return useQuery(['roles'], () =>
    axios.get('/api/roles').then((res) => res.data)
  );
}

const createRole = async (role) => {
  const result = await axios.post('/api/admin/roles', role);
  return result.data;
};

export function useCreateRole(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createRole,
    onSuccess: (role) => {
      queryClient.invalidateQueries(['roles']);
      options?.onSuccess?.(role);
    },
    onError: (error) => options?.onError?.(error),
  });
}
