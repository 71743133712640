import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { Plus } from 'react-bootstrap-icons';
import { MedicationContext } from './MedicationProvider';
import { UserContext, Roles } from 'components/Provider/UserProvider';

export default function MedicationButton({ className }) {
  const { addMedication } = useContext(MedicationContext);
  const { userRole } = useContext(UserContext);

  if (!Roles.canModifyRecords(userRole)) return null;

  return (
    <Button
      size="sm"
      variant="outline-primary"
      onClick={addMedication}
      className={className}
    >
      <Plus className="align-text-top" size={18} /> Add Medication
    </Button>
  );
}

MedicationButton.defaultProps = {
  className: '',
};

MedicationButton.propTypes = {
  className: PropTypes.string,
};
