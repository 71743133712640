import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

const createPatientStudy = async (values) => {
  const { patientId, ...patientStudy } = values;
  const response = await axios.post(
    `/api/patients/${patientId}/studies`,
    patientStudy
  );
  return response.data;
};

export function useCreatePatientStudy(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPatientStudy,
    onSuccess: (patientStudy) => {
      queryClient.invalidateQueries(['patient', patientStudy.patientId]);
      queryClient.invalidateQueries([
        'patients',
        { studyId: patientStudy.studyId },
      ]);
      queryClient.invalidateQueries(['status-group-metrics']);
      queryClient.invalidateQueries([
        'patientStudyHistory',
        patientStudy.patientId,
      ]);

      options?.onSuccess?.(patientStudy);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const updatePatientStudyStatus = async (values) => {
  const { patientId, patientStudyId, studyStatusId, reason } = values;
  const res = await axios.put(
    `/api/patients/${patientId}/studies/${patientStudyId}`,
    { studyStatusId, reason }
  );

  return res.data;
};

export function useUpdatePatientStudyStatus(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updatePatientStudyStatus,
    onSuccess: (patientStudy) => {
      queryClient.invalidateQueries(['patient', patientStudy.patientId]);
      queryClient.invalidateQueries([
        'patients',
        { studyId: patientStudy.studyId },
      ]);
      queryClient.invalidateQueries(['status-group-metrics']);
      queryClient.invalidateQueries([
        'patientStudyHistory',
        patientStudy.patientId,
      ]);

      options?.onSuccess?.(patientStudy);
    },
    onError: (error) => options?.onError?.(error),
  });
}
