import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import './ScoreCard.scss';

export default function NumericScoreCard({ name, value }) {
  return (
    <Card className="text-center indication-card border-top-0">
      <Card.Title className="p-2 bg-light text-white rounded-top">
        {name}
      </Card.Title>
      <Card.Body className="d-flex align-items-center justify-content-center">
        <div className="CircularProgressbar-text text-teal">
          <div className="progress-value">{`${value}`}</div>
          <div className="progress-description">Score</div>
        </div>
      </Card.Body>
    </Card>
  );
}

NumericScoreCard.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
