import { DateTime, Interval } from 'luxon';

export function formatDateDisplay(isoDate) {
  return isoDate ? DateTime.fromISO(isoDate).toFormat('MM/dd/yyyy') : '';
}

export function formatDateTimeDisplay(isoDate) {
  return isoDate
    ? DateTime.fromISO(isoDate).toFormat('MM/dd/yyyy hh:mm a')
    : '';
}

export function weekRangeInterval(dateTime = DateTime.local()) {
  const isSunday = dateTime.weekday === 7;

  if (isSunday) {
    const start = dateTime.startOf('day');
    const end = start.plus({ days: 6 }).endOf('day');

    return Interval.fromDateTimes(start, end);
  }
  const start = dateTime.startOf('week').minus({ days: 1 });
  const end = dateTime.endOf('week').minus({ days: 1 });

  return Interval.fromDateTimes(start, end);
}

export function getDefaultInterval(zone) {
  return zone
    ? weekRangeInterval(DateTime.local().setZone(zone, { keepLocalTime: true }))
    : weekRangeInterval();
}

/**
 * hasItemsCreatedToday - Takes an array of objects and returns true if
 * any of the objects have a createdAt property with today's date.  This
 * is a helper to be used with domain objects with the default Sequelize
 * createdAt timestamp.
 *
 * @param {Array} arr
 * @returns {boolean}
 */
export function hasItemsCreatedToday(arr) {
  const today = DateTime.now().startOf('day');

  return arr.some(
    (x) =>
      DateTime.fromISO(x.createdAt).startOf('day').toString() ===
      today.toString()
  );
}
