import { useQuery } from '@tanstack/react-query';
import axios from '../axios';

export function useZones() {
  return useQuery(['zones'], () =>
    axios.get('/api/zones').then((res) => res.data)
  );
}

export default {
  useZones,
};
