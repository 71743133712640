import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, Stack } from 'react-bootstrap';

export default function UpcomingAppointmentCard({
  appointment,
  createEncounter,
  cancelAppointment,
  rescheduleAppointment,
  isBusy,
  isReadOnly,
}) {
  const timeZone = appointment?.site?.zone.name;
  const appointmentDateTime = DateTime.fromISO(appointment?.appointmentDate, {
    zone: timeZone,
  }).toFormat('EEEE, MMMM d, yyyy, h:mm a ZZZZ');

  const matches = /^(.*), (.*)$/g.exec(appointmentDateTime);
  const date = matches[1];
  const time = matches[2];

  return (
    <Card
      className="mt-2 border-info text-center h-100"
      style={{ width: '240px' }}
    >
      <Card.Header className="bg-info text-white display-4">
        Prescreen Visit
      </Card.Header>
      <Card.Body className="d-grid gap-1">
        {appointment.site && (
          <p className="display-3 mb-1">{appointment.site.name}</p>
        )}

        <Stack>
          <small className="h5 opacity-50">Start Date</small>
          <div>{date}</div>
          <div>{time}</div>
        </Stack>

        <Stack gap={1} className="align-self-end">
          <Button
            variant="dark"
            className="w-100"
            onClick={() => createEncounter(appointment)}
            disabled={isReadOnly || isBusy}
          >
            Begin Visit
          </Button>
          <Button
            variant="outline-dark"
            className="w-100"
            onClick={() => rescheduleAppointment(appointment)}
            disabled={isReadOnly || isBusy}
          >
            Reschedule
          </Button>
          <Button
            variant="outline-dark"
            className="w-100"
            onClick={() => cancelAppointment(appointment.id)}
            disabled={isReadOnly || isBusy}
          >
            Cancel
          </Button>
        </Stack>
      </Card.Body>
    </Card>
  );
}

UpcomingAppointmentCard.propTypes = {
  appointment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    site: PropTypes.shape({
      name: PropTypes.string,
      zone: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    appointmentDate: PropTypes.string.isRequired,
  }).isRequired,

  createEncounter: PropTypes.func.isRequired,
  rescheduleAppointment: PropTypes.func.isRequired,
  cancelAppointment: PropTypes.func.isRequired,
  isBusy: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};
