import PropTypes from 'prop-types';
import React from 'react';
import * as yup from 'yup';
import SelectField from 'components/FormikForm/SelectField';
import ModalForm from 'components/ModalForm';
import { useActiveOrganizations } from 'hooks/organizationHooks';
import { useRoles } from 'hooks/roleHooks';

const schema = yup.object({
  organizationId: yup.number().required(),
  roleId: yup.number().required(),
});

export default function UserOrganizationAddModal({
  userId,
  organizationIds,
  onClose,
  onSubmit,
  isSubmitting = false,
}) {
  const organizations = useActiveOrganizations();
  const roles = useRoles();

  if (organizations.isLoading || roles.isLoading) return null;

  const filteredOrganizations = organizations.data.filter(
    (org) => !organizationIds.includes(org.id)
  );

  const handleCloseModal = () => {
    onClose();
  };

  const handleFormSubmit = (values) => {
    if (!values.organizationId || !values.roleId) return;

    onSubmit({ userId, ...values });
    onClose();
  };

  return (
    <ModalForm
      formId="add-user-organization-form"
      title="Add User Organization"
      onClose={handleCloseModal}
      isSubmitting={isSubmitting}
      validationSchema={schema}
      onSubmit={handleFormSubmit}
      initialValues={{ organizationId: '', roleId: '' }}
    >
      <SelectField
        label="Organization"
        name="organizationId"
        defaultDisplay="Select Organization"
        options={filteredOrganizations}
        valueAccessor="id"
        displayAccessor="name"
      />
      <SelectField
        label="Role"
        name="roleId"
        defaultDisplay="Select Role"
        options={roles.data}
        valueAccessor="id"
        displayAccessor="name"
      />
    </ModalForm>
  );
}

UserOrganizationAddModal.defaultProps = {
  isSubmitting: false,
};

UserOrganizationAddModal.propTypes = {
  userId: PropTypes.number.isRequired,
  organizationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};
