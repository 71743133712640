import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { ShieldLock, Dash } from 'react-bootstrap-icons';
import { formatDateDisplay } from '../../utils/dateTimeUtils';
import TableLayout from 'components/Layouts/TableLayout';
import ReadonlyIcon from 'components/ReadonlyIcon';

function HistoricalMedicationsTable({ medications, showAdd = false, onAdd }) {
  const formatNameDisplay = (item) =>
    item.customName || item.medicationCode?.name;

  const columns = useMemo(() => {
    const config = [
      {
        accessorKey: 'external',
        header: () => <ShieldLock />,
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { className: 'text-center' },
        },
        cell: (props) => (
          <ReadonlyIcon
            readonly={props.row.original.external}
            showDash
            tooltip="External"
          />
        ),
      },
      {
        accessorKey: 'name',
        header: 'Medication',
        cell: (props) => formatNameDisplay(props.row.original) || <Dash />,
      },
      {
        accessorKey: 'medicationFrequency',
        header: 'Frequency',
        cell: (props) => props.getValue()?.name || <Dash />,
      },
      {
        accessorKey: 'dosage',
        header: 'Dosage',
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { className: 'text-center' },
        },
        cell: (props) => props.getValue() || <Dash />,
      },
      {
        accessorKey: 'route',
        header: 'Route',
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { className: 'text-center' },
        },
        cell: (props) => props.getValue() || <Dash />,
      },
      {
        accessorKey: 'startDate',
        header: 'Start Date',
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { className: 'text-center' },
        },
        cell: (props) =>
          (props.getValue() && formatDateDisplay(props.getValue())) || <Dash />,
      },
      {
        accessorKey: 'stopDate',
        header: 'Stop Date',
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { className: 'text-center' },
        },
        cell: (props) =>
          (props.getValue() && formatDateDisplay(props.getValue())) || <Dash />,
      },
      {
        accessorKey: 'reason',
        header: 'Reason',
      },
    ];

    if (showAdd) {
      config.push({
        id: 'actions',
        header: '',
        meta: {
          headerProps: { style: { width: '80px' } },
          cellProps: { className: 'text-center small' },
        },
        cell: (props) => (
          <Button
            variant="secondary"
            size="sm"
            onClick={() => onAdd(props.row.original)}
          >
            Add
          </Button>
        ),
      });
    }
    return config;
  }, [medications]);

  return (
    <TableLayout
      columns={columns}
      data={medications}
      noResultsText="0 medications found."
    />
  );
}

export default HistoricalMedicationsTable;
