import { useQuery } from '@tanstack/react-query';
import axios from '../axios';

export function usePatientRecruitmentHistory(patientId, sort) {
  return useQuery(patientId && ['patientRecruitmentHistory', patientId], () =>
    axios
      .get(`/api/patients/${patientId}/recruitment-history`, {
        params: { sort },
      })
      .then((res) => res.data)
  );
}

export default {
  usePatientRecruitmentHistory,
};
