export default Object.freeze({
  PROSPECT: 'Prospect',
  RECOMMENDED: 'Recommended',
  PREQUALIFIED: 'Prequalified',
  NOT_INTERESTED: 'Not Interested',
  ELIGIBLE: 'Eligible',
  NOT_ELIGIBLE: 'Not Eligible',
  SCREEN_PENDING: 'Screen Pending',
  SCREENING: 'Screening',
  SCREEN_FAIL: 'Screen Fail',
  ENROLLED: 'Enrolled',
  COMPLETED: 'Completed',
});
