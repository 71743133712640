import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useLocation, useSearchParams } from 'react-router-dom';
import HistoryTab from './HistoryTab';

import MedicalRecordTab from './MedicalRecordTab';
import ResearchTab from './ResearchTab';

function MedicalInfoTabs({ patient }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const selectedTab = searchParams.get('selected_tab');

  // preserve backPath in location state when updating selected_tab
  const handleTabSelect = (key) => {
    setSearchParams(
      { selected_tab: key },
      { replace: true, state: location.state }
    );
  };

  useEffect(() => {
    if (!searchParams.has('selected_tab'))
      setSearchParams(
        {
          selected_tab: 'research',
        },
        { replace: true, state: location.state }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="position-static">
      <Tabs activeKey={selectedTab} onSelect={(k) => handleTabSelect(k)}>
        <Tab eventKey="research" title="RESEARCH">
          <ResearchTab patient={patient} />
        </Tab>
        <Tab eventKey="medical-record" title="MEDICAL RECORD">
          <MedicalRecordTab patient={patient} />
        </Tab>
        <Tab eventKey="history" title="HISTORY">
          <HistoryTab patientContext={{ patientId: patient.id }} />
        </Tab>
      </Tabs>
    </div>
  );
}

MedicalInfoTabs.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default MedicalInfoTabs;
