import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import AgeSearchField from './AgeSearchField';
import CitySearchField from './CitySearchField';
import DoNotContactDropdown from './DoNotContactDropdown';
import EthnicityDropdown from './EthnicityDropdown';
import GenderDropdown from './GenderDropdown';
import RaceDropdown from './RaceDropdown';
import SiteMultiSelectField from './SiteMultiSelect';
import ZipSearchField from './ZipSearchField';

function DemographicsSection({ searchCriteria, onChange }) {
  return (
    <Row xs={2} className="gy-3">
      <Col>
        <AgeSearchField
          value={{
            min: searchCriteria.minAge,
            max: searchCriteria.maxAge,
          }}
          onChange={onChange}
        />
      </Col>
      <Col>
        <EthnicityDropdown
          value={searchCriteria.ethnicGroups}
          onChange={onChange}
        />
      </Col>
      <Col>
        <GenderDropdown
          value={searchCriteria.genders ?? []}
          onChange={onChange}
        />
      </Col>
      <Col>
        <CitySearchField value={searchCriteria.city} onChange={onChange} />
      </Col>
      <Col>
        <RaceDropdown value={searchCriteria.races} onChange={onChange} />
      </Col>
      <Col>
        <ZipSearchField value={searchCriteria.postalCode} onChange={onChange} />
      </Col>
      <Col>
        <SiteMultiSelectField
          value={searchCriteria.siteIds ?? []}
          onChange={onChange}
        />
      </Col>
      <Col>
        <DoNotContactDropdown
          value={searchCriteria.showDoNotContact || false}
          onChange={onChange}
        />
      </Col>
    </Row>
  );
}

DemographicsSection.propTypes = {
  searchCriteria: PropTypes.shape({
    minAge: PropTypes.number,
    maxAge: PropTypes.number,
    siteIds: PropTypes.arrayOf(PropTypes.number),
    postalCode: PropTypes.string,
    races: PropTypes.arrayOf(PropTypes.number),
    genders: PropTypes.arrayOf(PropTypes.number),
    city: PropTypes.string,
    ethnicGroups: PropTypes.arrayOf(PropTypes.number),
    showDoNotContact: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DemographicsSection;
