/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';
import Select, { components } from 'react-select';

import LoadingIndicator from 'components/LoadingIndicator';
import { useSites } from 'hooks/siteHooks';

const overrideHeights = { minHeight: 0, maxHeight: 28 };

const customTheme = {
  colors: {
    primary: '#e35205',
    primary75: '#fa7832',
    primary50: '#fca576',
    primary25: '#fdd2ba',
    danger: '#e35205',
    dangerLight: '#fdd2ba',
  },
};

const customStyles = {
  placeholder: (base) => ({ ...base, ...overrideHeights }),
  control: (base) => ({ ...base, ...overrideHeights }),
  valueContainer: (base) => ({
    ...base,
    maxHeight: 22,
    marginBottom: 5,
  }),
  indicatorsContainer: (base) => ({
    ...base,
    ...overrideHeights,
    paddingTop: 6,
    paddingBottom: 6,
  }),
  multiValue: (base, state) =>
    state.data.isFixed
      ? { ...base, backgroundColor: '#38484e' }
      : { ...base, maxHeight: 18, marginRight: 4 },
  multiValueLabel: (base, state) =>
    state.data.isFixed
      ? {
          ...base,
          fontWeight: 'bold',
          color: 'white',
          fontFamily: 'Bicyclette',
          paddingRight: 6,
        }
      : {
          base,
          fontFamily: 'Bicyclette',
          fontSize: '1.1rem',
          paddingLeft: 3,
          alignSelf: 'center',
        },
  multiValueRemove: (base, state) =>
    state.data.isFixed ? { ...base, display: 'none' } : { ...base },
};

function ValueContainer({ children, ...props }) {
  const { getValue } = props;
  const nbValues = getValue().length;
  const newChildren =
    nbValues > 1
      ? [
          <div
            key="label"
            className="display-4"
          >{`${nbValues} sites selected`}</div>,
          children[1],
        ]
      : children;

  return (
    <components.ValueContainer {...props}>
      {newChildren}
    </components.ValueContainer>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  getValue: PropTypes.func.isRequired,
};

export default function SiteMultiSelect({
  onChange,
  selectedSites,
  className,
  placeholderText,
}) {
  const { data: sites, isLoading } = useSites();

  if (isLoading) return <LoadingIndicator />;

  const siteOptions = sites.map((s) => ({ value: s.id, label: s.name }));
  const placeholder = placeholderText || 'Patients at all available sites';

  const getValue = () =>
    siteOptions.filter((o) => selectedSites.includes(o.value));

  return (
    <Select
      components={{ ValueContainer }}
      hideSelectedOptions={false}
      isSearchable={false}
      isMulti
      name="sites"
      placeholder={placeholder}
      noOptionsMessage={() => 'No sites available for logged in user.'}
      options={siteOptions}
      value={getValue()}
      onChange={onChange}
      className={className}
      classNamePrefix="select"
      styles={customStyles}
      theme={(theme) => ({
        ...theme,
        colors: { ...theme.colors, ...customTheme.colors },
      })}
    />
  );
}

SiteMultiSelect.defaultProps = {
  className: undefined,
  placeholderText: undefined,
};

SiteMultiSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedSites: PropTypes.arrayOf(PropTypes.number).isRequired,
  className: PropTypes.string,
  placeholderText: PropTypes.string,
};
