import PropTypes from 'prop-types';
import React from 'react';
import { FormControl } from 'react-bootstrap';
import Field from '../BaseFields/Field';

function CitySearchField({ onChange, value }) {
  const handleOnChange = (e) => {
    onChange('city', e.target.value);
  };

  return (
    <Field label="City">
      <FormControl
        placeholder="Any"
        size="sm"
        type="text"
        onChange={handleOnChange}
        value={value}
      />
    </Field>
  );
}

CitySearchField.defaultProps = {
  value: '',
};

CitySearchField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default CitySearchField;
