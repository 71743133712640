import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

export function usePatientObservations({ type, patientId }) {
  return useQuery(
    patientId && ['patientObservations', { type, patientId }],
    () =>
      axios
        .get(`/api/patients/${patientId}/observations`, { params: { type } })
        .then((res) => res.data)
  );
}

const createPatientObservation = async (values) => {
  const { patientId, ...observation } = values;
  const response = await axios.post(
    `/api/patients/${patientId}/observations`,
    observation
  );
  return response.data;
};

export function useCreatePatientObservation(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPatientObservation,
    onSuccess: (observation) => {
      const observationsKeyOptions = { patientId: observation.patientId };

      if (options?.meta?.type) {
        observationsKeyOptions.type = options.meta.type;
      }

      queryClient.invalidateQueries([
        'patientObservations',
        observationsKeyOptions,
      ]);
      queryClient.invalidateQueries(['patient', observation.patientId]);

      options?.onSuccess?.(observation);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const updatePatientObservation = async (values) => {
  const { patientId, ...observation } = values;
  const response = await axios.put(
    `/api/patients/${patientId}/observations`,
    observation
  );
  return response.data;
};

export function useUpdatePatientObservation(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updatePatientObservation,
    onSuccess: (observation) => {
      const observationsKeyOptions = { patientId: observation.patientId };

      if (options?.meta?.type) {
        observationsKeyOptions.type = options.meta.type;
      }

      queryClient.invalidateQueries([
        'patientObservations',
        observationsKeyOptions,
      ]);
      queryClient.invalidateQueries(['patient', observation.patientId]);

      options?.onSuccess?.(observation);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const deletePatientObservation = async (values) => {
  const { patientId, id, type } = values;
  await axios.delete(`/api/patients/${patientId}/observations`, {
    data: { id },
  });

  return { type, patientId };
};

export function useDeletePatientObservation(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePatientObservation,
    onSuccess: ({ type, patientId }) => {
      queryClient.invalidateQueries([
        'patientObservations',
        { type, patientId },
      ]);
      queryClient.invalidateQueries(['patient', { type, patientId }]);

      options?.onSuccess?.({ type, patientId });
    },
    onError: (error) => options?.onError?.(error),
  });
}
