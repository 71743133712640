import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Alert, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import CheckField from 'components/FormikForm/CheckField';
import PhoneField from 'components/FormikForm/PhoneField';
import SelectField from 'components/FormikForm/SelectField';
import TextField from 'components/FormikForm/TextField';
import ModalForm from 'components/ModalForm';
import { statesArray } from 'entities/States';
import { useIsAdmin } from 'hooks/adminHooks';
import { useActiveOrganizations } from 'hooks/organizationHooks';
import { useCreateSite, useUpdateSite } from 'hooks/siteHooks';
import { useCurrentUser } from 'hooks/userHooks';
import { useZones } from 'hooks/zoneHooks';

const schema = yup.object({
  name: yup.string().required(),
  organizationId: yup.number().integer().required(),
  zoneId: yup.number().integer().required(),
  fullName: yup.string().nullable(),
  address1: yup.string().nullable(),
  address2: yup.string().nullable(),
  city: yup.string().nullable(),
  state: yup
    .string()
    .matches(/^[A-Z]{2}$/)
    .nullable(),
  postalCode: yup
    .string()
    .matches(/^\d{5}([ -]\d{4})?$/, 'Must be a valid zip code')
    .nullable(),
  phone: yup
    .string()
    .matches(/^[0-9]{10}$/, 'Must be exactly 10 digits')
    .nullable(),
  eSourceId: yup.number().integer().notRequired(),
  active: yup.boolean().required(),
});

export default function SiteModal({ site, setShowSiteModal }) {
  const navigate = useNavigate();

  const [appError, setAppError] = useState();
  const isAdmin = useIsAdmin();

  const organizations = useActiveOrganizations(isAdmin);
  const zones = useZones();
  const currentUser = useCurrentUser();

  const handleSiteSaved = (savedSite, redirect) => {
    setShowSiteModal(false);

    if (redirect) {
      if (isAdmin) {
        navigate(`/admin/sites/${savedSite.id}`);
      } else {
        navigate(`/organizations/sites/${savedSite.id}`);
      }
    }
  };

  useEffect(() => {
    setAppError();
  }, []);

  const createSite = useCreateSite({
    onSuccess: (createdSite) => handleSiteSaved(createdSite, true),
    onError: (e) =>
      setAppError({
        name: e.response?.data?.name,
        message: e.response?.data?.message,
      }),
  });

  const updateSite = useUpdateSite({
    onSuccess: (updatedSite) => {
      handleSiteSaved(updatedSite, false);
    },
    onError: (e) =>
      setAppError({
        name: e.response?.data?.name,
        message: e.response?.data?.message,
      }),
  });

  const handleModalOnClose = () => {
    setShowSiteModal(false);
  };

  const handleFormSubmit = (values) => {
    const siteValues = { ...site, ...values };

    if (siteValues.id === null) {
      createSite.mutate(values);
    } else {
      updateSite.mutate(values);
    }
  };

  if (organizations.isFetching || zones.isLoading) {
    return null;
  }

  return (
    <ModalForm
      title="Site"
      onClose={() => handleModalOnClose()}
      onSubmit={handleFormSubmit}
      isSubmitting={createSite.isLoading || updateSite.isLoading}
      initialValues={{
        ...site,
        organizationId: site.organizationId
          ? site.organizationId
          : currentUser.data.organizationId,
      }}
      validationSchema={schema}
      formId="site-modal-form"
      submitButtonText={!site.id ? 'Create' : 'Save'}
      size="lg"
    >
      {appError && (
        <Alert variant="danger" dismissible>
          {appError.message}
        </Alert>
      )}
      <Row className="my-2 gy-2" xs={2}>
        <TextField focus label="Site Nickname" name="name" />
        <TextField label="Research Site Name" name="fullName" />
        <TextField label="Address 1" name="address1" />
        <TextField label="Address 2" name="address2" />
        <TextField label="City" name="city" />
        <SelectField
          label="State"
          name="state"
          defaultDisplay="Select State..."
          options={statesArray()}
          valueAccessor="abbreviation"
          displayAccessor="name"
        />
        <TextField label="ZIP/Postal Code" name="postalCode" />
        <PhoneField label="Main Phone Number" name="phone" />
        {isAdmin && (
          <SelectField
            label="Organization"
            name="organizationId"
            defaultDisplay="Select Organization..."
            options={organizations.data}
            valueAccessor="id"
            displayAccessor="name"
          />
        )}
        <SelectField
          label="IANA Time Zone"
          name="zoneId"
          defaultDisplay="Select Time Zone..."
          options={zones.data}
          valueAccessor="id"
          displayAccessor="name"
        />
        <TextField label="eSource ID" name="eSourceId" />
        <div className="w-100">
          <CheckField name="active" label="Active" className="mt-2" />
        </div>
      </Row>
    </ModalForm>
  );
}

SiteModal.propTypes = {
  site: PropTypes.shape({
    id: PropTypes.number,
    organizationId: PropTypes.number,
  }).isRequired,
  setShowSiteModal: PropTypes.func.isRequired,
};
