import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import axios from '../axios';

export function useUserOrganizations(userId) {
  return useQuery(['userOrganizations', userId], async () => {
    const { data } = await axios.get(`/api/users/${userId}/organizations`);
    return data;
  });
}

const createUserOrganization = async (values) => {
  const { userId, organizationId, roleId } = values;
  const result = await axios.put(
    `/api/users/${userId}/organizations/${organizationId}`,
    { roleId }
  );
  return result.data;
};

export function useCreateUserOrganization(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createUserOrganization,
    onSuccess: (userOrganization) => {
      queryClient.invalidateQueries(['userMetrics']);
      queryClient.invalidateQueries([
        'organization',
        userOrganization.organizationId,
      ]);
      queryClient.invalidateQueries(['user', userOrganization.userId]);
      queryClient.invalidateQueries([
        'userOrganizations',
        userOrganization.userId,
      ]);

      options?.onSuccess?.(userOrganization);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const deleteUserOrganization = async (values) => {
  const { organizationId, userId } = values;
  const result = await axios.delete(
    `/api/users/${userId}/organizations/${organizationId}`
  );
  return result.data;
};

export function useDeleteUserOrganization(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteUserOrganization,
    onSuccess: (userOrganization) => {
      queryClient.invalidateQueries(['userMetrics']);
      queryClient.invalidateQueries([
        'organization',
        userOrganization.organizationId,
      ]);
      queryClient.invalidateQueries(['user', userOrganization.userId]);
      queryClient.invalidateQueries([
        'userOrganizations',
        userOrganization.userId,
      ]);

      options?.onSuccess?.(userOrganization);
    },
    onError: (error) => options?.onError?.(error),
  });
}
