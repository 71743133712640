import PropTypes from 'prop-types';
import React from 'react';
import * as yup from 'yup';
import TextField from 'components/FormikForm/TextField';
import ModalForm from 'components/ModalForm';

const MAX_CHARACTERS = 255;

const schema = yup.object({
  text: yup.string().required().max(MAX_CHARACTERS),
});

export default function StudyStatusReasonModal({
  studyId,
  patientStudyId,
  studyStatusId,
  formFieldLabel,
  onClose,
  onSubmit,
  isSubmitting,
}) {
  const handleCloseModal = () => {
    onClose();
  };

  const handleFormSubmit = (values) => {
    if (!values.text.length) return;

    onSubmit(studyId, studyStatusId, patientStudyId, values.text);
    onClose();
  };

  return (
    <ModalForm
      formId="update-study-status-form"
      title="Update Study Status"
      onClose={handleCloseModal}
      isSubmitting={isSubmitting}
      validationSchema={schema}
      onSubmit={handleFormSubmit}
      initialValues={{ text: '' }}
    >
      <TextField
        name="text"
        label={formFieldLabel}
        placeholder="Enter Note Text"
        as="textarea"
        style={{ height: '70px' }}
        focus
      />
    </ModalForm>
  );
}

StudyStatusReasonModal.defaultProps = {
  patientStudyId: null,
};

StudyStatusReasonModal.propTypes = {
  studyId: PropTypes.number.isRequired,
  patientStudyId: PropTypes.string,
  studyStatusId: PropTypes.number.isRequired,
  formFieldLabel: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};
