import PropTypes from 'prop-types';
import React, { useState } from 'react';

import SiteIndicationsTable from './SiteIndicationsTableReusable';
import ConfirmationDialog from 'components/ConfirmationDialog';
import {
  useDeleteSiteIndication,
  useSiteIndications,
} from 'hooks/siteIndicationHooks';

export default function SiteIndicationList({ site }) {
  const [selectedIndication, setSelectedIndication] = useState(null);

  const indications = useSiteIndications(site.id);

  const deleteSiteIndication = useDeleteSiteIndication({
    onSuccess: () => {
      setSelectedIndication(null);
    },
  });

  if (indications.isLoading) return null;

  const confirmRemoveIndication = (indication) => {
    setSelectedIndication(indication);
  };

  const handleRemove = () => {
    deleteSiteIndication.mutate({
      indicationId: selectedIndication.id,
      siteId: site.id,
    });
  };

  return (
    <>
      <SiteIndicationsTable
        indications={indications.data}
        confirmRemoveIndication={confirmRemoveIndication}
      />

      <ConfirmationDialog
        show={!!selectedIndication}
        onConfirm={handleRemove}
        onCancel={() => setSelectedIndication(null)}
        title="Confirm Remove"
        bodyText={
          <span>
            Are you sure you want to remove the{' '}
            <span className="fw-bold">{selectedIndication?.displayName}</span>{' '}
            indication from the <span className="fw-bold">{site.name}</span>{' '}
            site?
          </span>
        }
      />
    </>
  );
}

SiteIndicationList.propTypes = {
  site: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};
