import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Form, Button, Spinner, FormSelect } from 'react-bootstrap';
import * as yup from 'yup';
import LoadingIndicator from 'components/LoadingIndicator';
import StudyStatuses from 'entities/StudyStatuses';
import { useReferralSources } from 'hooks/referralSourceHooks';
import { useStudyStatuses } from 'hooks/studyStatusHooks';

const MAX_CHARACTERS = 500;

const initialValues = {
  siteId: '',
  studyId: '',
  reason: '',
  referralSourceId: '',
  studyStatusId: '',
  requiresReason: false,
};

const schema = yup.object({
  siteId: yup.number().required(),
  studyId: yup.number().required(),
  studyStatusId: yup.number().required(),
  reason: yup
    .string()
    .max(MAX_CHARACTERS)
    .when('requiresReason', {
      is: (val) => val === true,
      then: () => yup.string().required(),
    }),
  referralSourceId: yup.number().required(),
  requiresReason: yup.boolean().required(),
});

/** @type {React.ForwardRefExoticComponent<any, any>} */
const ChartReviewForm = React.forwardRef(
  ({ handleFormSubmit, handleSubmitClick, sites, disabled, isBusy }, ref) => {
    const referralSources = useReferralSources();
    const studyStatuses = useStudyStatuses({
      names: [
        StudyStatuses.ELIGIBLE,
        StudyStatuses.NOT_ELIGIBLE,
        StudyStatuses.SCREEN_PENDING,
      ],
    });

    const [selectedSiteId, setSelectedSiteId] = useState();

    const siteStudies = useMemo(() => {
      if (!selectedSiteId) return [];

      return sites
        .find((s) => s.id === Number(selectedSiteId))
        .studies.filter((s) => !s.isRegistry && s.active);
    }, [selectedSiteId]);

    if (referralSources.isLoading || studyStatuses.isLoading) {
      return <LoadingIndicator />;
    }

    const handleSiteChange = async (event, setFieldValue) => {
      setSelectedSiteId(event.target.value);
      await setFieldValue('siteId', event.target.value, true);
    };

    const isReasonRequired = (studyStatusId) => {
      const studyStatus = studyStatuses.data.find(
        (s) => s.id === Number(studyStatusId)
      );

      return [
        StudyStatuses.NOT_ELIGIBLE,
        StudyStatuses.SCREEN_PENDING,
      ].includes(studyStatus?.name);
    };

    const handleStudyStatusChange = async (event, setFieldValue) => {
      const { value } = event.target;

      const requiresReason = isReasonRequired(value);

      if (requiresReason) {
        await setFieldValue('requiresReason', true, true);
      } else {
        await setFieldValue('requiresReason', false, true);
      }

      await setFieldValue('studyStatusId', value, true);
    };

    return (
      <Formik
        validationSchema={schema}
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
        }) => (
          <Form className="px-4" noValidate onSubmit={handleSubmit} ref={ref}>
            <Form.Group controlId="formSiteId">
              <Form.Label>Site</Form.Label>
              <FormSelect
                name="siteId"
                value={values.siteId}
                onChange={(e) => handleSiteChange(e, setFieldValue)}
                isValid={touched.siteId && !errors.siteId}
                isInvalid={!!errors.siteId}
                disabled={disabled}
              >
                <option value="">Select Site</option>
                {sites.map((site) => (
                  <option key={site.id} value={site.id}>
                    {site.name}
                  </option>
                ))}
              </FormSelect>
            </Form.Group>
            <Form.Group controlId="formStudyId">
              <Form.Label>Study</Form.Label>
              <FormSelect
                name="studyId"
                value={values.studyId}
                onChange={handleChange}
                isValid={touched.studyId && !errors.studyId}
                isInvalid={!!errors.studyId}
                disabled={disabled}
              >
                <option value="">Select Study</option>
                {siteStudies.map((study) => (
                  <option key={study.id} value={study.id}>
                    {study.name}
                  </option>
                ))}
              </FormSelect>
            </Form.Group>

            <Form.Group controlId="studyStatusId">
              <Form.Label>Study Status</Form.Label>
              <FormSelect
                name="studyStatusId"
                value={values.studyStatusId}
                onChange={(event) =>
                  handleStudyStatusChange(event, setFieldValue)
                }
                isValid={touched.studyStatusId && !errors.studyStatusId}
                isInvalid={!!errors.studyStatusId}
                disabled={disabled}
              >
                <option value="">Select Status</option>
                {studyStatuses.data.map((status) => (
                  <option key={status.id} value={status.id}>
                    {status.name}
                  </option>
                ))}
              </FormSelect>
            </Form.Group>

            <Form.Group controlId="formReason">
              <Form.Label>Reason</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                disabled={!isReasonRequired(values.studyStatusId)}
                style={{ resize: 'none' }}
                placeholder="Refer to inclusion/exclusion criteria"
                type="text"
                name="reason"
                value={values.reason}
                onChange={handleChange}
                isValid={
                  touched.reason &&
                  (!!errors.reason || !isReasonRequired(values.studyStatusId))
                }
                isInvalid={!!errors.reason}
              />
            </Form.Group>
            <Form.Group controlId="formReferralSourceId">
              <Form.Label>Referral Source</Form.Label>
              <FormSelect
                name="referralSourceId"
                value={values.referralSourceId}
                onChange={handleChange}
                isValid={touched.referralSourceId && !errors.referralSourceId}
                isInvalid={!!errors.referralSourceId}
                disabled={disabled}
              >
                <option value="">Select Referral Source</option>
                {referralSources.data.map((source) => (
                  <option key={source.id} value={source.id}>
                    {source.name}
                  </option>
                ))}
              </FormSelect>
            </Form.Group>
            <Button
              variant="outline-primary"
              className="mt-2 float-end"
              onClick={() => handleSubmitClick()}
              disabled={disabled}
            >
              {isBusy ? (
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  className="ms-2"
                />
              ) : (
                'Save'
              )}
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
);

export default ChartReviewForm;

ChartReviewForm.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  handleSubmitClick: PropTypes.func.isRequired,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      studies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          isRegistry: PropTypes.bool,
          active: PropTypes.bool,
        })
      ),
    })
  ).isRequired,
  isBusy: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};
