import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Alert, Button, Form, Modal, Spinner, Row } from 'react-bootstrap';
import * as yup from 'yup';
import TextField from '../../../components/FormikForm/TextField';
import SelectField from 'components/FormikForm/SelectField';

import {
  useCreateConnection,
  useUpdateConnection,
} from 'hooks/connectionHooks';
import { useActiveOrganizations } from 'hooks/organizationHooks';

const schema = yup.object({
  sourceId: yup.string().required(),
  sourceName: yup.string().required(),
  destinationId: yup.string().required(),
  destinationName: yup.string().required(),
  medicalRecordNumberIdentifier: yup.string().required(),
  clinicalSummarySourceId: yup.string().nullable(),
  clinicalSummarySourceName: yup.string().nullable(),
  organizationId: yup.number().integer().required(),
});

export default function ConnectionModal({ connection = { id: 0 }, onClose }) {
  const formRef = useRef();

  const [appError, setAppError] = useState();

  const organizations = useActiveOrganizations(true);

  const handleConnectionSaved = () => {
    onClose();
  };

  const handleOnSubmitButtonClick = () => {
    setAppError();
    formRef.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  };

  const createConnection = useCreateConnection({
    onSuccess: () => handleConnectionSaved(),
    onError: (e) =>
      setAppError({
        name: e.response?.data?.name,
        message: e.response?.data?.message,
      }),
  });

  const updateConnection = useUpdateConnection({
    onSuccess: () => {
      handleConnectionSaved();
    },
    onError: (e) =>
      setAppError({
        name: e.response?.data?.name,
        message: e.response?.data?.message,
      }),
  });

  const handleOnFormSubmit = (values) => {
    const configurationValues = {
      ...values,
    };

    if (connection.id === 0) {
      createConnection.mutate(configurationValues);
    } else {
      updateConnection.mutate({
        connectionId: connection.id,
        configuration: configurationValues,
      });
    }
  };

  const isSubmitting = createConnection.isLoading || updateConnection.isLoading;

  if (organizations.isFetching) {
    return null;
  }

  return (
    <Modal
      show
      size="lg"
      backdrop="static"
      keyboard={false}
      centered
      onHide={onClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Connection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="danger" show={!!appError}>
          {appError?.message}
        </Alert>
        <Formik
          validationSchema={schema}
          onSubmit={handleOnFormSubmit}
          initialValues={{
            ...connection.configuration,
            organizationId: connection.organizationId,
          }}
        >
          {({ handleSubmit }) => (
            <Form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              ref={formRef}
            >
              <Row xs={2} className="gy-3">
                <TextField focus label="Source ID" name="sourceId" />
                <TextField label="Source Name" name="sourceName" />
                <TextField label="Destination ID" name="destinationId" />
                <TextField label="Destination Name" name="destinationName" />
                <TextField
                  label="MRN Source ID"
                  name="medicalRecordNumberIdentifier"
                  controlClassName="w-100"
                />
                <TextField
                  label="Clinical Summary ID"
                  name="clinicalSummarySourceId"
                />
                <TextField
                  label="Clinical Summary Name"
                  name="clinicalSummarySourceName"
                />
                <SelectField
                  label="Organization"
                  name="organizationId"
                  defaultDisplay="Select Organization..."
                  options={organizations.data}
                  valueAccessor="id"
                  displayAccessor="name"
                  disabled={connection.id !== 0}
                />
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleOnSubmitButtonClick}
          disabled={isSubmitting}
        >
          {connection.id === 0 ? 'Create' : 'Save'}
          <Spinner
            animation="border"
            role="status"
            size="sm"
            className={`ms-2 ${isSubmitting ? '' : 'd-none'}`}
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ConnectionModal.propTypes = {
  connection: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
};

ConnectionModal.defaultProps = {
  connection: { id: 0 },
};
