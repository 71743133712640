import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import PropTypes from 'prop-types';
import React from 'react';

const debug = process.env.NODE_ENV === 'development';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: debug ? Infinity : 30000,
    },
  },
});

export default function AppQueryProvider({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      {debug && <ReactQueryDevtools />}
      {children}
    </QueryClientProvider>
  );
}

AppQueryProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
