import { useQuery } from '@tanstack/react-query';
import axios from '../axios';

/**
 *
 * @param {object} filter
 * @param {Array<number>} [filter.ids = []] only include specified ids
 * @param {Array<string>} [filter.names = []] only include specified names
 * @param {boolean} [filter.activeOnly = false] only include active study statuses
 * @returns {object}
 */
export function useStudyStatuses(filter = { activeOnly: true }) {
  return useQuery(['study-statuses', { ...filter }], () =>
    axios
      .get('/api/study-statuses', { params: { ...filter } })
      .then((res) => res.data)
  );
}

export default {
  useStudyStatuses,
};
