import React, { useCallback, useState } from 'react';
import {
  ListGroup,
  Tab,
  Tabs,
  Dropdown,
  DropdownButton,
  Stack,
  FormCheck,
} from 'react-bootstrap';
import { Pencil } from 'react-bootstrap-icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import OrganizationSiteIndicationsTab from './OrganizationSiteIndicationsTab';
import { OrganizationSiteStudiesTab } from './OrganizationSiteStudiesTab';
import OrganizationSiteReasonsTab from './ReasonsTab';
import SettingsTab from './SettingsTab';
import {
  ACTION_BUTTON_TYPES,
  ActionButton,
} from 'components/Button/ActionButton';
import AddButton from 'components/Button/AddButton';
import NavigateBack from 'components/Button/NavigateBack';
import ConfirmationDialog from 'components/ConfirmationDialog';
import TableLayout from 'components/Layouts/TableLayout';
import LoadingIndicator from 'components/LoadingIndicator';
import UserModal from 'components/UserModal';
import UserSearchAdd from 'components/UserSearchAdd';
import { useSite, useUserSites } from 'hooks/siteHooks';
import { useCurrentUser } from 'hooks/userHooks';
import { useDeleteUserSite } from 'hooks/userSiteHooks';
import SiteModal from 'pages/Admin/components/SiteModal';
import { getAddressDisplay } from 'pages/utils/addressUtils';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';
import { formatPhoneDisplay } from 'pages/utils/phoneUtils';

export default function OrganizationSite() {
  const [showSiteModal, setShowSiteModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const params = useParams();
  const siteId = Number(params.siteId);
  const navigate = useNavigate();
  const location = useLocation();
  const [showActiveUsers, setShowActiveUsers] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);

  const currentUser = useCurrentUser();

  const deleteUserSite = useDeleteUserSite({
    onSuccess: () => {
      setShowConfirm(false);
      setSelectedUserId(null);
    },
  });

  const site = useSite(siteId);
  const users = useUserSites(siteId);

  const viewUser = (userId) => {
    navigate(`/organizations/user/${userId}`, {
      state: { backPath: location.pathname },
    });
  };

  const getRowProps = useCallback(
    (row) => ({
      className: 'hoverable-row',
      onClick: () => viewUser(row.original.id),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const renderDeletebutton = (userId) => (
    <ActionButton
      action={ACTION_BUTTON_TYPES.DELETE}
      onClick={(e) => {
        setSelectedUserId(userId);
        setShowConfirm(true);
        e.stopPropagation();
      }}
    />
  );

  const USER_COLUMNS = [
    {
      accessorKey: 'fullName',
      header: 'Name',
    },
    {
      accessorKey: 'email',
      header: 'Email',
    },
    {
      accessorKey: 'createdAt',
      header: 'Date Created',
      // eslint-disable-next-line react/prop-types
      cell: ({ getValue }) => formatDateDisplay(getValue()),
      meta: {
        headerProps: {
          className: 'text-center text-nowrap',
        },
        cellProps: {
          className: 'text-center',
        },
      },
    },
    {
      header: 'Actions',
      meta: {
        headerProps: {
          className: 'col-1 text-center',
        },
        cellProps: {
          className: 'text-center',
        },
      },
      cell: ({ row }) => renderDeletebutton(row.original.id),
    },
  ];

  if (site.isLoading || users.isLoading) return <LoadingIndicator />;

  const { name, fullName, organization, zone, phone, createdAt } = site.data;

  return (
    <Stack gap={2}>
      {currentUser.data && (
        <NavigateBack
          onClick={() =>
            navigate(`/organizations/${currentUser.data.organizationId}`)
          }
          text="Back to My Organization"
        />
      )}
      <Stack direction="horizontal" className="justify-content-between">
        <h2 className="display-2 text-info fw-bold">{name}</h2>

        <DropdownButton title="Actions">
          <Dropdown.Item onClick={() => setShowSiteModal(true)}>
            <Pencil /> Edit
          </Dropdown.Item>
        </DropdownButton>
      </Stack>

      <ListGroup>
        <ListGroup.Item>
          <span className="fw-bold">Organization:</span> &nbsp;
          {organization?.name}
        </ListGroup.Item>
        <ListGroup.Item>
          <span className="fw-bold">Research Site Name:</span> &nbsp;
          {fullName}
        </ListGroup.Item>
        <ListGroup.Item>
          <span className="fw-bold">Address:</span> &nbsp;
          {getAddressDisplay(site.data)}
        </ListGroup.Item>
        <ListGroup.Item>
          <span className="fw-bold">Main Phone Number:</span> &nbsp;
          {formatPhoneDisplay(phone)}
        </ListGroup.Item>
        <ListGroup.Item>
          <span className="fw-bold">IANA Zone Name:</span> &nbsp;
          {zone?.name}
        </ListGroup.Item>
        <ListGroup.Item>
          <span className="fw-bold">Date Created:</span> &nbsp;
          {formatDateDisplay(createdAt)}
        </ListGroup.Item>
      </ListGroup>

      <div className="mb-4">
        <Tabs transition>
          <Tab eventKey="settings" title="Settings">
            <SettingsTab siteId={siteId} />
          </Tab>
          <Tab eventKey="users" title="Users">
            <Stack gap={3}>
              <Stack direction="horizontal" className="justify-content-between">
                <div className="w-100">
                  <UserSearchAdd
                    siteId={site.data.id}
                    organizationId={site.data.organization.id}
                  />
                </div>
                <AddButton label="User" onClick={() => setSelectedUserId(0)} />
              </Stack>

              <Stack direction="horizontal">
                <FormCheck
                  id="users-active"
                  type="radio"
                  name="users"
                  label="Active"
                  checked={showActiveUsers}
                  onChange={() => setShowActiveUsers(true)}
                />

                <FormCheck
                  id="users-inactive"
                  className="ms-3"
                  type="radio"
                  name="users"
                  label="Inactive"
                  checked={!showActiveUsers}
                  onChange={() => setShowActiveUsers(false)}
                />
              </Stack>
              <TableLayout
                columns={USER_COLUMNS}
                data={users.data.filter(
                  (user) => user.active === showActiveUsers
                )}
                getRowProps={getRowProps}
              />
            </Stack>
          </Tab>
          <Tab eventKey="studies" title="Studies">
            <OrganizationSiteStudiesTab siteId={siteId} />
          </Tab>

          <Tab eventKey="reason" title="Reason">
            <OrganizationSiteReasonsTab siteId={siteId} />
          </Tab>
          <Tab eventKey="indications" title="Indications">
            <OrganizationSiteIndicationsTab site={site.data} />
          </Tab>
        </Tabs>
      </div>
      {showSiteModal && (
        <SiteModal site={site.data} setShowSiteModal={setShowSiteModal} />
      )}
      {Number.isInteger(selectedUserId) && !showConfirm && (
        <UserModal
          userId={selectedUserId}
          onClose={() => setSelectedUserId(null)}
        />
      )}
      <ConfirmationDialog
        show={showConfirm}
        title="Delete User's Site"
        bodyText="Are you sure you want to remove this user from this site?"
        confirmText="Delete"
        onConfirm={() =>
          deleteUserSite.mutate({
            userId: selectedUserId,
            siteId: site.data.id,
          })
        }
        onCancel={() => {
          setShowConfirm(false);
          setSelectedUserId(null);
        }}
      />
    </Stack>
  );
}
