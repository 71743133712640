import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import TableLayout from 'components/Layouts/TableLayout';

export default function UserSitesTable({ user, confirmRemove }) {
  const data = useMemo(() => user.sites, [user]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Site',
      },
      {
        id: 'actions',
        header: '',
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { className: 'text-center', style: { width: '60px' } },
        },
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row }) => (
          <Button
            size="sm"
            variant="dark"
            className="btn-lg btn-block"
            onClick={() => confirmRemove(row.original)}
          >
            <Trash />
          </Button>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <TableLayout columns={columns} data={data} />;
}

UserSitesTable.propTypes = {
  user: PropTypes.shape({
    sites: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  confirmRemove: PropTypes.func.isRequired,
};
