import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function CalendarEvent({ event }) {
  const location = useLocation();
  return (
    <div className="small">
      {event.patientDetailUrl ? (
        <Link
          className="mt-1 fw-bold text-uppercase"
          to={event.patientDetailUrl}
          state={{ backPath: location.pathname + location.search }}
        >
          {event.fullName}
        </Link>
      ) : (
        <div className="text-dark text-uppercase fw-bold">{event.fullName}</div>
      )}
      <div className="mt-1 text-dark">{event.reason}</div>
    </div>
  );
}

CalendarEvent.propTypes = {
  event: PropTypes.shape({
    patientDetailUrl: PropTypes.string,
    fullName: PropTypes.string,
    reason: PropTypes.string,
  }).isRequired,
};
