import React, { useCallback, useState } from 'react';
import { Form, InputGroup, Button, Stack, FormCheck } from 'react-bootstrap';
import { Dash, PlusCircle } from 'react-bootstrap-icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TableLayout from 'components/Layouts/TableLayout';
import UserModal from 'components/UserModal';
import { useOrganizationUsers } from 'hooks/organizationHooks';
import { usePagination } from 'hooks/tableHooks';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

const COLUMNS = [
  {
    accessorKey: 'user.fullName',
    header: 'Name',
  },
  {
    accessorKey: 'user.email',
    header: 'Email',
  },
  {
    accessorFn: (row) => row.role?.name,
    cell: (props) => props.getValue() ?? <Dash />,
    header: 'Role',
  },
  {
    accessorKey: 'user.createdAt',
    header: 'Date Created',
    cell: (props) => formatDateDisplay(props.getValue()),
    meta: {
      headerProps: {
        className: 'text-center text-nowrap',
      },
      cellProps: {
        className: 'text-center',
      },
    },
  },
];

const paginationOptions = {
  pageIndex: 0,
  pageSize: 25,
};

export default function UsersTab() {
  const [query, setQuery] = useState('');
  const [show, setShow] = useState(false);
  const [showActiveUsers, setShowActiveUsers] = useState(true);

  const [pagination, setPagination] = usePagination(paginationOptions);

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const orgId = Number(params.organizationId);

  const organizationUsers = useOrganizationUsers(
    orgId,
    query,
    pagination.pageIndex,
    pagination.pageSize,
    showActiveUsers
  );

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  const viewUser = (userId) => {
    navigate(`/organizations/user/${userId}`, {
      state: { backPath: `${location.pathname}?selectedTab=users` },
    });
  };

  const handleShowActiveUsers = (value) => {
    setShowActiveUsers(value);
    setPagination(paginationOptions);
    setQuery('');
  };

  const getRowProps = useCallback(
    (row) => ({
      className: 'hoverable-row',
      onClick: () => viewUser(row.original.user.id),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <Stack className="my-2" gap={3}>
        <Stack direction="horizontal">
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => setShow(true)}
          >
            <PlusCircle /> New User
          </Button>

          <Form className="ms-auto">
            <InputGroup className="mb-0 h-100 flex-nowrap justify-content-end">
              <Form.Control
                placeholder="Search by name or email"
                value={query || ''}
                onChange={handleSearch}
                style={{ minWidth: '200px' }}
              />
            </InputGroup>
          </Form>
        </Stack>

        <Stack direction="horizontal">
          <FormCheck
            id="users-active"
            type="radio"
            name="users"
            label="Active"
            checked={showActiveUsers}
            onChange={() => handleShowActiveUsers(true)}
          />

          <FormCheck
            id="users-inactive"
            className="ms-3"
            type="radio"
            name="users"
            label="Inactive"
            checked={!showActiveUsers}
            onChange={() => handleShowActiveUsers(false)}
          />
        </Stack>

        <TableLayout
          columns={COLUMNS}
          data={organizationUsers.data}
          pagination={pagination}
          setPagination={setPagination}
          getRowProps={getRowProps}
          isLoading={organizationUsers.isLoading}
        />
      </Stack>
      {show && <UserModal onClose={() => setShow(false)} />}
    </>
  );
}
