import React from 'react';

import ReasonSection from 'components/Reasons/ReasonSection';
import {
  useCreateCancellationReason,
  useCancellationReasons,
  useUpdateCancellationReason,
} from 'hooks/cancellationReasonHooks';

function CancellationReasonsSection() {
  return (
    <ReasonSection
      useOrgReasons={useCancellationReasons}
      useCreateReason={useCreateCancellationReason}
      useUpdateReason={useUpdateCancellationReason}
      reasonType="Cancellation Reason"
    />
  );
}

export default CancellationReasonsSection;
