import PropTypes from 'prop-types';
import React from 'react';

import ReasonSection from 'components/Reasons/ReasonSection';
import {
  useCreateDocumentType,
  useDocumentTypes,
  useUpdateDocumentType,
} from 'hooks/documentTypeHooks';

function DocumentTypesSection({ orgId }) {
  return (
    <ReasonSection
      useOrgReasons={useDocumentTypes}
      useCreateReason={useCreateDocumentType}
      useUpdateReason={useUpdateDocumentType}
      orgId={orgId}
      reasonType="Document Type"
      globalOnly
    />
  );
}

DocumentTypesSection.propTypes = {
  orgId: PropTypes.number.isRequired,
};

export default DocumentTypesSection;
