import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

export function usePatientInteractions(patientId, source, sort) {
  const debug = process.env.NODE_ENV === 'development';

  return useQuery(
    patientId && ['patientInteractions', { patientId, source, sort }],
    () =>
      axios
        .get(`/api/patients/${patientId}/interactions`, {
          params: { source, sort },
        })
        .then((res) => res.data),
    { refetchInterval: debug ? Infinity : 10000 }
  );
}

const createPatientInteraction = async (values) => {
  const { patientId, ...interaction } = values;
  const response = await axios.post(
    `/api/patients/${patientId}/interactions`,
    interaction
  );

  return response.data;
};

export function useCreatePatientInteraction(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPatientInteraction,
    onSuccess: (interaction) => {
      queryClient.invalidateQueries(['patients']);
      queryClient.invalidateQueries([
        'patientInteractions',
        { patientId: interaction.patientId },
      ]);

      options?.onSuccess?.(interaction);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const updatePatientInteraction = async (values) => {
  const result = await axios.put(
    `/api/patients/${values.patientId}/interactions/${values.id}`,
    values
  );

  return result.data;
};

export function useUpdatePatientInteraction(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updatePatientInteraction,
    onSuccess: (interaction) => {
      queryClient.invalidateQueries(['patients']);
      queryClient.invalidateQueries([
        'patientInteractions',
        { patientId: interaction.patientId },
      ]);

      options?.onSuccess?.(interaction);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const deletePatientInteraction = async (values) => {
  await axios.delete(
    `/api/patients/${values.patientId}/interactions/${values.id}`
  );

  return { patientId: values.patientId };
};

export function useDeletePatientInteraction(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePatientInteraction,
    onSuccess: ({ patientId }) => {
      queryClient.invalidateQueries(['patients']);
      queryClient.invalidateQueries(['patientInteractions', { patientId }]);

      options?.onSuccess?.({ patientId });
    },
    onError: (error) => options?.onError?.(error),
  });
}
