import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import './StatusGroupMetrics.scss';

export default function StatusGroupCard({
  name,
  count,
  clickable,
  active,
  onClick,
}) {
  const cardId = `${name.replace(' ', '-').toLowerCase()}-metrics`;
  let className = 'text-nowrap border-start rounded-1 border-10';

  if (clickable) {
    className += ' clickable';

    if (active) {
      className += ' active';
    }
  }

  return (
    <Card className="text-center">
      <Row className="g-0">
        <Col>
          <Card.Body
            id={cardId}
            className={className}
            onClick={(value) => onClick(value)}
          >
            <h4 className="display-2 mb-0">{name}</h4>
            <h5 className="display-2 fw-normal">{count}</h5>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
}

StatusGroupCard.propTypes = {
  name: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  clickable: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
