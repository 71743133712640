import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import SearchResultsTable from 'components/SearchResultsTable';

function ResultsTab({
  results,
  setPagination,
  pagination,
  setSorting,
  sorting,
}) {
  if (!results?.rows.length) {
    return (
      <Card body className="text-muted fst-italic mb-2">
        No patients found.
      </Card>
    );
  }

  return (
    <SearchResultsTable
      data={results}
      pagination={pagination}
      setPagination={setPagination}
      sorting={sorting}
      setSorting={setSorting}
    />
  );
}

ResultsTab.defaultProps = {
  results: undefined,
};

ResultsTab.propTypes = {
  results: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  setPagination: PropTypes.func.isRequired,
  setSorting: PropTypes.func.isRequired,
  pagination: PropTypes.shape({}).isRequired,
  sorting: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ResultsTab;
