import React from 'react';
import { Button, Form, Stack, Col } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';

const DiagnosesList = ({ diagnoses, onChange, onRemoveObservation }) =>
  Object.entries(diagnoses).map(([key, diagnosis]) => (
    <Stack className="my-3" direction="horizontal" key={key} gap={3}>
      <div className="text-primary">Diagnosis</div>

      <Col xs={2}>
        <Form.Select
          value={diagnosis.isIncluded ? 'is' : 'is not'}
          onChange={(e) => onChange(key, e.target.value === 'is')}
        >
          <option value="is" label="is" />
          <option value="is not" label="is not" />
        </Form.Select>
      </Col>

      <div className="rounded-3 bg-dark text-white ps-2 pe-1 w-100 d-flex align-items-center">
        {diagnosis.code} - {diagnosis.name}
        <Button
          variant=""
          className="ms-auto"
          onClick={() => onRemoveObservation(key)}
        >
          <X color="white" size={20} />
        </Button>
      </div>
    </Stack>
  ));

export default DiagnosesList;
