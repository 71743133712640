import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

import TableLayout from 'components/Layouts/TableLayout';

export default function SiteStudiesTable({ studies, confirmRemoveStudy }) {
  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'sponsor',
        header: 'Sponsor Name',
      },
      {
        accessorKey: 'protocol',
        header: 'Protocol Number',
      },
      {
        accessorKey: 'marketName',
        header: 'Market Name',
      },
      {
        accessorKey: 'indication',
        header: 'Indication',
        cell: ({ row }) => row.original.indication?.name ?? null,
      },
      {
        accessorKey: 'isRegistry',
        header: 'Registry',
        cell: ({ row }) => (row.original.isRegistry ? 'Yes' : 'No'),
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { className: 'text-center' },
        },
      },
      {
        header: 'Actions',
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { style: { width: '60px' }, className: 'text-center' },
        },
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row }) => (
          <Button
            size="sm"
            variant="dark"
            className="btn-lg btn-block"
            onClick={() => confirmRemoveStudy(row.original)}
          >
            <Trash />
          </Button>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <TableLayout columns={columns} data={studies} />;
}

SiteStudiesTable.propTypes = {
  studies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,

  confirmRemoveStudy: PropTypes.func.isRequired,
};
