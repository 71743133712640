import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

export function useCancellationReasons(activeOnly = true, globalOnly = false) {
  return useQuery(
    ['cancellation-reasons', activeOnly, globalOnly],
    async () => {
      const { data } = await axios.get(`/api/cancellation-reasons`, {
        params: { activeOnly, globalOnly },
      });
      return data;
    },
    { placeholderData: [] }
  );
}

const createCancellationReason = async (cancellationReason) => {
  const result = await axios.post(
    `/api/cancellation-reasons`,
    cancellationReason
  );
  return result.data;
};

export function useCreateCancellationReason(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createCancellationReason,
    onSuccess: (cancellationReason) => {
      queryClient.invalidateQueries(['cancellation-reasons']);

      options?.onSuccess?.(cancellationReason);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const updateCancellationReason = async (cancellationReason) => {
  const result = await axios.put(
    `/api/cancellation-reasons/${cancellationReason.id}`,
    cancellationReason
  );
  return result.data;
};

export function useUpdateCancellationReason(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateCancellationReason,
    onSuccess: (cancellationReason) => {
      queryClient.invalidateQueries(['cancellation-reasons']);

      options?.onSuccess?.(cancellationReason);
    },
    onError: (error) => options?.onError?.(error),
  });
}
