/* eslint-disable react/jsx-props-no-spreading */
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Table, Pagination, Card, FormControl } from 'react-bootstrap';
import { SortUp, SortDown } from 'react-bootstrap-icons';
import LoadingIndicator from 'components/LoadingIndicator';

export default function TableLayout({
  columns,
  data = [],
  pagination,
  setPagination,
  sorting,
  setSorting,
  getRowProps,
  serverSidePagination = true,
  serverSideSorting = true,
  isLoading = false,
  noResultsText = 'No results found.',
}) {
  const paginationEnabled = !!pagination;
  const sortingEnabled = !!sorting;
  const rows = Array.isArray(data) ? data : data?.rows ?? [];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const table = useReactTable({
    data: rows,
    columns,
    state: {
      pagination,
      sorting,
    },
    manualPagination: serverSidePagination,
    onPaginationChange: setPagination,
    enableSorting: sortingEnabled,
    manualSorting: serverSideSorting,
    onSortingChange: setSorting,
    enableMultiSort: false,
    enableSortingRemoval: false,
    sortDescFirst: false,
    pageCount: data?.pageCount ?? -1,
    getCoreRowModel: getCoreRowModel(),
  });

  const [displayedPageIndex, setDisplayedPageIndex] = useState(
    pagination ? pagination.pageIndex + 1 : 0
  );

  useEffect(() => {
    if (pagination?.pageIndex !== undefined) {
      setDisplayedPageIndex(pagination.pageIndex + 1);
    }
  }, [pagination?.pageIndex]);

  if (isLoading) return <LoadingIndicator />;

  if (!rows.length) {
    return <EmptyTableMessage message={noResultsText} />;
  }

  const getTableHeader = (header) => {
    const { className, ...rest } =
      header.column.columnDef?.meta?.headerProps ?? {};

    const headerProps = header.column.getCanSort()
      ? {
          className: `cursor-pointer user-select-none ${className}`,
          onClick: header.column.getToggleSortingHandler(),
          onKeyDown: () => {},
          role: 'button',
          tabIndex: 0,
        }
      : {
          className: `pe-none user-select-none ${className}`,
        };

    return (
      <th key={header.id} colSpan={header.colSpan} {...rest} {...headerProps}>
        {flexRender(header.column.columnDef.header, header.getContext())}
        {
          {
            asc: <SortUp className="ms-2" />,
            desc: <SortDown className="ms-2" />,
          }[header.column.getIsSorted() ?? null]
        }
      </th>
    );
  };

  const getTableHeaderRow = (headerGroup) => (
    <tr key={headerGroup.id}>
      {headerGroup.headers.map((header) => getTableHeader(header))}
    </tr>
  );

  return (
    <>
      <Table bordered hover size="sm">
        <thead>{table.getHeaderGroups().map(getTableHeaderRow)}</thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} {...getRowProps?.(row)}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="bg-inherit"
                  {...cell.column.columnDef.meta?.cellProps}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      {paginationEnabled && (
        <div className="d-flex justify-content-end">
          <div className="me-2">
            <FormControl
              className="shadow-none me-1"
              type="number"
              value={displayedPageIndex}
              onChange={(e) => setDisplayedPageIndex(e.target.value)}
              style={{ width: '50px' }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  const page = Number(displayedPageIndex)
                    ? Number(displayedPageIndex) - 1
                    : 0;
                  table.setPageIndex(page);
                }
              }}
            />
          </div>
          <div className="me-2 pt-2">of {table.getPageCount()}</div>
          <Pagination>
            <Pagination.First
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            />
            <Pagination.Prev
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            />
            <Pagination.Next
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            />
            <Pagination.Last
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            />
          </Pagination>
        </div>
      )}
    </>
  );
}

TableLayout.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.oneOfType([
    PropTypes.shape({
      rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }),
    PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  ]),
  pagination: PropTypes.shape({
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
  }),
  setPagination: PropTypes.func,
  sorting: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, desc: PropTypes.bool })
  ),
  setSorting: PropTypes.func,
  getRowProps: PropTypes.func,
  serverSidePagination: PropTypes.bool,
  serverSideSorting: PropTypes.bool,
  noResultsText: PropTypes.string,
  isLoading: PropTypes.bool,
};

TableLayout.defaultProps = {
  data: [],
  pagination: undefined,
  setPagination: undefined,
  sorting: undefined,
  setSorting: undefined,
  getRowProps: undefined,
  serverSidePagination: true,
  serverSideSorting: true,
  noResultsText: 'No results found.',
  isLoading: false,
};

export function EmptyTableMessage({ message }) {
  return (
    <Card className="text-muted fst-italic mb-2">
      <Card.Body>{message}</Card.Body>
    </Card>
  );
}

EmptyTableMessage.propTypes = {
  message: PropTypes.string.isRequired,
};
