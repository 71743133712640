import PropTypes from 'prop-types';
import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';

const SettingsPopover = React.forwardRef(({ header, body, ...props }, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Popover {...props} ref={ref} className="patient-note">
    {header && <Popover.Header>Header</Popover.Header>}
    <Popover.Body>{body}</Popover.Body>
  </Popover>
));

SettingsPopover.defaultProps = {
  header: undefined,
};

SettingsPopover.propTypes = {
  header: PropTypes.string,
  body: PropTypes.string.isRequired,
};

// eslint-disable-next-line import/prefer-default-export
export function InfoIconWithPopover({
  header,
  body,
  className,
  placement = 'right',
}) {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={<SettingsPopover header={header} body={body} />}
    >
      <InfoCircle size={12} className={className} />
    </OverlayTrigger>
  );
}

InfoIconWithPopover.defaultProps = {
  header: undefined,
  placement: 'right',
  className: undefined,
};

InfoIconWithPopover.propTypes = {
  header: PropTypes.string,
  body: PropTypes.string.isRequired,
  placement: PropTypes.string,
  className: PropTypes.string,
};
