import { Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Form, Modal, Spinner, Stack } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import * as yup from 'yup';
import { FamilyHistoryContext } from './FamilyHistoryProvider';
import axios from '../../../../../../axios';
import CheckField from 'components/FormikForm/CheckField';
import SelectField from 'components/FormikForm/SelectField';
import TextField from 'components/FormikForm/TextField';
import LoadingIndicator from 'components/LoadingIndicator';
import { useFamilyMembers } from 'hooks/familyMemberHooks';

const schema = yup.object({
  familyHistoryUnknown: yup.boolean().required(),
  familyMemberId: yup.number().when('familyHistoryUnknown', {
    is: false,
    then: () => yup.number().integer().required(),
    otherwise: () => yup.number().integer().nullable(),
  }),
  diagnosisCodeId: yup.number().when('familyHistoryUnknown', {
    is: false,
    then: () => yup.number().integer().required(),
    otherwise: () => yup.number().integer().nullable(),
  }),
  age: yup.number().when('familyHistoryUnknown', {
    is: false,
    then: () => yup.number().integer().max(120).nullable(),
    otherwise: () => yup.number().integer().nullable(),
  }),
});

export default function FamilyHistoryModal() {
  const formRef = useRef();
  const [codeOptions, setCodeOptions] = useState([]);
  const [selectedCode, setSelectedCode] = useState(null);

  const familyMembers = useFamilyMembers();

  const { error, diagnosis, showModal, closeModal, saveDiagnosis, isBusy } =
    useContext(FamilyHistoryContext);

  useEffect(() => {
    if (showModal) {
      setCodeOptions([]);
      setSelectedCode(diagnosis.diagnosisCode || null);
    } else {
      setSelectedCode(null);
    }
  }, [diagnosis.diagnosisCode, showModal]);

  const handleSearch = (substring) => {
    axios.get('/api/diagnoses/codes', { params: { substring } }).then((res) => {
      setCodeOptions(res.data);
    });
  };

  const handleFormSubmit = (values) => {
    saveDiagnosis({
      ...diagnosis,
      ...values,
    });
  };

  const handleSave = () => {
    formRef.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  };

  if (familyMembers.isLoading) return <LoadingIndicator />;

  return (
    <Modal
      show={showModal}
      backdrop="static"
      keyboard={false}
      centered
      onHide={closeModal}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Family History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="danger" show={!!error}>
          {error}
        </Alert>

        <Formik
          validationSchema={schema}
          onSubmit={handleFormSubmit}
          initialValues={{
            id: diagnosis.id,
            familyHistoryUnknown: diagnosis.familyHistoryUnknown,
            age: diagnosis.age,
            familyMemberId: diagnosis.familyMemberId,
            diagnosisCodeId: diagnosis.diagnosisCodeId || undefined,
          }}
        >
          {({
            handleSubmit,
            setFieldValue,
            touched,
            errors,
            values,
            setValues,
          }) => (
            <Form noValidate onSubmit={handleSubmit} ref={formRef}>
              <Stack gap={3}>
                <CheckField
                  name="familyHistoryUnknown"
                  label="Family History Unknown"
                  disabled={!!values.id}
                  autoFocus
                  additionalOnChange={() => {
                    setValues({
                      id: diagnosis.id,
                      age: null,
                      familyMemberId: null,
                      diagnosisCodeId: null,
                    });
                    setSelectedCode(null);
                  }}
                />

                <SelectField
                  label="Family Member"
                  name="familyMemberId"
                  defaultDisplay="Select family member..."
                  options={familyMembers.data}
                  valueAccessor="id"
                  displayAccessor="name"
                  disabled={values.familyHistoryUnknown}
                />

                <Form.Group controlId="formDiagnosisCode">
                  <Form.Label>Diagnosis Code</Form.Label>
                  <AsyncTypeahead
                    disabled={values.familyHistoryUnknown}
                    id="diagnosisCodeId"
                    name="diagnosisCodeId"
                    filterBy={() => true}
                    labelKey="name"
                    isLoading={false}
                    minLength={3}
                    onSearch={handleSearch}
                    options={codeOptions}
                    selected={selectedCode ? [selectedCode] : []}
                    placeholder="Start typing code or description..."
                    onChange={(code) => {
                      if (code.length === 0) {
                        setFieldValue('diagnosisCodeId', null);
                        setSelectedCode(null);
                      } else {
                        setFieldValue('diagnosisCodeId', code[0].id);
                        setSelectedCode(code[0]);
                      }
                    }}
                    isValid={touched.diagnosisCodeId && !errors.diagnosisCodeId}
                    isInvalid={!!errors.diagnosisCodeId}
                    renderMenuItemChildren={(option) => (
                      <section className="d-flex flex-row">
                        <span className="text-primary me-2 w-10">
                          {option.code}
                        </span>
                        <span className="text-truncate w-90">
                          {option.name}
                        </span>
                      </section>
                    )}
                  />
                </Form.Group>

                <TextField
                  disabled={values.familyHistoryUnknown}
                  label="Age"
                  name="age"
                  type="number"
                  placeholder="Enter age of family member at time of condition diagnosis..."
                />
              </Stack>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={isBusy}>
          Save
          <Spinner
            animation="border"
            role="status"
            size="sm"
            className={`ms-2 ${isBusy ? '' : 'd-none'}`}
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
