import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, FormSelect, Row, Button } from 'react-bootstrap';
import { useAssignableSites } from 'hooks/userHooks';
import { useCreateUserSite } from 'hooks/userSiteHooks';

export default function SiteAdd({ user }) {
  const [selectedSiteId, setSelectedSiteId] = useState();

  const assignableSites = useAssignableSites(user.id);

  const createUserSite = useCreateUserSite({
    onSuccess: () => {
      setSelectedSiteId();
    },
  });

  if (assignableSites.isLoading) return null;

  const handleClick = () => {
    createUserSite.mutate({ userId: user.id, siteId: selectedSiteId });
  };

  const filteredSites = assignableSites.data.filter(
    (as) => !user.sites.map((site) => site.id).includes(as.siteId)
  );

  const getSiteOptions = () =>
    filteredSites.map((site) => (
      <option key={`option-${site.siteId}`} value={site.siteId}>
        {site.siteName}
      </option>
    ));

  return (
    <Row>
      <Col xs={6}>
        <FormSelect
          value={selectedSiteId}
          onChange={(e) => setSelectedSiteId(e.target.value)}
        >
          <option value="">Select Site</option>
          {getSiteOptions()}
        </FormSelect>
      </Col>
      <Col xs="1">
        <Button
          variant="primary"
          className="ms-n3"
          disabled={!selectedSiteId || createUserSite.isLoading}
          onClick={handleClick}
        >
          Add
        </Button>
      </Col>
    </Row>
  );
}

SiteAdd.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sites: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      })
    ),
  }).isRequired,
};
