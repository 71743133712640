import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Row, Col, Dropdown, DropdownButton, ListGroup } from 'react-bootstrap';
import { Pencil } from 'react-bootstrap-icons';
import UserModal from 'components/UserModal';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

function UserInfo({ user }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <h2 className="fs-3 fw-normal mb-0">{user.fullName}</h2>
        </div>
        <DropdownButton title="Actions">
          <Dropdown.Item onClick={() => setShowModal(true)}>
            <Pencil /> Edit
          </Dropdown.Item>
        </DropdownButton>
      </div>
      <Row>
        <Col>
          <ListGroup>
            <ListGroup.Item>
              <span className="fw-bold">Username: </span>
              {user.userName}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="fw-bold">Email: </span>
              {user.email}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="fw-bold">Active: </span>
              {user.active ? 'Yes' : 'No'}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="fw-bold">Date Created: </span>
              {formatDateDisplay(user.createdAt)}
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
      {showModal && (
        <UserModal userId={user.id} onClose={() => setShowModal(false)} />
      )}
    </>
  );
}

UserInfo.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    fullName: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }).isRequired,
};

export default UserInfo;
