import PropTypes from 'prop-types';
import React, { createContext, useState, useMemo, useCallback } from 'react';
import {
  useCreatePatientNote,
  useUpdatePatientNote,
} from 'hooks/patientNoteHooks';

const noteTemplate = {
  id: 0,
  patientId: '',
  text: '',
  source: 'Note',
  type: '',
  durationMin: 0,
};

const NoteContext = createContext();

function NoteProvider({ patientId, children }) {
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [note, setNote] = useState(noteTemplate);

  const mutationOptions = {
    onSuccess: () => setShowModal(false),
    onError: (e) => setError(e.response?.data?.message),
  };

  const createPatientNote = useCreatePatientNote(mutationOptions);
  const updatePatientNote = useUpdatePatientNote(mutationOptions);

  const closeModal = () => {
    setShowModal(false);
  };

  const addNote = () => {
    setNote(noteTemplate);
    setError('');
    setShowModal(true);
  };

  const editNote = (targetNote) => {
    setNote(targetNote);
    setError('');
    setShowModal(true);
  };

  const saveNote = useCallback(
    (values) => {
      setError('');

      if (values.id === 0) {
        createPatientNote.mutate({ patientId, text: values.text });
      } else {
        updatePatientNote.mutate({
          patientId,
          noteId: values.id,
          text: values.text,
        });
      }
    },
    [createPatientNote, patientId, updatePatientNote]
  );

  const value = useMemo(
    () => ({
      note,
      error,
      showModal,
      closeModal,
      addNote,
      editNote,
      saveNote,
      isBusy: createPatientNote.isLoading || updatePatientNote.isLoading,
    }),
    [
      createPatientNote.isLoading,
      error,
      note,
      saveNote,
      showModal,
      updatePatientNote.isLoading,
    ]
  );

  return <NoteContext.Provider value={value}>{children}</NoteContext.Provider>;
}

NoteProvider.propTypes = {
  patientId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default NoteProvider;
export { NoteProvider, NoteContext };
