import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useRef, useState, useEffect } from 'react';

import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';
import Stack from 'react-bootstrap/Stack';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import CheckField from 'components/FormikForm/CheckField';
import SelectField from 'components/FormikForm/SelectField';
import TextField from 'components/FormikForm/TextField';
import { useEncounterTypes } from 'hooks/encounterHooks';
import { useIndications } from 'hooks/indicationHooks';
import { useCreateStudy, useUpdateStudy } from 'hooks/studyHooks';

const initialValues = {
  sponsor: '',
  protocol: '',
  name: '',
  marketName: '',
  indicationId: '',
  encounterTypeId: '',
  isRegistry: false,
  active: false,
  isObservational: false,
};

const schema = yup.object({
  name: yup.string().required(),
  indicationId: yup.number().integer().notRequired(),
  encounterTypeId: yup.number().integer().notRequired(),
  isRegistry: yup.boolean().required(),
  isObservational: yup.boolean().required(),
  sponsor: yup.string().when('isRegistry', {
    is: false,
    then: () => yup.string().required(),
    otherwise: () => yup.string().notRequired(),
  }),
  protocol: yup.string().when('isRegistry', {
    is: false,
    then: () => yup.string().required(),
    otherwise: () => yup.string().notRequired(),
  }),
  marketName: yup.string().when('isRegistry', {
    is: false,
    then: () => yup.string().required(),
    otherwise: () => yup.string().notRequired(),
  }),
});

export default function StudyModal({ context }) {
  const formRef = useRef(null);
  const navigate = useNavigate();

  const { study, showStudyModal, setShowStudyModal } = useContext(context);
  const indications = useIndications();
  const encounterTypes = useEncounterTypes();
  const [appError, setAppError] = useState(null);

  const handleStudySaved = (savedStudy, redirect) => {
    setShowStudyModal(false);

    if (redirect) {
      navigate(`/admin/studies/${savedStudy.id}`);
    }
  };

  useEffect(() => {
    setAppError(null); // clear any errors when modal is opened
  }, [showStudyModal]);

  const createStudy = useCreateStudy({
    onSuccess: (createdStudy) => handleStudySaved(createdStudy, true),
    onError: (e) =>
      setAppError({
        name: e.response?.data?.name,
        message: e.response?.data?.message,
      }),
  });

  const updateStudy = useUpdateStudy({
    onSuccess: (updatedStudy) => {
      handleStudySaved(updatedStudy, false);
    },
    onError: (e) =>
      setAppError({
        name: e.response?.data?.name,
        message: e.response?.data?.message,
      }),
  });

  const handleSave = () => {
    setAppError();
    formRef.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  };

  const handleModalClosed = () => {
    setShowStudyModal(false);
  };

  const handleFormSubmit = (values) => {
    const studyValues = { ...study, ...values };

    if (Number(studyValues.indicationId) === 0) studyValues.indicationId = null;

    if (Number(studyValues.encounterTypeId) === 0)
      studyValues.encounterTypeId = null;

    if (studyValues.id === 0) {
      createStudy.mutate(studyValues);
    } else {
      updateStudy.mutate(studyValues);
    }
  };

  if (indications.isLoading || encounterTypes.isLoading) return null;

  return (
    <Modal
      show={showStudyModal}
      backdrop="static"
      keyboard={false}
      centered
      onHide={handleModalClosed}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{study.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="danger" show={!!appError}>
          {appError?.message}
        </Alert>
        <Formik
          validationSchema={schema}
          onSubmit={handleFormSubmit}
          initialValues={{ ...initialValues, ...study }}
        >
          {({ handleSubmit }) => (
            <Form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              ref={formRef}
            >
              <Stack gap={3}>
                <TextField label="Sponsor" name="sponsor" focus />
                <TextField label="Protocol Number" name="protocol" />
                <TextField label="Study Name" name="name" />
                <TextField label="Market Name" name="marketName" />
                <SelectField
                  label="Indication"
                  name="indicationId"
                  defaultDisplay="Select Indication..."
                  options={indications.data}
                  valueAccessor="id"
                  displayAccessor="displayName"
                />
                <SelectField
                  label="Encounter Type"
                  name="encounterTypeId"
                  defaultDisplay="Select Encounter Type..."
                  options={encounterTypes.data}
                  valueAccessor="id"
                  displayAccessor="name"
                />
                <Stack gap={4} direction="horizontal">
                  <CheckField label="Active" type="switch" name="active" />
                  <CheckField
                    label="Is Registry"
                    type="switch"
                    name="isRegistry"
                  />
                  <CheckField
                    label="Is Observational"
                    type="switch"
                    name="isObservational"
                  />
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClosed}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={createStudy.isLoading || updateStudy.isLoading}
        >
          {study.id === 0 ? 'Create' : 'Save'}
          <Spinner
            animation="border"
            role="status"
            size="sm"
            className={`ms-2 ${
              createStudy.isLoading || updateStudy.isLoading ? '' : 'd-none'
            }`}
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

StudyModal.propTypes = {
  context: PropTypes.shape({}).isRequired,
};
