import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

// eslint-disable-next-line import/prefer-default-export
export function useIndications(active = true) {
  return useQuery(['indications', active], () =>
    axios
      .get('/api/indications', { params: { active } })
      .then((res) => res.data)
  );
}

const createIndicaton = async (indication) => {
  const result = await axios.post(`/api/admin/indications`, indication);
  return result.data;
};

export function useCreateindication(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createIndicaton,
    onSuccess: (indication) => {
      queryClient.invalidateQueries(['indications']);

      options?.onSuccess?.(indication);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const updateIndication = async (indication) => {
  const result = await axios.put(
    `/api/admin/indications/${indication.id}`,
    indication
  );
  return result.data;
};

export function useUpdateindication(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateIndication,
    onSuccess: (indication) => {
      queryClient.invalidateQueries(['indications']);

      options?.onSuccess?.(indication);
    },
    onError: (error) => options?.onError?.(error),
  });
}
