import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

export default function CollapsibleSection({ title, children, isLast }) {
  return (
    <>
      <Row className="mt-2">
        <Col className="d-flex align-items-center">
          <div className="display-3">{title}</div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col className={!isLast && `border-bottom border-lighter px-0 mx-2`}>
          {children}
        </Col>
      </Row>
    </>
  );
}

CollapsibleSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isLast: PropTypes.bool.isRequired,
};
