import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { PlusCircle } from 'react-bootstrap-icons';
import EncounterMedicationModal from './EncounterMedicationModal';
import EncounterMedicationsTable from './EncounterMedicationsTable';

function EncounterMedications({
  encounterId,
  patientId,
  medications,
  onMedicationsChange,
  allowChanges,
  isBusy,
}) {
  const medicationTemplate = {
    dirty: true,
    index: 0,
    patientId,
    medicationCode: { id: '', name: '' },
    medicationFrequency: { id: '', name: '' },
    startDate: '',
    stopDate: '',
    dosage: '',
    units: '',
    route: '',
    reason: '',
    patientEncounterId: encounterId,
  };

  const [showModal, setShowModal] = useState(false);
  const [currentMedication, setCurrentMedication] =
    useState(medicationTemplate);
  const [pendingMedications, setPendingMedications] = useState([
    ...medications,
  ]);

  useEffect(() => {
    setPendingMedications([...medications]);
  }, [medications]);

  const addNewMedication = () => {
    setCurrentMedication({
      ...medicationTemplate,
      index: pendingMedications.length,
    });
    setShowModal(true);
  };

  const editMedication = (medication) => {
    setCurrentMedication({
      ...medicationTemplate,
      ...medication,
      startDate: medication.startDate || '',
      stopDate: medication.stopDate || '',
    });
    setShowModal(true);
  };

  const deleteMedication = (medication) => {
    const updatedMedications = pendingMedications
      .filter((m) => m.index !== medication.index)
      .map((m, index) => ({ ...m, index }));

    setPendingMedications(updatedMedications);
    onMedicationsChange(updatedMedications);
  };

  const handleSave = (medication) => {
    medication.dirty = true;

    const updatedMedications = [...pendingMedications];

    if (typeof medication.id === 'undefined') {
      medication.id = 0;
      updatedMedications.push(medication);
    } else {
      updatedMedications[medication.index] = medication;
    }
    setPendingMedications(updatedMedications);
    onMedicationsChange(updatedMedications);
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const showTable = () => pendingMedications.length > 0;

  return (
    <>
      <Row className="mt-2">
        <Col>
          <h6 className="mb-0 d-inline align-middle me-3">
            Encounter Medications
          </h6>
          {allowChanges && (
            <Button
              variant="outline-primary"
              onClick={addNewMedication}
              className="btn-sm"
              disabled={isBusy}
            >
              <PlusCircle />{' '}
              <span className="align-middle">Add Medication</span>
            </Button>
          )}
        </Col>
      </Row>
      {showTable() ? (
        <EncounterMedicationsTable
          medications={pendingMedications}
          showSavedStatus={allowChanges}
          onEdit={editMedication}
          onDelete={deleteMedication}
          showEditAndDelete={allowChanges}
        />
      ) : (
        <Card body className="mt-3 text-muted fst-italic">
          0 medications. Click Add Medication to add new records.
        </Card>
      )}
      <EncounterMedicationModal
        medication={currentMedication}
        showModal={showModal}
        handleClose={handleClose}
        handleSave={handleSave}
        isBusy={isBusy}
      />
    </>
  );
}

export default EncounterMedications;
