import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Button, Stack, FormCheck } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

import TableLayout from 'components/Layouts/TableLayout';

export default function UserSitesTable({ users, confirmRemoveUser }) {
  const [showActiveUsers, setShowActiveUsers] = useState(true);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'fullName',
        header: 'Name',
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        header: 'Actions',
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { style: { width: '60px' }, className: 'text-center' },
        },
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row }) => (
          <Button
            size="sm"
            variant="dark"
            className="btn-lg btn-block"
            onClick={() => confirmRemoveUser(row.original)}
          >
            <Trash />
          </Button>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Stack gap={2}>
      <Stack direction="horizontal">
        <FormCheck
          id="users-active"
          type="radio"
          name="users"
          label="Active"
          checked={showActiveUsers}
          onChange={() => setShowActiveUsers(true)}
        />

        <FormCheck
          id="users-inactive"
          className="ms-3"
          type="radio"
          name="users"
          label="Inactive"
          checked={!showActiveUsers}
          onChange={() => setShowActiveUsers(false)}
        />
      </Stack>
      <TableLayout
        columns={columns}
        data={users.filter((user) => user.active === showActiveUsers)}
      />
    </Stack>
  );
}

UserSitesTable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  confirmRemoveUser: PropTypes.func.isRequired,
};
