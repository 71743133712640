import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { ChevronLeft } from 'react-bootstrap-icons';

export default function NavigateBack({
  onClick,
  text = 'Back',
  className = '',
}) {
  return (
    <Button
      variant="link"
      className={`shadow-none text-decoration-none d-flex align-items-center ${className}`.trim()}
      onClick={onClick}
    >
      <ChevronLeft />
      {text}
    </Button>
  );
}

NavigateBack.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
  className: PropTypes.string,
};

NavigateBack.defaultProps = {
  text: 'Back',
  className: '',
};
