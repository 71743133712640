import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AdminMenu from './components/AdminMenu';

function Admin() {
  return (
    <Row xs={1} md={3} className="g-4">
      {Object.entries(AdminMenu).map(([key, card]) => (
        <Col key={key}>
          <Card>
            <Card.Body>
              <Card.Title>{card.title}</Card.Title>
              <Card.Text>{card.description}</Card.Text>
              <Card.Link as="div">
                <Link to={card.pathname}>Manage</Link>
              </Card.Link>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default Admin;
