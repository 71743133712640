import { createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';

export const columnHelper = createColumnHelper();

/** @return {Object[]} */
export function usePagination(initPagination = { pageIndex: 0, pageSize: 25 }) {
  const [pagination, setPagination] = useState(initPagination);
  return [pagination, setPagination];
}

/** @return {Object[]} */
export function useSorting(initSorting = [{ id: '', desc: false }]) {
  const [sorting, setSorting] = useState(initSorting);
  return [sorting, setSorting];
}
