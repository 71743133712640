import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import MultiSelect from 'components/MultiSelect';

function MultiSelectField({
  label,
  name,
  options,
  placeholder,
  labelClassName,
}) {
  const [, meta, helpers] = useField(name);
  const { value = [] } = meta;
  const { setValue } = helpers;

  const selectedValues = options.filter((option) =>
    value.includes(option.value)
  );

  const handleChange = (selectedOptions) => {
    const values = selectedOptions.map((option) => option.value);
    setValue(values);
  };

  const isValid = meta.touched && !meta.error;
  const isInvalid = meta.touched && !!meta.error;

  return (
    <Form.Group controlId={name}>
      <Form.Label className={labelClassName}>{label}</Form.Label>

      <MultiSelect
        name={name}
        options={options}
        selectedValues={selectedValues}
        onChange={handleChange}
        placeholderText={placeholder}
        isValid={isValid}
        isInvalid={isInvalid}
      />

      {isInvalid && (
        <div className="invalid-feedback d-block">
          The {meta.error} selected.
        </div>
      )}
    </Form.Group>
  );
}

MultiSelectField.defaultProps = {
  label: undefined,
  placeholder: undefined,
  labelClassName: undefined,
};

MultiSelectField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  placeholder: PropTypes.string,
  labelClassName: PropTypes.string,
};

export default MultiSelectField;
