import React, { useEffect } from 'react';
import { Badge, Button, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PatientSearch from './PatientSearch';
import SiteDistanceOptions from 'entities/SiteDistanceOptions';
import { useSites } from 'hooks/siteHooks';
import useDashboardStore from 'stores/dashboardStore';

export default function DashboardToolbar() {
  const {
    site,
    distanceInMiles,
    study,
    updateSite,
    updateDistanceInMiles,
    updateStudy,
  } = useDashboardStore((state) => state.toolbar);

  const sites = useSites();

  const navigate = useNavigate();

  const updateSelectedSite = (newSiteId) => {
    const newSite = sites.data.find((x) => x.id === newSiteId);
    updateSite(newSite);
  };

  useEffect(() => {
    if (sites.data?.length > 0 && !site) {
      updateSite(sites.data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sites.data]);

  if (!site) return null;

  const handleSiteChange = (event) => {
    const updatedSiteId = Number(event.target.value);
    updateSelectedSite(updatedSiteId);
  };

  const handleDistanceInMilesChange = (event) => {
    const value = Number(event.target.value);
    updateDistanceInMiles(value);
  };

  const handleStudyChange = (event) => {
    const newStudy = site.studies?.find(
      (s) => s.id === Number(event.target.value)
    );
    updateStudy(newStudy);
  };

  if (sites.isLoading) return null;

  return (
    <Stack
      direction="horizontal"
      gap={5}
      className="justify-content-between mt-3 mx-n2"
    >
      <Stack direction="horizontal" gap={2} className="w-100">
        <select
          className="form-select"
          onChange={handleSiteChange}
          value={site.id}
        >
          {sites.data.map((s) => (
            <option key={s.id} value={s.id.toString()}>
              {s.name}
            </option>
          ))}
        </select>
        <select
          className={
            site.studies.length
              ? 'form-select'
              : 'form-select pe-none user-select-none'
          }
          onChange={handleStudyChange}
          value={study?.id || ''}
        >
          {site.studies.map((s) => (
            <option key={s.id} value={s.id}>
              {s.name}
            </option>
          ))}
        </select>
        <select
          className="form-select"
          onChange={handleDistanceInMilesChange}
          value={distanceInMiles}
        >
          {SiteDistanceOptions.map((opt) => (
            <option key={`distance-${opt.value}`} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
      </Stack>
      <Stack direction="horizontal">
        <PatientSearch />
        <Button
          onClick={() => navigate('/feasibility')}
          className="align-self-center ms-1 text-nowrap"
          variant="outline-info"
        >
          Feasibility
          <small>
            <Badge className="ms-1" bg="info">
              BETA
            </Badge>
          </small>
        </Button>
      </Stack>
    </Stack>
  );
}
