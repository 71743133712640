import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

export function useReferralSources(activeOnly = true, globalOnly = false) {
  return useQuery(
    ['referral-sources', activeOnly, globalOnly],
    async () => {
      const { data } = await axios.get(`/api/referral-sources`, {
        params: { activeOnly, globalOnly },
      });
      return data;
    },
    { placeholderData: [] }
  );
}

const createReferralSource = async (referralSource) => {
  const result = await axios.post(`/api/referral-sources`, referralSource);
  return result.data;
};

export function useCreateReferralSource(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createReferralSource,
    onSuccess: (referralSource) => {
      queryClient.invalidateQueries(['referral-sources']);

      options?.onSuccess?.(referralSource);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const updateReferralSource = async (referralSource) => {
  const result = await axios.put(
    `/api/referral-sources/${referralSource.id}`,
    referralSource
  );
  return result.data;
};

export function useUpdateReferralSource(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateReferralSource,
    onSuccess: (referralSource) => {
      queryClient.invalidateQueries(['referral-sources']);

      options?.onSuccess?.(referralSource);
    },
    onError: (error) => options?.onError?.(error),
  });
}
