import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

export function useConnections(pageIndex = null, pageSize = null) {
  return useQuery(['connections', pageIndex, pageSize], async () => {
    const params = { pageIndex, pageSize };
    const { data } = await axios.get('/api/admin/connections', { params });
    return data;
  });
}

export function useConnection(id) {
  return useQuery(['connection', id], async () => {
    const { data } = await axios.get(`/api/admin/connections/${id}`);
    return data;
  });
}

const createConnection = async (configuration) => {
  const result = await axios.post('/api/admin/connections', configuration);
  return result.data;
};

export function useCreateConnection(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createConnection,
    onSuccess: (connection) => {
      queryClient.invalidateQueries(['connections']);

      options?.onSuccess?.(connection);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const createConnectionSite = async ({ siteId, connectionId }) => {
  const result = await axios.post(
    `/api/admin/sites/${siteId}/connections/${connectionId}`
  );
  return result.data;
};

export function useCreateConnectionSite(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createConnectionSite,
    onSuccess: (connectionSite) => {
      queryClient.invalidateQueries([
        'connection',
        Number(connectionSite.connectionId),
      ]);
      queryClient.invalidateQueries([
        'siteConnections',
        Number(connectionSite.siteId),
      ]);

      options?.onSuccess?.(connectionSite);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const updateConnection = async ({ connectionId, configuration }) => {
  const result = await axios.put(
    `/api/admin/connections/${connectionId}`,
    configuration
  );
  return result.data;
};

export function useUpdateConnection(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateConnection,
    onSuccess: (connection) => {
      queryClient.invalidateQueries(['connections']);
      queryClient.invalidateQueries(['connection', Number(connection.id)]);
      queryClient.invalidateQueries(['siteConnections']);

      options?.onSuccess?.(connection);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const deleteConnection = async (connection) => {
  const result = await axios.delete(`/api/admin/connections/${connection.id}`);
  return result.data;
};

export function useDeleteConnection(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteConnection,
    onSuccess: (connection) => {
      queryClient.invalidateQueries(['connections']);
      queryClient.invalidateQueries(['connection', Number(connection.id)]);
      queryClient.invalidateQueries(['siteConnections']);

      options?.onSuccess?.(connection);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const deleteConnectionSite = async ({ siteId, connectionId }) => {
  const result = await axios.delete(
    `/api/admin/sites/${siteId}/connections/${connectionId}`
  );
  return result.data;
};

export function useDeleteConnectionSite(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteConnectionSite,
    onSuccess: (connectionSite) => {
      queryClient.invalidateQueries(['connections']);

      queryClient.invalidateQueries([
        'connection',
        Number(connectionSite.connectionId),
      ]);

      queryClient.invalidateQueries([
        'siteConnections',
        Number(connectionSite.siteId),
      ]);

      options?.onSuccess?.(connectionSite);
    },
    onError: (error) => options?.onError?.(error),
  });
}

export function useSiteConnections(siteId) {
  return useQuery(
    ['siteConnections', siteId],
    () =>
      axios
        .get(`/api/admin/sites/${siteId}/connections`)
        .then((res) => res.data),
    { enabled: Boolean(siteId) }
  );
}
