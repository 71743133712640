import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

export function useSiteReferralSources(siteId) {
  return useQuery(
    ['site-referral-sources', Number(siteId)],
    () =>
      axios
        .get(`/api/sites/${siteId}/referral-sources`)
        .then((res) => res.data),
    { enabled: Boolean(siteId) }
  );
}

const createSiteReferralSource = async ({ siteId, referralSourceId }) => {
  const result = await axios.post(`/api/sites/${siteId}/referral-sources`, {
    referralSourceId,
  });
  return result.data;
};

export function useCreateSiteReferralSource(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createSiteReferralSource,
    onSuccess: (siteReferralSource) => {
      queryClient.invalidateQueries(['site', siteReferralSource.siteId]);
      queryClient.invalidateQueries([
        'site-referral-sources',
        siteReferralSource.siteId,
      ]);

      options?.onSuccess?.(siteReferralSource);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const deleteSiteReferralSource = async ({ siteId, referralSourceId }) => {
  const result = await axios.delete(
    `/api/sites/${siteId}/referral-sources/${referralSourceId}`
  );
  return result.data;
};

export function useDeleteSiteReferralSource(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteSiteReferralSource,
    onSuccess: (siteReferralSource) => {
      queryClient.invalidateQueries(['site', siteReferralSource.siteId]);
      queryClient.invalidateQueries([
        'site-referral-sources',
        siteReferralSource.siteId,
      ]);

      options?.onSuccess?.(siteReferralSource);
    },
    onError: (error) => options?.onError?.(error),
  });
}
