import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { Calendar, luxonLocalizer } from 'react-big-calendar';
import CalendarEvent from './CalendarEvent';
import CalendarToolbar from './CalendarToolbar';
import MonthListView from './MonthListView';
import WeekListView from './WeekListView';
import { SCHEDULE_VIEW } from '../constants';
import AppointmentCancellationModal from 'components/Encounter/AppointmentCancellationModal';
import AppointmentModal from 'components/Encounter/AppointmentModal';
import AppointmentTypes from 'entities/AppointmentTypes';

import './CalendarView.scss';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const getDefaultDate = (view, startDate) => {
  const date = DateTime.fromISO(startDate, {
    setZone: true,
  }).setZone('local', { keepLocalTime: true });

  let returnValue = date;

  switch (view) {
    case SCHEDULE_VIEW.WORK_WEEK:
      if (date.isWeekend) {
        // date is weekend and out of range, need to adjust
        returnValue = date.plus({ days: 1 }).startOf('day');
      }
      break;
    case SCHEDULE_VIEW.MONTH:
      if (date.day > 1) {
        // calendar will show days from previous month, need to adjust
        returnValue = date.startOf('month').plus({ months: 1 });
      }
      break;
    default:
      break;
  }

  return returnValue.toJSDate();
};

export default function CalendarView({ context }) {
  const {
    allowChanges,
    calendarEvents,
    stateValues,
    handleRangeChange,
    handleSelectEvent,
    handleCancelAppointment,
    handleCloseAppointmentModal,
    handleCloseCancellationModal,
    getEventProps,
    selectedAppointment,
    showAppointmentModal,
    showCancellationModal,
  } = useContext(context);

  const { view, startDate, siteId, siteTimeZone, appointmentTypeId } =
    stateValues;

  const components = useMemo(
    () => ({
      event: CalendarEvent,
      // eslint-disable-next-line react/jsx-props-no-spreading, react/no-unstable-nested-components
      toolbar: (props) => <CalendarToolbar {...props} />,
    }),
    []
  );

  const { defaultDate, getNow, localizer, events, scrollToTime } =
    useMemo(() => {
      const localTimeAtSite = DateTime.local({ zone: siteTimeZone })
        .setZone('local', { keepLocalTime: true })
        .toJSDate();

      return {
        defaultDate: getDefaultDate(view, startDate),
        getNow: () => localTimeAtSite,
        localizer: luxonLocalizer(DateTime),
        events: [...calendarEvents],
        scrollToTime: localTimeAtSite,
      };
    }, [siteTimeZone, calendarEvents, startDate, view]);

  if (!startDate) {
    return null;
  }

  return (
    <>
      <Calendar
        defaultDate={defaultDate}
        defaultView={view}
        getNow={getNow}
        localizer={localizer}
        events={events}
        scrollToTime={scrollToTime}
        views={{
          [SCHEDULE_VIEW.LIST]:
            appointmentTypeId === AppointmentTypes.OBJECTIVE_SCREEN
              ? MonthListView
              : WeekListView,
          [SCHEDULE_VIEW.DAY]: true,
          [SCHEDULE_VIEW.WORK_WEEK]: true,
          [SCHEDULE_VIEW.MONTH]: true,
        }}
        components={components}
        onRangeChange={handleRangeChange}
        onSelectEvent={handleSelectEvent}
        eventPropGetter={getEventProps}
        formats={{ eventTimeRangeFormat: () => null }}
        step={30}
        timeslots={1}
        startAccessor="start"
        endAccessor="end"
        titleAccessor="title"
        tooltipAccessor={(e) => e.tooltip}
        min={new Date(0, 0, 0, 7, 0, 0)}
        max={new Date(0, 0, 0, 18, 0, 0)}
        popup
      />
      {showAppointmentModal && (
        <AppointmentModal
          appointment={selectedAppointment}
          siteId={siteId}
          onClose={handleCloseAppointmentModal}
          allowChanges={allowChanges}
          onCancelButtonClick={handleCancelAppointment}
        />
      )}

      {showCancellationModal && (
        <AppointmentCancellationModal
          onClose={handleCloseCancellationModal}
          appointment={selectedAppointment}
        />
      )}
    </>
  );
}

CalendarView.defaultProps = {
  context: {
    allowChanges: false,
    calendarEvents: [],
    stateValues: {
      view: '',
      startDate: '',
      siteId: '',
      siteTimeZone: '',
    },
    handleRangeChange: () => {},
    handleSelectEvent: () => {},
    handleCancelAppointment: () => {},
    handleCloseAppointmentModal: () => {},
    handleCloseCancellationModal: () => {},
    getEventProps: () => {},
    selectedAppointment: {},
    showAppointmentModal: false,
    showCancellationModal: false,
  },
};

CalendarView.propTypes = {
  context: PropTypes.shape({
    allowChanges: PropTypes.bool,
    calendarEvents: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        start: PropTypes.instanceOf(Date).isRequired,
        end: PropTypes.instanceOf(Date).isRequired,
        tooltip: PropTypes.string.isRequired,
        appointment: PropTypes.shape({
          id: PropTypes.number.isRequired,
          patient: PropTypes.shape({
            id: PropTypes.number.isRequired,
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
          }).isRequired,
          appointmentType: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
          }).isRequired,
          status: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired
    ),
    stateValues: PropTypes.shape({
      view: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      siteId: PropTypes.string.isRequired,
      siteTimeZone: PropTypes.string.isRequired,
    }),
    handleRangeChange: PropTypes.func,
    handleSelectEvent: PropTypes.func,
    handleCancelAppointment: PropTypes.func,
    handleCloseAppointmentModal: PropTypes.func,
    handleCloseCancellationModal: PropTypes.func,
    getEventProps: PropTypes.func,
    selectedAppointment: PropTypes.shape({
      id: PropTypes.number.isRequired,
      patient: PropTypes.shape({
        id: PropTypes.number.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }),
      appointmentType: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
      status: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    }),
    showAppointmentModal: PropTypes.bool,
    showCancellationModal: PropTypes.bool,
  }),
};
