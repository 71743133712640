import React from 'react';
import { Stack } from 'react-bootstrap';

import CallOutcomesSection from './CallOutcomesSection';
import CancellationReasonsSection from './CancellationReasonsSection';
import DocumentTypesSection from './DocumentTypesSection';
import ReferralSourcesSection from './ReferralSourcesSection';

function OrganizationReasonsTab() {
  const sections = [
    CallOutcomesSection,
    CancellationReasonsSection,
    DocumentTypesSection,
    ReferralSourcesSection,
  ];

  return (
    <Stack>
      {sections.map((Section, i) => (
        <React.Fragment key={Section.name}>
          <Section />
          {i < sections.length - 1 && <hr />}
        </React.Fragment>
      ))}
    </Stack>
  );
}

export default OrganizationReasonsTab;
