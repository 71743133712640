import PropTypes from 'prop-types';
import React from 'react';
import FormSelect from 'react-bootstrap/FormSelect';

export default function SiteSelector({ sites, selectedSiteId, onChange }) {
  return (
    <FormSelect
      id="site-selector"
      value={selectedSiteId || ''}
      onChange={(e) => onChange(e.target.value)}
    >
      {sites.map((site) => (
        <option key={site.id} value={site.id}>
          {site.name.toUpperCase()}
        </option>
      ))}
    </FormSelect>
  );
}

SiteSelector.defaultProps = {
  selectedSiteId: '',
};

SiteSelector.propTypes = {
  sites: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedSiteId: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};
