import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

const createUserSite = async (values) => {
  const { siteId, userId } = values;
  const result = await axios.put(`/api/users/${userId}/sites/${siteId}`);
  return result.data;
};

export function useCreateUserSite(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createUserSite,
    onSuccess: (userSite) => {
      queryClient.invalidateQueries(['siteMetrics']);
      queryClient.invalidateQueries(['sites']);
      queryClient.invalidateQueries(['site', userSite.siteId]);
      queryClient.invalidateQueries(['users']);
      queryClient.invalidateQueries(['user', userSite.userId]);
      queryClient.invalidateQueries(['assignableSites', userSite.userId]);

      options?.onSuccess?.(userSite);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const deleteUserSite = async (values) => {
  const { siteId, userId } = values;
  const result = await axios.delete(`/api/users/${userId}/sites/${siteId}`);
  return result.data;
};

export function useDeleteUserSite(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteUserSite,
    onSuccess: (userSite) => {
      queryClient.invalidateQueries(['siteMetrics']);
      queryClient.invalidateQueries(['sites']);
      queryClient.invalidateQueries(['site', userSite.siteId]);
      queryClient.invalidateQueries(['users']);
      queryClient.invalidateQueries(['user', userSite.userId]);
      queryClient.invalidateQueries(['assignableSites', userSite.userId]);

      options?.onSuccess?.(userSite);
    },
    onError: (error) => options?.onError?.(error),
  });
}
