import PropTypes from 'prop-types';
import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import {
  useCreatePatientFamilyHistory,
  useUpdatePatientFamilyHistory,
} from 'hooks/patientFamilyHistoryHooks';

const diagnosisTemplate = {
  id: 0,
  patientId: '',
  diagnosisCodeId: '',
  age: undefined,
  familyHistoryUnknown: false,
  familyMemberId: undefined,
};

const FamilyHistoryContext = createContext();

function FamilyHistoryProvider({ patientId, children }) {
  const [diagnosis, setDiagnosis] = useState(diagnosisTemplate);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);

  const mutationOptions = {
    onSuccess: () => setShowModal(false),
    onError: (e) => setError(e.response?.data?.message),
  };

  const createPatientFamilyHistory =
    useCreatePatientFamilyHistory(mutationOptions);
  const updatePatientFamilyHistory =
    useUpdatePatientFamilyHistory(mutationOptions);

  useEffect(() => {
    setError('');
  }, [showModal]);

  const closeModal = () => {
    setShowModal(false);
  };

  const addDiagnosis = () => {
    setDiagnosis(diagnosisTemplate);
    setShowModal(true);
  };

  const editDiagnosis = (selectedDiagnosis) => {
    setDiagnosis(selectedDiagnosis);
    setShowModal(true);
  };

  const saveDiagnosis = useCallback(
    (values) => {
      setError('');

      const data = {
        ...values,
        patientId,
      };

      if (values.id === 0) {
        createPatientFamilyHistory.mutate(data);
      } else {
        updatePatientFamilyHistory.mutate(data);
      }
    },
    [createPatientFamilyHistory, patientId, updatePatientFamilyHistory]
  );

  const isBusy =
    createPatientFamilyHistory.isLoading ||
    updatePatientFamilyHistory.isLoading;

  const value = useMemo(
    () => ({
      diagnosis,
      error,
      showModal,
      closeModal,
      addDiagnosis,
      editDiagnosis,
      saveDiagnosis,
      isBusy,
    }),
    [diagnosis, error, isBusy, saveDiagnosis, showModal]
  );

  return (
    <FamilyHistoryContext.Provider value={value}>
      {children}
    </FamilyHistoryContext.Provider>
  );
}

export default FamilyHistoryProvider;
export { FamilyHistoryContext };

FamilyHistoryProvider.propTypes = {
  patientId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};
