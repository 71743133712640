import PropTypes from 'prop-types';
import React from 'react';
import { ListGroup, Badge } from 'react-bootstrap';

export default function SiteList({ sites, selectedSiteId, onSelect }) {
  return (
    <ListGroup activeKey={selectedSiteId}>
      {sites.map((site) => (
        <ListGroup.Item
          action
          key={site.id}
          eventKey={site.id}
          onClick={() => onSelect(site)}
        >
          <div className="d-flex justify-content-between">
            <div>{site.name}</div>
            <div>
              {!!site.messageCount && (
                <Badge pill bg="primary" className="ms-3">
                  {site.messageCount}
                </Badge>
              )}
            </div>
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}

SiteList.defaultProps = {
  selectedSiteId: undefined,
};

SiteList.propTypes = {
  sites: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedSiteId: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
};
