export const EMPTY_APPOINTMENT = {
  encounterTypeId: '',
  siteId: '',
  patientId: '',
  appointmentDate: '',
  duration: '',
};

export const SCHEDULE_VIEW = {
  DAY: 'day',
  WORK_WEEK: 'work_week',
  MONTH: 'month',
  LIST: 'list',
};
