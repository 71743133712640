import React, { useState } from 'react';
import { Form, FormCheck, Stack } from 'react-bootstrap';
import { Dash } from 'react-bootstrap-icons';
import IndicationModal from '../components/IndicationModal';
import AdminListLayout from '../Layouts/AdminListLayout';
import {
  ACTION_BUTTON_TYPES,
  ActionButton,
} from 'components/Button/ActionButton';
import AddButton from 'components/Button/AddButton';
import TableLayout from 'components/Layouts/TableLayout';
import { useIndications } from 'hooks/indicationHooks';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

const getValue = (props) => props.getValue() ?? <Dash />;
const getBooleanValue = (props) => (props.getValue() ? 'Yes' : 'No');
const getDateValue = (props) => formatDateDisplay(props.getValue());

export default function IndicationsList() {
  const [showActive, setShowActive] = useState(true);
  const [selectedIndication, setSelectedIndication] = useState(null);

  const indications = useIndications(showActive);

  const handleShowActive = (value) => {
    setShowActive(value);
  };

  const addIndication = () => {
    setSelectedIndication({ id: null, active: true });
  };

  const renderActionButtons = ({ row }) => (
    <ActionButton
      action={ACTION_BUTTON_TYPES.EDIT}
      onClick={() => setSelectedIndication(row.original)}
    />
  );

  const COLUMNS = [
    {
      accessorKey: 'name',
      header: 'Name',
    },
    {
      accessorKey: 'displayName',
      header: 'Display Name',
      cell: getValue,
    },
    {
      accessorKey: 'valueType',
      header: 'Value Type',
      meta: {
        headerProps: { className: 'text-center' },
        cellProps: { className: 'text-center' },
      },
      cell: getValue,
    },
    {
      accessorKey: 'active',
      header: 'Active',
      meta: {
        headerProps: { className: 'text-center' },
        cellProps: { className: 'text-center' },
      },
      cell: getBooleanValue,
    },
    {
      accessorKey: 'createdAt',
      header: 'Date Created',
      meta: {
        headerProps: { className: 'text-center' },
        cellProps: { className: 'text-center' },
      },
      cell: getDateValue,
    },
    {
      header: 'Actions',
      meta: {
        headerProps: { className: 'text-center' },
        cellProps: {
          className: 'text-center',
        },
      },
      cell: renderActionButtons,
    },
  ];

  return (
    <AdminListLayout
      topRow={<AddButton label="Indication" onClick={addIndication} />}
      table={
        <Stack gap={2}>
          <Form direction="horizontal" gap={3} as={Stack}>
            <FormCheck
              type="radio"
              label="Active"
              checked={showActive}
              onChange={() => handleShowActive(true)}
            />
            <FormCheck
              className="ms-3"
              type="radio"
              label="Inactive"
              checked={!showActive}
              onChange={() => handleShowActive(false)}
            />
          </Form>
          <TableLayout
            columns={COLUMNS}
            data={indications.data}
            isLoading={indications.isLoading}
          />
        </Stack>
      }
      modal={
        selectedIndication && (
          <IndicationModal
            indication={selectedIndication}
            onClose={() => setSelectedIndication(null)}
          />
        )
      }
    />
  );
}
