import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col, Stack, Button, Form } from 'react-bootstrap';
import DemographicsSection from './DemographicsSection';

import DiagnosesSection from './DiagnosesSection';
import ObservationsSection from './ObservationsSection';
import RegistrySection from './RegistrySection';
import StudySection from './StudySection';

export default function CriteriaTab({
  searchCriteria,
  onCriteriaChange,
  onClearAll,
  onSearch,
  isLoading,
}) {
  const handleFormOnChange = (key, val) => {
    onCriteriaChange({ [key]: val });
  };

  const handleSubmit = () => {
    onSearch(true);
  };

  const handleClearAll = async () => {
    onClearAll();
  };

  return (
    <Stack gap={2} className="mb-3">
      <Form>
        <h4 className="display-4 text-info">Registry</h4>
        <RegistrySection
          searchCriteria={searchCriteria}
          onChange={handleFormOnChange}
        />
        <hr />
        <h4 className="display-4 text-info">Demographics</h4>
        <DemographicsSection
          searchCriteria={searchCriteria}
          onChange={handleFormOnChange}
        />
        <hr />
        <h4 className="display-4 text-info">Study</h4>
        <StudySection
          searchCriteria={searchCriteria}
          onChange={handleFormOnChange}
        />
        <hr />
        <Row>
          <Col>
            <h4 className="display-4 text-info">Diagnosis</h4>
            <DiagnosesSection
              onChange={handleFormOnChange}
              initialValue={searchCriteria.diagnoses ?? {}}
            />
          </Col>
        </Row>
        <hr />

        <h4 className="display-4 text-info">Observations</h4>
        <ObservationsSection
          onChange={handleFormOnChange}
          initialValue={searchCriteria.observations ?? {}}
        />
        <hr />
        <Row className="mt-4">
          <Stack direction="horizontal">
            <Button
              type="reset"
              size="sm"
              variant="gray-300"
              className="ms-auto px-3"
              onClick={handleClearAll}
              disabled={isLoading}
            >
              Clear All
            </Button>
            <Button
              size="sm"
              variant="info"
              className="ms-2 px-4"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Search
            </Button>
          </Stack>
        </Row>
      </Form>
      <div style={{ height: '30px' }} />
    </Stack>
  );
}

CriteriaTab.propTypes = {
  searchCriteria: PropTypes.shape({
    studies: PropTypes.arrayOf(PropTypes.number),
    registry: PropTypes.number,
    indications: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        displayName: PropTypes.string,
        valueType: PropTypes.string,
      })
    ),
    minValue: PropTypes.string,
    maxValue: PropTypes.string,
    studyStatuses: PropTypes.arrayOf(PropTypes.number),
    medications: PropTypes.shape({}),
    diagnoses: PropTypes.shape({}),
    observations: PropTypes.shape({}),
  }).isRequired,
  onCriteriaChange: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
