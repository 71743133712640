import PropTypes from 'prop-types';
import React, { createContext, useEffect, useMemo } from 'react';

import { StringParam, useQueryParam } from 'use-query-params';
import { usePagination, useSorting } from 'hooks/tableHooks';

export const SearchContext = createContext(null);

const DEFAULT_PAGINATION = {
  pageIndex: 0,
  pageSize: 25,
};

const DEFAULT_SORTING = [{ id: 'fullName', desc: false }];

export default function SearchProvider({ children }) {
  const [query] = useQueryParam('query', StringParam);

  const [pagination, setPagination] = usePagination(DEFAULT_PAGINATION);
  const [sorting, setSorting] = useSorting(DEFAULT_SORTING);

  useEffect(() => {
    setPagination(DEFAULT_PAGINATION);
    setSorting(DEFAULT_SORTING);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const value = useMemo(
    () => ({
      pagination,
      setPagination,
      sorting,
      setSorting,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination, setPagination, sorting, setSorting]
  );

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
}

SearchProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
