import PropTypes from 'prop-types';
import React from 'react';

import ReasonSection from 'components/Reasons/ReasonSection';
import {
  useCreateCallOutcome,
  useCallOutcomes,
  useUpdateCallOutcome,
} from 'hooks/callOutcomeHooks';

function CallOutcomesSection({ orgId }) {
  return (
    <ReasonSection
      useOrgReasons={useCallOutcomes}
      orgId={orgId}
      useCreateReason={useCreateCallOutcome}
      useUpdateReason={useUpdateCallOutcome}
      reasonType="Call Outcome"
      globalOnly
    />
  );
}

CallOutcomesSection.propTypes = {
  orgId: PropTypes.number.isRequired,
};

export default CallOutcomesSection;
