/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { EyeFill, Dash } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import TableLayout from 'components/Layouts/TableLayout';
import { formatDateTimeDisplay } from 'pages/utils/dateTimeUtils';

const getValue = (props) => props.getValue() ?? <Dash />;

function CompletedEncountersTable({ appointments }) {
  const navigate = useNavigate();

  const navigateToEncounter = (appointment) => {
    navigate(
      `/patients/${appointment.patientId}/encounters/${appointment.encounter.id}`
    );
  };

  const columns = [
    {
      accessorFn: (row) => row.site?.name,
      header: 'LOCATION',
      cell: getValue,
    },
    {
      id: 'encounter.type.name',
      accessorFn: (row) => row.encounter?.type?.name,
      header: 'ENCOUNTER TYPE',
      cell: getValue,
    },
    {
      accessorKey: 'appointmentDate',
      header: 'APPOINTMENT DATE',
      cell: (props) =>
        props.getValue() ? formatDateTimeDisplay(props.getValue()) : <Dash />,
    },
    {
      id: 'encounter.startDate',
      accessorFn: (row) => row.encounter?.startDate,
      header: 'START DATE',
      cell: (props) =>
        props.getValue() ? formatDateTimeDisplay(props.getValue()) : <Dash />,
    },
    {
      id: 'encounter.completedDate',
      accessorFn: (row) => row.encounter?.completedDate,
      header: 'COMPLETED DATE',
      cell: (props) =>
        props.getValue() ? formatDateTimeDisplay(props.getValue()) : <Dash />,
    },
    {
      id: 'encounter.completedBy.fullName',
      accessorFn: (row) => row.encounter?.completedBy?.fullName,
      header: 'COMPLETED BY',
      cell: (props) => props.getValue() ?? <Dash />,
    },
    {
      id: 'actions',
      header: '',
      meta: {
        headerProps: { className: 'text-center' },
        cellProps: { className: 'text-center' },
      },
      cell: (props) => (
        <Button
          variant=""
          size="sm"
          onClick={() => navigateToEncounter(props.row.original)}
        >
          <EyeFill size={18} />
        </Button>
      ),
    },
  ];

  return (
    <TableLayout
      columns={columns}
      data={appointments}
      noResultsText="This patient has not completed any prescreen visits."
    />
  );
}

CompletedEncountersTable.propTypes = {
  appointments: PropTypes.arrayOf(
    PropTypes.shape({
      patientId: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default CompletedEncountersTable;
