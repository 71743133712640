import React, { useContext, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import DashboardToolbar from './components/DashboardToolbar';
import PatientsTable from './components/PatientsTable';
import StatusGroupMetrics from './components/StatusGroupMetrics';
import AddButton from 'components/Button/AddButton';
import PatientDemographicsModal from 'components/Patient/PatientDemographicsModal';
import { Roles, UserContext } from 'components/Provider/UserProvider';
import useDashboardStore from 'stores/dashboardStore';

export default function Dashboard() {
  const { userRole } = useContext(UserContext);

  const { study } = useDashboardStore((state) => state.toolbar);

  const [showModal, setShowModal] = useState(false);

  const isAddPatientsButtonDisabled = !Roles.canModifyRecords(userRole);

  return (
    <Row id="dashboard" className="bg-light-gray h-100 overflow-scroll">
      <Col className="mx-4">
        <DashboardToolbar />
        <div className="mt-4 mb-2">{study && <StatusGroupMetrics />}</div>
        <Row id="container" className="mb-4">
          <Col id="content">
            <Row className="align-items-center">
              <Col className="d-flex pe-0">
                <AddButton
                  isDisabled={isAddPatientsButtonDisabled}
                  onClick={() => setShowModal(true)}
                  className="ms-auto"
                  label="New Patient"
                />
                {showModal && (
                  <PatientDemographicsModal
                    onHide={() => setShowModal(false)}
                    redirectOnSave
                  />
                )}
              </Col>
            </Row>
            {study ? (
              <PatientsTable />
            ) : (
              <div className="text-center mt-4">
                <h5>No active studies for selected site.</h5>
              </div>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
