import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Stack, InputGroup, FormControl } from 'react-bootstrap';
import { ScheduleContext } from './ScheduleProvider';
import MultiSelect, { buildOptions } from 'components/MultiSelect';
import AppointmentTypes from 'entities/AppointmentTypes';
import useDebounce from 'hooks/useDebounce';

export default function ScheduleFilters() {
  const {
    stateValues: {
      providerName,
      studyIds,
      registryIds,
      studyStatusIds,
      appointmentStatusIds,
      appointmentTypeId,
    },
    studies,
    registries,
    studyStatuses,
    appointmentStatuses,
    handleProviderNameChange,
    handleStudiesChange,
    handleRegistriesChange,
    handleStudyStatusesChange,
    handleAppointmentStatusesChange,
  } = useContext(ScheduleContext);

  const [providerFilter, setProviderFilter] = useState(providerName);

  const debouncedProviderName = useDebounce(providerFilter, 500);

  useEffect(() => {
    if (debouncedProviderName !== providerName) {
      handleProviderNameChange(debouncedProviderName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedProviderName]);

  const getStudyOptions = useCallback(
    (_studies) => buildOptions(_studies, 'id', (d) => d.name),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [studies]
  );

  const getRegistryOptions = useCallback(
    (_registries) => buildOptions(_registries, 'id', (d) => d.name),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [registries]
  );

  const getStudyStatusOptions = useCallback(
    (_studyStatuses) => buildOptions(_studyStatuses, 'id', 'name'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [studyStatuses]
  );

  const getAppointmentStatusOptions = useCallback(
    (_appointmentStatuses) => buildOptions(_appointmentStatuses, 'id', 'name'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appointmentStatuses]
  );

  const handleMultiSelectFilterChange = (e, fieldName) => {
    const updatedValues = e.map((v) => v.value);

    switch (fieldName) {
      case 'studyIds':
        handleStudiesChange(updatedValues);
        break;
      case 'registryIds':
        handleRegistriesChange(updatedValues);
        break;
      case 'studyStatusIds':
        handleStudyStatusesChange(updatedValues);
        break;
      case 'appointmentStatusIds':
        handleAppointmentStatusesChange(updatedValues);
        break;
      default:
        break;
    }
  };

  return (
    <Stack gap={3} className="mx-1 mt-2">
      {appointmentTypeId === AppointmentTypes.FACILITY && (
        <div>
          <div className="mb-n2">
            <p className="display-4 ">Schedule Filters</p>
          </div>
          <div>
            <p className="fs-5 mb-1 text-uppercase">Provider</p>
            <InputGroup size="sm">
              <FormControl
                autoComplete="off"
                size="sm"
                name="providerName"
                type="text"
                value={providerFilter || ''}
                onChange={(e) => setProviderFilter(e.target.value)}
                aria-label="provider name filter"
              />
            </InputGroup>
          </div>
        </div>
      )}
      {appointmentTypeId === AppointmentTypes.OBJECTIVE_SCREEN && (
        <div>
          <div className="mb-n2">
            <p className="fs-5 mb-1 text-uppercase">Appointment Status</p>
          </div>
          <InputGroup>
            <MultiSelect
              className="w-100"
              name="schedule-studyFilter"
              onChange={(e) =>
                handleMultiSelectFilterChange(e, 'appointmentStatusIds')
              }
              selectedValues={getAppointmentStatusOptions(
                appointmentStatuses
              ).filter((o) => appointmentStatusIds?.includes(o.value))}
              placeholderText="Scheduled"
              options={getAppointmentStatusOptions(appointmentStatuses)}
            />
          </InputGroup>
        </div>
      )}
      <div>
        <p className="fs-5 mb-1 text-uppercase">Study</p>

        <InputGroup>
          <MultiSelect
            className="w-100"
            name="schedule-studyFilter"
            onChange={(e) => handleMultiSelectFilterChange(e, 'studyIds')}
            selectedValues={getStudyOptions(studies).filter((o) =>
              studyIds?.includes(o.value)
            )}
            placeholderText="All"
            options={getStudyOptions(studies)}
          />
        </InputGroup>
      </div>

      <div>
        <p className="fs-5 mb-1 text-uppercase">Registry</p>

        <InputGroup>
          <MultiSelect
            className="w-100"
            name="schedule-registryFilter"
            onChange={(e) => handleMultiSelectFilterChange(e, 'registryIds')}
            selectedValues={getRegistryOptions(registries)?.filter((o) =>
              registryIds?.includes(o.value)
            )}
            placeholderText="All"
            options={getRegistryOptions(registries)}
          />
        </InputGroup>
      </div>
      <div>
        <p className="fs-5 mb-1 text-uppercase">Study Status</p>

        <InputGroup>
          <MultiSelect
            className="w-100"
            name="schedule-studyStatusFilter"
            onChange={(e) => handleMultiSelectFilterChange(e, 'studyStatusIds')}
            selectedValues={getStudyStatusOptions(studyStatuses).filter((o) =>
              studyStatusIds?.includes(o.value)
            )}
            placeholderText="All"
            options={getStudyStatusOptions(studyStatuses)}
          />
        </InputGroup>
      </div>
    </Stack>
  );
}
