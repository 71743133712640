import { withAuthenticationRequired } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import React from 'react';

function ProtectedRoute({ component }) {
  const Component = withAuthenticationRequired(component);
  return <Component />;
}

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default ProtectedRoute;
