/* eslint-disable react/no-unstable-nested-components */
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { Trash, Dash } from 'react-bootstrap-icons';
import TableLayout from 'components/Layouts/TableLayout';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

export default function SiteStudiesTable({ study, confirmRemove }) {
  const data = useMemo(() => study.sites, [study]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        meta: { cellProps: { style: { width: '70%' } } },
      },
      {
        id: 'row.SiteStudy.createdAt',
        accessorFn: (row) => row.SiteStudy?.createdAt,
        header: 'Date Added',
        cell: ({ getValue }) =>
          getValue() ? formatDateDisplay(getValue()) : <Dash />,
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { className: 'text-center' },
        },
      },
      {
        header: 'Actions',
        meta: {
          cellProps: { style: { width: '60px' }, className: 'text-center' },
        },
        cell: ({ row }) => (
          <Button
            size="sm"
            variant="dark"
            className="btn-lg btn-block"
            onClick={() => confirmRemove(row.original)}
          >
            <Trash />
          </Button>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <TableLayout columns={columns} data={data} />;
}

SiteStudiesTable.propTypes = {
  study: PropTypes.shape({
    sites: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  confirmRemove: PropTypes.func.isRequired,
};
