import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

export function usePatientFamilyHistory(patientId) {
  return useQuery(patientId && ['patientFamilyHistory', patientId], () =>
    axios
      .get(`/api/patients/${patientId}/family-history`)
      .then((res) => res.data)
  );
}

const createPatientFamilyHistory = async (values) => {
  const { patientId, ...diagnosis } = values;
  const response = await axios.post(
    `/api/patients/${patientId}/family-history`,
    diagnosis
  );
  return response.data;
};

export function useCreatePatientFamilyHistory(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPatientFamilyHistory,
    onSuccess: (diagnosis) => {
      queryClient.invalidateQueries([
        'patientFamilyHistory',
        diagnosis.patientId,
      ]);
      queryClient.invalidateQueries(['patient', diagnosis.patientId]);

      options?.onSuccess?.(diagnosis);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const updatePatientFamilyHistory = async (values) => {
  const { patientId, ...diagnosis } = values;
  const response = await axios.put(
    `/api/patients/${patientId}/family-history`,
    diagnosis
  );
  return response.data;
};

export function useUpdatePatientFamilyHistory(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updatePatientFamilyHistory,
    onSuccess: (diagnosis) => {
      queryClient.invalidateQueries([
        'patientFamilyHistory',
        diagnosis.patientId,
      ]);
      queryClient.invalidateQueries(['patient', diagnosis.patientId]);

      options?.onSuccess?.(diagnosis);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const deletePatientFamilyHistory = async (values) => {
  const { patientId, id } = values;
  await axios.delete(`/api/patients/${patientId}/family-history`, {
    data: { id },
  });

  return { patientId };
};

export function useDeletePatientFamilyHistory(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePatientFamilyHistory,
    onSuccess: ({ patientId }) => {
      queryClient.invalidateQueries(['patientFamilyHistory', patientId]);
      queryClient.invalidateQueries(['patient', patientId]);

      options?.onSuccess?.({ patientId });
    },
    onError: (error) => options?.onError?.(error),
  });
}
