import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, FormSelect, Row, Button } from 'react-bootstrap';
import LoadingIndicator from 'components/LoadingIndicator';
import { useIndications } from 'hooks/indicationHooks';
import {
  useCreateSiteIndication,
  useSiteIndications,
} from 'hooks/siteIndicationHooks';

export default function IndicationAdd({ siteId }) {
  const [selectedIndication, setSelectedIndication] = useState();

  const siteIndications = useSiteIndications(siteId);
  const indications = useIndications();
  const createSiteIndication = useCreateSiteIndication({
    onSuccess: () => setSelectedIndication(),
  });

  const handleClick = () => {
    const indicationId = Number(selectedIndication);
    createSiteIndication.mutate({ indicationId, siteId });
  };

  if (indications.isLoading || siteIndications.isLoading)
    return <LoadingIndicator />;

  const filteredIndications = indications.data.filter(
    (indication) =>
      !siteIndications.data.map((s) => s.id).includes(indication.id)
  );

  return (
    <Row>
      <Col xs={6}>
        <FormSelect
          value={selectedIndication}
          onChange={(e) => setSelectedIndication(e.target.value)}
        >
          <option key="option-default" value="">
            Select Indication
          </option>
          {filteredIndications.map((d) => (
            <option key={`option-${d.id || 'default'}`} value={d.id}>
              {d.displayName}
            </option>
          ))}
        </FormSelect>
      </Col>
      <Col xs="1">
        <Button
          variant="primary"
          className="ms-n3"
          disabled={!selectedIndication || createSiteIndication.isLoading}
          onClick={handleClick}
        >
          Add
        </Button>
      </Col>
    </Row>
  );
}

IndicationAdd.propTypes = {
  siteId: PropTypes.number.isRequired,
};
