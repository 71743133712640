import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Dropdown, FormControl, Stack } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import * as yup from 'yup';
import API from 'api';
import CheckField from 'components/FormikForm/CheckField';
import LoadingIndicator from 'components/LoadingIndicator';
import ModalForm from 'components/ModalForm';

const SCHEMA = yup.object({});

export default function DiagnosisSearchField({ onSelect }) {
  const [query, setQuery] = useState('');
  const [codeOptions, setCodeOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSearch = () => {
    setLoading(true);
    API.searchDiagnosisConcepts(query)
      .then((data) => {
        setError(false);
        setCodeOptions(data);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => setLoading(false));
  };

  const handleOnSubmit = (values) => {
    const selectedDiagnoses = Object.entries(values)
      .filter((entry) => entry[1])
      .map((entry) => entry[0]);

    onSelect(selectedDiagnoses);
    setCodeOptions([]);
    setQuery('');
  };

  if (loading) return <LoadingIndicator />;

  return (
    <>
      <Dropdown align="end">
        <Stack direction="horizontal">
          <FormControl
            type="text"
            placeholder="Search by diagnosis name or code"
            value={query}
            onChange={(event) => setQuery(event.target.value)}
            onKeyUp={(e) => {
              e.preventDefault();
              if (e.key === 'Enter' && query.length) {
                handleSearch();
              }
            }}
          />

          <Button
            className="border-0"
            variant=""
            onClick={(e) => {
              e.preventDefault();
              handleSearch();
            }}
            disabled={!query.length}
          >
            <Search />
          </Button>
        </Stack>
      </Dropdown>
      {error && (
        <div className="text-danger">
          There was an error fetching that diagnosis. Try a different code or
          name.
        </div>
      )}
      {!!codeOptions.length && (
        <ModalForm
          validationSchema={SCHEMA}
          formId="diagnosis-code-select"
          title="Select Diagnosis Codes"
          onClose={() => setCodeOptions([])}
          onSubmit={handleOnSubmit}
          initialValues={codeOptions
            .map((o) => o.code)
            .reduce((acc, key) => ({ ...acc, [key]: false }), {})}
        >
          <Stack
            className="overflow-auto"
            style={{
              maxHeight: '300px',
            }}
          >
            {codeOptions.map((option) => (
              <CheckField
                key={option.code + option.name}
                name={option.code}
                label={
                  <div>
                    <span className="text-primary">{option.code}</span>
                    &nbsp;
                    {option.name}
                  </div>
                }
              />
            ))}
          </Stack>
        </ModalForm>
      )}
    </>
  );
}

DiagnosisSearchField.propTypes = {
  onSelect: PropTypes.func.isRequired,
};
