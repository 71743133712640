import React, { useCallback } from 'react';
import { Stack } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TableLayout from 'components/Layouts/TableLayout';
import { useOrganizationSites } from 'hooks/organizationHooks';
import { usePagination } from 'hooks/tableHooks';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

const COLUMNS = [
  {
    accessorKey: 'name',
    header: 'Nickname',
  },
  {
    accessorKey: 'fullName',
    header: 'Full Name',
  },
  {
    accessorKey: 'city',
    header: 'City',
  },
  {
    accessorKey: 'state',
    header: 'State',
  },
  {
    accessorKey: 'createdAt',
    header: 'Date Created',
    cell: (props) => formatDateDisplay(props.getValue()),
    meta: {
      headerProps: {
        className: 'text-center text-nowrap',
      },
      cellProps: {
        className: 'text-center',
      },
    },
  },
];

export default function SitesTab() {
  const [pagination, setPagination] = usePagination();

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const orgId = Number(params.organizationId);

  const { data: sites, isLoading } = useOrganizationSites(
    orgId,
    pagination.pageIndex,
    pagination.pageSize
  );

  const viewSite = (siteId) => {
    navigate(`/organizations/sites/${siteId}`, {
      state: { backPath: location.pathname },
    });
  };

  const getRowProps = useCallback(
    (row) => ({
      className: 'hoverable-row',
      onClick: () => viewSite(row.original.id),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Stack className="my-2" gap={3}>
      <TableLayout
        columns={COLUMNS}
        data={sites}
        pagination={pagination}
        setPagination={setPagination}
        getRowProps={getRowProps}
        isLoading={isLoading}
      />
    </Stack>
  );
}
