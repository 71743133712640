import React from 'react';
import * as yup from 'yup';
import {
  ACTION_BUTTON_TYPES,
  ActionButton,
} from 'components/Button/ActionButton';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

const COLUMN_WIDTH_PERCENTAGE = '15%';

export const getReasonTableColumns = (onEdit, isAdminPage) => [
  {
    accessorKey: 'name',
    header: 'Reason',
  },
  {
    accessorKey: 'active',
    header: 'Active',
    meta: {
      headerProps: { className: 'text-center' },
      cellProps: {
        className: 'text-center',
        style: { width: COLUMN_WIDTH_PERCENTAGE },
      },
    },
    cell: ({ getValue }) => (getValue() ? 'Yes' : 'No'),
  },
  {
    accessorKey: 'createdAt',
    header: 'Date Created',
    cell: (props) => formatDateDisplay(props.getValue()),
    meta: {
      headerProps: {
        className: 'text-center text-nowrap',
      },
      cellProps: {
        className: 'text-center',
        style: { width: COLUMN_WIDTH_PERCENTAGE },
      },
    },
  },
  {
    header: 'Actions',
    meta: {
      headerProps: { className: 'text-center' },
      cellProps: {
        style: { width: COLUMN_WIDTH_PERCENTAGE },
        className: 'text-center',
      },
    },
    // eslint-disable-next-line react/no-unstable-nested-components
    cell: ({ row }) =>
      // do not show edit acctions for gloabl reasons
      // reasons without an orgId are global reasons
      (isAdminPage || row.original.organizationId) && (
        <ActionButton
          action={ACTION_BUTTON_TYPES.EDIT}
          className="btn-lg btn-block"
          onClick={() => onEdit(row.original)}
        />
      ),
  },
];

export const REASON_SCHEMA = yup.object({
  name: yup.string().required(),
  active: yup.boolean().required(),
});
