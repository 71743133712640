import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';
import {
  Badge,
  FormCheck,
  OverlayTrigger,
  Popover,
  PopoverBody,
  PopoverHeader,
  Stack,
  Table,
} from 'react-bootstrap';
import { Dash } from 'react-bootstrap-icons';
import StudyStatusDropdown from './StudyStatusDropdown';
import StudyStatusReasonModal from './StudyStatusReasonModal';
import { EmptyTableMessage } from 'components/Layouts/TableLayout';
import { Roles, UserContext } from 'components/Provider/UserProvider';
import StudyStatuses from 'entities/StudyStatuses';
import { usePatientStudyHistory } from 'hooks/patientStudyHistoryHooks';
import {
  useCreatePatientStudy,
  useUpdatePatientStudyStatus,
} from 'hooks/patientStudyHooks';
import { useStudyStatuses } from 'hooks/studyStatusHooks';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';
import { sortAlphabeticallyByProperty } from 'pages/utils/sort';

const ALL_SITES_OPTION = '';

const findStudyStatus = (studyStatuses, statusName) =>
  studyStatuses.find((s) => s.name === statusName);

export default function PatientStudiesSection({
  patientId,
  patientStudies,
  patientSites = [],
}) {
  const { userRole } = useContext(UserContext);
  const [selectedSiteId, setSelectedSiteId] = useState(ALL_SITES_OPTION);

  const [modalValues, setModalValues] = useState({});
  const [showActiveStudies, setShowActiveStudies] = useState(true);

  const createPatientStudy = useCreatePatientStudy();

  const studyHistory = usePatientStudyHistory(patientId);

  const updatePatientStudyStatus = useUpdatePatientStudyStatus();

  const studyStatuses = useStudyStatuses();

  const notInterestedStudyStatus = useMemo(() => {
    if (!studyStatuses.data) return null;

    return findStudyStatus(studyStatuses.data, StudyStatuses.NOT_INTERESTED);
  }, [studyStatuses]);

  const screenFailStudyStatus = useMemo(() => {
    if (!studyStatuses.data) return null;

    return findStudyStatus(studyStatuses.data, StudyStatuses.SCREEN_FAIL);
  }, [studyStatuses]);

  const studies = useMemo(() => {
    if (!patientSites.length) return [];

    let studiesToReturn = [];

    // Returning all of the studies for all of the patientSites.
    if (selectedSiteId === ALL_SITES_OPTION) {
      for (let i = 0; i < patientSites.length; i += 1) {
        const patientSite = patientSites[i];
        const patientSiteStudies = patientSite.studies?.filter(
          (s) => s.active === showActiveStudies && s.isRegistry === false
        );

        for (let j = 0; j < patientSiteStudies.length; j += 1) {
          const studyToDisplay = patientSiteStudies[j];

          if (
            typeof studiesToReturn.find(
              (study) => study.id === studyToDisplay.id
            ) === 'undefined'
          ) {
            studiesToReturn.push(studyToDisplay);
          }
        }
      }

      sortAlphabeticallyByProperty(studiesToReturn, 'name');
    } else {
      studiesToReturn = patientSites
        .find((ps) => ps.id === Number(selectedSiteId))
        .studies?.filter(
          (s) => s.active === showActiveStudies && s.isRegistry === false
        );
    }

    // We need to add information about all sites associated with given studies.
    for (let i = 0; i < studiesToReturn.length; i += 1) {
      studiesToReturn[i].sites = [];

      for (let j = 0; j < patientSites.length; j += 1) {
        if (
          typeof patientSites[j].studies.find(
            (s) => s.id === studiesToReturn[i].id
          ) !== 'undefined'
        ) {
          studiesToReturn[i].sites.push({
            id: patientSites[j].id,
            name: patientSites[j].name,
          });
        }
      }
    }

    return studiesToReturn;
  }, [selectedSiteId, patientStudies]);

  const handleStudyStatusChange = (
    studyId,
    studyStatusId,
    patientStudyId,
    reason
  ) => {
    if (patientStudyId) {
      updatePatientStudyStatus.mutate({
        patientId,
        patientStudyId,
        studyStatusId,
        reason,
      });
    } else {
      createPatientStudy.mutate({
        patientId,
        studyId,
        studyStatusId,
        reason,
      });
    }
  };

  const handleStudyStatusOnChange = (
    studyId,
    updatedStatusId,
    patientStudyId
  ) => {
    switch (Number(updatedStatusId)) {
      case notInterestedStudyStatus.id:
        setModalValues({
          studyId,
          patientStudyId,
          studyStatusId: Number(updatedStatusId),
          formFieldLabel: 'Not Interested Reason',
        });
        break;
      case screenFailStudyStatus.id:
        setModalValues({
          studyId,
          patientStudyId,
          studyStatusId: Number(updatedStatusId),
          formFieldLabel: 'Screen Fail Reason',
        });
        break;
      default:
        handleStudyStatusChange(studyId, updatedStatusId, patientStudyId);
        break;
    }
  };

  if (studyHistory.isLoading || studyStatuses.isLoading) return null;

  const recommendedStudyHistory = studyHistory.data
    ?.filter((item) => item.newStudyStatus?.name === StudyStatuses.RECOMMENDED)
    .map((item) => ({ study: item.study, date: item.createdAt }));

  return (
    <Stack>
      <div className="display-3">Studies</div>
      <div className="d-flex justify-content-between my-2">
        <Stack direction="horizontal">
          <FormCheck
            id="studyStatus-active"
            type="radio"
            name="studyStatus"
            label="Active"
            checked={showActiveStudies}
            onChange={() => setShowActiveStudies(true)}
          />

          <FormCheck
            id="studyStatus-inactive"
            className="ms-3"
            type="radio"
            name="studyStatus"
            label="Inactive"
            checked={!showActiveStudies}
            onChange={() => setShowActiveStudies(false)}
          />
        </Stack>
        <div>
          {patientSites.length > 0 && (
            <select
              className="form-select w-auto"
              value={selectedSiteId}
              onChange={(e) => setSelectedSiteId(e.target.value)}
            >
              {/* There is no point displaying "All" option if only one site is available. */}
              {patientSites.length > 1 && (
                <option key={ALL_SITES_OPTION} value={ALL_SITES_OPTION}>
                  All Sites
                </option>
              )}

              {patientSites.map((ps) => (
                <option key={ps.id} value={ps.id}>
                  {ps.name}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>
      {studies.length ? (
        <Table>
          <thead>
            <tr className="text-uppercase">
              <th>Study Name</th>
              <th>Site Name</th>
              <th className="text-center">Last Updated</th>
              <th className="text-center">Study Status</th>
            </tr>
          </thead>
          <tbody
            className={showActiveStudies ? undefined : 'text-muted pe-none'}
          >
            {studies.map((study) => {
              const patientStudy = patientStudies.find(
                (ps) => ps.studyId === study.id
              );

              const recommendedDate = recommendedStudyHistory.find(
                (item) => item.study.id === study.id
              )?.date;

              return (
                <tr key={study.id} className="mt-1 pb-2 align-middle">
                  <td>
                    {study.name}{' '}
                    {recommendedDate && (
                      <OverlayTrigger
                        placement="right-end"
                        overlay={
                          <Popover>
                            <PopoverHeader>Studies</PopoverHeader>
                            <PopoverBody className="p-2">
                              Recommended - {formatDateDisplay(recommendedDate)}
                            </PopoverBody>
                          </Popover>
                        }
                      >
                        <Badge pill>R</Badge>
                      </OverlayTrigger>
                    )}
                  </td>
                  <td>
                    {study.sites.map((studySite) => (
                      <div key={studySite.id}>{studySite.name}</div>
                    ))}
                  </td>
                  <td className="text-center" style={{ width: '15%' }}>
                    {formatDateDisplay(patientStudy?.updatedAt) || <Dash />}
                  </td>
                  <td style={{ width: '25%' }}>
                    <StudyStatusDropdown
                      studyStatus={patientStudy?.studyStatus}
                      onChange={(updatedStatusId) =>
                        handleStudyStatusOnChange(
                          study.id,
                          updatedStatusId,
                          patientStudy?.id
                        )
                      }
                      disabled={
                        !Roles.canModifyRecords(userRole) || !showActiveStudies
                      }
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <EmptyTableMessage message="No studies found." />
      )}
      {!!modalValues.studyId && (
        <StudyStatusReasonModal
          studyId={modalValues.studyId}
          patientStudyId={modalValues.patientStudyId}
          studyStatusId={modalValues.studyStatusId}
          formFieldLabel={modalValues.formFieldLabel}
          onClose={() => setModalValues({})}
          onSubmit={handleStudyStatusChange}
          isSubmitting={updatePatientStudyStatus.isLoading}
        />
      )}
    </Stack>
  );
}

PatientStudiesSection.propTypes = {
  patientId: PropTypes.number.isRequired,
  patientStudies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      study: PropTypes.shape({
        id: PropTypes.number.isRequired,
        isRegistry: PropTypes.bool.isRequired,
      }).isRequired,
    })
  ).isRequired,
  patientSites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      studies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          active: PropTypes.bool.isRequired,
          isRegistry: PropTypes.bool.isRequired,
          marketName: PropTypes.string,
          name: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};
