import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import IndicationDropdown from './IndicationDropdown';
import IndicationValueSearchField from './IndicationValueSearchField';
import StudyDropdown from './StudyDropdown';
import StudyStatusDropdown from './StudyStatusDropdown';
import IndicaitonValueTypes from 'entities/IndicationValueTypes';

function StudySection({ searchCriteria, onChange }) {
  return (
    <Row xs={2} className="gy-3">
      <Col>
        <StudyDropdown value={searchCriteria.studies} onChange={onChange} />
      </Col>
      <Col>
        <IndicationDropdown
          value={searchCriteria.indications}
          onChange={onChange}
          valueTypeFilter={IndicaitonValueTypes.RISK_SCORE}
        />
      </Col>
      <Col>
        <StudyStatusDropdown
          value={searchCriteria.studyStatuses}
          onChange={onChange}
        />
      </Col>
      <Col>
        <IndicationValueSearchField
          value={{
            min: searchCriteria.minIndicationValue,
            max: searchCriteria.maxIndicationValue,
          }}
          onChange={onChange}
        />
      </Col>
    </Row>
  );
}

StudySection.propTypes = {
  searchCriteria: PropTypes.shape({
    minIndicationValue: PropTypes.number,
    maxIndicationValue: PropTypes.number,
    studyStatuses: PropTypes.arrayOf(PropTypes.number),
    studies: PropTypes.arrayOf(PropTypes.number),
    indications: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        displayName: PropTypes.string,
        valueType: PropTypes.string,
      })
    ),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default StudySection;
