import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

export function usePatientSearch(
  query = '',
  pagination = { pageIndex: 0, pageSize: 25 },
  sorting = [{ id: null, desc: null }]
) {
  return useQuery(['patient-search', query, pagination, sorting], () => {
    if (query.toString().trim() === '') {
      return null;
    }
    return axios
      .get('/api/patients/search', {
        params: {
          query,
          pageIndex: pagination.pageIndex,
          pageSize: pagination.pageSize,
          sortField: sorting?.[0]?.id,
          sortDirection: sorting?.[0]?.desc ? 'DESC' : 'ASC',
        },
      })
      .then((res) => res.data);
  });
}

export function usePatients(
  filters = {},
  pagination = { pageIndex: 0, pageSize: 25 },
  sorting = [{ id: null, desc: null }]
) {
  return useQuery(
    ['patients', filters, pagination, sorting],
    () => {
      let path = '/api/patients';

      if (filters.statusGroup) {
        path += `/${filters.statusGroup.toLowerCase().replace(' ', '-')}`;
      }

      return axios
        .get(path, {
          params: {
            ...filters,
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize,
            sortField: sorting?.[0]?.id,
            sortDirection: sorting?.[0]?.desc ? 'DESC' : 'ASC',
          },
        })
        .then((res) => res.data);
    },
    { enabled: Boolean(filters.siteId) && Boolean(filters.studyId) }
  );
}

export function usePatient(patientId) {
  return useQuery(patientId && ['patient', patientId], () =>
    axios.get(`/api/patients/${patientId}`).then((res) => res.data)
  );
}

const createPatient = async (patient) => {
  const result = await axios.post('/api/patients', patient);
  return result.data;
};

export function useCreatePatient(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPatient,
    onSuccess: (patient) => {
      queryClient.invalidateQueries(['status-group-metrics']);
      queryClient.invalidateQueries(['patients']);

      options?.onSuccess?.(patient);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const updatePatientDoNotContact = async (patient) => {
  const { id, ...rest } = patient;
  const result = await axios.put(`/api/patients/${id}/do-not-contact`, rest);
  return result.data;
};

export function useUpdatePatientDoNotContact(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updatePatientDoNotContact,
    onSuccess: (patient) => {
      queryClient.invalidateQueries(['patients']);
      queryClient.invalidateQueries(['patient', patient.id]);
      queryClient.invalidateQueries(['patientRecruitmentHistory', patient.id]);
      options?.onSuccess?.(patient);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const updatePatient = async (patient) => {
  const { id, ...rest } = patient;
  const result = await axios.put(`/api/patients/${id}`, rest);
  return result.data;
};

export function useUpdatePatient(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updatePatient,
    onSuccess: (patient) => {
      queryClient.invalidateQueries(['patients']);
      queryClient.invalidateQueries(['patient', patient.id]);
      queryClient.invalidateQueries(['patientRecruitmentHistory', patient.id]);
      options?.onSuccess?.(patient);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const deletePatient = async (values) => {
  const { id } = values;
  await axios.delete('/api/patients', { data: { id } });
  return { id };
};

export function useDeletePatient(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePatient,
    onSuccess: ({ id }) => {
      queryClient.invalidateQueries(['status-group-metrics']);
      queryClient.invalidateQueries(['patients']);
      queryClient.invalidateQueries(['patient', id]);

      options?.onSuccess?.({ patientId: id });
    },
    onError: (error) => options?.onError?.(error),
  });
}
