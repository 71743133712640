import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

const createPatientResearchProfile = async (values) => {
  const { patientId, siteId } = values;

  const res = await axios.post(`/api/patients/${patientId}/research-profile`, {
    siteId,
  });
  return res.data;
};

export function useCreatePatientResearchProfile(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPatientResearchProfile,
    onSuccess: (data) => {
      queryClient.invalidateQueries(['patient']);

      options?.onSuccess?.(data);
    },
    onError: (error) => options?.onError?.(error),
  });
}

export default {
  useCreatePatientResearchProfile,
};
