import React from 'react';
import { Navigate } from 'react-big-calendar';

import { Col, Row } from 'react-bootstrap';
import PatientAppointmentsTable from './PatientAppointmentsTable';

export default function WeekListView() {
  return (
    <Row className="mx-2">
      <Col className="bg-white rounded">
        <PatientAppointmentsTable />
      </Col>
    </Row>
  );
}

WeekListView.propTypes = {};

WeekListView.range = (date, { localizer }) => ({
  start: localizer.startOf(date, 'week'),
  end: localizer.endOf(date, 'week'),
});

WeekListView.navigate = (date, action, { localizer }) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -1, 'week');

    case Navigate.NEXT:
      return localizer.add(date, 1, 'week');

    default:
      return date;
  }
};

WeekListView.title = (date, { localizer }) => {
  const { start, end } = WeekListView.range(date, { localizer });
  return localizer.format({ start, end }, 'dayRangeHeaderFormat');
};
