import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { Plus } from 'react-bootstrap-icons';

export default function ObservationButton({ className, onClick }) {
  return (
    <Button
      className={className}
      variant="outline-primary"
      size="sm"
      onClick={onClick}
    >
      <Plus className="align-text-top" size={18} /> Add Observation
    </Button>
  );
}

ObservationButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};
