/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { create } from 'zustand';
import StatusGroups from 'entities/StatusGroups';

const INITIAL_STATE_TOOLBAR = {
  site: null,
  distanceInMiles: -1,
  study: null,
};

const INITIAL_STATE_STATUS_GROUPS = {
  selectedGroup: StatusGroups.PROSPECTS,
  discriminator: 'Recommended',
};

const INITIAL_STATE_PATIENTS_TABLE = {
  pagination: {
    pageIndex: 0,
    pageSize: 25,
  },
  sorting: [],
};

const useDashboardStore = create((set, get) => ({
  toolbar: {
    ...INITIAL_STATE_TOOLBAR,
    updateSite: (site) => {
      set((state) =>
        produce(state, (draft) => {
          draft.toolbar.site = site;

          if (site.studies?.length > 0) {
            const [updatedStudy] = site.studies;
            draft.toolbar.study = updatedStudy;
          } else {
            draft.toolbar.study = null;
          }
        })
      );
    },
    updateDistanceInMiles: (distanceInMiles) => {
      set((state) =>
        produce(state, (draft) => {
          draft.toolbar.distanceInMiles = distanceInMiles;
        })
      );
    },
    updateStudy: (study) => {
      set((state) =>
        produce(state, (draft) => {
          draft.toolbar.study = study;
        })
      );
    },
  },
  statusGroups: {
    ...INITIAL_STATE_STATUS_GROUPS,
    updateStatusGroup: (statusGroup) => {
      if (statusGroup === get().statusGroups.selectedGroup) return;

      set((state) =>
        produce(state, (draft) => {
          draft.statusGroups.selectedGroup = statusGroup;

          draft.patientsTable = {
            ...draft.patientsTable,
            ...INITIAL_STATE_PATIENTS_TABLE,
          };

          switch (statusGroup) {
            case StatusGroups.PROSPECTS:
              draft.statusGroups.discriminator = 'Recommended';
              break;
            case StatusGroups.PRESCREEN:
              draft.statusGroups.discriminator = 'Prequalified';
              break;
            case StatusGroups.SCREENING:
              draft.statusGroups.discriminator = 'Eligible';
              break;
            case StatusGroups.ENROLLED:
              draft.statusGroups.discriminator = 'Enrolled';
              break;
            case StatusGroups.NOT_ELIGIBLE:
              draft.statusGroups.discriminator = 'Not Eligible';
              break;
            default:
              break;
          }
        })
      );
    },
    updateDiscriminator: (discriminator) => {
      set((state) =>
        produce(state, (draft) => {
          draft.statusGroups.discriminator = discriminator;
        })
      );
    },
  },
  patientsTable: {
    ...INITIAL_STATE_PATIENTS_TABLE,
    updatePagination: (pagination) => {
      set((state) =>
        produce(state, (draft) => {
          draft.patientsTable.pagination = pagination;
        })
      );
    },
    updateSorting: (sorting) => {
      set((state) =>
        produce(state, (draft) => {
          draft.patientsTable.sorting = sorting;
        })
      );
    },
  },
  resetDashboard: () => {
    set((state) =>
      produce(state, (draft) => {
        draft.toolbar = { ...draft.toolbar, ...INITIAL_STATE_TOOLBAR };
        draft.statusGroups = {
          ...draft.statusGroups,
          ...INITIAL_STATE_STATUS_GROUPS,
        };
        draft.patientsTable = {
          ...draft.patientsTable,
          ...INITIAL_STATE_PATIENTS_TABLE,
        };
      })
    );
  },
}));

export default useDashboardStore;
