/**
 * joinAvailable - Takes an array of strings that may contain empty strings
 * and returns a string with the non-empty strings joined by a separator.
 * Array items are trimmed before being joined.
 *
 * @param {Array<string>} arr
 * @param {string} separator
 * @returns {string}
 */
export function joinAvailable(arr, separator = ', ') {
  return arr
    .map((x) => x && x.toString().trim())
    .filter(Boolean)
    .join(separator);
}

/**
 * partition - Splits an array into two arrays based on a predicate specification.
 * @param {Array<{}>} arr
 * @param {({}) => Array<Array<{}>>} spec
 * @returns {Array<Array<{}>>}
 */
export function partition(arr, spec) {
  if (!arr || !spec) {
    return [[], []];
  }

  return [arr.filter((x) => spec(x)), arr.filter((x) => !spec(x))];
}
