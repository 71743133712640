import React, { useEffect } from 'react';
import { ListGroup, Tab, Tabs, Stack } from 'react-bootstrap';
import { Hospital, Person } from 'react-bootstrap-icons';
import { useParams, useSearchParams } from 'react-router-dom';
import OrganizationStudiesTab from './OrganizationStudiesTab';
import OrganizationReasonsTab from './ReasonsTab';
import SitesTab from './SitesTab';
import UsersTab from './UsersTab';
import LoadingIndicator from 'components/LoadingIndicator';
import { useOrganization } from 'hooks/organizationHooks';
import { formatPhoneDisplayV2 } from 'pages/utils/phoneUtils';

export default function Organization() {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const orgId = Number(params.organizationId);

  const organization = useOrganization(orgId);
  const selectedTab = searchParams.get('selectedTab');

  // preserve backPath in location state when updating selected_tab
  const handleTabSelect = (key) => {
    setSearchParams({ selectedTab: key }, { replace: true });
  };

  useEffect(() => {
    if (!searchParams.has('selectedTab'))
      setSearchParams(
        {
          selectedTab: 'sites',
        },
        { replace: true }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (organization.isLoading) return <LoadingIndicator />;

  const { name, useIdentityProvider, smsPrefix, smsOriginationNumber } =
    organization.data;

  return (
    <Stack gap={3}>
      <h2 className="display-2 text-info fw-bold mt-2">{name}</h2>

      <ListGroup className="mt-n2">
        <ListGroup.Item>
          <span className="fw-bold">Use Identity Provider:</span> &nbsp;
          {useIdentityProvider ? 'Yes' : 'No'}
        </ListGroup.Item>
        <ListGroup.Item>
          <span className="fw-bold">SMS Prefix:</span> &nbsp;
          {smsPrefix}
        </ListGroup.Item>
        <ListGroup.Item>
          <span className="fw-bold">SMS Origination Number:</span> &nbsp;
          {formatPhoneDisplayV2(smsOriginationNumber)}
        </ListGroup.Item>
      </ListGroup>
      <div>
        <Tabs activeKey={selectedTab} onSelect={(k) => handleTabSelect(k)}>
          <Tab
            eventKey="sites"
            title={
              <>
                <Hospital /> Sites
              </>
            }
          >
            <SitesTab />
          </Tab>
          <Tab eventKey="studies" title="Studies">
            <OrganizationStudiesTab />
          </Tab>
          <Tab
            eventKey="users"
            title={
              <>
                <Person /> Users
              </>
            }
          >
            <UsersTab />
          </Tab>
          <Tab eventKey="reasons" title="Reasons">
            <OrganizationReasonsTab />
          </Tab>
        </Tabs>
      </div>
    </Stack>
  );
}
