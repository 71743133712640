import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Alert, Button, Form, Row, Modal, Spinner } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';

import * as yup from 'yup';

import MultiSelectField from 'components/FormikForm/MultiSelectField';
import PhoneField from 'components/FormikForm/PhoneField';
import SelectField from 'components/FormikForm/SelectField';
import TextField from 'components/FormikForm/TextField';
import { statesArray } from 'entities/States';
import {
  useGenders,
  useEthnicGroups,
  useRaces,
  useLanguages,
} from 'hooks/demographicHooks';
import { useCreatePatient, useUpdatePatient } from 'hooks/patientHooks';
import { useSites } from 'hooks/siteHooks';

const PATIENT_MODAL_SCHEMA = yup.object({
  firstName: yup.string().required(),
  middleName: yup.string().nullable(),
  lastName: yup.string().required(),
  state: yup.string().length(2).nullable(),
  address: yup.string().nullable(),
  city: yup.string().nullable(),
  genderId: yup.number().integer().required(),
  ethnicGroupId: yup.number().integer().required(),
  raceId: yup.number().integer().required(),
  languae: yup.number().integer().nullable(),
  siteIds: yup.array().of(yup.number().integer()).min(1).required(),
  email: yup.string().email().nullable(),
  medicalRecordNumber: yup.string().required(),
  postalCode: yup
    .string()
    .required()
    .matches(/^\d{5}([ -]\d{4})?$/, 'Must be a valid zip code'),
  birthDate: yup
    .date()
    .required()
    .max(new Date(), 'Date cannot be in the future'),
  homePhone: yup
    .string()
    .matches(/^[0-9]{10}$/, 'Must be exactly 10 digits')
    .nullable(),
  mobilePhone: yup
    .string()
    .matches(/^[0-9]{10}$/, 'Must be exactly 10 digits')
    .nullable(),
});

const PATIENT_TEMPLATE = {
  id: 0,
  address: '',
  city: '',
  state: '',
  firstName: '',
  lastName: '',
  birthDate: '',
  genderId: '',
  ethnicGroupId: '',
  raceId: '',
  languageId: '',
  siteIds: [],
  medicalRecordNumber: '',
  postalCode: '',
  email: '',
  homePhone: '',
  mobilePhone: '',
  studyId: 0,
};

const LABEL_CLASS_NAME = 'small mb-0';

export default function PatientDemographicsModal({
  patient = PATIENT_TEMPLATE,
  onHide,
  redirectOnSave = false,
}) {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const ethnicGroups = useEthnicGroups();
  const genders = useGenders();
  const races = useRaces();
  const languages = useLanguages();
  const sites = useSites();
  const formRef = useRef();

  const handlePatientSaved = (createdPatient) => {
    onHide();

    if (redirectOnSave) {
      navigate(`/patients/${createdPatient.id}`);
    }
  };

  const handlePatientError = (err) => {
    setError(err.response?.data?.message);
  };

  const createPatient = useCreatePatient({
    onSuccess: handlePatientSaved,
    onError: handlePatientError,
  });

  const updatePatient = useUpdatePatient({
    onSuccess: handlePatientSaved,
    onError: handlePatientError,
  });

  const handleFormSubmit = (values) => {
    const patientValues = {
      ...patient,
      ...values,
      birthDate: new Date(values.birthDate),
    };

    if (patientValues.id === 0) {
      createPatient.mutate(patientValues);
    } else {
      updatePatient.mutate(patientValues);
    }
  };

  const handleSaveAndContinue = () => {
    formRef.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  };

  const sitesOptions =
    sites.data?.map((d) => ({
      value: d.id,
      label: d.name,
    })) ?? [];

  return (
    <Modal show backdrop="static" animation size="lg" centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Patient</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="danger" show={!!error}>
          {error}
        </Alert>

        <Formik
          validationSchema={PATIENT_MODAL_SCHEMA}
          onSubmit={handleFormSubmit}
          initialValues={patient}
          enableReinitialize
        >
          {({ handleSubmit }) => (
            <Form noValidate onSubmit={handleSubmit} ref={formRef}>
              <Row xs={2} className="gy-2">
                <TextField
                  focus
                  labelClassName={LABEL_CLASS_NAME}
                  label="First Name"
                  name="firstName"
                  placeholder="Jane"
                />
                <TextField
                  labelClassName={LABEL_CLASS_NAME}
                  label="Middle Name"
                  name="middleName"
                />
                <TextField
                  labelClassName={LABEL_CLASS_NAME}
                  label="Last Name"
                  name="lastName"
                />
                <TextField
                  labelClassName={LABEL_CLASS_NAME}
                  name="medicalRecordNumber"
                  label="Medical Record Number"
                  placeholder="100456"
                />
                <TextField
                  labelClassName={LABEL_CLASS_NAME}
                  name="birthDate"
                  label="Birth Date"
                  type="date"
                />
                <SelectField
                  labelClassName={LABEL_CLASS_NAME}
                  label="Gender"
                  name="genderId"
                  defaultDisplay="Select Gender"
                  options={genders.data}
                  valueAccessor="id"
                  displayAccessor="name"
                />
                <SelectField
                  labelClassName={LABEL_CLASS_NAME}
                  label="Race"
                  name="raceId"
                  defaultDisplay="Select Race"
                  options={races.data}
                  valueAccessor="id"
                  displayAccessor="name"
                />
                <SelectField
                  labelClassName={LABEL_CLASS_NAME}
                  label="Ethnic Group"
                  name="ethnicGroupId"
                  defaultDisplay="Select Ethnic Group"
                  options={ethnicGroups.data}
                  valueAccessor="id"
                  displayAccessor="name"
                />
                <TextField
                  labelClassName={LABEL_CLASS_NAME}
                  name="address"
                  label="Address"
                  placeholder="101 Objective Drive"
                />

                <TextField
                  labelClassName={LABEL_CLASS_NAME}
                  name="city"
                  label="City"
                  placeholder="Franklin"
                />
                <SelectField
                  labelClassName={LABEL_CLASS_NAME}
                  label="State"
                  name="state"
                  defaultDisplay="Select State"
                  options={statesArray()}
                  valueAccessor="abbreviation"
                  displayAccessor="name"
                />

                <TextField
                  labelClassName={LABEL_CLASS_NAME}
                  label="Postal Code"
                  name="postalCode"
                  placeholder="90210"
                />

                <PhoneField
                  label="Home Phone"
                  name="homePhone"
                  labelClassName={LABEL_CLASS_NAME}
                />

                <PhoneField
                  label="Mobile Phone"
                  name="mobilePhone"
                  labelClassName={LABEL_CLASS_NAME}
                />

                <MultiSelectField
                  labelClassName={LABEL_CLASS_NAME}
                  label="Sites"
                  name="siteIds"
                  placeholder="Select Sites"
                  options={sitesOptions}
                />

                <TextField
                  labelClassName={LABEL_CLASS_NAME}
                  label="Email"
                  name="email"
                  placeholder="example@email.com"
                />

                <SelectField
                  labelClassName={LABEL_CLASS_NAME}
                  label="Language"
                  name="languageId"
                  defaultDisplay="Select Language"
                  options={languages.data}
                  valueAccessor="id"
                  displayAccessor="description"
                />
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>

        <Button
          variant="primary"
          onClick={handleSaveAndContinue}
          disabled={createPatient.isLoading || updatePatient.isLoading}
        >
          Save &amp; Continue
          <Spinner
            animation="border"
            role="status"
            size="sm"
            className={`ms-2 ${
              createPatient.isLoading || updatePatient.isLoading ? '' : 'd-none'
            }`}
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

PatientDemographicsModal.defaultProps = {
  patient: PATIENT_TEMPLATE,
  redirectOnSave: false,
};

PatientDemographicsModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  patient: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  redirectOnSave: PropTypes.bool,
};
