import React, { useMemo } from 'react';
import { Nav, TabContainer, TabContent, TabPane } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import getStatusGroupTabs from './StatusGroupTabs';
import getPatientTableColumns from '../helpers/getPatientTableColumns';
import TableLayout from 'components/Layouts/TableLayout';
import paths from 'entities/SitePaths';
import { usePatients } from 'hooks/patientHooks';
import useDashboardStore from 'stores/dashboardStore';

export default function PatientsTable() {
  const {
    toolbar: { site, study, distanceInMiles },
    statusGroups: { selectedGroup, discriminator, updateDiscriminator },
    patientsTable: { pagination, sorting, updatePagination, updateSorting },
  } = useDashboardStore();

  const { data, isLoading } = usePatients(
    {
      siteId: site?.id,
      distanceInMiles,
      studyId: study?.id,
      statusGroup: selectedGroup,
      discriminator,
    },
    pagination,
    sorting
  );

  const navigate = useNavigate();
  const columns = useMemo(
    () => getPatientTableColumns(discriminator),
    [discriminator]
  );

  return (
    <div className="bg-light-gray ">
      <TabContainer defaultActiveKey="Recommended" activeKey={discriminator}>
        <Nav variant="tabs">
          {getStatusGroupTabs(selectedGroup, (tabName) =>
            updateDiscriminator(tabName)
          )}
        </Nav>
        <TabContent>
          <TabPane active>
            <TableLayout
              isLoading={isLoading}
              columns={columns}
              data={data}
              pagination={pagination}
              setPagination={(prev) => updatePagination(prev(pagination))}
              sorting={sorting}
              setSorting={(prev) => updateSorting(prev(sorting))}
              getRowProps={(row) => ({
                className: 'hoverable-row',
                onClick: () =>
                  navigate(`/patients/${row.original.id}`, {
                    state: { backPath: paths.DASHBOARD },
                  }),
              })}
              noResultsText="0 patients. Click Add Patient to add new records."
            />
          </TabPane>
        </TabContent>
      </TabContainer>
    </div>
  );
}
