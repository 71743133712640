/* eslint-disable react/jsx-no-constructed-context-values */
import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

import { useStudy } from 'hooks/studyHooks';

const StudyContext = createContext({});

function StudyProvider({ studyId, children }) {
  const [showStudyModal, setShowStudyModal] = useState(false);
  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const { data: study, isSuccess } = useStudy(studyId);

  return (
    isSuccess && (
      <StudyContext.Provider
        value={{
          study,
          showStudyModal,
          setShowStudyModal,
          showConfirmRemove,
          setShowConfirmRemove,
        }}
      >
        {children}
      </StudyContext.Provider>
    )
  );
}

StudyProvider.propTypes = {
  studyId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default StudyProvider;
export { StudyContext, StudyProvider };
