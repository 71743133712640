import React from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

import Header from './Header';
import { UserProvider } from '../Provider/UserProvider';
import AppQueryProvider from 'components/Provider/AppQueryProvider';

function AppLayout() {
  return (
    <UserProvider>
      <AppQueryProvider>
        <Header />
        <div style={{ height: 'calc(100vh - 60px)' }} id="content">
          <Container fluid className="h-100">
            <Outlet />
          </Container>
        </div>
      </AppQueryProvider>
    </UserProvider>
  );
}

export default AppLayout;
