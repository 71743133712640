import { useQuery } from '@tanstack/react-query';
import axios from '../axios';

export function useEncounterStatuses() {
  return useQuery(['encounterStatuses'], () =>
    axios.get('/api/encounter-statuses').then((res) => res.data)
  );
}

export function useEncounterTypes() {
  return useQuery(['encounterTypes'], () =>
    axios.get('/api/encounter-types').then((res) => res.data)
  );
}
