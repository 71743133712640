import { useMutation } from '@tanstack/react-query';
import axios from '../axios';

const createSupportRequest = async (request) => {
  const result = await axios.post(`/api/support-requests`, request);
  return result.data;
};

// eslint-disable-next-line import/prefer-default-export
export function useCreateSupportRequest(options) {
  return useMutation({
    mutationFn: createSupportRequest,
    onSuccess: () => {
      options?.onSuccess?.();
    },
    onError: (error) => options?.onError?.(error),
  });
}
