import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Plus } from 'react-bootstrap-icons';

import DocumentList from './DocumentList';
import DocumentModal from './DocumentModal';
import { DocumentProvider, DocumentContext } from './DocumentProvider';
import { UserContext, Roles } from 'components/Provider/UserProvider';

export default function DocumentsSection({ patientId }) {
  const { userRole } = useContext(UserContext);

  return (
    <DocumentProvider patientId={patientId}>
      <DocumentContext.Consumer>
        {({ addDocument }) => (
          <>
            <Row className="align-items-center">
              <Col className="d-flex align-items-center">
                <div className="display-3">Documents</div>
              </Col>
              <Col sm={2}>
                {Roles.canModifyRecords(userRole) && (
                  <Button
                    variant="outline-primary"
                    onClick={addDocument}
                    size="sm"
                    className="w-100"
                  >
                    <Plus size={18} /> Add Document
                  </Button>
                )}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <DocumentList patientId={patientId} />
              </Col>
            </Row>

            <DocumentModal />
          </>
        )}
      </DocumentContext.Consumer>
    </DocumentProvider>
  );
}

DocumentsSection.propTypes = {
  patientId: PropTypes.number.isRequired,
};
