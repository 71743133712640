import PropTypes from 'prop-types';
import React from 'react';
import SiteReasonsSection from './SiteReasonsSection';

import { useCallOutcomes } from 'hooks/callOutcomeHooks';
import {
  useSiteCallOutcomes,
  useCreateSiteCallOutcome,
  useDeleteSiteCallOutcome,
} from 'hooks/siteCallOutcomeHooks';

function CallOutcomesSection({ siteId }) {
  return (
    <SiteReasonsSection
      siteId={siteId}
      reasonType="Call Outcome"
      useSiteReasons={useSiteCallOutcomes}
      useOrgReasons={useCallOutcomes}
      useCreateSiteReason={useCreateSiteCallOutcome}
      useDeleteSiteReason={useDeleteSiteCallOutcome}
    />
  );
}

CallOutcomesSection.propTypes = {
  siteId: PropTypes.number.isRequired,
};

export default CallOutcomesSection;
