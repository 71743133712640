import PropTypes from 'prop-types';
import React from 'react';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';

import { Dash } from 'react-bootstrap-icons';
import InfoIconWithPopover from './InfoIconWithPopover';
import TableLayout from 'components/Layouts/TableLayout';
import LoadingIndicator from 'components/LoadingIndicator';
import { useIsAdmin } from 'hooks/adminHooks';
import { useSite, useUpdateSite } from 'hooks/siteHooks';

const COLUMNS = [
  {
    header: 'Source',
    cell: (props) => (
      <div>
        <div>{props.row.original.sourceName}</div>
        <div className="text-muted">{props.row.original.sourceId}</div>
      </div>
    ),
  },
  {
    header: 'Destination',
    cell: (props) => (
      <div>
        <div>{props.row.original.destinationName}</div>
        <div className="text-muted">{props.row.original.destinationId}</div>
      </div>
    ),
  },
  {
    accessorKey: 'medicalRecordNumberIdentifier',
    header: 'MRN Identifier',
  },
  {
    header: 'Clinical Summary Source',
    cell: (props) => (
      <div>
        <div>{props.row.original.clinicalSummarySourceName ?? <Dash />}</div>
        <div className="text-muted">
          {props.row.original.clinicalSummarySourceId ?? <Dash />}
        </div>
      </div>
    ),
  },
];

function SettingsTab({ siteId }) {
  const isAdmin = useIsAdmin();

  const site = useSite(siteId);
  const updateSite = useUpdateSite();

  const handleToggleTextReminders = (value) => {
    updateSite.mutate({ ...site.data, enableTextReminders: value });
  };

  if (site.isLoading) return <LoadingIndicator />;

  const { enableTextReminders, dataSources } = site.data;

  return (
    <Stack gap={2}>
      <div className="d-flex">
        <Form.Switch
          checked={enableTextReminders}
          type="switch"
          label="Enable SMS Appointment Reminders"
          onChange={(e) => handleToggleTextReminders(e.target.checked)}
          className="mt-1"
          disabled={!isAdmin}
          reverse
        />
      </div>
      <Stack direction="horizontal" gap={2}>
        <h5 className="mb-0">Data Sources</h5>

        <InfoIconWithPopover />
      </Stack>

      <TableLayout columns={COLUMNS} data={dataSources} />
    </Stack>
  );
}

export default SettingsTab;

SettingsTab.propTypes = {
  siteId: PropTypes.number.isRequired,
};
