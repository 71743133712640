import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';

import { Dash, FileEarmarkText } from 'react-bootstrap-icons';

import PatientNotePopover from 'components/PatientNotePopover';

export default function RecentNoteIcon({ id, show }) {
  if (!show) {
    return <Dash />;
  }

  return (
    <OverlayTrigger
      key={`patient-note-${id}`}
      placement="left"
      overlay={<PatientNotePopover patientId={id} />}
    >
      <FileEarmarkText />
    </OverlayTrigger>
  );
}

RecentNoteIcon.propTypes = {
  id: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
};
