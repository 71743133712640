import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

export function useSiteDocumentTypes(siteId) {
  return useQuery(
    ['site-document-types', Number(siteId)],
    () =>
      axios.get(`/api/sites/${siteId}/document-types`).then((res) => res.data),
    { enabled: Boolean(siteId) }
  );
}

const createSiteDocumentType = async (siteDocumentType) => {
  const result = await axios.post(
    `/api/sites/${siteDocumentType.siteId}/document-types`,
    siteDocumentType
  );
  return result.data;
};

export function useCreateSiteDocumentType(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createSiteDocumentType,
    onSuccess: (siteDocumentType) => {
      queryClient.invalidateQueries(['site', siteDocumentType.siteId]);
      queryClient.invalidateQueries([
        'site-document-types',
        siteDocumentType.siteId,
      ]);

      options?.onSuccess?.(siteDocumentType);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const deleteSiteDocumentType = async ({ siteId, documentTypeId }) => {
  const result = await axios.delete(
    `/api/sites/${siteId}/document-types/${documentTypeId}`
  );
  return result.data;
};

export function useDeleteSiteDocumentType(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteSiteDocumentType,
    onSuccess: (siteDocumentType) => {
      queryClient.invalidateQueries(['site', siteDocumentType.siteId]);
      queryClient.invalidateQueries([
        'site-document-types',
        siteDocumentType.siteId,
      ]);

      options?.onSuccess?.(siteDocumentType);
    },
    onError: (error) => options?.onError?.(error),
  });
}
