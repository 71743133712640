import PropTypes from 'prop-types';
import React from 'react';

export default function CheckedIndicator({ checked, variant }) {
  return (
    <div
      className={`d-flex justify-content-center text-white legend-item rounded-2 bg-${
        variant ?? 'primary'
      }`}
    >
      {checked && '✓'}
    </div>
  );
}

CheckedIndicator.defaultProps = {
  variant: 'primary',
};

CheckedIndicator.propTypes = {
  checked: PropTypes.bool.isRequired,
  variant: PropTypes.string,
};
