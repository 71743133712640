import React, { useState } from 'react';
import { Stack, FormCheck } from 'react-bootstrap';
import { Dash } from 'react-bootstrap-icons';
import { useParams } from 'react-router-dom';
import TableLayout from 'components/Layouts/TableLayout';
import { useOrganizationStudies } from 'hooks/organizationHooks';

const COLUMNS = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'market_name',
    header: 'Market Name',
  },
  {
    accessorFn: (row) => row.indication?.name,
    header: 'Indication',
    cell: (props) => props.getValue() ?? <Dash />,
  },
  {
    accessorKey: 'siteCount',
    header: 'Sites',
  },
];

export default function OrganizationStudiesTab() {
  const [showActiveStudies, setShowActiveStudies] = useState(true);

  const params = useParams();
  const orgId = Number(params.organizationId);

  const orgStudies = useOrganizationStudies(orgId, false);

  return (
    <Stack gap={2}>
      <Stack direction="horizontal">
        <FormCheck
          id="studyStatus-active"
          type="radio"
          name="studyStatus"
          label="Active"
          checked={showActiveStudies}
          onChange={() => setShowActiveStudies(true)}
        />

        <FormCheck
          id="studyStatus-inactive"
          className="ms-3"
          type="radio"
          name="studyStatus"
          label="Inactive"
          checked={!showActiveStudies}
          onChange={() => setShowActiveStudies(false)}
        />
      </Stack>
      <TableLayout
        isLoading={orgStudies.isLoading}
        columns={COLUMNS}
        data={orgStudies.data?.filter(
          (study) => study.active === showActiveStudies
        )}
      />
    </Stack>
  );
}
