import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Stack, Form, Alert } from 'react-bootstrap';
import * as yup from 'yup';
import TextField from 'components/FormikForm/TextField';
import ModalForm from 'components/ModalForm';
import { useCreateSupportRequest } from 'hooks/supportRequestHooks';

const SCHEMA = yup.object({
  subject: yup
    .string()
    .required()
    .max(100, 'Must be less than 100 characters.'),
  issue: yup
    .string()
    .required()
    .max(1000, 'Must be less than 1000 characters.'),
});

export default function SupportRequestModal({ onClose }) {
  const [appError, setAppError] = useState();

  const createSupportRequest = useCreateSupportRequest({
    onSuccess: () => onClose(),
    onError: (err) => setAppError(err),
  });

  const handleOnSubmit = (values) => createSupportRequest.mutate(values);

  return (
    <ModalForm
      onClose={onClose}
      formId="support-form"
      title="Support Request"
      isSubmitting={createSupportRequest.isLoading}
      modalHeadClassName="bg-info text-white"
      modalCloseVariant="white"
      submitButtonText="Send"
      validationSchema={SCHEMA}
      onSubmit={handleOnSubmit}
      initialValues={{}}
    >
      <Alert variant="danger" show={!!appError}>
        {appError?.message}
      </Alert>

      <Stack gap={3}>
        <TextField label="Subject" name="subject" />
        <TextField
          as="textarea"
          label="Issue Description"
          name="issue"
          maxLength="1000"
        />
        <Form.Text as="small">Max Characters: 1000</Form.Text>
      </Stack>
    </ModalForm>
  );
}

SupportRequestModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
