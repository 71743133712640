import PropTypes from 'prop-types';
import React from 'react';
import MultiSelect from '../BaseFields/MultiSelectField';
import LoadingIndicator from 'components/LoadingIndicator';
import { useRaces } from 'hooks/demographicHooks';

function RaceDropdown({ onChange, value }) {
  const { data, isLoading } = useRaces();

  if (isLoading) return <LoadingIndicator />;

  const options = data.map((d) => ({
    value: d.id,
    label: d.name,
  }));

  const selectedValues = options.filter((o) => value.includes(o.value));

  const handleOnChange = (selected) => {
    onChange('races', [...selected.map((s) => s.value)]);
  };

  return (
    <MultiSelect
      name="race"
      onChange={handleOnChange}
      selectedValues={selectedValues}
      placeholderText="Any"
      options={options}
    />
  );
}

RaceDropdown.defaultProps = {
  value: [],
};

RaceDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.number),
};

export default RaceDropdown;
