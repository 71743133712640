import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

export function useSites() {
  return useQuery(['sites'], () =>
    axios.get('/api/sites').then((res) => res.data)
  );
}

export function useSite(siteId) {
  return useQuery(siteId && ['site', siteId], () =>
    axios.get(`/api/sites/${siteId}`).then((res) => res.data)
  );
}

export function useSiteMetrics(pageIndex, pageSize, active = true) {
  return useQuery(['siteMetrics', pageIndex, pageSize, active], () =>
    axios
      .get('/api/admin/sites/metrics', {
        params: { pageIndex, pageSize, active },
      })
      .then((res) => res.data)
  );
}

const createSite = async (site) => {
  const result = await axios.post('/api/admin/sites', site);
  return result.data;
};

export function useCreateSite(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createSite,
    onSuccess: (site) => {
      queryClient.invalidateQueries(['siteMetrics']);
      queryClient.invalidateQueries(['sites']);

      options?.onSuccess?.(site);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const updateSite = async (site) => {
  const res = await axios.put(`/api/sites/${site.id}`, site);
  return res.data;
};

export function useUpdateSite(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateSite,
    onSuccess: (site) => {
      queryClient.invalidateQueries(['siteMetrics']);
      queryClient.invalidateQueries(['sites']);
      queryClient.invalidateQueries(['site', site.id]);

      options?.onSuccess?.(site);
    },
    onError: (error) => options?.onError?.(error),
  });
}

export function useUserSites(siteId) {
  return useQuery(['site', siteId, 'users'], () =>
    axios.get(`/api/sites/${siteId}/users`).then((res) => res.data)
  );
}
