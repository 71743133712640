import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OrganizationModal from '../components/OrganizationModal';
import AdminListLayout from '../Layouts/AdminListLayout';
import AddButton from 'components/Button/AddButton';
import TableLayout from 'components/Layouts/TableLayout';
import LoadingIndicator from 'components/LoadingIndicator';
import { useOrganizations } from 'hooks/organizationHooks';
import { usePagination } from 'hooks/tableHooks';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

const COLUMNS = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'active',
    header: 'Active',
    meta: {
      headerProps: { className: 'col-1 text-center' },
      cellProps: { className: 'text-center' },
    },
    cell: ({ getValue }) => (getValue() ? 'Yes' : 'No'),
  },
  {
    accessorKey: 'createdAt',
    header: 'Date Created',
    meta: {
      headerProps: { className: 'col-1 text-center text-nowrap px-3' },
      cellProps: { className: 'text-center' },
    },
    cell: ({ getValue }) => formatDateDisplay(getValue()),
  },
];

export default function OrganizationsList() {
  const navigate = useNavigate();
  const [pagination, setPagination] = usePagination();
  const [showModal, setShowModal] = useState(false);

  const { data: organizations, isLoading } = useOrganizations(
    pagination.pageIndex,
    pagination.pageSize
  );

  const handleAddOrganizationClick = () => {
    setShowModal(true);
  };

  const getRowProps = (row) => ({
    className: 'hoverable-row',
    onClick: () => navigate(`/admin/organizations/${row.original.id}`),
  });

  return (
    <AdminListLayout
      topRow={
        <AddButton onClick={handleAddOrganizationClick} label="Organization" />
      }
      table={
        isLoading ? (
          <LoadingIndicator />
        ) : (
          <TableLayout
            columns={COLUMNS}
            data={organizations}
            pagination={pagination}
            setPagination={setPagination}
            getRowProps={getRowProps}
          />
        )
      }
      modal={
        showModal && (
          <OrganizationModal
            organization={{}}
            onClose={() => setShowModal(false)}
          />
        )
      }
    />
  );
}
