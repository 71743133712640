import deepmerge from 'deepmerge';
import { useEffect, useState } from 'react';
import getEncounterConfiguration from 'entities/encounterConfigurationFactory';
import EncounterStatuses from 'entities/EncounterStatuses';
import { useEncounterStatuses, useEncounterTypes } from 'hooks/encounterHooks';
import {
  usePatientEncounter,
  useCreatePatientEncounter,
  useUpdatePatientEncounter,
} from 'hooks/patientEncounterHooks';
import { usePatient } from 'hooks/patientHooks';
import { usePatientMedications } from 'hooks/patientMedicationHooks';

export default function usePrescreenVisit(patientId, encounterId) {
  const { data: encounter, isLoading: encounterLoading } = usePatientEncounter(
    patientId,
    encounterId
  );
  const patient = usePatient(patientId);

  const { data: encounterStatuses, isLoading: encounterStatusesLoading } =
    useEncounterStatuses();
  const { data: encounterTypes, isLoading: encounterTypesLoading } =
    useEncounterTypes();
  const { data: medications, isLoading: medicationsLoading } =
    usePatientMedications(patientId);
  const createPatientEncounter = useCreatePatientEncounter();
  const updatePatientEncounter = useUpdatePatientEncounter();
  const [error, setError] = useState('');
  const [encounterMedications, setEncounterMedications] = useState([]);
  const [observations, setObservations] = useState({});

  const isLoading =
    encounterStatusesLoading ||
    encounterTypesLoading ||
    encounterLoading ||
    medicationsLoading ||
    patient.isLoading;

  useEffect(() => {
    if (!encounterLoading) {
      const updatedMedications = encounter.medications?.map((m, index) => ({
        ...m,
        dirty: false,
        index,
      }));

      setEncounterMedications(updatedMedications);

      const updatedObservations =
        encounter.observations?.reduce((acc, curr) => {
          const typeName = curr.observationType.name
            .toLowerCase()
            .split(' ')
            .join('_');
          const keyword = curr.observationCode.keyword.toLowerCase();

          acc[typeName] = acc[typeName] || {};

          acc[typeName][keyword] = {
            value: curr.value,
          };

          return acc;
        }, {}) ?? {};

      setObservations(updatedObservations);
    }
  }, [encounter, encounterLoading]);

  if (isLoading) {
    return { isLoading: true };
  }

  const encounterType = encounterTypes.find(
    (x) => x.id === encounter.encounterTypeId
  );
  const encounterStatus = encounterStatuses.find(
    (x) => x.id === encounter.encounterStatusId
  );
  const completedStatus = encounterStatuses.find(
    (x) => x.name === EncounterStatuses.COMPLETED
  );

  const config = getEncounterConfiguration(
    patientId,
    encounterType,
    encounterStatus
  );

  const initialFormValues = deepmerge(config.initialFormValues, observations);
  const { formSchema } = config;
  const { formComponent } = config;

  const handleSaveEncounter = async (values, isCompleting = false) => {
    const data = {
      ...encounter,
      observations: values.observations,
      medications: values.medications,
    };

    if (isCompleting) {
      data.encounterStatusId = completedStatus.id;
    }

    try {
      if (data.id) {
        await updatePatientEncounter.mutateAsync(data);
      } else {
        await createPatientEncounter.mutateAsync(data);
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Unable to save encounter data.');
    }
  };

  const onSave = async (values, isCompleting, callback) => {
    setError('');

    await handleSaveEncounter(values, isCompleting);
    callback?.();
  };

  return {
    isLoading,
    encounter,
    encounterMedications,
    medications,
    initialFormValues,
    formSchema,
    formComponent,
    error,
    onSave,
    isCompleted: encounter.encounterStatusId === completedStatus.id,
    isBusy:
      createPatientEncounter.isLoading || updatePatientEncounter.isLoading,
  };
}
