import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import SiteConnectionsTable from './SiteConnectionsTable';
import ConfirmationDialog from 'components/ConfirmationDialog';
import LoadingIndicator from 'components/LoadingIndicator';
import {
  useSiteConnections,
  useDeleteConnectionSite,
} from 'hooks/connectionHooks';

function ConfirmationBody() {
  return (
    <span>Are you sure you want to detach the site from the connection?</span>
  );
}

export default function SiteConnectionsList({ siteId }) {
  const [showConfirmRemoveConnection, setShowConfirmRemoveConnection] =
    useState(false);
  const [selectedConnection, setSelectedConnection] = useState();
  const [confirmationBody, setConfirmationBody] = useState('');

  const connections = useSiteConnections(siteId, true);

  const deleteConnectionSite = useDeleteConnectionSite({
    onSuccess: () => {
      setShowConfirmRemoveConnection(false);
    },
  });

  const confirmRemoveConnection = (connection) => {
    const newConfirmationBody = ConfirmationBody();

    setConfirmationBody(newConfirmationBody);

    setSelectedConnection(connection);
    setShowConfirmRemoveConnection(true);
  };

  const handleRemove = () => {
    deleteConnectionSite.mutate({
      connectionId: selectedConnection.id,
      siteId,
    });
  };

  if (connections.isLoading) return <LoadingIndicator />;

  return (
    <>
      <Row>
        <Col>
          <h5>Associated Connections</h5>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          {connections.data.length ? (
            <SiteConnectionsTable
              connections={connections.data}
              confirmRemoveConnection={confirmRemoveConnection}
            />
          ) : (
            <span className="fst-italic">
              Site has no associated connections...
            </span>
          )}
        </Col>
      </Row>
      <ConfirmationDialog
        show={showConfirmRemoveConnection}
        title="Confirm Remove"
        bodyText={confirmationBody}
        confirmText="Remove"
        onConfirm={handleRemove}
        onCancel={() => setShowConfirmRemoveConnection(false)}
      />
    </>
  );
}

SiteConnectionsList.propTypes = {
  siteId: PropTypes.number.isRequired,
};
