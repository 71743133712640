import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Alert, Button, Form, Modal, Spinner, Stack } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import CheckField from './FormikForm/CheckField';
import SelectField from './FormikForm/SelectField';
import TextField from './FormikForm/TextField';
import LoadingIndicator from './LoadingIndicator';
import { useIsAdmin } from 'hooks/adminHooks';
import { useRoles } from 'hooks/roleHooks';
import { useCreateUser, useUpdateUser, useUser } from 'hooks/userHooks';

const schema = yup.object({
  email: yup.string().email().required(),
  userName: yup.string().required(),
  fullName: yup.string().required(),
  active: yup.boolean().required(),
});

const schemaForOrgUser = yup.object({
  email: yup.string().email().required(),
  userName: yup.string().required(),
  fullName: yup.string().required(),
  roleId: yup.number().integer().required(),
  active: yup.boolean().required(),
});

export default function UserModal({ userId, onClose }) {
  const formRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [appError, setAppError] = useState();

  const isAdmin = useIsAdmin();

  const { data: user = { id: 0 }, isInitialLoading } = useUser(userId);
  const roles = useRoles();

  const handleUserSaved = (savedUser, redirect) => {
    onClose();

    if (redirect) {
      if (isAdmin) navigate(`/admin/users/${savedUser.id}`);
      else
        navigate(`/organizations/user/${savedUser.id}`, {
          state: { backPath: location.pathname },
        });
    }
  };

  const handleOnSubmitButtonClick = () => {
    setAppError();
    formRef.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  };

  const createUser = useCreateUser({
    onSuccess: (createdUser) => handleUserSaved(createdUser, true),
    onError: (e) =>
      setAppError({
        name: e.response?.data?.name,
        message: e.response?.data?.message,
      }),
  });

  const updateUser = useUpdateUser({
    onSuccess: (updatedUser) => {
      handleUserSaved(updatedUser, false);
    },
    onError: (e) =>
      setAppError({
        name: e.response?.data?.name,
        message: e.response?.data?.message,
      }),
  });

  const handleOnFormSubmit = (values) => {
    const userValues = {
      ...user,
      ...values,
    };

    if (userValues.id === 0) {
      createUser.mutate(values);
    } else {
      updateUser.mutate(values);
    }
  };

  const showRoleField = !isAdmin && user.id === 0;

  if (roles.isLoading || isInitialLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Modal
      show
      backdrop="static"
      keyboard={false}
      centered
      onHide={onClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="danger" show={!!appError}>
          {appError?.message}
        </Alert>
        <Formik
          validationSchema={isAdmin ? schema : schemaForOrgUser}
          onSubmit={handleOnFormSubmit}
          initialValues={{ active: true, ...user }}
        >
          {({ handleSubmit }) => (
            <Form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              ref={formRef}
            >
              <Stack gap={3}>
                <TextField
                  focus
                  label="Email"
                  name="email"
                  disabled={user.id !== 0}
                />
                <TextField
                  label="Username"
                  name="userName"
                  disabled={user.id !== 0}
                />
                <TextField label="Full Name" name="fullName" />
                {showRoleField && (
                  <SelectField
                    label="Role"
                    name="roleId"
                    defaultDisplay="Select Role..."
                    options={roles.data}
                    valueAccessor="id"
                    displayAccessor="name"
                  />
                )}
                <CheckField controlId="active" name="active" label="Active" />
              </Stack>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleOnSubmitButtonClick}
          disabled={createUser.isLoading || updateUser.isLoading}
        >
          {user.id === 0 ? 'Create' : 'Save'}
          <Spinner
            animation="border"
            role="status"
            size="sm"
            className={`ms-2 ${
              createUser.isLoading || updateUser.isLoading ? '' : 'd-none'
            }`}
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

UserModal.propTypes = {
  userId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};

UserModal.defaultProps = {
  userId: 0,
};
