import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import SiteStudiesTable from './SiteStudiesTable';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { useDeleteSiteStudy } from 'hooks/siteStudyHooks';

function ConfirmationBody(siteName, studyName) {
  return (
    <span>
      Are you sure you want to remove the{' '}
      <span className="fw-bold">{studyName}</span> study from the{' '}
      <span className="fw-bold">{siteName}</span> site?
    </span>
  );
}

export default function SiteStudyList({ context }) {
  const { study, showConfirmRemove, setShowConfirmRemove } =
    useContext(context);
  const [selectedSiteStudy, setSelectedSiteStudy] = useState();
  const [confirmationBody, setConfirmationBody] = useState('');

  const deleteSiteStudy = useDeleteSiteStudy({
    onSuccess: () => {
      setShowConfirmRemove(false);
    },
  });

  const confirmRemove = (site) => {
    const siteName = site.name;
    const newConfirmationBody = ConfirmationBody(siteName, study.name);

    setConfirmationBody(newConfirmationBody);

    setSelectedSiteStudy(site.SiteStudy);
    setShowConfirmRemove(true);
  };

  const handleRemove = () =>
    deleteSiteStudy.mutate({
      id: parseInt(selectedSiteStudy.id, 10),
      siteId: parseInt(selectedSiteStudy.siteId, 10),
      studyId: parseInt(selectedSiteStudy.studyId, 10),
    });

  return (
    <>
      <Row>
        <Col>
          <h5>Current Sites</h5>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={8}>
          {study.sites.length ? (
            <SiteStudiesTable study={study} confirmRemove={confirmRemove} />
          ) : (
            <span className="fst-italic">
              No sites assigned to this study...
            </span>
          )}
        </Col>
      </Row>
      <ConfirmationDialog
        show={showConfirmRemove}
        title="Confirm Remove"
        bodyText={confirmationBody}
        confirmText="Remove"
        onConfirm={handleRemove}
        onCancel={() => setShowConfirmRemove(false)}
      />
    </>
  );
}

SiteStudyList.propTypes = {
  context: PropTypes.shape({}).isRequired,
};
