import { useQuery } from '@tanstack/react-query';
import axios from '../axios';

export function useLatestPatientRemark(patientId) {
  return useQuery(patientId && ['latestPatientRemark', patientId], () =>
    axios
      .get(`/api/patients/${patientId}/remarks/latest`)
      .then((res) => res.data)
  );
}

export default {
  useLatestPatientRemark,
};
