import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

export function useUserMetrics(pageIndex, pageSize, query, active) {
  return useQuery(['userMetrics', pageIndex, pageSize, query, active], () =>
    axios
      .get('/api/admin/users/metrics', {
        params: { pageIndex, pageSize, query, active },
      })
      .then((res) => res.data)
  );
}

export function useUser(userId) {
  return useQuery(
    ['user', userId],
    () => axios.get(`/api/users/${userId}`).then((res) => res.data),
    { enabled: Boolean(userId) }
  );
}

export function useAssignableSites(userId) {
  return useQuery(['assignableSites', userId], () =>
    axios.get(`/api/users/${userId}/assignable-sites`).then((res) => res.data)
  );
}

export function useCurrentUser() {
  return useQuery(['currentUser'], async () => {
    const { data } = await axios.get(`/api/users/current`);
    return data;
  });
}

const createUser = async (user) => {
  const result = await axios.post('/api/users', user);
  return result.data;
};

export function useCreateUser(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createUser,
    onSuccess: (user) => {
      queryClient.invalidateQueries(['userMetrics']);
      queryClient.invalidateQueries(['users']);
      queryClient.invalidateQueries(['organizationUsers', user.organizationId]);

      options?.onSuccess?.(user);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const updateUser = async (user) => {
  const result = await axios.put(`/api/users`, user);
  return result.data;
};

export function useUpdateUser(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateUser,
    onSuccess: (user) => {
      queryClient.invalidateQueries(['userMetrics']);
      queryClient.invalidateQueries(['users']);
      queryClient.invalidateQueries(['user', user.id]);
      queryClient.invalidateQueries(['organizationUsers', user.organizationId]);
      queryClient.invalidateQueries(['site']);

      options?.onSuccess?.(user);
    },
    onError: (error) => options?.onError?.(error),
  });
}
