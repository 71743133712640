import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import { Stack, Button } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import UserOrganizationAddModal from './UserOrganizationAddModal';
import AddButton from 'components/Button/AddButton';
import ConfirmationDialog from 'components/ConfirmationDialog';
import TableLayout from 'components/Layouts/TableLayout';
import {
  useDeleteUserOrganization,
  useCreateUserOrganization,
  useUserOrganizations,
} from 'hooks/userOrganizationHooks';
import AdminListLayout from 'pages/Admin/Layouts/AdminListLayout';

export default function UserOrganizationList({ userId }) {
  const [selectedUserOrganization, setSelectedUserOrganization] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showConfirmRemove, setShowConfirmRemove] = useState(false);

  const userOrganizations = useUserOrganizations(userId);

  const deleteUserOrganization = useDeleteUserOrganization({
    onSuccess: () => {
      setShowConfirmRemove(false);
    },
  });

  const createUserOrganization = useCreateUserOrganization({
    onSuccess: () => {
      setShowModal(false);
    },
  });

  const confirmRemove = (organization) => {
    setSelectedUserOrganization(organization);
    setShowConfirmRemove(true);
  };

  const handleRemoveClick = () =>
    deleteUserOrganization.mutate({
      userId: parseInt(selectedUserOrganization.userId, 10),
      organizationId: parseInt(selectedUserOrganization.organizationId, 10),
    });

  const addOrganization = () => {
    setShowModal(true);
  };

  const handleOnSubmit = (formValues) => {
    createUserOrganization.mutate(formValues);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'organization.name',
        header: 'Organization',
      },
      {
        accessorKey: 'role',
        header: 'Role',
        cell: ({ row }) => row.original.role.name,
      },
      {
        id: 'actions',
        header: '',
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { className: 'text-center', style: { width: '60px' } },
        },
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row }) => (
          <Button
            size="sm"
            variant="dark"
            className="btn-lg btn-block"
            onClick={() => confirmRemove(row.original)}
          >
            <Trash />
          </Button>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Stack gap={2}>
      <AdminListLayout
        topRow={
          <AddButton
            label="Organization"
            onClick={addOrganization}
            className="mt-3"
          />
        }
        table={
          <TableLayout
            columns={columns}
            data={userOrganizations.data}
            isLoading={userOrganizations.isLoading}
          />
        }
        modal={
          showModal && (
            <UserOrganizationAddModal
              userId={userId}
              organizationIds={userOrganizations.data.map(
                (org) => org.organizationId
              )}
              onClose={() => setShowModal(false)}
              onSubmit={(formValues) => handleOnSubmit(formValues)}
              isSubmitting={deleteUserOrganization.isLoading}
            />
          )
        }
      />
      <ConfirmationDialog
        show={showConfirmRemove}
        title="Confirm Remove"
        bodyText="Are you sure you want to remove the user from this organization?"
        confirmText="Remove"
        cancelText="Cancel"
        onConfirm={handleRemoveClick}
        onCancel={() => setShowConfirmRemove(false)}
      />
    </Stack>
  );
}

UserOrganizationList.propTypes = {
  userId: PropTypes.number.isRequired,
};
