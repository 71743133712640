import React, { useState } from 'react';
import { Col, InputGroup, Row, Form, Tabs, Tab } from 'react-bootstrap';
import { Dash } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

import {
  StudyAdminProvider,
  StudyAdminContext,
} from './components/StudyAdminProvider';
import StudyButton from './components/StudyButton';
import StudyModal from '../components/StudyModal';
import AdminListLayout from '../Layouts/AdminListLayout';
import TableLayout from 'components/Layouts/TableLayout';
import { useStudyMetrics } from 'hooks/studyHooks';
import { usePagination } from 'hooks/tableHooks';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

const getValue = (props) => props.getValue() ?? <Dash />;

const getYesNoValue = (props) => (props.getValue() ? 'Yes' : 'No');

const getShortDate = (props) => formatDateDisplay(props.getValue());

const COLUMNS = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'marketName',
    header: 'Market Name',
  },
  {
    accessorKey: 'indication',
    header: 'Indication',
    cell: getValue,
  },
  {
    accessorKey: 'encounterType',
    header: 'Encounter Type',
    cell: getValue,
  },
  {
    accessorKey: 'isRegistry',
    header: 'Registry',
    cell: getYesNoValue,
    meta: {
      headerProps: { className: 'col-1 text-center' },
      cellProps: { className: 'text-center' },
    },
  },
  {
    accessorKey: 'isObservational',
    header: 'Observational',
    cell: getYesNoValue,
    meta: {
      headerProps: { className: 'col-1 text-center' },
      cellProps: { className: 'text-center' },
    },
  },
  {
    accessorKey: 'siteCount',
    header: 'Sites',
    meta: {
      headerProps: { className: 'col-1 text-center' },
      cellProps: { className: 'text-center' },
    },
    cell: getValue,
  },
  {
    accessorKey: 'createdAt',
    header: 'Date Created',
    meta: {
      headerProps: { className: 'col-1 text-center', style: { width: '10%' } },
      cellProps: { className: 'text-center' },
    },
    cell: getShortDate,
  },
];

export default function StudiesTable() {
  const [query, setQuery] = useState('');

  const navigate = useNavigate();

  const [activePagination, setActivePagination] = usePagination({
    pageSize: 25,
    pageIndex: 0,
  });

  const [inactivePagination, setInactivePagination] = usePagination({
    pageSize: 25,
    pageIndex: 0,
  });

  const activeStudies = useStudyMetrics(
    activePagination.pageIndex,
    activePagination.pageSize,
    query,
    'ACTIVE'
  );

  const inactiveStudies = useStudyMetrics(
    inactivePagination.pageIndex,
    inactivePagination.pageSize,
    query,
    'INACTIVE'
  );

  const handleSearch = (e) => {
    e.preventDefault();
    setQuery(e.target.value);
  };

  const getRowProps = (row) => ({
    className: 'hoverable-row',
    onClick: () => navigate(`/admin/studies/${row.original.studyId}`),
  });

  return (
    <StudyAdminProvider>
      <AdminListLayout
        topRow={
          <Row className="mb-2">
            <Col>
              <StudyButton context={StudyAdminContext} />
            </Col>
            <Col>
              <Form className="float-end">
                <InputGroup className="mb-0 h-100 flex-nowrap justify-content-end">
                  <Form.Control
                    placeholder="Search by name or protocol"
                    value={query || ''}
                    onChange={handleSearch}
                    style={{ minWidth: '200px' }}
                    onSubmit={(e) => e.preventDefault()}
                  />
                </InputGroup>
              </Form>
            </Col>
          </Row>
        }
        modal={<StudyModal context={StudyAdminContext} />}
        table={
          <Tabs defaultActiveKey="active">
            <Tab eventKey="active" title="Active">
              <TableLayout
                isLoading={activeStudies.isLoading}
                columns={COLUMNS}
                data={activeStudies.data}
                getRowProps={getRowProps}
                pagination={activePagination}
                setPagination={setActivePagination}
              />
            </Tab>
            <Tab eventKey="inactive" title="Inactive">
              <TableLayout
                isLoading={inactiveStudies.isLoading}
                columns={COLUMNS}
                data={inactiveStudies.data}
                getRowProps={getRowProps}
                pagination={inactivePagination}
                setPagination={setInactivePagination}
              />
            </Tab>
          </Tabs>
        }
        tableStyles="bg-body"
      />
    </StudyAdminProvider>
  );
}
