import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.scss';

import AppLayout from './components/Layouts/AppLayout';
import ProtectedRoute from './components/ProtectedRoute';

import Admin from './pages/Admin';
import AdminLayout from './pages/Admin/Layouts/AdminLayout';
import Dashboard from './pages/Dashboard';
import EncounterDetails from './pages/Encounter';
import Messages from './pages/Messages';
import PatientDetails from './pages/Patient';
import Schedule from './pages/Schedule';
import Search from './pages/Search';
import ConnectionDetails from 'pages/Admin/ConnectionDetails';
import ConnectionsList from 'pages/Admin/ConnectionsList';
import IndicationsList from 'pages/Admin/IndicationsList';
import OrganizationDetails from 'pages/Admin/OrganizationDetails';
import OrganizationsList from 'pages/Admin/OrganizationsList';
import ReasonsList from 'pages/Admin/ReasonsList';
import SiteDetails from 'pages/Admin/SiteDetails';
import SitesList from 'pages/Admin/SitesList';
import StudiesList from 'pages/Admin/StudiesList';
import StudyDetails from 'pages/Admin/StudyDetails';
import StudyMatchingConfigurations from 'pages/Admin/StudyMatchingConfigurations';
import UserDetails from 'pages/Admin/UserDetails';
import UsersList from 'pages/Admin/UsersList';
import FeasibilitySearch from 'pages/FeasibilitySearch';
import LoginError from 'pages/LoginError';
import Organization from 'pages/Organization';
import OrganizationSite from 'pages/Organization/Site';
import OrganizationUserDetails from 'pages/Organization/UserDetails';

function App() {
  const { isLoading, isAuthenticated, loginWithRedirect, error, logout } =
    useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (!isLoading && error) {
    return <LoginError logout={logout} />;
  }

  if (isLoading) {
    return null;
  }

  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute component={AppLayout} />}>
        <Route index element={<Dashboard />} />
        <Route path="admin" element={<AdminLayout />}>
          <Route path="indications" element={<IndicationsList />} />
          <Route path="organizations" element={<OrganizationsList />} />
          <Route path="organizations/:id" element={<OrganizationDetails />} />
          <Route path="sites" element={<SitesList />} />
          <Route path="sites/:id" element={<SiteDetails />} />
          <Route path="studies" element={<StudiesList />} />
          <Route path="studies/:id" element={<StudyDetails />} />
          <Route path="connections" element={<ConnectionsList />} />
          <Route path="connections/:id" element={<ConnectionDetails />} />
          <Route path="users" element={<UsersList />} />
          <Route path="users/:id" element={<UserDetails />} />
          <Route path="reasons" element={<ReasonsList />} />
          <Route
            path="study-matching"
            element={<StudyMatchingConfigurations />}
          />
          <Route index element={<Admin />} />
        </Route>
        <Route path="patients">
          <Route
            path=":patientId/encounters/:encounterId"
            element={<EncounterDetails />}
          />
          <Route path=":patientId" element={<PatientDetails />} />
        </Route>
        <Route path="schedule" element={<Schedule />} />
        <Route path="search" element={<Search />} />
        <Route path="messages" element={<Messages />} />
        <Route path="feasibility" element={<FeasibilitySearch />} />
        <Route path="organizations">
          <Route path=":organizationId" element={<Organization />} />
          <Route path="sites/:siteId" element={<OrganizationSite />} />
          <Route path="user/:id" element={<OrganizationUserDetails />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
