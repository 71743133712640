export default Object.freeze({
  ADMIN: '/admin',
  DASHBOARD: '/',
  FEASIBILITY: '/feasibility',
  MESSAGES: '/messages',
  PATIENTS: '/patients',
  SCHEDULE: '/schedule',
  SEARCH: '/search',
  MY_ORGANIZATION: '/organizations',
});
