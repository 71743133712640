import PropTypes from 'prop-types';
import React from 'react';
import {
  OverlayTrigger,
  Popover,
  PopoverHeader,
  PopoverBody,
  ListGroup,
  ListGroupItem,
} from 'react-bootstrap';
import { Dash } from 'react-bootstrap-icons';

/** @type {React.ForwardRefExoticComponent<any, any>} */
const StringArrayPopover = React.forwardRef(
  // eslint-disable-next-line no-unused-vars
  ({ displayText, header, items }, ref) => {
    if (!displayText) {
      return <Dash />;
    }

    return (
      <OverlayTrigger
        overlay={
          <Popover>
            {header && <PopoverHeader>{header}</PopoverHeader>}
            <PopoverBody className="p-1">
              <ListGroup variant="flush">
                {items.map((item) => (
                  <ListGroupItem key={item}>{item}</ListGroupItem>
                ))}
              </ListGroup>
            </PopoverBody>
          </Popover>
        }
      >
        <span style={{ cursor: 'pointer' }} className="user-select-none">
          {displayText}
        </span>
      </OverlayTrigger>
    );
  }
);

export default StringArrayPopover;

StringArrayPopover.defaultProps = {
  displayText: '',
  header: '',
  items: [],
};

StringArrayPopover.propTypes = {
  displayText: PropTypes.string,
  header: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
};
