import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import MessagesTable from './components/MessagesTable';
import SiteList from './components/SiteList';
import LoadingIndicator from 'components/LoadingIndicator';
import { useRecentPatientMessages } from 'hooks/patientMessageHooks';
import { useSites } from 'hooks/siteHooks';
import { hasItemsCreatedToday } from 'pages/utils/dateTimeUtils';
import { partition } from 'pages/utils/helpers';

const defaultPageSize = 20;

const fromDate = DateTime.now().minus({ days: 3 }).startOf('day').toJSDate();

export default function Messages() {
  const { data: siteData, isLoading: sitesLoading } = useSites();
  const { data: patientData, isLoading: patientsLoading } =
    useRecentPatientMessages(fromDate);

  const [selectedSiteId, setSelectedSiteId] = useState();
  const [sites, setSites] = useState([]);

  useEffect(() => {
    if (siteData && patientData) {
      const items = siteData.map((s) => {
        const patientIds = patientData
          .filter(
            (p) =>
              p.sites.map((site) => site.id).indexOf(s.id) !== -1 &&
              hasItemsCreatedToday(p.interactions)
          )
          .map((p) => p.id);
        return { ...s, messageCount: new Set(patientIds).size };
      }, []);

      setSites(items);

      if (items.length > 0) {
        setSelectedSiteId(items[0].id);
      }
    }
  }, [siteData, patientData, setSites]);

  if (sitesLoading || patientsLoading) {
    return <LoadingIndicator />;
  }

  const sitePatients = patientData.filter(
    (p) => p.sites.map((site) => site.id).indexOf(selectedSiteId) !== -1
  );

  const grouped = partition(sitePatients, (patient) =>
    hasItemsCreatedToday(patient.interactions)
  );

  const unreadData = {
    rows: grouped[0],
    pageCount: Math.ceil(grouped[0].length / defaultPageSize),
  };

  const historyData = {
    rows: grouped[1],
    pageCount: Math.ceil(grouped[1].length / defaultPageSize),
  };

  return (
    <Row id="messages" className="bg-light-gray h-100 overflow-auto py-4 py-1">
      <Col id="sidebar" md="2" className="col-sm-12 pe-md-0">
        <SiteList
          sites={sites}
          selectedSiteId={selectedSiteId}
          onSelect={(site) => setSelectedSiteId(site.id)}
        />
      </Col>
      <Col md={10} className="">
        <div className="mx-2">
          <Row>
            <Col>
              <h4 className="text-primary display-2">
                Today&apos;s Messages
                <span className="text-muted ms-3 smaller fst-italic align-middle">{`${unreadData.rows.length} total`}</span>
              </h4>
            </Col>
          </Row>
          <Row className="rounded bg-white pt-3 pb-1">
            <Col>
              <MessagesTable
                data={unreadData}
                isLoading={false}
                defaultPageSize={defaultPageSize}
              />
            </Col>
          </Row>
        </div>

        {historyData.rows.length > 0 && (
          <div className="mx-2">
            <hr />
            <Row className="mt-4 mb-2">
              <Col>
                <h4 className="text-info display-2">History</h4>
              </Col>
            </Row>
            <Row className="rounded bg-white pt-3 pb-1">
              <Col>
                <MessagesTable
                  data={historyData}
                  isLoading={false}
                  defaultPageSize={defaultPageSize}
                />
              </Col>
            </Row>
          </div>
        )}
      </Col>
    </Row>
  );
}
