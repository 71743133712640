import { useQuery } from '@tanstack/react-query';
import axios from '../axios';

export function useMedicationCodes() {
  return useQuery(['medicationCodes'], () =>
    axios.get('/api/medications/codes').then((res) => res.data)
  );
}

export function useMedicationFrequencies() {
  return useQuery(['medicationFrequencies'], () =>
    axios.get('/api/medications/frequencies').then((res) => res.data)
  );
}
