import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Alert, Stack } from 'react-bootstrap';
import * as yup from 'yup';
import CheckField from 'components/FormikForm/CheckField';
import SelectField from 'components/FormikForm/SelectField';
import TextField from 'components/FormikForm/TextField';
import ModalForm from 'components/ModalForm';
import IndicationValueTypes from 'entities/IndicationValueTypes';
import {
  useCreateindication,
  useUpdateindication,
} from 'hooks/indicationHooks';

const schema = yup.object({
  name: yup.string().required(),
  displayName: yup.string().required(),
  valueType: yup.string().required().oneOf(Object.values(IndicationValueTypes)),
  active: yup.boolean().required(),
});

const INITIAL_VALUES = {
  id: null,
  active: true,
};

export default function IndicationModal({
  indication = INITIAL_VALUES,
  onClose,
}) {
  const [appError, setAppError] = useState();

  const createIndication = useCreateindication({
    onSuccess: onClose,
    onError: (e) =>
      setAppError({
        name: e.response?.data?.name,
        message: e.response?.data?.message,
      }),
  });

  const updateIndication = useUpdateindication({
    onSuccess: onClose,
    onError: (e) =>
      setAppError({
        name: e.response?.data?.name,
        message: e.response?.data?.message,
      }),
  });

  const handleFormSubmit = (values) => {
    const indicationValues = { ...indication, ...values };

    if (indicationValues.id === null) {
      createIndication.mutate(values);
    } else {
      updateIndication.mutate(values);
    }
  };

  const options = Object.entries(IndicationValueTypes).map(([, value]) => ({
    value,
  }));

  return (
    <ModalForm
      title="Indication"
      onClose={onClose}
      onSubmit={handleFormSubmit}
      isSubmitting={createIndication.isLoading || updateIndication.isLoading}
      initialValues={indication}
      validationSchema={schema}
      formId="site-indication-form"
      submitButtonText={!indication.id ? 'Create' : 'Save'}
    >
      {appError && (
        <Alert variant="danger" dismissible>
          {appError.message}
        </Alert>
      )}

      <Stack gap={2}>
        <TextField focus label="Name" name="name" />
        <TextField label="Display Name" name="displayName" />

        <SelectField
          label="Value Type"
          name="valueType"
          defaultDisplay="Select Value Type..."
          options={options}
          valueAccessor="value"
          displayAccessor="value"
        />
        <CheckField name="active" label="Active" type="switch" />
      </Stack>
    </ModalForm>
  );
}

IndicationModal.defaultProps = {
  indication: { id: 0 },
};

IndicationModal.propTypes = {
  indication: PropTypes.shape({
    id: PropTypes.number,
    organizationId: PropTypes.number,
  }),
  onClose: PropTypes.func.isRequired,
};
