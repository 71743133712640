import PropTypes from 'prop-types';
import React from 'react';
import { FormControl } from 'react-bootstrap';
import Field from '../BaseFields/Field';

function ZipSearchField({ onChange, value }) {
  const handleOnChange = (e) => {
    onChange('postalCode', e.target.value);
  };

  return (
    <Field label="Zip">
      <FormControl
        placeholder="Any"
        size="sm"
        type="number"
        onChange={handleOnChange}
        value={value}
      />
    </Field>
  );
}

ZipSearchField.defaultProps = {
  value: '',
};

ZipSearchField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default ZipSearchField;
