import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import TableLayout from 'components/Layouts/TableLayout';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';
import { sortAlphabeticallyByProperty } from 'pages/utils/sort';

const ALL_SITES_OPTION = '';

export default function PatientRegistriesSection({
  patientRegistries = [],
  patientSites = [],
}) {
  const [selectedSiteId, setSelectedSiteId] = useState(ALL_SITES_OPTION);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Registry Name',
        meta: {
          headerProps: {
            className: 'display-4 fw-bold text-uppercase',
            style: { width: '35%' },
          },
        },
      },
      {
        accessorKey: 'sites',
        header: 'Site Name',
        meta: {
          headerProps: {
            className: 'display-4 fw-bold text-uppercase',
            style: { width: '35%' },
          },
        },
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ getValue }) => {
          const sites = getValue();

          return (
            <>
              {sites.map((registrySite) => (
                <div key={registrySite.id}>{registrySite.name}</div>
              ))}
            </>
          );
        },
      },
      {
        accessorKey: 'patientAddedToRegistryAt',
        header: 'Added to Registry',
        meta: {
          headerProps: {
            className: 'text-center display-4 fw-bold text-uppercase',
          },
          cellProps: { className: 'text-center' },
        },
        cell: ({ getValue }) => formatDateDisplay(getValue()),
      },
    ],
    []
  );

  const displayedRegistries = useMemo(() => {
    if (!patientSites.length) return [];

    let registriesToReturn = [];

    // Returning all of the registries for all of the patientSites.
    if (selectedSiteId === ALL_SITES_OPTION) {
      const allRegistries = [];

      for (let i = 0; i < patientSites.length; i += 1) {
        const patientSite = patientSites[i];
        const patientSiteRegistries = patientSite.studies?.filter(
          (s) =>
            s.isRegistry === true &&
            patientRegistries.some((pr) => pr.studyId === s.id)
        );

        for (let j = 0; j < patientSiteRegistries.length; j += 1) {
          const registryToDisplay = patientSiteRegistries[j];

          if (
            typeof allRegistries.find(
              (study) => study.id === registryToDisplay.id
            ) === 'undefined'
          ) {
            allRegistries.push(registryToDisplay);
          }
        }
      }

      sortAlphabeticallyByProperty(allRegistries, 'name');

      registriesToReturn = allRegistries;
    } else {
      registriesToReturn = patientSites
        .find((ps) => ps.id === Number(selectedSiteId))
        .studies?.filter(
          (s) =>
            s.isRegistry === true &&
            patientRegistries.some((pr) => pr.studyId === s.id)
        );
    }

    for (let i = 0; i < registriesToReturn.length; i += 1) {
      const patientRegistry = patientRegistries.find(
        (pr) => pr.StudyId === registriesToReturn[i].id
      );

      if (patientRegistry) {
        registriesToReturn[i].patientAddedToRegistryAt =
          patientRegistry.createdAt;
      }

      // We need to add information about all sites associated with given registries.
      registriesToReturn[i].sites = [];

      for (let j = 0; j < patientSites.length; j += 1) {
        if (
          typeof patientSites[j].studies.find(
            (s) => s.id === registriesToReturn[i].id
          ) !== 'undefined'
        ) {
          registriesToReturn[i].sites.push({
            id: patientSites[j].id,
            name: patientSites[j].name,
          });
        }
      }
    }

    // Excluding inactive registries.
    return registriesToReturn.filter((r) => r.active);
  }, [selectedSiteId, patientRegistries]);

  return (
    <>
      <div className="d-flex justify-content-between my-2">
        <div className="display-3">Registries</div>
        <div>
          {patientSites.length > 0 && (
            <select
              className="form-select w-auto"
              value={selectedSiteId}
              onChange={(e) => setSelectedSiteId(e.target.value)}
            >
              {/* There is no point displaying "All" option if only one site is available. */}
              {patientSites.length > 1 && (
                <option key={ALL_SITES_OPTION} value={ALL_SITES_OPTION}>
                  All Sites
                </option>
              )}

              {patientSites.map((ps) => (
                <option key={ps.id} value={ps.id}>
                  {ps.name}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>
      <TableLayout
        className="mt-1"
        columns={columns}
        data={displayedRegistries}
      />
    </>
  );
}

PatientRegistriesSection.defaultProps = {
  patientRegistries: [],
  patientSites: [],
};

PatientRegistriesSection.propTypes = {
  patientRegistries: PropTypes.arrayOf(PropTypes.shape({})),
  patientSites: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })
  ),
};
