import React, { useState } from 'react';
import { Stack, Tab, Nav } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import SiteAdd from './components/SiteAdd';
import UserInfo from './components/UserInfo';
import UserOrganizationAddModal from './components/UserOrganizationAddModal';
import UserOrganizationList from './components/UserOrganizationList';
import UserSiteList from './components/UserSiteList';
import { useCreateOrganizationUser } from 'hooks/organizationHooks';
import { useUser } from 'hooks/userHooks';

function UserDetails() {
  const params = useParams();
  const userId = Number(params.id);
  const [showModal, setShowModal] = useState(false);

  const user = useUser(userId);
  const createOrganizationuser = useCreateOrganizationUser();

  const handleClose = () => setShowModal(false);

  const handleOnSubmit = (id, organizationId, roleId) => {
    createOrganizationuser.mutate(id, organizationId, roleId);
    handleClose();
  };

  if (user.isLoading) return null;

  return (
    <Stack gap={2}>
      <UserInfo user={user.data} />
      <div>
        <Tab.Container defaultActiveKey="user-organizations">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="user-organizations">Organizations</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="user-sites">Sites</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="user-organizations">
              <UserOrganizationList userId={userId} />
            </Tab.Pane>
            <Tab.Pane eventKey="user-sites">
              <Stack gap={2} className="pt-2">
                <SiteAdd user={user.data} />
                <UserSiteList user={user.data} />
              </Stack>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      {showModal && (
        <UserOrganizationAddModal
          user={user}
          onClose={handleClose}
          onSubmit={handleOnSubmit}
          isSubmitting={createOrganizationuser.isLoading}
        />
      )}
    </Stack>
  );
}

export default UserDetails;
