import PropTypes from 'prop-types';
import React from 'react';
import MultiSelect from '../BaseFields/MultiSelectField';
import LoadingIndicator from 'components/LoadingIndicator';
import { getGroupedOptions } from 'components/MultiSelect';
import { useStudies } from 'hooks/studyHooks';

function StudyDropdown({ onChange, value }) {
  const { data, isLoading } = useStudies(false);

  if (isLoading) return <LoadingIndicator />;

  const optionData = data.map((d) => ({
    value: d.id,
    label: d.name,
    active: d.active,
  }));

  const options = getGroupedOptions(
    optionData,
    (o) => (o.active ? 'Active' : 'Inactive'),
    'value',
    'label'
  );

  const selectedValues = options.flatMap((x) =>
    x.options.filter((y) => value.includes(y.value))
  );

  const handleOnChange = (selected) => {
    onChange('studies', [...selected.map((s) => s.value)]);
  };

  return (
    <MultiSelect
      name="Study"
      onChange={handleOnChange}
      selectedValues={selectedValues}
      placeholderText="Any"
      options={options}
    />
  );
}

StudyDropdown.defaultProps = {
  value: [],
};

StudyDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.number),
};

export default StudyDropdown;
