import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

export function useSiteCancellationReasons(siteId) {
  return useQuery(
    ['site-cancellation-reasons', Number(siteId)],
    () =>
      axios
        .get(`/api/sites/${siteId}/cancellation-reasons`)
        .then((res) => res.data),
    { enabled: Boolean(siteId) }
  );
}

const createSiteCancellationReason = async ({
  siteId,
  cancellationReasonId,
}) => {
  const result = await axios.post(`/api/sites/${siteId}/cancellation-reasons`, {
    cancellationReasonId,
  });
  return result.data;
};

export function useCreateSiteCancellationReason(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createSiteCancellationReason,
    onSuccess: (siteCancellationReason) => {
      queryClient.invalidateQueries(['site', siteCancellationReason.siteId]);
      queryClient.invalidateQueries([
        'site-cancellation-reasons',
        siteCancellationReason.siteId,
      ]);

      options?.onSuccess?.(siteCancellationReason);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const deleteSiteCancellationReason = async ({
  siteId,
  cancellationReasonId,
}) => {
  const result = await axios.delete(
    `/api/sites/${siteId}/cancellation-reasons/${cancellationReasonId}`
  );
  return result.data;
};

export function useDeleteSiteCancellationReason(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteSiteCancellationReason,
    onSuccess: (siteCancellationReason) => {
      queryClient.invalidateQueries(['site', siteCancellationReason.siteId]);
      queryClient.invalidateQueries([
        'site-cancellation-reasons',
        siteCancellationReason.siteId,
      ]);

      options?.onSuccess?.(siteCancellationReason);
    },
    onError: (error) => options?.onError?.(error),
  });
}
