import PropTypes from 'prop-types';
import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import {
  useCreatePatientDiagnosis,
  useUpdatePatientDiagnosis,
} from 'hooks/patientDiagnosisHooks';

const diagnosisTemplate = {
  id: 0,
  patientId: '',
  diagnosisCodeId: '',
  startDate: '',
  endDate: '',
  diagnosisStatusId: '',
};

const DiagnosisContext = createContext();

function DiagnosisProvider({ patientId, children }) {
  const [diagnosis, setDiagnosis] = useState(diagnosisTemplate);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);

  const mutationOptions = {
    onSuccess: () => setShowModal(false),
    onError: (e) => setError(e.response?.data?.message),
  };

  const createPatientDiagnosis = useCreatePatientDiagnosis(mutationOptions);
  const updatePatientDiagnosis = useUpdatePatientDiagnosis(mutationOptions);

  useEffect(() => {
    setError('');
  }, [showModal]);

  const closeModal = () => {
    setShowModal(false);
  };

  const addDiagnosis = () => {
    setDiagnosis(diagnosisTemplate);
    setShowModal(true);
  };

  const editDiagnosis = (selectedDiagnosis) => {
    setDiagnosis(selectedDiagnosis);
    setShowModal(true);
  };

  const saveDiagnosis = useCallback(
    (values) => {
      setError('');

      const data = {
        ...values,
        patientId,
        startDate: values.startDate ? new Date(values.startDate) : null,
        endDate: values.endDate ? new Date(values.endDate) : null,
        diagnosisStatusId: values.diagnosisStatusId || null,
      };

      if (values.id === 0) {
        createPatientDiagnosis.mutate(data);
      } else {
        updatePatientDiagnosis.mutate(data);
      }
    },
    [createPatientDiagnosis, patientId, updatePatientDiagnosis]
  );

  const isBusy =
    createPatientDiagnosis.isLoading || updatePatientDiagnosis.isLoading;

  const value = useMemo(
    () => ({
      diagnosis,
      error,
      showModal,
      closeModal,
      addDiagnosis,
      editDiagnosis,
      saveDiagnosis,
      isBusy,
    }),
    [diagnosis, error, isBusy, saveDiagnosis, showModal]
  );

  return (
    <DiagnosisContext.Provider value={value}>
      {children}
    </DiagnosisContext.Provider>
  );
}

DiagnosisProvider.propTypes = {
  patientId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default DiagnosisProvider;
export { DiagnosisProvider, DiagnosisContext };
