import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Stack, FormCheck } from 'react-bootstrap';
import { Dash } from 'react-bootstrap-icons';
import TableLayout from 'components/Layouts/TableLayout';
import LoadingIndicator from 'components/LoadingIndicator';
import { useSiteStudies } from 'hooks/siteStudyHooks';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

export const STUDIES_COLUMNS = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorFn: (row) => row.indication?.name,
    header: 'Indication',
    cell: (props) => props.getValue() ?? <Dash />,
  },
  {
    accessorKey: 'createdAt',
    header: 'Date Created',
    cell: (props) => formatDateDisplay(props.getValue()),
    meta: {
      headerProps: {
        className: 'text-center text-nowrap',
      },
      cellProps: {
        className: 'text-center',
      },
    },
  },
];

export function OrganizationSiteStudiesTab({ siteId }) {
  const [showActiveStudies, setShowActiveStudies] = useState(true);

  const siteStudies = useSiteStudies(siteId, true);

  if (siteStudies.isLoading) return <LoadingIndicator />;

  return (
    <Stack gap={2}>
      <Stack direction="horizontal">
        <FormCheck
          id="studyStatus-active"
          type="radio"
          name="studyStatus"
          label="Active"
          checked={showActiveStudies}
          onChange={() => setShowActiveStudies(true)}
        />

        <FormCheck
          id="studyStatus-inactive"
          className="ms-3"
          type="radio"
          name="studyStatus"
          label="Inactive"
          checked={!showActiveStudies}
          onChange={() => setShowActiveStudies(false)}
        />
      </Stack>
      <TableLayout
        columns={STUDIES_COLUMNS}
        data={siteStudies.data.filter(
          (study) => study.active === showActiveStudies
        )}
      />
    </Stack>
  );
}

OrganizationSiteStudiesTab.propTypes = {
  siteId: PropTypes.number.isRequired,
};
