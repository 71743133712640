import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import MedicalInfoTabs from './components/MedicalInfoTabs';
import PatientCommunications from './components/PatientCommunications';
import PatientDemographics from './components/PatientDemographics';
import PatientInfoHeader from './components/PatientInfoHeader';
import NavigateBack from 'components/Button/NavigateBack';
import { UserContext, Roles } from 'components/Provider/UserProvider';
import { usePatient } from 'hooks/patientHooks';

import './Patient.scss';

function PatientDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const patientId = Number(params.patientId);

  const patient = usePatient(patientId);
  const { userRole } = useContext(UserContext);

  if (patient.isLoading) return null;

  const {
    firstName,
    middleName,
    lastName,
    organization,
    doNotContact,
    researchProfiles,
    sites,
  } = patient.data;

  const backText = () => {
    const path = location.state?.backPath?.split('?')[0].replace('/', '');
    return path ? `BACK TO ${path.toUpperCase()}` : `BACK TO DASHBOARD`;
  };

  return (
    <Row
      id="patient-details"
      className="bg-light-gray"
      style={{ height: 'calc(100vh - 60px)' }}
    >
      <Col className="h-100 overflow-auto pe-4" xs={9}>
        <Row className="mt-2">
          <Col className="ps-0">
            <NavigateBack
              onClick={() =>
                navigate(location.state?.backPath ?? '/', {
                  state: location.state,
                })
              }
              text={backText()}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <PatientInfoHeader
              name={`${firstName} ${middleName ?? ''} ${lastName}`}
              patientId={patientId}
              researchProfiles={researchProfiles}
              sites={sites}
              doNotContact={doNotContact}
              isCrioButtonVisible={organization.useESourceIntegration}
              isCrioButtonDisabled={
                researchProfiles.length ===
                  sites.filter((site) => site.eSourceId).length ||
                !Roles.canModifyRecords(userRole) ||
                doNotContact
              }
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <PatientDemographics patient={patient.data} />
          </Col>
        </Row>
        <Row className="mb-5">
          <Col className="position-relative">
            <MedicalInfoTabs patient={patient.data} />
          </Col>
        </Row>
      </Col>
      <Col xs={3} className="border-start border-light bg-white px-0">
        <PatientCommunications patient={patient.data} />
      </Col>
    </Row>
  );
}

export default PatientDetails;
