/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useMemo, useEffect, useCallback } from 'react';
import { Dash } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import TableLayout from 'components/Layouts/TableLayout';
import LoadingIndicator from 'components/LoadingIndicator';
import { usePagination } from 'hooks/tableHooks';
import { formatPhoneDisplay } from 'pages/utils/phoneUtils';

export default function MessagesTable({ data, isLoading, defaultPageSize }) {
  const [pagination, setPagination] = usePagination({
    pageIndex: 0,
    pageSize: defaultPageSize,
  });
  const navigate = useNavigate();

  const navigateToPatient = useCallback((patientId) => {
    navigate(`/patients/${patientId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: defaultPageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'fullName',
        header: 'Name',
        meta: {
          headerProps: { className: 'display-4', style: { width: '33%' } },
        },
        headerStyle: { width: '33%' },
        cell: (props) => (
          <div>
            <div>{props.row.original.fullName}</div>
            <div className="text-muted">
              MRN - {props.row.original.medicalRecordNumber}
            </div>
          </div>
        ),
      },
      {
        id: 'interactions.createdAt',
        accessorFn: (row) => row.interactions?.[0]?.createdAt,
        header: 'Message Date',
        meta: { headerProps: { className: 'display-4' } },
        cell: (props) =>
          props.getValue() ? (
            DateTime.fromISO(props.getValue()).toLocaleString(
              DateTime.DATETIME_MED_WITH_WEEKDAY
            )
          ) : (
            <Dash />
          ),
      },
      {
        accessorKey: 'mobilePhone',
        header: 'Mobile Phone',
        meta: { headerProps: { className: 'display-4' } },
        cell: (props) => formatPhoneDisplay(props.getValue()),
      },
      {
        id: 'gender.name',
        accessorFn: (row) => row.gender?.name,
        header: 'Gender',
        meta: { headerProps: { className: 'display-4' } },
        cell: (props) => props.getValue() ?? <Dash />,
      },
      {
        accessorKey: 'birthDate',
        header: 'Birth Date',
        meta: {
          headerProps: { className: 'text-center display-4' },
          cellProps: { className: 'text-center' },
        },
        cell: (props) =>
          DateTime.fromISO(props.getValue()).toFormat('MM/dd/yyyy'),
      },
    ],
    []
  );

  const getRowProps = useCallback(
    (row) => ({
      className: 'hoverable-row',
      onClick: () => navigateToPatient(row.original.id),
    }),
    [navigateToPatient]
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <TableLayout
      columns={columns}
      data={data}
      pagination={pagination}
      setPagination={setPagination}
      getRowProps={getRowProps}
      noResultsText="0 patients with new messages found for the selected site."
    />
  );
}

MessagesTable.propTypes = {
  data: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.shape({})),
    pageCount: PropTypes.number,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  defaultPageSize: PropTypes.number.isRequired,
};
