import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Row, Col, Button, Stack } from 'react-bootstrap';
import { Dash, Plus, ShieldLock } from 'react-bootstrap-icons';

import FamilyHistoryModal from './FamilyHistoryModal';
import { FamilyHistoryContext } from './FamilyHistoryProvider';
import {
  ACTION_BUTTON_TYPES,
  ActionButton,
} from 'components/Button/ActionButton';
import ConfirmationDialog from 'components/ConfirmationDialog';
import TableLayout from 'components/Layouts/TableLayout';
import { UserContext, Roles } from 'components/Provider/UserProvider';
import ReadonlyIcon from 'components/ReadonlyIcon';
import {
  usePatientFamilyHistory,
  useDeletePatientFamilyHistory,
} from 'hooks/patientFamilyHistoryHooks';
import { formatDateTimeDisplay } from 'pages/utils/dateTimeUtils';

const LIST_LENGTH = 10;

const renderShieldLockIcon = () => <ShieldLock />;

const renderReadOnlyIcon = (props) => (
  <ReadonlyIcon
    readonly={props.row.original.external}
    showDash
    tooltip="External"
  />
);

const getDateTime = (props) =>
  props.getValue() ? formatDateTimeDisplay(props.getValue()) : <Dash />;

const getValue = (props) => props.getValue() ?? <Dash />;

const getDiagnosis = (props) => props.getValue() ?? 'Family History Unknown';

function FamilyHistoryList({ patientId }) {
  const [show, setShow] = useState(false);
  const familyHistory = usePatientFamilyHistory(patientId);
  const { editDiagnosis, addDiagnosis } = useContext(FamilyHistoryContext);
  const { userRole } = useContext(UserContext);
  const [selectedItemId, setSelectedItemId] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [showLength, setShowLength] = useState(LIST_LENGTH);

  const deleteDiagnosis = useDeletePatientFamilyHistory({
    onSuccess: () => {
      setSelectedItemId(0);
      setShowConfirm(false);
    },
  });

  const confirmDelete = (itemId) => {
    setSelectedItemId(itemId);
    setShowConfirm(true);
  };

  const deleteConfirmed = () =>
    deleteDiagnosis.mutate({ patientId, id: selectedItemId });

  const toggleShowAll = () => {
    setShowLength(showAll ? LIST_LENGTH : familyHistory.data.length);
    setShowAll(!showAll);
  };

  const isDisabled = useCallback(
    (role, item) =>
      !Roles.canModifyRecords(role) || item.external || item.patientEncounterId,
    []
  );

  useEffect(() => {
    if (!familyHistory.isLoading && familyHistory.data.length) {
      setShow(true);
    }
  }, [familyHistory.data, familyHistory.isLoading, setShow]);

  const renderActions = (item) => (
    <>
      {!item.familyHistoryUnknown && (
        <ActionButton
          disabled={isDisabled(userRole, item)}
          onClick={() => editDiagnosis(item)}
          action={ACTION_BUTTON_TYPES.EDIT}
        />
      )}

      <ActionButton
        disabled={isDisabled(userRole, item)}
        onClick={() => confirmDelete(item.id)}
        action={ACTION_BUTTON_TYPES.DELETE}
      />
    </>
  );

  const COLUMNS = [
    {
      accessorKey: 'external',
      header: renderShieldLockIcon,
      cell: renderReadOnlyIcon,
      meta: {
        headerProps: { className: 'text-center text-uppercase' },
        cellProps: { className: 'text-center' },
      },
    },
    {
      id: 'familyMember',
      accessorFn: (row) => row.familyMember?.name,
      header: 'Family Member',
      cell: getValue,
      meta: {
        headerProps: {
          className: 'text-uppercase',
        },
      },
    },
    {
      id: 'age',
      accessorFn: (row) => row.age,
      header: 'Age',
      cell: getValue,
      meta: {
        headerProps: {
          className: 'text-uppercase',
        },
      },
    },
    {
      id: 'diagnosis',
      accessorFn: (row) => row.diagnosisCode?.name,
      header: 'Diagnosis',
      cell: getDiagnosis,
      meta: {
        headerProps: {
          className: 'text-uppercase',
        },
      },
    },
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      cell: getDateTime,
      meta: {
        headerProps: {
          className: 'text-uppercase',
        },
      },
    },
    {
      accessorKey: 'createdBy.fullName',
      header: 'Created By',
      meta: {
        headerProps: {
          className: 'text-uppercase',
        },
        cellProps: {
          className: 'text-center',
        },
      },
    },
    {
      header: 'Actions',
      meta: {
        headerProps: {
          className: 'col-2 text-center text-uppercase',
        },
        cellProps: {
          className: 'text-center',
        },
      },
      cell: ({ row }) => renderActions(row.original),
    },
  ];

  return (
    <>
      <div className="mt-2">
        <Row className="align-items-center">
          <Col className="d-flex align-items-center">
            <div className="display-3">Family History</div>
            <Button
              variant=""
              className="ms-1"
              onClick={() => setShow((prev) => !prev)}
            >
              {show ? <Dash size={24} /> : <Plus size={24} />}
            </Button>
          </Col>
          <Col
            xs={3}
            className="align-items-center text-end"
            style={{ width: '175px' }}
          >
            <Button
              size="sm"
              variant="outline-primary"
              onClick={addDiagnosis}
              className="w-100"
            >
              <Plus className="align-text-top" size={18} /> Add History
            </Button>
          </Col>
        </Row>
        <Stack gap={2}>
          {show && (
            <TableLayout
              columns={COLUMNS}
              data={familyHistory.data.slice(0, showLength)}
              isLoading={familyHistory.isLoading}
              noResultsText="0 history. Click Add Family History to add new records."
            />
          )}

          {familyHistory.data && familyHistory.data.length > LIST_LENGTH && (
            <Button variant="link" onClick={toggleShowAll}>
              {showAll ? 'Show less' : 'Show more'}
            </Button>
          )}
        </Stack>
      </div>

      <FamilyHistoryModal />

      <ConfirmationDialog
        show={showConfirm}
        title="Confirm Delete"
        bodyText="Are you sure you want to delete this diagnosis?"
        confirmText="Delete"
        onConfirm={deleteConfirmed}
        onCancel={() => setShowConfirm(false)}
      />
    </>
  );
}

FamilyHistoryList.propTypes = {
  patientId: PropTypes.number.isRequired,
};

export default FamilyHistoryList;
