import PropTypes from 'prop-types';
import React from 'react';
import SingleSelectField from '../BaseFields/SingleSelectField';

const options = [
  { value: false, label: 'No' },
  { value: true, label: 'Yes' },
];

function DoNotContactDropdown({ onChange, value = false }) {
  const selectedValue = options.find((o) => o.value === value);

  const handleOnChange = (selected) => {
    onChange('showDoNotContact', selected?.value ?? '');
  };

  return (
    <SingleSelectField
      name="Include Do Not Contact"
      onChange={handleOnChange}
      selectedValue={selectedValue}
      options={options}
    />
  );
}

DoNotContactDropdown.defaultProps = {
  value: false,
};

DoNotContactDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
};

export default DoNotContactDropdown;
