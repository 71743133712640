import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

export function useCallOutcomes(activeOnly = true, globalOnly = false) {
  return useQuery(
    ['call-outcomes', activeOnly, globalOnly],
    async () => {
      const { data } = await axios.get(`/api/call-outcomes`, {
        params: { activeOnly, globalOnly },
      });
      return data;
    },
    { placeholderData: [] }
  );
}

const createCallOutcome = async (callOutcome) => {
  const result = await axios.post(`/api/call-outcomes`, callOutcome);
  return result.data;
};

export function useCreateCallOutcome(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createCallOutcome,
    onSuccess: (callOutcome) => {
      queryClient.invalidateQueries(['call-outcomes']);

      options?.onSuccess?.(callOutcome);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const updateCallOutcome = async (callOutcome) => {
  const result = await axios.put(
    `/api/call-outcomes/${callOutcome.id}`,
    callOutcome
  );
  return result.data;
};

export function useUpdateCallOutcome(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateCallOutcome,
    onSuccess: (callOutcome) => {
      queryClient.invalidateQueries(['call-outcomes']);

      options?.onSuccess?.(callOutcome);
    },
    onError: (error) => options?.onError?.(error),
  });
}
