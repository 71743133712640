import { Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Alert,
  Button,
  Form,
  FormSelect,
  Row,
  Col,
  Modal,
  Spinner,
} from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import * as yup from 'yup';
import { DiagnosisContext } from './DiagnosisProvider';
import axios from '../../../../../../axios';
import { useDiagnosisStatuses } from 'hooks/diagnosisHooks';

const schema = yup.object({
  diagnosisCodeId: yup.number().integer().required(),
  startDate: yup
    .date()
    .nullable()
    .max(new Date(), 'start date cannot be in the future'),
  endDate: yup
    .date()
    .nullable()
    .max(new Date(), 'end date cannot be in the future'),
  diagnosisStatusId: yup.number().integer().nullable(),
});

export default function DiagnosisModal() {
  const formRef = useRef();
  const [codeOptions, setCodeOptions] = useState([]);
  const [selectedCode, setSelectedCode] = useState(null);
  const diagnosisStatuses = useDiagnosisStatuses();

  const { error, diagnosis, showModal, closeModal, saveDiagnosis, isBusy } =
    useContext(DiagnosisContext);

  useEffect(() => {
    if (showModal) {
      setCodeOptions([]);
      setSelectedCode(diagnosis.diagnosisCode || null);
    } else {
      setSelectedCode(null);
    }
  }, [diagnosis.diagnosisCode, showModal]);

  const handleSearch = (substring) => {
    axios.get('/api/diagnoses/codes', { params: { substring } }).then((res) => {
      setCodeOptions(res.data);
    });
  };

  const handleFormSubmit = (values) => {
    saveDiagnosis({
      ...diagnosis,
      ...values,
    });
  };

  const handleSave = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }
  };

  return (
    <Modal
      show={showModal}
      backdrop="static"
      keyboard={false}
      centered
      onHide={closeModal}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Diagnosis</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="danger" show={!!error}>
          {error}
        </Alert>

        <Formik
          validationSchema={schema}
          onSubmit={handleFormSubmit}
          initialValues={diagnosis}
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit} ref={formRef}>
              <Row>
                <Col>
                  <Form.Group controlId="formDiagnosisCode">
                    <Form.Label>Diagnosis Code</Form.Label>
                    <AsyncTypeahead
                      autoFocus
                      id="diagnosisCodeId"
                      filterBy={() => true}
                      labelKey="name"
                      minLength={3}
                      onSearch={handleSearch}
                      isLoading={false}
                      options={codeOptions}
                      selected={selectedCode ? [selectedCode] : []}
                      placeholder="Start typing code or description..."
                      onChange={(code) => {
                        if (code.length === 0) {
                          setFieldValue('diagnosisCodeId', null);
                          setSelectedCode(null);
                        } else {
                          setFieldValue('diagnosisCodeId', code[0].id);
                          setSelectedCode(code[0]);
                        }
                      }}
                      isValid={
                        touched.diagnosisCodeId && !errors.diagnosisCodeId
                      }
                      isInvalid={!!errors.diagnosisCodeId}
                      renderMenuItemChildren={(option) => (
                        <section className="d-flex flex-row">
                          <span className="text-primary me-2 w-10">
                            {option.code}
                          </span>
                          <span className="text-truncate w-90">
                            {option.name}
                          </span>
                        </section>
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formStartDate">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="startDate"
                      value={values.startDate || ''}
                      onChange={handleChange}
                      isValid={touched.startDate && !errors.startDate}
                      isInvalid={!!errors.startDate}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formEndDate">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="endDate"
                      value={values.endDate || ''}
                      onChange={handleChange}
                      isValid={touched.endDate && !errors.endDate}
                      isInvalid={!!errors.endDate}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formDiagnosisStatus">
                    <Form.Label>Status</Form.Label>
                    <FormSelect
                      name="diagnosisStatusId"
                      value={values.diagnosisStatusId || ''}
                      onChange={handleChange}
                      isValid={
                        touched.diagnosisStatusId && !errors.diagnosisStatusId
                      }
                      isInvalid={!!errors.diagnosisStatusId}
                    >
                      <option key="0" value="">
                        Select Status
                      </option>
                      {diagnosisStatuses.data?.map((status) => (
                        <option key={status.id} value={status.id}>
                          {status.name}
                        </option>
                      ))}
                    </FormSelect>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={isBusy}>
          Save
          <Spinner
            animation="border"
            role="status"
            size="sm"
            className={`ms-2 ${isBusy ? '' : 'd-none'}`}
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
