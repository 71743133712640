import PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'react-bootstrap';

import DiagnosisList from './DiagnosisList';
import { DiagnosisProvider } from './DiagnosisList/DiagnosisProvider';
import FamilyHistoryList from './FamilyHistoryList';
import FamilyHistoryProvider from './FamilyHistoryList/FamilyHistoryProvider';
import MedicationList from './MedicationList';
import { MedicationProvider } from './MedicationList/MedicationProvider';

import ObservationList, { OBSERVATION_TYPES } from './ObservationList';
// import ProcedureList from './ProcedureList';
// import { ProcedureProvider } from './ProcedureList/ProcedureProvider';

export default function MedicalRecordTab({ patient }) {
  return (
    <Col className="bg-white pt-2 pb-2 px-3 d-grid gap-1">
      <ObservationList type={OBSERVATION_TYPES.RESULT} patientId={patient.id} />

      <ObservationList
        type={OBSERVATION_TYPES.PROCEDURE_RESULT}
        patientId={patient.id}
      />

      <DiagnosisProvider patientId={patient.id}>
        <DiagnosisList patientId={patient.id} />
      </DiagnosisProvider>

      {/* <ProcedureProvider patientId={patient.id}>
        <ProcedureList patientId={patient.id} />
      </ProcedureProvider> */}

      <MedicationProvider patientId={patient.id}>
        <MedicationList patientId={patient.id} />
      </MedicationProvider>

      <ObservationList
        type={OBSERVATION_TYPES.MEASUREMENT}
        patientId={patient.id}
      />

      <FamilyHistoryProvider patientId={patient.id}>
        <FamilyHistoryList patientId={patient.id} />
      </FamilyHistoryProvider>
    </Col>
  );
}

MedicalRecordTab.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};
