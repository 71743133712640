import React from 'react';
import { Navigate } from 'react-big-calendar';

import { Col, Row } from 'react-bootstrap';
import PatientAppointmentsTable from './PatientAppointmentsTable';

export default function MonthListView() {
  return (
    <Row className="mx-2">
      <Col className="bg-white rounded">
        <PatientAppointmentsTable />
      </Col>
    </Row>
  );
}

MonthListView.propTypes = {};

MonthListView.range = (date, { localizer }) => ({
  start: localizer.startOf(date, 'month'),
  end: localizer.endOf(date, 'month'),
});

MonthListView.navigate = (date, action, { localizer }) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -1, 'month');

    case Navigate.NEXT:
      return localizer.add(date, 1, 'month');

    default:
      return date;
  }
};

MonthListView.title = (date, { localizer }) => {
  const { start } = MonthListView.range(date, { localizer });
  return localizer.format(start, 'monthHeaderFormat');
};
