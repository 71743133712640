import { useQuery } from '@tanstack/react-query';
import axios from '../axios';

export function useDiagnosisStatuses() {
  return useQuery(['diagnosis-statuses'], () =>
    axios.get('/api/diagnosis-statuses').then((res) => res.data)
  );
}

export default {
  useDiagnosisStatuses,
};
