import PropTypes from 'prop-types';
import React from 'react';
import SingleSelectField from '../BaseFields/SingleSelectField';
import LoadingIndicator from 'components/LoadingIndicator';
import { useRegistries } from 'hooks/studyHooks';

function RegistryDropdown({ onChange, value }) {
  const { data, isLoading } = useRegistries();

  if (isLoading) return <LoadingIndicator />;

  const options = data.map((d) => ({
    value: d.id,
    label: d.name,
  }));

  const selectedValue = options.find((o) => value === o.value);

  const handleOnChange = (selected) => {
    onChange('registry', selected?.value ?? undefined);
  };

  return (
    <SingleSelectField
      name="Registry"
      onChange={handleOnChange}
      selectedValue={selectedValue}
      placeholderText="Any"
      options={options}
      isClearable
    />
  );
}

RegistryDropdown.defaultProps = {
  value: undefined,
};

RegistryDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
};

export default RegistryDropdown;
