import { useQuery } from '@tanstack/react-query';
import axios from '../axios';
import Roles from 'entities/Roles';
import { useCurrentUser } from 'hooks/userHooks';

export function useAdminSites(active = true) {
  return useQuery(['admin-sites', active], () =>
    axios
      .get('/api/admin/sites', {
        params: { active },
      })
      .then((res) => res.data)
  );
}

export const useIsAdmin = () => {
  const currentUser = useCurrentUser();

  return currentUser.data?.userRole === Roles.ADMINISTRATOR;
};
