import PropTypes from 'prop-types';
import React from 'react';
import { Col, FormControl, Stack } from 'react-bootstrap';
import Field from './Field';
import { convertToSnakeCase } from 'pages/FeasibilitySearch/helpers';

function RangeSearchField({
  colOverrides,
  label,
  keySuffix,
  onChange,
  value,
  deleteButton,
  labelAlginment,
}) {
  const snakeCaseMinName = convertToSnakeCase(`Min ${keySuffix}`);
  const snakeCaseMaxName = convertToSnakeCase(`Max ${keySuffix}`);

  const handleMinOnChange = (e) => {
    onChange(snakeCaseMinName, e.target.value || undefined);
  };

  const handleMaxOnChange = (e) => {
    onChange(snakeCaseMaxName, e.target.value || undefined);
  };

  return (
    <Field label={label} labelAlginment={labelAlginment}>
      <Stack direction="horizontal" gap={3}>
        <p className="mb-0">from</p>
        <Col xs={colOverrides?.col}>
          <FormControl
            data-testid="minInput"
            className="text-center"
            value={value.min || ''}
            placeholder="0"
            size="sm"
            type="number"
            onChange={handleMinOnChange}
          />
        </Col>
        <p className="mb-0"> to</p>
        <Col xs={colOverrides?.col}>
          <FormControl
            data-testid="maxInput"
            className="text-center"
            value={value.max || ''}
            placeholder="100"
            size="sm"
            type="number"
            onChange={handleMaxOnChange}
          />
        </Col>
        {deleteButton}
      </Stack>
    </Field>
  );
}

RangeSearchField.defaultProps = {
  keySuffix: '',
  colOverrides: {},
  deleteButton: undefined,
  labelAlginment: undefined,
};

RangeSearchField.propTypes = {
  labelAlginment: PropTypes.string,
  colOverrides: PropTypes.shape({
    col: PropTypes.number,
  }),
  label: PropTypes.string.isRequired,
  keySuffix: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  deleteButton: PropTypes.element,
  value: PropTypes.shape({
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
};

export default RangeSearchField;
