import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Dash, ShieldLock } from 'react-bootstrap-icons';

export default function ReadonlyIcon({
  readonly = false,
  showDash = true,
  tooltip = 'Read-only',
  placement = 'top',
}) {
  if (!readonly && showDash) {
    return <Dash />;
  }

  return (
    <OverlayTrigger
      overlay={<Tooltip>{tooltip}</Tooltip>}
      placement={placement}
    >
      <ShieldLock />
    </OverlayTrigger>
  );
}

ReadonlyIcon.propTypes = {
  readonly: PropTypes.bool,
  showDash: PropTypes.bool,
  tooltip: PropTypes.string,
  placement: PropTypes.string,
};

ReadonlyIcon.defaultProps = {
  readonly: false,
  showDash: true,
  tooltip: 'Read-only',
  placement: 'top',
};
