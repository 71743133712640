import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

export function useActiveOrganizations(enabled) {
  return useQuery(
    ['activeOrganizations', enabled],
    async () => {
      const { data } = await axios.get('/api/admin/organizations/active');
      return data;
    },
    { enabled }
  );
}

export function useOrganizations(pageIndex = null, pageSize = null) {
  return useQuery(['organizations', pageIndex, pageSize], async () => {
    const params = { pageIndex, pageSize };
    const { data } = await axios.get('/api/admin/organizations', { params });
    return data;
  });
}

export function useOrganization(id) {
  return useQuery(['organization', id], async () => {
    const { data } = await axios.get(`/api/organizations/${id}`);
    return data;
  });
}

export function useOrganizationSites(
  id,
  pageIndex = null,
  pageSize = null,
  active = true
) {
  return useQuery(
    ['organizationSites', id, pageIndex, pageSize, active],
    async () => {
      const params = { pageIndex, pageSize, active };
      const { data } = await axios.get(`/api/organizations/${id}/sites`, {
        params,
      });
      return data;
    }
  );
}

export function useOrganizationUsers(
  id,
  query,
  pageIndex = null,
  pageSize = null,
  active = true,
  enabled = true
) {
  return useQuery(
    ['organizationUsers', id, query, pageIndex, pageSize, active],
    async () => {
      const params = { query, pageIndex, pageSize, active };
      const { data } = await axios.get(`/api/organizations/${id}/users`, {
        params,
      });
      return data;
    },
    {
      enabled,
      placeholderData: { rows: [] },
    }
  );
}

export function useOrganizationStudies(id, activeOnly = true) {
  return useQuery(['organizationStudies', id, activeOnly], async () => {
    const params = { activeOnly };
    const { data } = await axios.get(`/api/organizations/${id}/studies`, {
      params,
    });
    return data;
  });
}

const createOrganization = async (organization) => {
  const result = await axios.post('/api/admin/organizations', organization);
  return result.data;
};

export function useCreateOrganization(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createOrganization,
    onSuccess: (organization) => {
      queryClient.invalidateQueries(['activeOrganizations']);
      queryClient.invalidateQueries(['organizations']);

      options?.onSuccess?.(organization);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const updateOrganization = async (organization) => {
  const result = await axios.put(
    `/api/organizations/${organization.id}`,
    organization
  );
  return result.data;
};

export function useUpdateOrganization(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateOrganization,
    onSuccess: (organization) => {
      queryClient.invalidateQueries(['activeOrganizations']);
      queryClient.invalidateQueries(['organizations']);
      queryClient.invalidateQueries(['organization', organization.id]);

      options?.onSuccess?.(organization);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const deleteOrganization = async (organization) => {
  const result = await axios.delete(
    `/api/admin/organizations/${organization.id}`
  );
  return result.data;
};

export function useDeleteOrganization(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteOrganization,
    onSuccess: (organization) => {
      queryClient.invalidateQueries(['activeOrganizations']);
      queryClient.invalidateQueries(['organizations']);
      queryClient.removeQueries(['organization', organization.id]);

      options?.onSuccess?.(organization);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const createOrganizationUser = async ({ organizationId, userId, roleId }) => {
  const result = await axios.post(
    `/api/organizations/${organizationId}/users`,
    { userId, roleId }
  );
  return result.data;
};

export function useCreateOrganizationUser(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createOrganizationUser,
    onSuccess: (organizationUser) => {
      queryClient.invalidateQueries([
        'organizationUsers',
        organizationUser.organizationId,
      ]);
      queryClient.invalidateQueries([
        'organization',
        organizationUser.organizationId,
      ]);

      options?.onSuccess?.(organizationUser);
    },
    onError: (error) => options?.onError?.(error),
  });
}
