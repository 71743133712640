import PropTypes from 'prop-types';
import React from 'react';
import Field from './Field';
import SingleSelect from 'components/SingleSelect';

export default function SingleSelectField({
  name,
  onChange,
  selectedValue,
  className,
  placeholderText,
  options,
  isClearable,
}) {
  return (
    <Field label={name}>
      <SingleSelect
        name={name}
        onChange={onChange}
        selectedValue={selectedValue}
        className={className}
        placeholderText={placeholderText}
        options={options}
        isClearable={isClearable}
      />
    </Field>
  );
}

SingleSelectField.defaultProps = {
  className: undefined,
  selectedValue: undefined,
  placeholderText: undefined,
  isClearable: undefined,
};

SingleSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.shape({}),
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  className: PropTypes.string,
  placeholderText: PropTypes.string,
  isClearable: PropTypes.bool,
};
