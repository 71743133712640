import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import UserSitesTable from './UserSitesTable';
import ConfirmationDialog from 'components/ConfirmationDialog';
import LoadingIndicator from 'components/LoadingIndicator';
import { useUserSites } from 'hooks/siteHooks';
import { useDeleteUserSite } from 'hooks/userSiteHooks';

function ConfirmationBody(fullName) {
  return (
    <span>
      Are you sure you want to remove{' '}
      <span className="fw-bold">{fullName}</span> from the site?
    </span>
  );
}

export default function SiteUsersList({ siteId }) {
  const [showConfirmRemoveUser, setShowConfirmRemoveUser] = useState(false);
  const [selectedUserSite, setSelectedUserSite] = useState();
  const [confirmationBody, setConfirmationBody] = useState('');

  const users = useUserSites(siteId);

  const deleteUserSite = useDeleteUserSite({
    onSuccess: () => {
      setShowConfirmRemoveUser(false);
    },
  });

  const confirmRemoveUser = (user) => {
    const newConfirmationBody = ConfirmationBody(user.fullName);

    setConfirmationBody(newConfirmationBody);

    setSelectedUserSite({ userId: user.id, siteId });
    setShowConfirmRemoveUser(true);
  };

  const handleRemove = () => deleteUserSite.mutate(selectedUserSite);

  if (users.isLoading) return <LoadingIndicator />;

  return (
    <>
      <Row>
        <Col>
          <h5>Current Users</h5>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={8}>
          {users.data.length ? (
            <UserSitesTable
              users={users.data}
              confirmRemoveUser={confirmRemoveUser}
            />
          ) : (
            <span className="fst-italic">Site has no assigned users...</span>
          )}
        </Col>
      </Row>
      <ConfirmationDialog
        show={showConfirmRemoveUser}
        title="Confirm Remove"
        bodyText={confirmationBody}
        confirmText="Remove"
        onConfirm={handleRemove}
        onCancel={() => setShowConfirmRemoveUser(false)}
      />
    </>
  );
}

SiteUsersList.propTypes = {
  siteId: PropTypes.number.isRequired,
};
