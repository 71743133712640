import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Stack } from 'react-bootstrap';
import DocumentsSection from './components/DocumentsSection';
import NotesSection from './components/NotesSection';
import PatientIndicationsSection from './components/PatientIndicationsSection';
import PatientRegistriesSection from './components/PatientRegistriesSection';
import PatientSitesSection from './components/PatientSitesSection';
import PatientStudiesSection from './components/PatientStudiesSection';
import PatientAppointmentsSection from './components/PrescreeningAppointmentsSection';
import { useSites } from 'hooks/siteHooks';
import { partition } from 'pages/utils/helpers';

export default function ResearchTab({ patient }) {
  const [patientRegistries, patientStudies] = partition(
    patient.patientStudies,
    (ps) => ps.study.isRegistry
  );

  const activeSites = useSites();

  const activePatientSites = useMemo(() => {
    if (activeSites.data?.length) {
      return patient.sites.filter(
        (ps) =>
          typeof activeSites.data.find((site) => site.id === ps.id) !==
          'undefined'
      );
    }

    return [];
  }, [activeSites.data, patient.sites]);

  return (
    <Stack gap={2} className="bg-white py-3 px-3">
      <PatientIndicationsSection patientId={patient.id} />
      <hr />
      <PatientSitesSection sites={activePatientSites} />
      <hr />
      <PatientStudiesSection
        patientId={patient.id}
        patientStudies={patientStudies}
        patientSites={activePatientSites}
      />
      <hr />
      <PatientAppointmentsSection patientId={patient.id} />
      <hr />
      <PatientRegistriesSection
        patientSites={activePatientSites}
        patientRegistries={patientRegistries.filter(
          (pr) => pr.studyStatusId != null
        )}
      />
      <hr />
      <NotesSection patientId={patient.id} />
      <hr />
      <DocumentsSection patientId={patient.id} />
      <hr />
    </Stack>
  );
}

ResearchTab.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sites: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        studies: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            active: PropTypes.bool.isRequired,
            isRegistry: PropTypes.bool.isRequired,
            marketName: PropTypes.string,
            name: PropTypes.string.isRequired,
          })
        ).isRequired,
      })
    ).isRequired,
    patientStudies: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        study: PropTypes.shape({
          id: PropTypes.number.isRequired,
          isRegistry: PropTypes.bool.isRequired,
        }).isRequired,
      })
    ).isRequired,
  }).isRequired,
};
