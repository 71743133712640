/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Dash } from 'react-bootstrap-icons';
import TableLayout from 'components/Layouts/TableLayout';
import LoadingIndicator from 'components/LoadingIndicator';
import SortDirection from 'entities/SortDirection';
import { usePatientRecruitmentHistory } from 'hooks/patientRecruitmentHistoryHooks';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

export default function RecruitmentHistoryTable({ patientContext }) {
  const { patientId } = patientContext;

  const recruitmentHistory = usePatientRecruitmentHistory(
    patientId,
    SortDirection.DESC
  );

  const columns = useMemo(() => {
    const config = [
      {
        header: 'DATE',
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { className: 'text-center text-nowrap' },
        },

        accessorKey: 'createdAt',
        cell: (props) => formatDateDisplay(props.getValue()),
      },
      {
        id: 'oldRecruitmentStatus.name',
        header: 'PREVIOUS STATUS',
        accessorFn: (row) => row.oldRecruitmentStatus?.name,
        cell: (props) => props.getValue() ?? <Dash />,
      },
      {
        id: 'newRecruitmentStatus.name',
        header: 'UPDATED STATUS',
        accessorFn: (row) => row.newRecruitmentStatus?.name,
        cell: (props) => props.getValue() ?? <Dash />,
      },
      { header: 'REASON', accessorKey: 'reason' },
      {
        id: 'createdBy.fullName',
        header: 'USER',
        meta: { cellProps: { className: 'text-nowrap' } },
        accessorFn: (row) => row.createdBy?.fullName,
        cell: (props) => props.getValue() ?? <Dash />,
      },
    ];

    return config;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recruitmentHistory.data]);

  if (recruitmentHistory.isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <TableLayout
      columns={columns}
      data={recruitmentHistory.data}
      noResultsText="0 status changes found."
    />
  );
}

RecruitmentHistoryTable.propTypes = {
  patientContext: PropTypes.shape({
    patientId: PropTypes.number.isRequired,
  }).isRequired,
};
