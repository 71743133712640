import PropTypes from 'prop-types';
import React from 'react';
import MultiSelect from '../BaseFields/MultiSelectField';
import LoadingIndicator from 'components/LoadingIndicator';
import { useUserIndicationOptions } from 'hooks/siteIndicationHooks';

function IndicationDropdown({ onChange, value, valueTypeFilter }) {
  const { data, isLoading } = useUserIndicationOptions();

  if (isLoading) return <LoadingIndicator />;

  const options = data
    .filter((d) => (valueTypeFilter ? d.valueType === valueTypeFilter : true))
    .map((d) => ({
      value: d.name,
      label: d.displayName,
      valueType: d.valueType,
    }));

  const selectedValues = options.filter((o) =>
    value.map((v) => v.value).includes(o.value)
  );

  const handleOnChange = (selected) => {
    onChange('indications', selected);
  };

  return (
    <MultiSelect
      name="indication"
      onChange={handleOnChange}
      selectedValues={selectedValues}
      placeholderText="Any"
      options={options}
    />
  );
}

IndicationDropdown.defaultProps = {
  value: [],
  valueTypeFilter: undefined,
};

IndicationDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      displayName: PropTypes.string,
      valueType: PropTypes.string,
    })
  ),
  valueTypeFilter: PropTypes.string,
};

export default IndicationDropdown;
