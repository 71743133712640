import PropTypes from 'prop-types';
import React from 'react';
import RangeSearchField from '../BaseFields/RangeSearchField';

function AgeSearchField({ onChange, value }) {
  return (
    <RangeSearchField
      label="Age"
      keySuffix="Age"
      onChange={onChange}
      value={value}
    />
  );
}

AgeSearchField.defaultProps = {
  value: {},
};

AgeSearchField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
};

export default AgeSearchField;
