import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Row, Col, Dropdown, DropdownButton, ListGroup } from 'react-bootstrap';
import { Dash, Pencil } from 'react-bootstrap-icons';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

function StudyInfo({ context }) {
  const { study, setShowStudyModal } = useContext(context);

  return (
    <>
      <Row>
        <Col>
          <div className="d-flex">
            <h2 className="flex-grow-1 fs-3 fw-normal mb-4">{study.name}</h2>
            <DropdownButton title="Actions">
              <Dropdown.Item onClick={() => setShowStudyModal(true)}>
                <Pencil /> Edit
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListGroup>
            <ListGroup.Item>
              <span className="fw-bold">Registry: </span>
              {study.isRegistry ? 'Yes' : 'No'}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="fw-bold">Sponsor: </span>
              {study.sponsor ?? <Dash />}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="fw-bold">Protocol: </span>
              {study.protocol ?? <Dash />}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="fw-bold">Market Name: </span>
              {study.marketName ?? <Dash />}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="fw-bold">Indication: </span>
              {study.indication?.displayName ?? <Dash />}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="fw-bold">Encounter Type: </span>
              {study.encounterType?.name ?? <Dash />}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="fw-bold">Active: </span>
              {study.active ? 'Yes' : 'No'}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="fw-bold">Date Created: </span>
              {formatDateDisplay(study.createdAt)}
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </>
  );
}

StudyInfo.propTypes = {
  context: PropTypes.shape({}).isRequired,
};

export default StudyInfo;
