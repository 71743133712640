import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import AddButton from 'components/Button/AddButton';

export default function StudyButton({ context }) {
  const { addStudy } = useContext(context);

  return <AddButton label="Study" onClick={addStudy} />;
}

StudyButton.propTypes = {
  context: PropTypes.shape({}).isRequired,
};
