import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';
import { PencilFill, TrashFill } from 'react-bootstrap-icons';

export const ACTION_BUTTON_TYPES = {
  EDIT: 'edit',
  DELETE: 'delete',
};

const getIconBasedOnAction = (action) => {
  switch (action) {
    case ACTION_BUTTON_TYPES.EDIT:
      return <PencilFill />;
    case ACTION_BUTTON_TYPES.DELETE:
      return <TrashFill />;
    default:
      return new Error(
        'Plese provide an action name from ACTION_BUTTON_TYPES.'
      );
  }
};

export function ActionButton({
  action,
  disabled = false,
  onClick,
  size = 'sm',
}) {
  const icon = getIconBasedOnAction(action);
  return (
    <Button
      variant=""
      disabled={disabled}
      className={disabled ? 'text-gray-200 border-0' : ''}
      onClick={onClick}
      size={size}
    >
      {icon}
    </Button>
  );
}

ActionButton.propTypes = {
  action: PropTypes.oneOf(Object.values(ACTION_BUTTON_TYPES)).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['sm', 'lg']),
};

ActionButton.defaultProps = {
  disabled: false,
  size: 'sm',
};
