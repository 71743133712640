import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Col, FormSelect, Row, Button } from 'react-bootstrap';
import { useAdminSites } from 'hooks/adminHooks';
import { useCreateSiteStudy } from 'hooks/siteStudyHooks';

export default function SiteAdd({ context }) {
  const { study } = useContext(context);
  const [selectedSite, setSelectedSite] = useState();

  const sites = useAdminSites();
  const createSiteStudy = useCreateSiteStudy({
    onSuccess: () => setSelectedSite(),
  });

  if (sites.isLoading) return null;

  const handleClick = () => {
    createSiteStudy.mutate({
      organizationId: selectedSite.organization.id,
      siteId: selectedSite.id,
      studyId: study.id,
    });
  };

  const handleSelect = (e) => {
    const site = sites.data.find((s) => s.id === Number(e.target.value));
    setSelectedSite(site);
  };

  const filteredSites = sites.data.filter(
    (si) => !study.sites.map((site) => site.id).includes(si.id)
  );

  const getGroupedSiteOptions = () => {
    const groupedByOrganization = Object.groupBy(
      filteredSites,
      ({ organization }) => organization.name
    );

    const groupedOptions = Object.entries(groupedByOrganization).map(
      ([key, value]) => (
        <optgroup label={key} key={key}>
          {value.map((site) => (
            <option key={`option-${site.id}`} value={site.id}>
              {site.name}
            </option>
          ))}
        </optgroup>
      )
    );

    return groupedOptions;
  };

  return (
    <Row>
      <Col xs="4">
        <FormSelect value={selectedSite?.id} onChange={handleSelect}>
          <option value="">Select Site</option>
          {getGroupedSiteOptions()}
        </FormSelect>
      </Col>
      <Col xs="1">
        <Button
          variant="primary"
          className="ms-n3"
          disabled={!selectedSite || !study.active || createSiteStudy.isLoading}
          onClick={handleClick}
        >
          Add
        </Button>
      </Col>
    </Row>
  );
}

SiteAdd.propTypes = {
  context: PropTypes.shape({}).isRequired,
};
