/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React, { useContext, useMemo, useCallback } from 'react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ShieldLock, ExclamationCircleFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { SearchContext } from './SearchProvider';
import TableLayout from 'components/Layouts/TableLayout';
import LoadingIndicator from 'components/LoadingIndicator';
import ReadonlyIcon from 'components/ReadonlyIcon';
import paths from 'entities/SitePaths';
import { usePatientSearch } from 'hooks/patientHooks';

function PatientsTable() {
  const { pagination, setPagination, sorting, setSorting } =
    useContext(SearchContext);

  const [query] = useQueryParam('query', StringParam);

  const { data, isSuccess, isLoading } = usePatientSearch(
    query,
    pagination,
    sorting
  );

  const navigate = useNavigate();

  const viewPatient = useCallback(
    (patientId) => {
      navigate(`${paths.PATIENTS}/${patientId}`, {
        state: { backPath: `${paths.SEARCH}?query=${query}` },
      });
    },
    [navigate, query]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'external',
        header: () => <ShieldLock />,
        headerStyle: { width: '32px' },
        meta: {
          headerProps: { className: 'text-center', style: { width: '32px' } },
          cellProps: { className: 'text-center' },
        },
        cell: (props) => (
          <ReadonlyIcon readonly={props.getValue()} tooltip="External" />
        ),
      },
      {
        accessorKey: 'fullName',
        header: 'Name',
        meta: { headerProps: { className: 'display-4' } },
        cell: (props) => {
          if (!props.row.original.doNotContact) return props.getValue();

          return (
            <div className="d-flex align-items-center">
              <div className="me-2">{props.getValue()}</div>
              <OverlayTrigger
                overlay={<Tooltip>Do Not Contact</Tooltip>}
                placement="top"
              >
                <ExclamationCircleFill className="text-danger" />
              </OverlayTrigger>
            </div>
          );
        },
      },
      {
        accessorKey: 'medicalRecordNumber',
        header: 'MRN',
        meta: { headerProps: { className: 'display-4' } },
      },
      {
        id: 'site.name',
        accessorFn: (row) => row.sites.map((s) => s.name).join(', '),
        enableSorting: false,
        header: 'Sites',
        meta: { headerProps: { className: 'display-4' } },
      },
    ],
    []
  );

  const getRowProps = useCallback(
    (row) => ({
      className: 'hoverable-row',
      onClick: () => viewPatient(row.original.id),
    }),
    [viewPatient]
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    isSuccess && (
      <Row className="mb-2 mx-1 row-cols-xl-12">
        <Col className="pt-3 bg-white">
          <TableLayout
            columns={columns}
            data={data}
            pagination={pagination}
            setPagination={setPagination}
            sorting={sorting}
            setSorting={setSorting}
            getRowProps={getRowProps}
            noResultsText="0 patients found."
          />
        </Col>
      </Row>
    )
  );
}

export default PatientsTable;
