import PropTypes from 'prop-types';
import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import {
  useCreatePatientMedication,
  useUpdatePatientMedication,
} from 'hooks/patientMedicationHooks';

const medicationTemplate = {
  id: 0,
  patientId: '',
  medicationCodeId: '',
  medicationFrequencyId: '',
  startDate: '',
  stopDate: '',
  dosage: null,
  units: null,
  route: null,
  reason: null,
};

const MedicationContext = createContext();

function MedicationProvider({ patientId, children }) {
  const [error, setError] = useState('');
  const [medication, setMedication] = useState(medicationTemplate);
  const [showModal, setShowModal] = useState(false);

  const mutationOptions = {
    onSuccess: () => setShowModal(false),
    onError: (e) => setError(e.response?.data?.message),
  };

  const createPatientMedication = useCreatePatientMedication(mutationOptions);
  const updatePatientMedication = useUpdatePatientMedication(mutationOptions);

  useEffect(() => {
    setError('');
  }, [showModal]);

  const closeModal = () => {
    setShowModal(false);
  };

  const addMedication = () => {
    setMedication(medicationTemplate);
    setShowModal(true);
  };

  const editMedication = (selectedMedication) => {
    setMedication(selectedMedication);
    setShowModal(true);
  };

  const saveMedication = useCallback(
    (values) => {
      setError('');

      const data = {
        ...values,
        patientId,
        startDate: values.startDate ? new Date(values.startDate) : null,
        stopDate: values.stopDate ? new Date(values.stopDate) : null,
      };

      if (values.id === 0) {
        createPatientMedication.mutate(data);
      } else {
        updatePatientMedication.mutate(data);
      }
    },
    [createPatientMedication, patientId, updatePatientMedication]
  );

  const isBusy =
    createPatientMedication.isLoading || updatePatientMedication.isLoading;

  const value = useMemo(
    () => ({
      medication,
      error,
      showModal,
      closeModal,
      addMedication,
      editMedication,
      saveMedication,
      isBusy,
    }),
    [error, isBusy, medication, saveMedication, showModal]
  );

  return (
    <MedicationContext.Provider value={value}>
      {children}
    </MedicationContext.Provider>
  );
}

MedicationProvider.propTypes = {
  patientId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default MedicationProvider;
export { MedicationContext, MedicationProvider };
