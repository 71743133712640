import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

import API from '../../api';
import Roles from '../../entities/Roles';

const UserContext = createContext(null);

function UserProvider({ children }) {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [profile, setProfile] = useState(null);

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      const setupAuthenticatedSession = async () => {
        const token = await getAccessTokenSilently();
        API.setBearerToken(token);
        const user = await API.getCurrentUser();

        setProfile({
          userName: user.userName,
          fullName: user.fullName,
          userRole: user.userRole,
          organizationId: user.organizationId,
        });
      };

      setupAuthenticatedSession();
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  if (isLoading || !isAuthenticated || !profile) {
    return null;
  }

  return (
    <UserContext.Provider value={profile}>{children}</UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Roles, UserContext, UserProvider };
export default UserProvider;
