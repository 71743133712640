import { Formik } from 'formik';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useMemo, useRef, useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Alert,
  Modal,
  Form,
  Spinner,
  FormSelect,
} from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import * as yup from 'yup';
import axios from '../../axios';
import SelectField from 'components/FormikForm/SelectField';
import AppointmentTypes from 'entities/AppointmentTypes';
import { useEncounterTypes } from 'hooks/encounterHooks';
import {
  useCreatePatientAppointment,
  useUpdatePatientAppointment,
} from 'hooks/patientAppointmentHooks';
import { useSites } from 'hooks/siteHooks';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

const SCHEMA = yup.object().shape({
  encounterTypeId: yup.number().integer().required(),
  siteId: yup.number().integer().required(),
  appointmentDate: yup.date().required(),
  patientId: yup.number().integer().required(),
  duration: yup.number().integer().required(),
});

export default function AppointmentModal({
  appointment,
  siteId,
  onClose,
  allowChanges,
  onCancelButtonClick,
}) {
  const formRef = useRef(null);
  const [appError, setAppError] = useState();
  const [timeZone, setTimeZone] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [patientOptions, setPatientOptions] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const readonly = !!appointment.encounter || !allowChanges;

  const encounterTypes = useEncounterTypes();
  const sites = useSites();

  const handlePatientAppointmentError = (error) => {
    setAppError(error.response?.data?.message);
  };

  const handleModalClose = () => {
    setAppError(null);
    setPatientOptions([]);
    setSelectedPatient(null);
    onClose();
  };

  const handlePatientAppointmentMutation = () => {
    handleModalClose();
  };

  const createPatientAppointment = useCreatePatientAppointment({
    onSuccess: handlePatientAppointmentMutation,
    onError: handlePatientAppointmentError,
  });

  const updatePatientAppointment = useUpdatePatientAppointment({
    onSuccess: handlePatientAppointmentMutation,
    onError: handlePatientAppointmentError,
  });

  useEffect(() => {
    if (appointment.id) {
      setTimeZone(appointment.site.zone.name);
    } else {
      const selectedSite = sites.data.find((x) => x.id === siteId);
      setTimeZone(selectedSite.zone.name);
    }
  }, [sites.isSuccess]);

  useEffect(() => {
    if (appointment.patient) {
      setSelectedPatient(appointment.patient);
    }
  }, [appointment]);

  useEffect(() => {
    if (
      createPatientAppointment.isLoading ||
      updatePatientAppointment.isLoading
    ) {
      setIsSubmitting(true);
    } else {
      setIsSubmitting(false);
    }
  }, [createPatientAppointment.isLoading, updatePatientAppointment.isLoading]);

  const activePatientSites = useMemo(() => {
    const patient = appointment.patient || selectedPatient;

    if (patient?.sites && sites.data?.length) {
      return patient.sites.filter(
        (ps) =>
          typeof sites.data.find((site) => site.id === ps.id) !== 'undefined'
      );
    }
    if (sites.data?.length && appointment.site) {
      return [appointment.site];
    }

    return [];
  }, [sites.data, appointment.patient?.sites, selectedPatient]);

  if (encounterTypes.isLoading || sites.isLoading) {
    return null;
  }

  const handleSave = () => {
    setAppError(null);
    formRef.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  };

  const handleCancel = () => {
    onCancelButtonClick();
  };

  const handleFormSubmit = (values) => {
    if (isSubmitting) {
      return;
    }

    const appointmentDate = DateTime.fromISO(values.appointmentDate, {
      zone: timeZone,
    })
      .toUTC()
      .toISO();
    const encounterType = encounterTypes.data.find(
      (e) => e.id === Number(values.encounterTypeId)
    );

    const newAppointment = {
      ...values,
      appointmentDate,
      reason: encounterType.name,
      visitNumber: 0,
      appointmentTypeId: AppointmentTypes.OBJECTIVE_SCREEN,
    };

    if (newAppointment.id) {
      updatePatientAppointment.mutate(newAppointment);
    } else {
      createPatientAppointment.mutate(newAppointment);
    }
  };

  const handleSearch = async (query) => {
    // There is no site here, as we want to associate user with given site
    // if they are not linked together yet.
    const results = await axios.get('/api/patients/search', {
      params: { query },
    });

    if (results.data.rows?.length === 0) {
      return;
    }

    setPatientOptions(results.data.rows);
  };

  const handleSiteChange = (event) => {
    const selectedSite = sites.data.find(
      (x) => x.id === Number(event.target.value)
    );

    if (selectedSite) {
      setTimeZone(selectedSite.zone.name);
    }
  };
  const modalTitle = `${appointment.id ? 'Edit' : 'New'} Appointment`;
  const buttonText = appointment.id ? 'Save' : 'Create';

  return (
    <Modal
      show
      backdrop="static"
      keyboard={false}
      centered
      onHide={handleModalClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Alert variant="danger" show={!!appError}>
              {appError}
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col>
            <Formik
              validationSchema={SCHEMA}
              initialValues={{ ...appointment, siteId }}
              onSubmit={handleFormSubmit}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                setFieldValue,
                touched,
                errors,
              }) => (
                <Form
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                  ref={formRef}
                >
                  <Form.Group controlId="formEncounterType">
                    <Form.Label>Visit Type</Form.Label>
                    <FormSelect
                      autoFocus
                      name="encounterTypeId"
                      disabled={readonly}
                      value={values.encounterTypeId || ''}
                      onChange={handleChange}
                      isValid={
                        touched.encounterTypeId && !errors.encounterTypeId
                      }
                      isInvalid={!!errors.encounterTypeId}
                    >
                      <option key="option-default" value="">
                        Select Visit Type
                      </option>
                      {encounterTypes.data.map((x) => (
                        <option key={`option-${x.id}`} value={x.id}>
                          {x.name}
                        </option>
                      ))}
                    </FormSelect>
                  </Form.Group>

                  <Form.Group controlId="formPatientId">
                    <Form.Label>Appointment For</Form.Label>
                    <AsyncTypeahead
                      id="patientId"
                      inputProps={{ name: 'patientId' }}
                      disabled={!!appointment.id || readonly}
                      filterBy={() => true}
                      labelKey="fullName"
                      minLength={3}
                      onSearch={(s) => handleSearch(s)}
                      isLoading={false}
                      options={patientOptions}
                      selected={selectedPatient ? [selectedPatient] : []}
                      placeholder="Search by patient name or MRN"
                      onChange={(patients) => {
                        if (patients.length === 0) {
                          setFieldValue('patientId', '');
                          setSelectedPatient('');
                        } else {
                          setFieldValue('patientId', patients[0].id);
                          setSelectedPatient(patients[0]);
                        }
                      }}
                      isValid={touched.patientId && !errors.patientId}
                      isInvalid={!!errors.patientId}
                      renderMenuItemChildren={(option) => (
                        <section className="d-flex flex-row">
                          <span className="text-truncate fw-bold me-2">
                            {option.fullName}
                          </span>
                          <span className="align-self-center small me-2">
                            DOB:&nbsp;
                            {formatDateDisplay(option.birthDate)}
                          </span>
                          <span className="align-self-center small">
                            MRN: {option.medicalRecordNumber}
                          </span>
                        </section>
                      )}
                    />
                  </Form.Group>
                  <Form.Group controlId="formSite">
                    <SelectField
                      labelClassName="Site"
                      label="Site"
                      name="siteId"
                      disabled={!!appointment.id || readonly}
                      defaultDisplay="Select Site"
                      options={activePatientSites || []}
                      valueAccessor="id"
                      displayAccessor="name"
                      additionalOnChange={handleSiteChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formAppointmentDate">
                    <Form.Label>
                      <span>Appointment Date</span>{' '}
                      <small className="text-muted fst-italic">
                        (time local to site location)
                      </small>
                    </Form.Label>
                    <Form.Control
                      type="datetime-local"
                      step={900}
                      name="appointmentDate"
                      disabled={readonly}
                      value={
                        values.appointmentDate
                          ? DateTime.fromISO(values.appointmentDate, {
                              zone: timeZone,
                            }).toFormat('yyyy-MM-dd HH:mm')
                          : ''
                      }
                      onChange={handleChange}
                      isValid={
                        touched.appointmentDate && !errors.appointmentDate
                      }
                      isInvalid={!!errors.appointmentDate}
                    />
                  </Form.Group>
                  <Form.Group controlId="formDuration">
                    <Form.Label>Duration</Form.Label>
                    <FormSelect
                      name="duration"
                      disabled={readonly}
                      value={values.duration || ''}
                      onChange={handleChange}
                      isValid={touched.duration && !errors.duration}
                      isInvalid={!!errors.duration}
                    >
                      <option key="0" value="">
                        Select Visit Duration
                      </option>
                      <option value={15}>15 minutes</option>
                      <option value={30}>30 minutes</option>
                      <option value={45}>45 minutes</option>
                      <option value={60}>1 hour</option>
                    </FormSelect>
                  </Form.Group>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <div>
          {appointment.id && !readonly && onCancelButtonClick ? (
            <Button
              variant="danger"
              onClick={handleCancel}
              disabled={isSubmitting}
            >
              Cancel Appointment
              <Spinner
                animation="border"
                role="status"
                size="sm"
                className={`ms-2 ${isSubmitting ? '' : 'd-none'}`}
              />
            </Button>
          ) : null}
        </div>
        <div>
          <Button
            variant="secondary"
            className="me-1"
            onClick={handleModalClose}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className={`ms-1 ${readonly ? 'd-none' : ''}`}
            onClick={handleSave}
            disabled={isSubmitting}
          >
            {buttonText}
            <Spinner
              animation="border"
              role="status"
              size="sm"
              className={`ms-2 ${isSubmitting ? '' : 'd-none'}`}
            />
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

AppointmentModal.defaultProps = {
  onCancelButtonClick: undefined,
};

AppointmentModal.propTypes = {
  appointment: PropTypes.shape({
    id: PropTypes.string,
    site: PropTypes.shape({
      zone: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    encounter: PropTypes.shape({}),
    patient: PropTypes.shape({ sites: PropTypes.arrayOf(PropTypes.shape({})) }),
  }).isRequired,
  siteId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  allowChanges: PropTypes.bool.isRequired,
  onCancelButtonClick: PropTypes.func,
};
