import PropTypes from 'prop-types';
import React from 'react';
import { Stack } from 'react-bootstrap';
import CallOutcomesSection from './CallOutcomesSection';
import CancellationReasonsSection from './CancellationReasonsSection';
import DoucmentTypesSection from './DocumentTypesSection';
import ReferralSourcesSection from './ReferralSourcesSection';

function OrganizationSiteReasonsTab({ siteId }) {
  const sections = [
    CallOutcomesSection,
    CancellationReasonsSection,
    DoucmentTypesSection,
    ReferralSourcesSection,
  ];

  return (
    <Stack>
      {sections.map((Section, i) => (
        <React.Fragment key={Section.name}>
          <Section key={Section.name} siteId={siteId} />
          {i < sections.length - 1 && <hr />}
        </React.Fragment>
      ))}
    </Stack>
  );
}

OrganizationSiteReasonsTab.propTypes = {
  siteId: PropTypes.number.isRequired,
};

export default OrganizationSiteReasonsTab;
