import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

export function useSiteCallOutcomes(siteId) {
  return useQuery(
    ['site-call-outcomes', Number(siteId)],
    () =>
      axios.get(`/api/sites/${siteId}/call-outcomes`).then((res) => res.data),
    { enabled: Boolean(siteId), placeholderData: [] }
  );
}

const createSiteCallOutcome = async (siteCallOutcome) => {
  const result = await axios.post(
    `/api/sites/${siteCallOutcome.siteId}/call-outcomes`,
    siteCallOutcome
  );
  return result.data;
};

export function useCreateSiteCallOutcome(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createSiteCallOutcome,
    onSuccess: (siteCallOutcome) => {
      queryClient.invalidateQueries(['site', siteCallOutcome.siteId]);
      queryClient.invalidateQueries([
        'site-call-outcomes',
        siteCallOutcome.siteId,
      ]);

      options?.onSuccess?.(siteCallOutcome);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const deleteSiteCallOutcome = async ({ siteId, callOutcomeId }) => {
  const result = await axios.delete(
    `/api/sites/${siteId}/call-outcomes/${callOutcomeId}`
  );
  return result.data;
};

export function useDeleteSiteCallOutcome(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteSiteCallOutcome,
    onSuccess: (siteCallOutcome) => {
      queryClient.invalidateQueries(['site', siteCallOutcome.siteId]);
      queryClient.invalidateQueries([
        'site-call-outcomes',
        siteCallOutcome.siteId,
      ]);

      options?.onSuccess?.(siteCallOutcome);
    },
    onError: (error) => options?.onError?.(error),
  });
}
