import { Formik } from 'formik';
import React, { useContext, useRef, useState, useEffect } from 'react';
import { Alert, Button, Form, Row, Modal, Spinner } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import * as yup from 'yup';
import { MedicationContext } from './MedicationProvider';
import axios from '../../../../../../axios';

import SelectField from 'components/FormikForm/SelectField';
import TextField from 'components/FormikForm/TextField';
import { useMedicationFrequencies } from 'hooks/medicationHooks';

const schema = yup.object({
  medicationCodeId: yup.number().integer().required(),
  medicationFrequencyId: yup.number().integer().required(),
  startDate: yup
    .date()
    .notRequired()
    .max(new Date(), 'date cannot be in the future'),
  stopDate: yup.date().notRequired(),
  dosage: yup.string().notRequired(),
  units: yup.string().notRequired(),
  route: yup.string().notRequired(),
  reason: yup.string().notRequired(),
});

export default function MedicationModal() {
  const medicationFrequencies = useMedicationFrequencies();
  const formRef = useRef();
  const [codeOptions, setCodeOptions] = useState([]);
  const [selectedCode, setSelectedCode] = useState(null);

  const { error, medication, showModal, closeModal, saveMedication, isBusy } =
    useContext(MedicationContext);

  useEffect(() => {
    if (showModal) {
      setCodeOptions([]);
      setSelectedCode(medication.medicationCode || null);
    } else {
      setSelectedCode(null);
    }
  }, [medication.medicationCode, showModal]);

  const handleSearch = (substring) => {
    axios
      .get('/api/medications/codes', { params: { substring } })
      .then((res) => {
        setCodeOptions(res.data);
      });
  };

  const handleFormSubmit = (values) => {
    saveMedication({ ...medication, ...values });
  };

  const handleSave = () => {
    formRef.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  };

  return (
    <Modal
      show={showModal}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Medication</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="danger" show={!!error}>
          {error}
        </Alert>

        <Formik
          validationSchema={schema}
          onSubmit={handleFormSubmit}
          initialValues={medication}
        >
          {({ handleSubmit, setFieldValue, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit} ref={formRef}>
              <Row xs={1} className="gy-3">
                <Form.Group controlId="formMedicationCode">
                  <Form.Label>Medication Code</Form.Label>
                  <AsyncTypeahead
                    autoFocus
                    id="medicationCodeId"
                    name="medicationCodeId"
                    filterBy={() => true}
                    labelKey="name"
                    minLength={3}
                    isLoading={false}
                    onSearch={handleSearch}
                    options={codeOptions}
                    selected={selectedCode ? [selectedCode] : []}
                    placeholder="Start typing code or description..."
                    onChange={(code) => {
                      if (code.length === 0) {
                        setFieldValue('medicationCodeId', null);
                        setSelectedCode(null);
                      } else {
                        setFieldValue('medicationCodeId', code[0].id);
                        setSelectedCode(code[0]);
                      }
                    }}
                    isValid={
                      touched.medicationCodeId && !errors.medicationCodeId
                    }
                    isInvalid={!!errors.medicationCodeId}
                    renderMenuItemChildren={(option) => (
                      <section className="d-flex flex-row">
                        <span className="text-primary me-2 w-10">
                          {option.code}
                        </span>
                        <span className="text-truncate w-90">
                          {option.name}
                        </span>
                      </section>
                    )}
                  />
                </Form.Group>
                <SelectField
                  label="Frequency"
                  name="medicationFrequencyId"
                  defaultDisplay="Select Frequency"
                  options={medicationFrequencies.data}
                  valueAccessor="id"
                  displayAccessor="name"
                />
              </Row>
              <Row xs={2} className="gy-3">
                <TextField label="Dosage" name="dosage" />
                <TextField label="Units" name="units" />
                <TextField label="Route" name="route" />
                <TextField label="Reason" name="reason" />
                <TextField name="startDate" label="Start Date" type="date" />
                <TextField name="stopDate" label="Stop Date" type="date" />
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={isBusy}>
          Save
          <Spinner
            animation="border"
            role="status"
            size="sm"
            className={`ms-2 ${isBusy ? '' : 'd-none'}`}
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
