import PropTypes from 'prop-types';
import React from 'react';
import { Stack, Table } from 'react-bootstrap';
import { Dash } from 'react-bootstrap-icons';
import { EmptyTableMessage } from 'components/Layouts/TableLayout';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

export default function PatientSitesSection({ sites }) {
  return (
    <Stack>
      <div className="display-3 mb-2">Sites</div>

      {sites.length ? (
        <Table>
          <thead>
            <tr className="text-uppercase">
              <th>Name</th>
              <th className="text-center">Date</th>
            </tr>
          </thead>
          <tbody>
            {sites.map((site) => (
              <tr key={site.id} className="mt-1 pb-2 align-middle">
                <td>{site.name} </td>
                <td className="text-center" style={{ width: '15%' }}>
                  {formatDateDisplay(site.PatientSite.createdAt) || <Dash />}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTableMessage message="This patient is not associated to any site." />
      )}
    </Stack>
  );
}

PatientSitesSection.propTypes = {
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      PatientSite: PropTypes.shape({
        createdAt: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
};
