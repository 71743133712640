import React, { useState } from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { Search, X } from 'react-bootstrap-icons';
import { StringParam, useQueryParam } from 'use-query-params';

export default function PatientSearch() {
  const [query, setQuery] = useQueryParam('query', StringParam);
  const [searchText, setSearchText] = useState(query);

  const handleReset = () => {
    setSearchText('');
    setQuery(undefined);
  };

  const onSubmit = (e) => {
    setQuery(searchText);
    e.preventDefault();
  };

  return (
    <Form onSubmit={onSubmit} className="float-end">
      <InputGroup className="mb-0 h-100 flex-nowrap justify-content-end">
        <Form.Control
          placeholder="Search by name, MRN or phone #"
          value={searchText || ''}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ minWidth: '200px' }}
          size="sm"
        />
        <Button
          className="bg-white border-start-0"
          variant="outline-gray-200"
          onClick={handleReset}
          type="reset"
        >
          <X size={22} className="text-teal" />
        </Button>
        <Button className="d-flex" variant="" type="submit">
          <Search size={18} className="m-auto" />
        </Button>
      </InputGroup>
    </Form>
  );
}
