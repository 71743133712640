import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ConnectionSitesTable from './ConnectionSitesTable';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { useDeleteConnectionSite } from 'hooks/connectionHooks';

function ConfirmationBody(siteName) {
  return (
    <span>
      Are you sure you want to detach the{' '}
      <span className="fw-bold">{siteName}</span> site from this connection?
    </span>
  );
}

export default function ConnectionSitesList({ context }) {
  const { connection, showConfirmRemove, setShowConfirmRemove } =
    useContext(context);
  const [selectedSite, setSelectedSite] = useState();
  const [confirmationBody, setConfirmationBody] = useState('');

  const deleteConnectionSite = useDeleteConnectionSite({
    onSuccess: () => {
      setShowConfirmRemove(false);
    },
  });

  const confirmRemove = (site) => {
    const siteName = site.name;
    const newConfirmationBody = ConfirmationBody(siteName);

    setConfirmationBody(newConfirmationBody);

    setSelectedSite(site);
    setShowConfirmRemove(true);
  };

  const handleRemove = () =>
    deleteConnectionSite.mutate({
      siteId: selectedSite.id,
      connectionId: connection.id,
    });

  return (
    <>
      <Row>
        <Col>
          <h5>Associated Sites</h5>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={8}>
          {connection.sites.length ? (
            <ConnectionSitesTable
              connection={connection}
              confirmRemove={confirmRemove}
            />
          ) : (
            <p className="fst-italic">
              No sites associated to this connection...
            </p>
          )}
        </Col>
      </Row>
      <ConfirmationDialog
        show={showConfirmRemove}
        title="Confirm Remove"
        bodyText={confirmationBody}
        confirmText="Remove"
        onConfirm={handleRemove}
        onCancel={() => setShowConfirmRemove(false)}
      />
    </>
  );
}

ConnectionSitesList.propTypes = {
  context: PropTypes.shape({}).isRequired,
};
