import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Alert, Button, Form, Row, Col, Modal, Spinner } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import * as yup from 'yup';
import axios from '../../../../../../axios';
import {
  useCreatePatientObservation,
  useUpdatePatientObservation,
} from 'hooks/patientObservationHooks';

const schema = yup.object({
  observationCodeId: yup.number().integer().required(),
  date: yup.date().nullable().max(new Date(), 'date cannot be in the future'),
  value: yup.string().required(),
  units: yup.string().ensure(),
});

export const DEFAULT_OBSSERVATION = {
  id: '',
  patientId: 0,
  observationTypeId: 0,
  observationCodeId: 0,
  date: '',
  value: '',
  units: '',
};

function ObservationModal({ type, onClose, patientId, observation }) {
  const formRef = useRef();
  const [error, setError] = useState(undefined);
  const [codeOptions, setCodeOptions] = useState([]);
  const [selectedCode, setSelectedCode] = useState(null);

  const mutationOptions = {
    meta: { type },
    onSuccess: () => onClose(),
    onError: (e) => setError(e.response?.data?.message),
  };

  const { mutate: createPatientObservation, isLoading: isCreateLoading } =
    useCreatePatientObservation(mutationOptions);
  const { mutate: updatePatientObservation, isLoading: isUpdateLoading } =
    useUpdatePatientObservation(mutationOptions);

  const isLoading = isCreateLoading || isUpdateLoading;

  const saveObservation = (values) => {
    setError('');

    const data = {
      ...values,
      patientId,
      date: values.date ? new Date(values.date) : null,
    };

    if (values.id) {
      updatePatientObservation(data);
    } else {
      createPatientObservation(data);
    }
  };

  const handleSearch = (substring) => {
    axios
      .get('/api/observations/codes', { params: { type, substring } })
      .then((res) => {
        setCodeOptions(res.data);
      });
  };

  const handleFormSubmit = (values) => {
    saveObservation({
      ...observation,
      ...values,
    });
  };

  const handleSave = useCallback(() => {
    formRef.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  }, []);

  useEffect(() => {
    setCodeOptions([]);
    setSelectedCode(observation.observationCode || null);
  }, [observation.observationCode]);

  return (
    <Modal
      show
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
      onHide={onClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Observation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="danger" show={!!error}>
          {error}
        </Alert>

        <Formik
          validationSchema={schema}
          onSubmit={handleFormSubmit}
          initialValues={observation}
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit} ref={formRef}>
              <Row>
                <Col>
                  <Form.Group controlId="formObservationCode">
                    <Form.Label>Code</Form.Label>
                    <AsyncTypeahead
                      autoFocus
                      id="observationCodeId"
                      name="observationCodeId"
                      filterBy={() => true}
                      labelKey="name"
                      minLength={3}
                      isLoading={false}
                      onSearch={handleSearch}
                      options={codeOptions}
                      selected={selectedCode ? [selectedCode] : []}
                      placeholder="Start typing code or description..."
                      onChange={(code) => {
                        if (code.length === 0) {
                          setFieldValue('observationTypeId', null);
                          setFieldValue('observationCodeId', null);
                          setSelectedCode(null);
                        } else {
                          setFieldValue(
                            'observationTypeId',
                            code[0].observationTypeId
                          );
                          setFieldValue('observationCodeId', code[0].id);
                          setSelectedCode(code[0]);
                        }
                      }}
                      isValid={
                        touched.observationCodeId && !errors.observationCodeId
                      }
                      isInvalid={!!errors.observationCodeId}
                      renderMenuItemChildren={(option) => (
                        <section className="d-flex flex-row">
                          <span className="text-primary me-2 w-10">
                            {option.code}
                          </span>
                          <span className="text-truncate w-90">
                            {option.name}
                          </span>
                        </section>
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formDate">
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="date"
                      value={values.date || ''}
                      onChange={handleChange}
                      isValid={touched.date && !errors.date}
                      isInvalid={!!errors.date}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formValue">
                    <Form.Label>Value</Form.Label>
                    <Form.Control
                      type="text"
                      name="value"
                      placeholder="10.0"
                      value={values.value}
                      onChange={handleChange}
                      isValid={touched.value && !errors.value}
                      isInvalid={!!errors.value}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formUnits">
                    <Form.Label>Units</Form.Label>
                    <Form.Control
                      type="text"
                      name="units"
                      placeholder="lbs"
                      value={values.units || ''}
                      onChange={handleChange}
                      isValid={touched.units && !errors.units}
                      isInvalid={!!errors.units}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={isLoading}>
          {isLoading ? (
            <Spinner
              animation="border"
              role="status"
              size="sm"
              className="ms-2"
            />
          ) : (
            'Save'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ObservationModal.propTypes = {
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  patientId: PropTypes.number.isRequired,
  observation: PropTypes.shape({
    id: PropTypes.string,
    patientId: PropTypes.number,
    observationTypeId: PropTypes.number,
    observationCodeId: PropTypes.number,
    date: PropTypes.string,
    value: PropTypes.string,
    units: PropTypes.string,
    observationCode: PropTypes.shape({}),
  }).isRequired,
};

export default ObservationModal;
