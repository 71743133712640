import React, { useState, useEffect } from 'react';
import SearchForm from './components/SearchForm';
import AdminListLayout from '../Layouts/AdminListLayout';
import LoadingIndicator from 'components/LoadingIndicator';
import SearchResultsTable from 'components/SearchResultsTable';
import { useQuerySearch } from 'hooks/searchHooks';
import { useStudy } from 'hooks/studyHooks';
import {
  useCreateStudyMatchingConfiguration,
  useUpdateStudyMatchingConfiguration,
} from 'hooks/studyMatchingConfigurationHooks';
import { usePagination, useSorting } from 'hooks/tableHooks';

const DEFAULT_PAGINATION = { pageIndex: 0, pageSize: 100 };
const DEFAULT_SORTING = [{ id: 'site.name', desc: false }];

export default function StudyMatchingConfigurations() {
  const [selectedStudyId, setSelectedStudyId] = useState(null);

  const [pagination, setPagination] = usePagination(DEFAULT_PAGINATION);
  const [sorting, setSorting] = useSorting(DEFAULT_SORTING);

  const { data: study } = useStudy(selectedStudyId);
  const [results, setResults] = useState(null);
  const [appError, setAppError] = useState(null);
  const querySearch = useQuerySearch({
    onSuccess: (searchResults) => setResults(searchResults.data),
    onError: (error) => setAppError(error.response.data.message),
  });

  const createStudyMatchingConfiguration =
    useCreateStudyMatchingConfiguration();

  const updateStudyMatchingConfiguration =
    useUpdateStudyMatchingConfiguration();

  const resetResults = () => {
    setResults(null);
    setAppError(null);
  };

  const handleSearch = () => {
    resetResults();

    /** @type {HTMLInputElement} */
    const el = document.querySelector('#formQuery');

    if (el?.value) {
      querySearch.mutate({ query: el.value, pagination, sorting });
    }
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, sorting]);

  const handleSelectedStudyChange = async (event, setFieldValue) => {
    const { value } = event.target;

    resetResults();

    setSelectedStudyId(Number(value));
    await setFieldValue('studyId', value, false);
  };

  const handleFormSubmit = (values) => {
    const fn = study.studyMatchingConfiguration?.id
      ? updateStudyMatchingConfiguration
      : createStudyMatchingConfiguration;

    const params = {
      id: study.studyMatchingConfiguration?.id,
      studyId: values.studyId,
      query: JSON.stringify(JSON.parse(values.query)),
      active: values.active,
    };

    fn.mutate(params);
  };

  return (
    <AdminListLayout
      topRow={
        <SearchForm
          configuration={study?.studyMatchingConfiguration}
          onStudyChange={handleSelectedStudyChange}
          onSubmit={handleFormSubmit}
          onSearch={handleSearch}
          disabled={
            createStudyMatchingConfiguration.isLoading ||
            updateStudyMatchingConfiguration.isLoading ||
            !study
          }
          isBusy={
            createStudyMatchingConfiguration.isLoading ||
            updateStudyMatchingConfiguration.isLoading
          }
        />
      }
      table={
        <>
          {appError && (
            <div className="m-3 alert alert-warning">
              <h5 className="text-center text-warning">{appError}</h5>
            </div>
          )}
          {querySearch.isLoading && <LoadingIndicator />}

          <SearchResultsTable
            data={results}
            pagination={pagination}
            setPagination={setPagination}
            sorting={sorting}
            setSorting={setSorting}
          />
        </>
      }
    />
  );
}
