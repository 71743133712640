import React from 'react';
import { Row, Col } from 'react-bootstrap';
import StatusGroupCard from './StatusGroupCard';
import useStatusGroupMetrics from 'hooks/statusGroupHooks';
import useDashboardStore from 'stores/dashboardStore';

export default function StatusGroupMetrics() {
  const {
    toolbar: { site, distanceInMiles, study },
    statusGroups: { selectedGroup, updateStatusGroup },
  } = useDashboardStore();

  const metrics = useStatusGroupMetrics(site?.id, distanceInMiles, study?.id);

  const handleGroupSelect = (groupName) => {
    updateStatusGroup(groupName);
  };

  if (metrics.isLoading) return null;

  return (
    <Row id="status-group-metrics" className="gap-2">
      {metrics.data.map((group) => (
        <Col key={group.name} className="px-0">
          <StatusGroupCard
            name={group.name}
            count={Number(group.count)}
            clickable
            active={selectedGroup.toLowerCase() === group.name.toLowerCase()}
            onClick={() => handleGroupSelect(group.name)}
          />
        </Col>
      ))}
    </Row>
  );
}
