import { DateTime } from 'luxon';
import React from 'react';
import { Dash, FileEarmark, ShieldLock } from 'react-bootstrap-icons';
import PatientStudyTableDisplay from 'components/PatientStudyTableDisplay';
import ReadonlyIcon from 'components/ReadonlyIcon';
import RecentNoteIcon from 'components/RecentNoteIcon';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

const externalIndicator = {
  accessorKey: 'external',
  header: () => <ShieldLock />,
  enableSorting: false,
  meta: {
    headerProps: { className: 'text-center', style: { width: '32px' } },
    cellProps: { className: 'text-center' },
  },
  cell: (props) => (
    <ReadonlyIcon readonly={props.getValue()} tooltip="External" />
  ),
};

const medicalRecordNumber = {
  accessorKey: 'medicalRecordNumber',
  header: 'MRN',
  meta: { headerProps: { className: 'display-4', style: { width: '15%' } } },
};

const fullName = {
  accessorKey: 'fullName',
  header: 'Name',
  meta: { headerProps: { className: 'display-4' } },
};

const study = {
  id: 'patientStudies',
  accessorFn: (row) => {
    const ps = row.patientStudies;

    if (ps.length === 0) {
      return '';
    }

    if (ps.length === 1) {
      return ps[0].study.name;
    }

    return `${ps.length} Studies`;
  },
  header: 'Study',
  enableSorting: false,
  meta: {
    headerProps: { className: 'display-4 fw-bold text-uppercase' },
  },
  cell: (props) => (
    <PatientStudyTableDisplay
      cellDisplay={props.getValue()}
      patientStudies={props.row.original.patientStudies}
    />
  ),
};

const chartReviewReason = {
  accessorKey: 'latest_chart_review_reason',
  header: 'Reason',
  meta: { headerProps: { className: 'display-4' } },
  cell: (props) => props.getValue() ?? <Dash />,
};

const studyHistoryReason = {
  accessorKey: 'latest_study_history_reason',
  header: 'Reason',
  meta: { headerProps: { className: 'display-4' } },
  cell: (props) => props.getValue() ?? <Dash />,
};

const screenPendingReason = {
  accessorFn: (row) => {
    const {
      latest_chart_review_created_at: latestChartReviewCreatedAt,
      latest_chart_review_reason: latestChartReviewReason,
      latest_study_history_created_at: latestStudyHistoryCreatedAt,
      latest_study_history_reason: latestStudyHistoryReason,
    } = row;

    const hasChartReview = Boolean(latestChartReviewCreatedAt);
    const hasStudyHistory = Boolean(latestStudyHistoryCreatedAt);

    if (!hasChartReview && !hasStudyHistory) {
      return null;
    }

    if (!hasChartReview) {
      return latestStudyHistoryReason;
    }

    if (!hasStudyHistory) {
      return latestChartReviewReason;
    }

    let reason = latestStudyHistoryReason;

    if (
      DateTime.fromISO(latestChartReviewCreatedAt) >
      DateTime.fromISO(latestStudyHistoryCreatedAt)
    ) {
      reason = latestChartReviewReason;
    }

    return reason;
  },
  enableSorting: false,
  header: 'Reason',
  meta: { headerProps: { className: 'display-4' } },
  cell: (props) => props.getValue() ?? <Dash />,
};

const screeningDate = {
  id: 'latest_study_history_created_at',
  accessorFn: (row) => formatDateDisplay(row.latest_study_history_created_at),
  header: 'Screening Date',
  cell: (props) => props.getValue() ?? <Dash />,
  meta: {
    headerProps: { className: 'text-center display-4' },
    cellProps: { className: 'text-center' },
  },
};

const completedDate = {
  id: 'completedDate',
  accessorFn: (row) =>
    formatDateDisplay(row.patientStudies?.[0]?.studyStatus.updatedAt),
  header: 'Completed Date',
  enableSorting: false,
  meta: {
    headerProps: { className: 'display-4 fw-bold text-uppercase' },
  },
};

const nextAppointment = {
  accessorKey: 'nextAppointment',
  header: 'Appt',
  meta: { headerProps: { className: 'display-4', style: { width: '15%' } } },
  cell: (props) => {
    const {
      nextAppointment: appointment,
      appointmentReason,
      sites: [{ zone }],
    } = props.row.original;

    if (appointment) {
      const timeZone = zone.name;
      const formattedDate = DateTime.fromISO(appointment, {
        zone: timeZone,
      }).toFormat('MM/dd/yy t ZZZZ');

      return (
        <div>
          <div>{formattedDate}</div>
          <div className="text-muted">{appointmentReason}</div>
        </div>
      );
    }
    return <Dash />;
  },
};

const remarks = {
  accessorKey: 'remarks',
  header: () => <FileEarmark />,
  enableSorting: false,
  meta: {
    headerProps: { className: 'text-center', style: { width: '32px' } },
    cellProps: { className: 'text-center' },
  },
  cell: (props) => (
    <RecentNoteIcon
      show={!!props.row.original.remarks.length}
      id={props.row.original.id}
    />
  ),
};

const enrollmentDate = {
  id: 'researchProfiles.updatedAt',
  accessorFn: (row) => row.researchProfiles[0]?.updatedAt,
  header: 'Enrollment Date',
  meta: {
    headerProps: { className: 'text-center display-4' },
    cellProps: { className: 'text-center' },
  },
  cell: (props) =>
    props.getValue() ? formatDateDisplay(props.getValue()) : <Dash />,
};

const lastContacted = {
  accessorKey: 'latest_interaction',
  header: 'Last Contacted',
  meta: {
    headerProps: {
      className: 'text-center display-4',
      style: { width: '15%' },
    },
    cellProps: { className: 'text-center' },
  },
  cell: (props) =>
    props.getValue() ? formatDateDisplay(props.getValue()) : <Dash />,
};

export default function getPatientTableColumns(discriminator) {
  switch (discriminator) {
    // Not Eligible bucket
    case 'Not Eligible':
      return [
        externalIndicator,
        fullName,
        medicalRecordNumber,
        chartReviewReason,
        remarks,
      ];
    case 'Screen Fail':
    case 'Not Interested':
      return [
        externalIndicator,
        fullName,
        medicalRecordNumber,
        studyHistoryReason,
        remarks,
      ];
    // ENrolled Bucket
    case 'Enrolled':
      return [
        externalIndicator,
        fullName,
        medicalRecordNumber,
        study,
        enrollmentDate,
        remarks,
      ];
    case 'Completed':
      return [
        externalIndicator,
        fullName,
        medicalRecordNumber,
        study,
        completedDate,
        remarks,
      ];
    // Prescreen bucket
    case 'Prequalified':
    case 'Prescreen Completed':
    case 'Prescreen Scheduled':
      return [
        externalIndicator,
        fullName,
        medicalRecordNumber,
        study,
        nextAppointment,
        lastContacted,
        remarks,
      ];
    // Screening status group
    case 'Screen Pending':
      return [
        externalIndicator,
        fullName,
        medicalRecordNumber,
        study,
        nextAppointment,
        screenPendingReason,
        lastContacted,
        remarks,
      ];
    case 'Screening':
      return [
        externalIndicator,
        fullName,
        medicalRecordNumber,
        study,
        screeningDate,
        lastContacted,
        remarks,
      ];
    // Prospects bucket
    default:
      return [
        externalIndicator,
        fullName,
        medicalRecordNumber,
        study,
        nextAppointment,
        lastContacted,
        remarks,
      ];
  }
}
