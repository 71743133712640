import PropTypes from 'prop-types';
import React from 'react';
import SiteReasonsSection from './SiteReasonsSection';

import { useReferralSources } from 'hooks/referralSourceHooks';
import {
  useSiteReferralSources,
  useCreateSiteReferralSource,
  useDeleteSiteReferralSource,
} from 'hooks/siteReferralSourceHooks';

function ReferralSourcesSection({ siteId }) {
  return (
    <SiteReasonsSection
      siteId={siteId}
      reasonType="Referral Source"
      useSiteReasons={useSiteReferralSources}
      useOrgReasons={useReferralSources}
      useCreateSiteReason={useCreateSiteReferralSource}
      useDeleteSiteReason={useDeleteSiteReferralSource}
    />
  );
}

ReferralSourcesSection.propTypes = {
  siteId: PropTypes.number.isRequired,
};

export default ReferralSourcesSection;
