import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import * as yup from 'yup';

import CollapsibleSection from '../../../../components/CollapsibleSection';
import {
  ACTION_BUTTON_TYPES,
  ActionButton,
} from 'components/Button/ActionButton';
import ConfirmationDialog from 'components/ConfirmationDialog';
import SelectField from 'components/FormikForm/SelectField';
import TableLayout from 'components/Layouts/TableLayout';
import LoadingIndicator from 'components/LoadingIndicator';
import ModalForm from 'components/ModalForm';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

const COLUMN_WIDTH_PERCENTAGE = '15%';

const getReasonTableColumns = (onDelete) => [
  {
    accessorKey: 'name',
    header: 'Reason',
  },
  {
    accessorKey: 'active',
    header: 'Active',
    meta: {
      headerProps: { className: 'text-center' },
      cellProps: {
        className: 'text-center',
        style: { width: COLUMN_WIDTH_PERCENTAGE },
      },
    },
    cell: ({ getValue }) => (getValue() ? 'Yes' : 'No'),
  },
  {
    accessorKey: 'createdAt',
    header: 'Date Created',
    cell: (props) => formatDateDisplay(props.getValue()),
    meta: {
      headerProps: {
        className: 'text-center text-nowrap',
      },
      cellProps: {
        className: 'text-center',
        style: { width: COLUMN_WIDTH_PERCENTAGE },
      },
    },
  },
  {
    header: 'Actions',
    meta: {
      headerProps: { className: 'text-center' },
      cellProps: {
        style: { width: COLUMN_WIDTH_PERCENTAGE },
        className: 'text-center',
      },
    },
    // eslint-disable-next-line react/no-unstable-nested-components
    cell: ({ row }) =>
      row.original.organizationId && (
        <ActionButton
          action={ACTION_BUTTON_TYPES.DELETE}
          className="btn-lg btn-block"
          onClick={() => onDelete(row.original.id)}
        />
      ),
  },
];

const convertStringToSnakeCaseWithId = (string) =>
  string
    .split(' ')
    .map((word, i) =>
      i === 0
        ? word.toLowerCase()
        : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join('');

export const REASONS_SELECT_SCHEMA = yup.object({
  id: yup.number().required(),
});

function SiteReasonsSection({
  siteId,
  useSiteReasons,
  useOrgReasons,
  useCreateSiteReason,
  useDeleteSiteReason,
  reasonType,
}) {
  const reasonSnakeCase = convertStringToSnakeCaseWithId(reasonType);
  const [showModal, setShowModal] = useState(false);
  const [reasonIdToDelete, setReasonIdToDelete] = useState(null);
  const [error, setError] = useState(null);

  const reasons = useSiteReasons(siteId);
  const orgReasons = useOrgReasons();

  const createSiteReason = useCreateSiteReason({
    onSuccess: () => setShowModal(false),
    onError: (err) => setError(err?.response.data.message),
  });

  const deleteSiteReason = useDeleteSiteReason({
    onSuccess: () => setReasonIdToDelete(null),
    onError: (err) => setError(err?.response.data.message),
  });

  useEffect(() => {
    setError(null);
  }, [showModal]);

  const handleOnSubmit = ({ id }) => {
    createSiteReason.mutate({ [`${reasonSnakeCase}Id`]: id, siteId });
  };

  const handleDeleteReason = (callOutcome) => {
    setReasonIdToDelete(callOutcome);
  };

  const isLoading = reasons.isLoading || orgReasons.isloading;

  if (isLoading) return <LoadingIndicator />;

  const assignedReasons = reasons.data.map((c) => c.id);

  const options = orgReasons.data.filter(
    (orgReason) => !assignedReasons.includes(orgReason.id)
  );

  const COLUMNS = getReasonTableColumns(handleDeleteReason);

  return (
    <>
      <CollapsibleSection
        title={`${reasonType}s`}
        onAddButtonClick={() => setShowModal(true)}
        isAddButtonDisabled={!options.length}
        collapsibleChildren={
          <TableLayout columns={COLUMNS} data={reasons.data} />
        }
      />
      {showModal && (
        <ModalForm
          title={`Add ${reasonType}`}
          onClose={() => setShowModal(false)}
          onSubmit={handleOnSubmit}
          isSubmitting={createSiteReason.isLoading}
          validationSchema={REASONS_SELECT_SCHEMA}
          initialValues={{ id: 0 }}
          formId={`add-site-${reasonType}`}
        >
          <Alert variant="danger" show={!!error}>
            {error}
          </Alert>
          <SelectField
            name="id"
            label={reasonType}
            defaultDisplay={`Select ${reasonType}`}
            options={options}
            valueAccessor="id"
            displayAccessor="name"
            focus
          />
        </ModalForm>
      )}

      <ConfirmationDialog
        show={!!reasonIdToDelete}
        title="Confirm Delete"
        bodyText={`Are you sure you want to delete this site's ${reasonType.toLowerCase()}?`}
        confirmText="Delete"
        onConfirm={() =>
          deleteSiteReason.mutate({
            [`${reasonSnakeCase}Id`]: reasonIdToDelete,
            siteId,
          })
        }
        onCancel={() => setReasonIdToDelete(null)}
      />
    </>
  );
}

SiteReasonsSection.propTypes = {
  siteId: PropTypes.number.isRequired,
  useSiteReasons: PropTypes.func.isRequired,
  useOrgReasons: PropTypes.func.isRequired,
  useCreateSiteReason: PropTypes.func.isRequired,
  useDeleteSiteReason: PropTypes.func.isRequired,
  reasonType: PropTypes.oneOf([
    'Call Outcome',
    'Cancellation Reason',
    'Document Type',
    'Referral Source',
  ]).isRequired,
};

export default SiteReasonsSection;
