import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

export function useDocumentTypes(activeOnly = true, globalOnly = false) {
  return useQuery(
    ['document-types', activeOnly, globalOnly],
    async () => {
      const { data } = await axios.get(`/api/document-types`, {
        params: { activeOnly, globalOnly },
      });
      return data;
    },
    { placeholderData: [] }
  );
}

const createDocumentType = async (documentType) => {
  const result = await axios.post(`/api/document-types`, documentType);
  return result.data;
};

export function useCreateDocumentType(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createDocumentType,
    onSuccess: (documentType) => {
      queryClient.invalidateQueries(['document-types']);

      options?.onSuccess?.(documentType);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const updateDocumentType = async (documentType) => {
  const result = await axios.put(
    `/api/document-types/${documentType.id}`,
    documentType
  );
  return result.data;
};

export function useUpdateDocumentType(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateDocumentType,
    onSuccess: (documentType) => {
      queryClient.invalidateQueries(['document-types']);

      options?.onSuccess?.(documentType);
    },
    onError: (error) => options?.onError?.(error),
  });
}
