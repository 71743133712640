import React from 'react';
import { Stack, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import SiteAdd from './components/SiteAdd';
import UserInfo from './components/UserInfo';
import UserSiteList from './components/UserSiteList';
import { useUser } from 'hooks/userHooks';

function UserDetails() {
  const params = useParams();
  const userId = Number(params.id);

  const user = useUser(userId);

  if (user.isLoading) return null;

  return (
    <Stack gap={2}>
      <UserInfo user={user.data} />
      <Container fluid className="bg-white border border-1 rounded-3">
        <Stack gap={2} className="pt-2">
          <SiteAdd user={user.data} />
          <UserSiteList user={user.data} />
        </Stack>
      </Container>
    </Stack>
  );
}

export default UserDetails;
