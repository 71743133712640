/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';
import { Popover } from 'react-bootstrap';
import { useLatestPatientRemark } from 'hooks/patientRemarkHooks';
import './PatientNotePopover.scss';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

const PatientNotePopover = React.forwardRef(({ patientId, ...props }, ref) => {
  const remark = useLatestPatientRemark(patientId);

  let message = 'Loading...';
  let formattedDate = '';
  let sourceType = '';

  if (remark.isSuccess && !remark.data) {
    message = 'No patient remarks found.';
  }

  if (remark.isSuccess && remark.data) {
    const { createdAt, text, source, type } = remark.data;

    message = text;
    formattedDate = createdAt ? formatDateDisplay(createdAt) : '';
    sourceType = source === 'phone call' ? `From ${type} ${source}` : '';
  }

  return (
    <Popover {...props} ref={ref} className="patient-note">
      <Popover.Header>Patient Note</Popover.Header>
      <Popover.Body>
        <p>{message}</p>
        <p className="text-muted fst-italic">{sourceType}</p>
        <div className="text-muted text-end">{formattedDate}</div>
      </Popover.Body>
    </Popover>
  );
});

PatientNotePopover.propTypes = {
  patientId: PropTypes.number.isRequired,
};

export default PatientNotePopover;
