import React, { useState } from 'react';

import {
  Row,
  Col,
  DropdownButton,
  ListGroup,
  Tab,
  Dropdown,
  Tabs,
  Stack,
  FormCheck,
} from 'react-bootstrap';

import { Pencil, Trash } from 'react-bootstrap-icons';
import { useNavigate, useParams } from 'react-router-dom';
import OrganizationModal from '../components/OrganizationModal';
import ConfirmationDialog from 'components/ConfirmationDialog';
import TableLayout from 'components/Layouts/TableLayout';

import LoadingIndicator from 'components/LoadingIndicator';
import {
  useDeleteOrganization,
  useOrganization,
  useOrganizationSites,
  useOrganizationUsers,
} from 'hooks/organizationHooks';
import { usePagination } from 'hooks/tableHooks';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';
import { formatPhoneDisplay } from 'pages/utils/phoneUtils';

const formatBoolean = (value) => (value ? 'Yes' : 'No');

const SITE_COLUMNS = [
  {
    accessorKey: 'name',
    header: 'Nickname',
    meta: {
      headerProps: {
        className: 'col-2',
      },
    },
  },
  {
    accessorKey: 'fullName',
    header: 'Full Name',
  },
  {
    accessorKey: 'city',
    header: 'City',
    meta: {
      headerProps: {
        className: 'col-2',
      },
    },
  },
  {
    accessorKey: 'state',
    header: 'State',
    meta: {
      headerProps: {
        className: 'col-2',
      },
    },
  },
  {
    accessorKey: 'createdAt',
    header: 'Date Created',
    meta: { headerProps: { className: 'col-1 text-nowrap px-3' } },
    cell: ({ getValue }) => formatDateDisplay(getValue()),
  },
];

const USER_COLUMNS = [
  {
    accessorKey: 'user.fullName',
    header: 'Name',
  },
  {
    accessorKey: 'user.email',
    header: 'Email',
    meta: { headerProps: { className: 'col-4' } },
  },
  {
    accessorKey: 'user.createdAt',
    header: 'Date Created',
    meta: { headerProps: { className: 'col-1 text-nowrap px-3' } },
    cell: ({ getValue }) => formatDateDisplay(getValue()),
  },
];

const paginationOptions = {
  pageIndex: 0,
  pageSize: 25,
};

export default function OrganizationDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const organizationId = Number(params.id);

  const [sitePagination, setSitePagination] = usePagination(paginationOptions);
  const [userPagination, setUserPagination] = usePagination(paginationOptions);

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showOrganizationModal, setShowOrganizationModal] = useState(false);
  const [showActiveUsers, setShowActiveUsers] = useState(true);
  const [showActiveSites, setShowActiveSites] = useState(true);

  const organization = useOrganization(organizationId);

  const deleteOrganization = useDeleteOrganization({
    onSuccess: () => {
      navigate('/admin/organizations');
    },
    onError: () => {},
  });

  const sites = useOrganizationSites(
    organizationId,
    sitePagination.pageIndex,
    sitePagination.pageSize,
    showActiveSites
  );

  const users = useOrganizationUsers(
    organizationId,
    '',
    userPagination.pageIndex,
    userPagination.pageSize,
    showActiveUsers
  );

  const handleShowActiveUsers = (value) => {
    setUserPagination(paginationOptions);
    setShowActiveUsers(value);
  };

  const handleShowActiveSites = (value) => {
    setSitePagination(paginationOptions);
    setShowActiveSites(value);
  };

  const handleConfirmDeleteOrganizationClick = () => {
    deleteOrganization.mutate(organization.data);
  };

  const handleDeleteOrganizationClick = () => {
    setShowConfirmationDialog(true);
  };

  const handleEditOrganizationClick = () => {
    setShowOrganizationModal(true);
  };

  const getUserRowProps = (row) => ({
    className: 'hoverable-row',
    onClick: () => navigate(`/admin/users/${row.original.userId}`),
  });

  const getSiteRowProps = (row) => ({
    className: 'hoverable-row',
    onClick: () => navigate(`/admin/sites/${row.original.id}`),
  });

  if (organization.isLoading || sites.isLoading || users.isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Row id="organization-details">
        <Col>
          <div className="d-flex">
            <h1 className="flex-grow-1 fs-3 fw-normal mb-4">
              {organization.data.name}
            </h1>
            <DropdownButton className="" title="Actions">
              <Dropdown.Item onClick={handleEditOrganizationClick}>
                <Pencil /> Edit
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={handleDeleteOrganizationClick}>
                <Trash /> Delete
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <ListGroup>
            <ListGroup.Item>
              <span className="fw-bold">Active: </span>
              {formatBoolean(organization.data.active)}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="fw-bold">Date Created: </span>
              {formatDateDisplay(organization.data.createdAt)}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="fw-bold">SMS Prefix: </span>
              {organization.data.smsPrefix}
            </ListGroup.Item>
            <ListGroup.Item>
              <span className="fw-bold">SMS Origination Number: </span>
              {formatPhoneDisplay(organization.data.smsOriginationNumber)}
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col>
          <Tabs defaultActiveKey="sites">
            <Tab eventKey="sites" title="Sites">
              <Stack gap={2}>
                <Stack direction="horizontal">
                  <FormCheck
                    id="sites-active"
                    type="radio"
                    name="sites"
                    label="Active"
                    checked={showActiveSites}
                    onChange={() => handleShowActiveSites(true)}
                  />

                  <FormCheck
                    id="sites-inactive"
                    className="ms-3"
                    type="radio"
                    name="sites"
                    label="Inactive"
                    checked={!showActiveSites}
                    onChange={() => handleShowActiveSites(false)}
                  />
                </Stack>
                <TableLayout
                  columns={SITE_COLUMNS}
                  data={sites.data}
                  pagination={sitePagination}
                  setPagination={setSitePagination}
                  noResultsText="No sites assigned to this organization."
                  getRowProps={getSiteRowProps}
                />
              </Stack>
            </Tab>
            <Tab eventKey="users" title="Users">
              <Stack gap={2}>
                <Stack direction="horizontal">
                  <FormCheck
                    id="users-active"
                    type="radio"
                    name="users"
                    label="Active"
                    checked={showActiveUsers}
                    onChange={() => handleShowActiveUsers(true)}
                  />

                  <FormCheck
                    id="users-inactive"
                    className="ms-3"
                    type="radio"
                    name="users"
                    label="Inactive"
                    checked={!showActiveUsers}
                    onChange={() => handleShowActiveUsers(false)}
                  />
                </Stack>
                <TableLayout
                  columns={USER_COLUMNS}
                  data={users.data}
                  pagination={userPagination}
                  setPagination={setUserPagination}
                  noResultsText="No users assigned to this organization."
                  getRowProps={getUserRowProps}
                />
              </Stack>
            </Tab>
          </Tabs>
        </Col>
      </Row>

      <ConfirmationDialog
        show={showConfirmationDialog}
        title="Confirm Delete"
        bodyText="Are you sure you want to delete this organization?"
        confirmText="Delete"
        onConfirm={handleConfirmDeleteOrganizationClick}
        onCancel={() => setShowConfirmationDialog(false)}
      />

      {showOrganizationModal && (
        <OrganizationModal
          organization={organization.data}
          onClose={() => setShowOrganizationModal(false)}
        />
      )}
    </>
  );
}
