import PropTypes from 'prop-types';
import React from 'react';
import Stack from 'react-bootstrap/Stack';

import IndicationAdd from 'components/Management/SiteIndications/IndicationAdd';
import SiteIndicationList from 'components/Management/SiteIndications/SiteIndicationListReusable';

function OrganizationSiteIndicationsTab({ site }) {
  return (
    <Stack gap={3} className="w-50">
      <IndicationAdd siteId={site.id} />
      <SiteIndicationList site={site} />
    </Stack>
  );
}

export default OrganizationSiteIndicationsTab;

OrganizationSiteIndicationsTab.propTypes = {
  site: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};
