import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

export function usePatientDocuments(patientId) {
  return useQuery(patientId && ['patientDocuments', patientId], () =>
    axios.get(`/api/patients/${patientId}/documents`).then((res) => res.data)
  );
}

const createPatientDocument = async (values) => {
  const { patientId, data } = values;
  const response = await axios.post(
    `/api/patients/${patientId}/documents`,
    data,
    {
      headers: { 'content-type': 'multipart/form-data' },
    }
  );

  return response.data;
};

export function useCreatePatientDocument(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPatientDocument,
    onSuccess: (document) => {
      queryClient.invalidateQueries(['patientDocuments', document.patientId]);

      options?.onSuccess?.(document);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const deletePatientDocument = async (values) => {
  const { patientId, id } = values;
  await axios.delete(`/api/patients/${patientId}/documents`, {
    data: { id },
  });

  return { patientId };
};

export function useDeletePatientDocument(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePatientDocument,
    onSuccess: ({ patientId }) => {
      queryClient.invalidateQueries(['patientDocuments', patientId]);

      options?.onSuccess?.({ patientId });
    },
    onError: (error) => options?.onError?.(error),
  });
}
