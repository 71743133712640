import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, FormSelect, Row, Button } from 'react-bootstrap';
import { useSiteStudies, useCreateSiteStudy } from 'hooks/siteStudyHooks';
import { useSearchStudies } from 'hooks/studyHooks';

export default function StudyAdd({ site }) {
  const [selectedStudy, setSelectedStudy] = useState();

  const studies = useSiteStudies(site.id, true);

  const { data, isLoading } = useSearchStudies();
  const createSiteStudy = useCreateSiteStudy({
    onSuccess: () => setSelectedStudy(),
  });

  const handleClick = () => {
    const studyId = Number(selectedStudy);
    createSiteStudy.mutate({
      studyId,
      siteId: site.id,
      organizationId: site.organizationId,
    });
  };

  if (isLoading || studies.isLoading) return null;

  const filteredStudies = data.filter(
    (st) => !studies.data.map((s) => s.id).includes(st.id)
  );

  return (
    <Row>
      <Col xs={6}>
        <FormSelect
          value={selectedStudy}
          onChange={(e) => setSelectedStudy(e.target.value)}
        >
          <option key="option-default" value="">
            Select Study
          </option>
          {filteredStudies.map((d) => (
            <option key={`option-${d.id || 'default'}`} value={d.id}>
              {d.name}
            </option>
          ))}
        </FormSelect>
      </Col>
      <Col xs="1">
        <Button
          variant="primary"
          className="ms-n3"
          disabled={!selectedStudy || createSiteStudy.isLoading}
          onClick={handleClick}
        >
          Add
        </Button>
      </Col>
    </Row>
  );
}

StudyAdd.propTypes = {
  site: PropTypes.shape({
    id: PropTypes.number.isRequired,
    organizationId: PropTypes.number.isRequired,
  }).isRequired,
};
