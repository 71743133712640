import PropTypes from 'prop-types';
import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';

import { SCHEDULE_VIEW } from '../constants';

export default function CalendarToolbar({ label, view, onView, onNavigate }) {
  const handleNavigate = (action) => {
    onNavigate(action);
  };

  const handleView = (selectedView) => {
    onView(selectedView);
  };

  return (
    <div className="d-flex mb-2 justify-content-between align-items-center">
      <ButtonGroup>
        <Button variant="outline-dark" onClick={() => handleNavigate('TODAY')}>
          Today
        </Button>
        <Button variant="outline-dark" onClick={() => handleNavigate('PREV')}>
          Back
        </Button>
        <Button variant="outline-dark" onClick={() => handleNavigate('NEXT')}>
          Next
        </Button>
      </ButtonGroup>
      <div className="display-3">{label}</div>
      <ButtonGroup>
        <Button
          variant={view === SCHEDULE_VIEW.LIST ? 'dark' : 'outline-dark'}
          onClick={() => handleView(SCHEDULE_VIEW.LIST)}
        >
          List View
        </Button>
        <Button
          variant={view === SCHEDULE_VIEW.DAY ? 'dark' : 'outline-dark'}
          onClick={() => handleView(SCHEDULE_VIEW.DAY)}
        >
          Day
        </Button>
        <Button
          variant={view === SCHEDULE_VIEW.WORK_WEEK ? 'dark' : 'outline-dark'}
          onClick={() => handleView(SCHEDULE_VIEW.WORK_WEEK)}
        >
          Work Week
        </Button>
        <Button
          variant={view === SCHEDULE_VIEW.MONTH ? 'dark' : 'outline-dark'}
          onClick={() => handleView(SCHEDULE_VIEW.MONTH)}
        >
          Month
        </Button>
      </ButtonGroup>
    </div>
  );
}

CalendarToolbar.propTypes = {
  label: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
  onView: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
};
