import React, { useMemo, useState, useEffect } from 'react';
import { Row, Col, Dropdown, SplitButton } from 'react-bootstrap';
import { Dash, Asterisk } from 'react-bootstrap-icons';
import TableLayout from 'components/Layouts/TableLayout';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

export default function EncounterMedicationsTable({
  medications,
  showSavedStatus,
  showEditAndDelete,
  onEdit,
  onDelete,
}) {
  const [_medications, setMedications] = useState(medications);

  useEffect(() => {
    setMedications(medications);
  }, [medications]);

  const formatNameDisplay = (item) =>
    item.customName || item.medicationCode?.name;

  const columns = useMemo(() => {
    const config = [
      {
        accessorKey: 'name',
        header: 'Medication',
        cell: (props) => formatNameDisplay(props.row.original) || <Dash />,
      },
      {
        accessorKey: 'medicationFrequency',
        header: 'Frequency',
        cell: (props) => props.getValue()?.name || <Dash />,
      },
      {
        accessorKey: 'dosage',
        header: 'Dosage',
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { className: 'text-center' },
        },
        cell: (props) => props.getValue() || <Dash />,
      },
      {
        accessorKey: 'route',
        header: 'Route',
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { className: 'text-center' },
        },
        cell: (props) => props.getValue() || <Dash />,
      },
      {
        accessorKey: 'startDate',
        header: 'Start Date',
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { className: 'text-center' },
        },
        cell: (props) =>
          (props.getValue() && formatDateDisplay(props.getValue())) || <Dash />,
      },
      {
        accessorKey: 'stopDate',
        header: 'Stop Date',
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { className: 'text-center' },
        },
        cell: (props) =>
          (props.getValue() && formatDateDisplay(props.getValue())) || <Dash />,
      },
      {
        accessorKey: 'reason',
        header: 'Reason',
      },
    ];

    if (showSavedStatus) {
      config.unshift({
        accessorKey: 'dirty',
        header: '',
        meta: {
          headerProps: { className: 'text-center', style: { width: '32px' } },
          cellProps: { className: 'text-center small' },
        },
        cell: (props) => (props.getValue() ? <Asterisk /> : null),
      });
    }

    if (showEditAndDelete) {
      config.push({
        id: 'actions',
        header: '',
        meta: {
          headerProps: {
            style: { width: '80px' },
            className: 'text-center small',
          },
        },
        cell: (props) => (
          <SplitButton
            title="Edit"
            variant="secondary"
            size="sm"
            onClick={() => onEdit(props.row.original)}
          >
            <Dropdown.Item onClick={() => onDelete(props.row.original)}>
              Delete
            </Dropdown.Item>
          </SplitButton>
        ),
      });
    }

    return config;
  }, [_medications]);

  return (
    _medications && (
      <Row className="mt-3">
        <Col>
          <TableLayout columns={columns} data={_medications} />
        </Col>
      </Row>
    )
  );
}
