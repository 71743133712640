import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import SiteAdd from './components/SiteAdd';
import SiteStudyList from './components/SiteStudyList';
import StudyInfo from './components/StudyInfo';
import { StudyContext, StudyProvider } from './components/StudyProvider';
import StudyModal from '../components/StudyModal';

function StudyDetails() {
  const params = useParams();
  const studyId = Number(params.id);

  return (
    <StudyProvider studyId={studyId}>
      <Row className="mb-4 rounded">
        <Col>
          <StudyInfo context={StudyContext} />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <SiteAdd context={StudyContext} />
        </Col>
      </Row>
      <Row className="mb-4 pt-2 bg-white rounded mx-0">
        <Col>
          <SiteStudyList context={StudyContext} />
        </Col>
      </Row>
      <StudyModal context={StudyContext} />
    </StudyProvider>
  );
}

export default StudyDetails;
