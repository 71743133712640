import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';

import { Alert, Button, Form, Stack, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import CheckField from 'components/FormikForm/CheckField';
import PhoneField from 'components/FormikForm/PhoneField';
import TextField from 'components/FormikForm/TextField';

import {
  useCreateOrganization,
  useUpdateOrganization,
} from 'hooks/organizationHooks';

const initialValues = {
  name: '',
  active: false,
  smsPrefix: '',
  smsOriginationNumber: '',
};

const schema = yup.object({
  name: yup.string().required(),
  active: yup.boolean().required(),
  smsPrefix: yup.string().nullable(),
  smsOriginationNumber: yup
    .string()
    .matches(/^[0-9]{10}$/, 'Must be exactly 10 digits')
    .nullable(),
});

function OrganizationModal({ organization = {}, onClose }) {
  const formRef = useRef();
  const navigate = useNavigate();

  const [error, setError] = useState('');

  useEffect(() => {
    setError('');
  }, []);

  const createOrganization = useCreateOrganization({
    onSuccess: (data) => {
      onClose();
      navigate(`/admin/organizations/${data.id}`);
    },
    onError: (e) => setError(e.response?.data?.message),
  });

  const updateOrganization = useUpdateOrganization({
    onSuccess: () => onClose(),
    onError: (e) => setError(e.response?.data?.message),
  });

  const handleSave = () => {
    setError('');

    formRef.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  };

  const handleModalClosed = () => {
    onClose();
  };

  const handleFormSubmit = (values) => {
    const updates = { ...organization, ...values };

    if (organization.id) {
      updateOrganization.mutate(updates);
    } else {
      createOrganization.mutate(updates);
    }
  };

  return (
    <Modal
      show
      backdrop="static"
      keyboard={false}
      centered
      onHide={handleModalClosed}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Organization</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="danger" show={!!error}>
          {error}
        </Alert>
        <Formik
          validationSchema={schema}
          onSubmit={handleFormSubmit}
          initialValues={{
            ...initialValues,
            ...organization,
          }}
        >
          {({ handleSubmit }) => (
            <Form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              ref={formRef}
            >
              <Stack gap={3}>
                <TextField focus label="Name" name="name" />
                <CheckField label="Active" name="active" type="switch" />
                <TextField label="SMS Prefix" name="smsPrefix" />
                <PhoneField
                  type="switch"
                  label="SMS Origination Number"
                  name="smsOriginationNumber"
                />
              </Stack>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClosed}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={
            createOrganization.isLoading || updateOrganization.isLoading
          }
        >
          {organization.id ? 'Update' : 'Create'}
          <Spinner
            animation="border"
            role="status"
            size="sm"
            className={`ms-2 ${
              createOrganization.isLoading || updateOrganization.isLoading
                ? ''
                : 'd-none'
            }`}
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

OrganizationModal.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.number,
    identityProvider: PropTypes.shape({}),
  }),
  onClose: PropTypes.func.isRequired,
};

OrganizationModal.defaultProps = {
  organization: {},
};

export default OrganizationModal;
