import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import DiagnosesList from './DiagnosisList';
import DiagnosisSearchField from './DiagnosisSearchField';
import API from '../../../../../api';

function DiagnosesSection({ onChange, initialValue }) {
  // {
  //  id: {
  //    code: string,
  //    name: string
  //    description: string
  //    isIncluded: boolean
  //  },
  //  id: {
  //    code: string,
  //    name: string,
  //    description: string,
  //    isIncluded: boolean
  //  },
  //  ...
  // }
  const [selectedDiagnoses, setSelectedDiagnoses] = useState({});

  const handleOnChange = (val) => {
    const included = Object.values(val).filter((v) => v.isIncluded);
    const excluded = Object.values(val).filter((v) => !v.isIncluded);
    onChange('diagnoses', {
      is: included,
      isNot: excluded,
    });
  };

  const handleRemoveSelectedDiagnosis = (identifier) => {
    const updatedDiagnoses = selectedDiagnoses;
    delete updatedDiagnoses[identifier];
    setSelectedDiagnoses({ ...updatedDiagnoses });
    handleOnChange(updatedDiagnoses);
  };

  const handleAddSelectedObservation = async (newDiagnosesCodes) => {
    const apiCalls = newDiagnosesCodes.map((diagnosis) =>
      API.getDiagnosisConceptRelatedCodes(diagnosis)
    );
    const responses = await Promise.all(apiCalls);

    const newSelectedDiagnoses = {
      ...selectedDiagnoses,
    };

    responses.forEach((response) => {
      (response ?? []).forEach((code) => {
        const key = code.code + code.codeset;
        newSelectedDiagnoses[key] = {
          code: code.code,
          codeset: code.codeset,
          name: code.name,
          isIncluded: true,
        };
      });
    });

    setSelectedDiagnoses(newSelectedDiagnoses);
    handleOnChange(newSelectedDiagnoses);
  };

  const handleValueChange = (identifier, inclusionValue) => {
    const updatedDiagnoses = selectedDiagnoses;
    updatedDiagnoses[identifier].isIncluded = inclusionValue;
    setSelectedDiagnoses({ ...updatedDiagnoses });
    handleOnChange(updatedDiagnoses);
  };

  useEffect(() => {
    const updatedDiagnoses = {};

    Object.values(initialValue).forEach((inclusionGroup) =>
      inclusionGroup.forEach((val) => {
        updatedDiagnoses[val.code + val.codeset] = {
          code: val.code,
          codeset: val.codeset,
          name: val.name,
          isIncluded: val.isIncluded,
        };
      })
    );

    setSelectedDiagnoses({ ...updatedDiagnoses });
  }, [initialValue]);

  return (
    <>
      <Row>
        <Col xs={{ offset: 1, span: 4 }}>
          <DiagnosisSearchField onSelect={handleAddSelectedObservation} />
        </Col>
      </Row>

      <Row>
        <Col className="mt-2 ms-2" xs={6}>
          <DiagnosesList
            diagnoses={selectedDiagnoses}
            onChange={handleValueChange}
            onRemoveObservation={handleRemoveSelectedDiagnosis}
          />
        </Col>
      </Row>
    </>
  );
}

DiagnosesSection.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.shape({}).isRequired,
};

export default DiagnosesSection;
