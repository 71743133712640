import PropTypes from 'prop-types';
import React, { useRef, useState, useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import ChartReviewForm from './ChartReviewForm';
import { useCreatePatientChartReview } from 'hooks/patientChartReviewHooks';
import { useSites } from 'hooks/siteHooks';

export default function ChartReviewPane({ patient, isReadOnly }) {
  const [error, setError] = useState();
  const formRef = useRef(null);

  const sites = useSites();

  const createChartReview = useCreatePatientChartReview({
    onError: (e) => setError(e.response?.data?.message),
  });

  const activePatientSites = useMemo(() => {
    if (sites.data?.length) {
      return patient.sites.filter(
        (ps) =>
          typeof sites.data.find((site) => site.id === ps.id) !== 'undefined'
      );
    }

    return [];
  }, [sites.data, patient.sites]);

  if (sites.isLoading) return null;

  const handleClick = () => {
    formRef.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    setError(null);

    const data = {
      patientId: patient.id,
      studyId: values.studyId,
      studyStatusId: values.studyStatusId,
      reason: values.reason,
      referralSourceId: values.referralSourceId,
    };

    createChartReview.mutate(data, {
      onSuccess: () => resetForm(),
    });
  };

  return (
    <div
      className="mt-3"
      style={{ height: 'calc(100vh - 150px)', overflow: 'hidden auto' }}
    >
      <Alert variant="danger" show={!!error}>
        {error}
      </Alert>
      <ChartReviewForm
        ref={formRef}
        handleFormSubmit={handleFormSubmit}
        handleSubmitClick={handleClick}
        disabled={isReadOnly || createChartReview.isLoading}
        isBusy={createChartReview.isLoading}
        sites={activePatientSites}
      />
    </div>
  );
}

ChartReviewPane.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sites: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};
