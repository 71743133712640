import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { CalendarPlus } from 'react-bootstrap-icons';

export default function ScheduleAppointmentButton({ onClick }) {
  return (
    <Button variant="primary" onClick={onClick}>
      <CalendarPlus className="align-middle" />
      <span className="ms-2 align-middle text-white">Create Appointment</span>
    </Button>
  );
}

ScheduleAppointmentButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
