import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import PatientSearch from './components/PatientSearch';
import PatientsTable from './components/PatientsTable';
import SearchProvider from './components/SearchProvider';
import NavigateBack from 'components/Button/NavigateBack';
import { UserContext } from 'components/Provider/UserProvider';
import paths from 'entities/SitePaths';

export default function Search() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const query = location.state?.query ?? '';

  const handleNavigate = () => {
    navigate(paths.DASHBOARD);
  };

  return (
    <SearchProvider user={user} initialQuery={query}>
      <Row id="search" className="bg-light-gray h-100 overflow-scroll">
        <Col>
          <Row className="mt-3 align-items-center">
            <Col className="px-0" xs={{ span: 2 }}>
              <NavigateBack onClick={handleNavigate} text="DASHBOARD" />
            </Col>
            <Col className="px-0" xs={{ span: 3, offset: 7 }}>
              <PatientSearch />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <h5 className="display-3 text-info">SEARCH RESULTS</h5>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <PatientsTable />
            </Col>
          </Row>
        </Col>
      </Row>
    </SearchProvider>
  );
}
