import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Plus } from 'react-bootstrap-icons';

import NoteList from './NoteList';
import NoteModal from './NoteModal';
import { NoteProvider, NoteContext } from './NoteProvider';
import { UserContext, Roles } from 'components/Provider/UserProvider';

export default function NotesSection({ patientId }) {
  const { userRole } = useContext(UserContext);

  return (
    <NoteProvider patientId={patientId}>
      <NoteContext.Consumer>
        {({ addNote }) => (
          <>
            <Row className="align-items-center">
              <Col className="d-flex align-items-center">
                <div className="display-3">Notes</div>
              </Col>
              <Col sm={2}>
                {Roles.canModifyRecords(userRole) && (
                  <Button
                    variant="outline-primary"
                    onClick={addNote}
                    size="sm"
                    className="w-100"
                  >
                    <Plus size={18} /> Add Note
                  </Button>
                )}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <NoteList patientId={patientId} />
              </Col>
            </Row>

            <NoteModal />
          </>
        )}
      </NoteContext.Consumer>
    </NoteProvider>
  );
}

NotesSection.propTypes = {
  patientId: PropTypes.number.isRequired,
};
