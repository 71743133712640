import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

function ConfirmationDialog({
  size,
  show,
  title,
  bodyText,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
}) {
  if (!show) {
    return null;
  }

  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      size={size}
      centered
      onHide={onCancel}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{bodyText}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          {cancelText}
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ConfirmationDialog.defaultProps = {
  size: 'md',
  confirmText: 'Confirm',
  cancelText: 'Cancel',
};

ConfirmationDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  bodyText: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

export default ConfirmationDialog;
