import React from 'react';
import { Nav } from 'react-bootstrap';
import { InfoIconWithPopover } from 'components/InfoIconWithPopover';
import StatusGroups from 'entities/StatusGroups';

export default function getStatusGroupTabs(groupName, onClick) {
  switch (groupName) {
    case StatusGroups.PROSPECTS:
      return (
        <>
          <Nav.Item>
            <Nav.Link
              eventKey="Recommended"
              onClick={() => onClick('Recommended')}
            >
              Recommended
              <InfoIconWithPopover
                className="ms-1 mb-2"
                placement="bottom"
                body="System-assigned top-priority patients identified during study matching"
              />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Prospects" onClick={() => onClick('Prospects')}>
              Prospects
              <InfoIconWithPopover
                className="ms-1 mb-2"
                placement="bottom"
                body="Patients identified through outside referral, feasibility search or other means, and manually assigned to this status"
              />
            </Nav.Link>
          </Nav.Item>
        </>
      );
    case StatusGroups.PRESCREEN:
      return (
        <>
          <Nav.Item>
            <Nav.Link
              eventKey="Prequalified"
              onClick={() => onClick('Prequalified')}
            >
              Prequalified
              <InfoIconWithPopover
                className="ms-1 mb-2"
                placement="bottom"
                body="Patients identified as ready to prescreen and may require action if they cancel or miss a prescreen visit"
              />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="Prescreen Completed"
              onClick={() => onClick('Prescreen Completed')}
            >
              Prescreen Completed
              <InfoIconWithPopover
                className="ms-1 mb-2"
                placement="bottom"
                body="Patients with a completed prescreen visit, ready for chart review"
              />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="Prescreen Scheduled"
              onClick={() => onClick('Prescreen Scheduled')}
            >
              Prescreen Scheduled
              <InfoIconWithPopover
                className="ms-1 mb-2"
                placement="bottom"
                body="Patients with a prescreen appointment scheduled"
              />
            </Nav.Link>
          </Nav.Item>
        </>
      );
    case StatusGroups.SCREENING:
      return (
        <>
          <Nav.Item>
            <Nav.Link eventKey="Eligible" onClick={() => onClick('Eligible')}>
              Eligible
              <InfoIconWithPopover
                className="ms-1 mb-2"
                placement="bottom"
                body="Patients marked as ‘Eligible’ for the selected study in chart review and need a screening visit scheduled "
              />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="Screen Pending"
              onClick={() => onClick('Screen Pending')}
            >
              Screen Pending
              <InfoIconWithPopover
                className="ms-1 mb-2"
                placement="bottom"
                body="Patients who are waiting to screen for the selected study and should be monitored"
              />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Screening" onClick={() => onClick('Screening')}>
              Screening
              <InfoIconWithPopover
                className="ms-1 mb-2"
                placement="bottom"
                body="Patients scheduled for or currently undergoing screening for the selected study"
              />
            </Nav.Link>
          </Nav.Item>
        </>
      );
    case StatusGroups.ENROLLED:
      return (
        <>
          <Nav.Item>
            <Nav.Link eventKey="Enrolled" onClick={() => onClick('Enrolled')}>
              Enrolled
              <InfoIconWithPopover
                className="ms-1 mb-2"
                placement="bottom"
                body="Patients enrolled in the selected study "
              />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Completed" onClick={() => onClick('Completed')}>
              Completed
              <InfoIconWithPopover
                className="ms-1 mb-2"
                placement="bottom"
                body="Completed patients for the selected study"
              />
            </Nav.Link>
          </Nav.Item>
        </>
      );
    case StatusGroups.NOT_ELIGIBLE:
      return (
        <>
          <Nav.Item>
            <Nav.Link
              eventKey="Not Eligible"
              onClick={() => onClick('Not Eligible')}
            >
              Not Eligible
              <InfoIconWithPopover
                className="ms-1 mb-2"
                placement="bottom"
                body="Patients marked as ‘Not Eligible’ for the selected study in chart review"
              />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="Not Interested"
              onClick={() => onClick('Not Interested')}
            >
              Not Interested
              <InfoIconWithPopover
                className="ms-1 mb-2"
                placement="bottom"
                body="Patients marked as ‘Not Interested’ for the selected study "
              />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="Screen Fail"
              onClick={() => onClick('Screen Fail')}
            >
              Screen Fail
              <InfoIconWithPopover
                className="ms-1 mb-2"
                placement="bottom"
                body="Patients marked as screen fail manually or via CTMS integration "
              />
            </Nav.Link>
          </Nav.Item>
        </>
      );
    default:
      return null;
  }
}
