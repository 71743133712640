import { Formik } from 'formik';
import React, { useRef, useState, useEffect, useContext } from 'react';
import { Alert, Row, Col, Button, Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import EncounterMedications from './components/EncounterMedications';
import PatientMedicationsView from './components/PatientMedicationsView';
import ConfirmationDialog from 'components/ConfirmationDialog';
import LoadingIndicator from 'components/LoadingIndicator';
import { UserContext, Roles } from 'components/Provider/UserProvider';
import usePrescreenVisit from 'hooks/prescreenVisitHooks';
import { formatDateTimeDisplay } from 'pages/utils/dateTimeUtils';

function EncounterDetails() {
  const navigate = useNavigate();
  const params = useParams();

  const { encounterId } = params;
  const patientId = Number(params.patientId);

  const { userRole } = useContext(UserContext);
  const [medicationsDraft, setMedicationsDraft] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const formRef = useRef();

  const {
    isLoading,
    encounter,
    encounterMedications,
    medications,
    initialFormValues,
    formSchema,
    formComponent: FormComponent,
    error,
    onSave,
    isCompleted,
    isBusy,
  } = usePrescreenVisit(patientId, encounterId);

  useEffect(() => {
    if (encounterMedications) {
      setMedicationsDraft(encounterMedications);
    }
  }, [encounterMedications]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (!encounter && error) {
    return (
      <Row className="mt-3 row-cols-xl-12">
        <Col className="d-flex justify-content-center">
          <Alert variant="danger">{error}</Alert>
        </Col>
      </Row>
    );
  }

  const navigateToPatient = () => {
    navigate(`/patients/${patientId}`);
  };

  const handleCopyMedication = (medication) => {
    const updatedMedications = [...medicationsDraft];

    updatedMedications.push({
      ...medication,
      id: 0,
      dirty: true,
      index: updatedMedications.length,
      patientEncounterId: encounter.id,
    });

    setMedicationsDraft(updatedMedications);
  };

  const handleEncounterMedicationsChange = (updatedEncounterMedications) => {
    setMedicationsDraft(updatedEncounterMedications);
  };

  const handleFormSubmit = async () => {
    const { measurement, procedure_result, result, siteId } =
      formRef.current.values;
    const values = {
      observations: { measurement, procedure_result, result },
      medications: medicationsDraft,
      siteId,
    };
    await onSave(values, true, navigateToPatient);
  };

  const handleSaveButton = async () => {
    const { measurement, procedure_result, result, siteId } =
      formRef.current.values;
    const values = {
      observations: { measurement, procedure_result, result },
      medications: medicationsDraft,
      siteId,
    };
    await onSave(values, false, navigateToPatient);
  };

  const confirmComplete = () => {
    setShowConfirm(true);
  };

  const completeConfirmed = async () => {
    setShowConfirm(false);
    await formRef.current.submitForm();
  };

  return (
    <Container fluid id="prescreen-visit" className="bg-white mt-3">
      <Row>
        <Col>
          <Button
            className="text-decoration-none text-uppercase ps-0"
            variant="link"
            onClick={navigateToPatient}
          >
            &lt; Back to Patient
          </Button>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm="6">
          <h4>{encounter.type?.name}</h4>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm="6">
          <h5 className="text-info d-inline">{encounter.patient.fullName}</h5>
          <span className="text-muted smaller ms-3">
            {formatDateTimeDisplay(
              encounter.completedDate || encounter.startDate
            )}
          </span>
        </Col>
      </Row>
      {error && (
        <Alert variant="danger" className="mt-2 mb-0">
          {error}
        </Alert>
      )}
      <Row className="mt-4">
        <Col sm="6">
          <Formik
            innerRef={formRef}
            validationSchema={formSchema}
            enableReinitialize
            initialValues={initialFormValues}
            onSubmit={handleFormSubmit}
            validateOnChange={false}
            component={(props) => (
              <FormComponent
                {...props}
                ref={formRef}
                allowChanges={!isCompleted && Roles.canModifyRecords(userRole)}
              />
            )}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <EncounterMedications
            encounterId={encounter.id}
            patientId={patientId}
            medications={medicationsDraft}
            onMedicationsChange={handleEncounterMedicationsChange}
            allowChanges={!isCompleted && Roles.canModifyRecords(userRole)}
            isBusy={isBusy}
          />
        </Col>
      </Row>
      {!isCompleted && (
        <>
          <hr />
          <PatientMedicationsView
            medications={medications}
            encounterId={encounter.id}
            onAdd={handleCopyMedication}
            allowChanges={!isCompleted && Roles.canModifyRecords(userRole)}
          />
          <Row className="my-3 pt-3 pb-5 bg-light-gray border-top border-1">
            <Col sm={6}>
              <Button
                variant="primary"
                className="btn-block m-1 float-sm-end w-auto"
                style={{ minWidth: '180px' }}
                onClick={handleSaveButton}
                disabled={!Roles.canModifyRecords(userRole) || isBusy}
              >
                Save
              </Button>
            </Col>
            <Col sm={6}>
              <Button
                variant="success"
                className="btn-block m-1 w-auto"
                style={{ minWidth: '180px' }}
                onClick={confirmComplete}
                disabled={!Roles.canModifyRecords(userRole) || isBusy}
              >
                Complete Encounter
              </Button>
              <ConfirmationDialog
                show={showConfirm}
                title="Complete Visit"
                bodyText="Are you sure you want to save and complete this encounter? Completed encounters cannot be modified at a later date."
                confirmText="Complete"
                onConfirm={completeConfirmed}
                onCancel={() => setShowConfirm(false)}
              />
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

export default EncounterDetails;
