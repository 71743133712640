import PropTypes from 'prop-types';
import React from 'react';
import SiteReasonsSection from './SiteReasonsSection';

import { useDocumentTypes } from 'hooks/documentTypeHooks';
import {
  useSiteDocumentTypes,
  useCreateSiteDocumentType,
  useDeleteSiteDocumentType,
} from 'hooks/siteDocumentTypeHooks';

function DoucmentTypesSection({ siteId }) {
  return (
    <SiteReasonsSection
      siteId={siteId}
      reasonType="Document Type"
      useSiteReasons={useSiteDocumentTypes}
      useOrgReasons={useDocumentTypes}
      useCreateSiteReason={useCreateSiteDocumentType}
      useDeleteSiteReason={useDeleteSiteDocumentType}
    />
  );
}

DoucmentTypesSection.propTypes = {
  siteId: PropTypes.number.isRequired,
};

export default DoucmentTypesSection;
