import React, { useContext } from 'react';
import { Nav, Row, Col } from 'react-bootstrap';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import AdminMenu from '../components/AdminMenu';
import { UserContext, Roles } from 'components/Provider/UserProvider';

function AdminLayout() {
  const { userRole } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const currentAdminMenuItem = `/admin/${location.pathname.split('/')[2]}`;

  if (![Roles.ADMINISTRATOR].includes(userRole)) {
    return (
      <div className="m-3 alert alert-primary">
        <h5 className="text-center text-primary">
          Administrator role is required
        </h5>
      </div>
    );
  }

  return (
    <Row id="container">
      <Col id="sidebar" md={2} className="mt-3">
        <div>
          <p className="display-3">Admin</p>
        </div>
        <hr />
        <Nav
          className="flex-column mb-auto"
          activeKey={currentAdminMenuItem}
          variant="pills"
        >
          {Object.entries(AdminMenu).map(([key, item]) => (
            <Nav.Item key={key}>
              <Nav.Link
                eventKey={item.pathname}
                onClick={() => navigate(item.pathname)}
              >
                {item.title}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </Col>
      <Col id="content" className="mt-3">
        <Outlet />
      </Col>
    </Row>
  );
}

export default AdminLayout;
