import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import LoadingIndicator from 'components/LoadingIndicator';
import './SchedulePane.scss';

export default function AppointmentSlots({
  value,
  data,
  onChange,
  isLoading = false,
}) {
  const getButtonVariant = (item) => {
    if (DateTime.fromJSDate(item.date).equals(DateTime.fromJSDate(value))) {
      return item.conflict ? 'warning' : 'secondary';
    }

    return item.conflict ? 'outline-warning' : 'outline-secondary';
  };

  if (isLoading) return <LoadingIndicator />;

  return (
    <div className="d-flex flex-wrap">
      {data.map((item) => {
        const variant = getButtonVariant(item);

        return (
          <Button
            variant={variant}
            key={item.date}
            className="flex-fill m-1"
            style={{ width: '45%' }}
            onClick={() => {
              onChange(item.date);
            }}
          >
            {DateTime.fromJSDate(item.date).toFormat('h:mma')}
          </Button>
        );
      })}
    </div>
  );
}

AppointmentSlots.defaultProps = {
  isLoading: false,
  value: undefined,
};

AppointmentSlots.propTypes = {
  value: PropTypes.instanceOf(Date),
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
