import { Formik } from 'formik';
import React, { useContext, useRef } from 'react';
import { Alert, Button, Form, Row, Col, Modal, Spinner } from 'react-bootstrap';
import * as yup from 'yup';
import { NoteContext } from './NoteProvider';

const MAX_CHARACTERS = 1000;

const schema = yup.object({
  text: yup.string().required().max(MAX_CHARACTERS),
});

export default function NoteModal() {
  const formRef = useRef();
  const { error, note, showModal, closeModal, saveNote, isBusy } =
    useContext(NoteContext);

  const handleCloseModal = () => {
    closeModal();
  };

  const handleFormSubmit = (values) => {
    saveNote({ ...values });
  };

  const handleSave = () => {
    formRef.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  };

  return (
    <Modal
      show={showModal}
      backdrop="static"
      keyboard={false}
      centered
      onHide={handleCloseModal}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Note</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="danger" show={!!error}>
          {error}
        </Alert>

        <Formik
          validationSchema={schema}
          onSubmit={handleFormSubmit}
          initialValues={note}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit} ref={formRef}>
              <Row>
                <Col>
                  <Form.Group controlId="formText" className="mb-1">
                    <Form.Control
                      autoFocus
                      as="textarea"
                      rows="5"
                      name="text"
                      placeholder="Enter Note Text"
                      value={values.text}
                      onChange={handleChange}
                      isValid={touched.text && !errors.text}
                      isInvalid={!!errors.text}
                    />
                  </Form.Group>
                  <div className="text-end">{`${values.text.length}/${MAX_CHARACTERS} characters`}</div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={isBusy}>
          Save
          <Spinner
            animation="border"
            role="status"
            size="sm"
            className={`ms-2 ${isBusy ? '' : 'd-none'}`}
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
