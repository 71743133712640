import PropTypes from 'prop-types';
import React from 'react';
import {
  OverlayTrigger,
  Popover,
  PopoverHeader,
  PopoverBody,
  ListGroup,
  ListGroupItem,
} from 'react-bootstrap';
import { Dash } from 'react-bootstrap-icons';

/** @type {React.ForwardRefExoticComponent<any, any>} */
const PatientStudyTableDisplay = React.forwardRef(
  // eslint-disable-next-line no-unused-vars
  ({ cellDisplay, patientStudies }, ref) => {
    if (!cellDisplay) {
      return <Dash />;
    }

    return (
      <OverlayTrigger
        overlay={
          <Popover>
            <PopoverHeader>Studies</PopoverHeader>
            <PopoverBody className="p-1">
              <ListGroup variant="flush">
                {patientStudies.map((ps) => (
                  <ListGroupItem key={`patient-study-${ps.id}`}>
                    {[ps.studyStatus?.name, ps.study.name]
                      .filter(Boolean)
                      .join(' - ')}
                  </ListGroupItem>
                ))}
              </ListGroup>
            </PopoverBody>
          </Popover>
        }
      >
        <span style={{ cursor: 'pointer' }} className="user-select-none">
          {cellDisplay}
        </span>
      </OverlayTrigger>
    );
  }
);

export default PatientStudyTableDisplay;

PatientStudyTableDisplay.defaultProps = {
  patientStudies: [],
  cellDisplay: '',
};

PatientStudyTableDisplay.propTypes = {
  patientStudies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      study: PropTypes.shape({ name: PropTypes.string }),
      studyStatus: PropTypes.shape({ name: PropTypes.string }),
    })
  ),
  cellDisplay: PropTypes.string,
};
