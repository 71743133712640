import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Collapse, Stack } from 'react-bootstrap';
import { Dash, Plus } from 'react-bootstrap-icons';
import AddButton from 'components/Button/AddButton';

function CollapsibleSection({
  title,
  onAddButtonClick,
  isAddButtonDisabled = false,
  collapsibleChildren,
}) {
  const [open, setOpen] = useState(true);

  return (
    <Stack gap={2} className="w-75">
      <Stack direction="horizontal" className="justify-content-between">
        <Stack direction="horizontal">
          <h4>{title}</h4>
          <Button
            variant=""
            className="p-0"
            onClick={() => setOpen(() => !open)}
          >
            {open ? <Dash size={24} /> : <Plus size={24} />}
          </Button>
        </Stack>
        <div>
          <AddButton
            onClick={onAddButtonClick}
            isDisabled={isAddButtonDisabled}
            label={`Add ${title}`}
          />
        </div>
      </Stack>
      <Collapse in={open}>
        <div>{collapsibleChildren}</div>
      </Collapse>
    </Stack>
  );
}

CollapsibleSection.defaultProps = {
  isAddButtonDisabled: false,
};

CollapsibleSection.propTypes = {
  title: PropTypes.string.isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
  isAddButtonDisabled: PropTypes.bool,
  collapsibleChildren: PropTypes.node.isRequired,
};

export default CollapsibleSection;
