import React from 'react';

import ReasonSection from 'components/Reasons/ReasonSection';
import {
  useCreateReferralSource,
  useReferralSources,
  useUpdateReferralSource,
} from 'hooks/referralSourceHooks';

function ReferralSourcesSection() {
  return (
    <ReasonSection
      useOrgReasons={useReferralSources}
      useCreateReason={useCreateReferralSource}
      useUpdateReason={useUpdateReferralSource}
      reasonType="Referral Source"
    />
  );
}

export default ReferralSourcesSection;
