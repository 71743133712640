import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

export function useActiveStudyMatchingConfigurations() {
  return useQuery(['activeStudyMatchingConfigurations'], async () => {
    const { data } = await axios.get(
      '/api/admin/study-matching-configurations/active'
    );
    return data;
  });
}

export function useStudyMatchingConfigurations(
  pageIndex = null,
  pageSize = null
) {
  return useQuery(
    ['studyMatchingConfigurations', pageIndex, pageSize],
    async () => {
      const params = { pageIndex, pageSize };
      const { data } = await axios.get('/api/study-matching-configurations', {
        params,
      });
      return data;
    }
  );
}

export function useStudyMatchingConfiguration(id) {
  return useQuery(
    ['studyMatchingConfiguration', id],
    async () => {
      const { data } = await axios.get(
        `/api/admin/study-matching-configurations/${id}`
      );
      return data;
    },
    { enabled: !!id }
  );
}

const createStudyMatchingConfiguration = async (studyMatchingConfiguration) => {
  const result = await axios.post(
    '/api/admin/study-matching-configurations',
    studyMatchingConfiguration
  );
  return result.data;
};

export function useCreateStudyMatchingConfiguration(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createStudyMatchingConfiguration,
    onSuccess: (studyMatchingConfiguration) => {
      queryClient.invalidateQueries(['activeStudyMatchingConfigurations']);
      queryClient.invalidateQueries(['studyMatchingConfigurations']);
      queryClient.invalidateQueries([
        'study',
        studyMatchingConfiguration.studyId,
      ]);

      options?.onSuccess?.(studyMatchingConfiguration);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const updateStudyMatchingConfiguration = async (studyMatchingConfiguration) => {
  const result = await axios.put(
    `/api/admin/study-matching-configurations/${studyMatchingConfiguration.id}`,
    studyMatchingConfiguration
  );
  return result.data;
};

export function useUpdateStudyMatchingConfiguration(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateStudyMatchingConfiguration,
    onSuccess: (studyMatchingConfiguration) => {
      queryClient.invalidateQueries(['activeStudyMatchingConfigurations']);
      queryClient.invalidateQueries(['studyMatchingConfigurations']);
      queryClient.invalidateQueries([
        'studyMatchingConfiguration',
        studyMatchingConfiguration.id,
      ]);
      queryClient.invalidateQueries([
        'study',
        studyMatchingConfiguration.studyId,
      ]);

      options?.onSuccess?.(studyMatchingConfiguration);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const deleteStudyMatchingConfiguration = async (studyMatchingConfiguration) => {
  const result = await axios.delete(
    `/api/study-matching-configurations/${studyMatchingConfiguration.id}`
  );
  return result.data;
};

export function useDeleteStudyMatchingConfiguration(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteStudyMatchingConfiguration,
    onSuccess: (studyMatchingConfiguration) => {
      queryClient.invalidateQueries(['activeStudyMatchingConfigurations']);
      queryClient.invalidateQueries(['studyMatchingConfigurations']);
      queryClient.invalidateQueries([
        'studyMatchingConfiguration',
        studyMatchingConfiguration.id,
      ]);

      options?.onSuccess?.(studyMatchingConfiguration);
    },
    onError: (error) => options?.onError?.(error),
  });
}
