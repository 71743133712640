import PropTypes from 'prop-types';
import React from 'react';
import RangeSearchField from '../BaseFields/RangeSearchField';
import IndicationValueTypes from 'entities/IndicationValueTypes';

function IndicationValueSearchField({ onChange, value }) {
  return (
    <RangeSearchField
      label={IndicationValueTypes.RISK_SCORE}
      keySuffix="Indication Value"
      onChange={onChange}
      value={value}
    />
  );
}

IndicationValueSearchField.defaultProps = {
  value: {},
};

IndicationValueSearchField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

export default IndicationValueSearchField;
