import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Row, Col, Button, Alert, Modal, Form } from 'react-bootstrap';
import * as yup from 'yup';
import SelectField from 'components/FormikForm/SelectField';
import { useCancellationReasons } from 'hooks/cancellationReasonHooks';
import { useCancelPatientAppointment } from 'hooks/patientAppointmentHooks';
import { useDeletePatientEncounter } from 'hooks/patientEncounterHooks';

const schema = yup.object().shape({
  cancellationReasonId: yup.number().integer().required(),
});

export default function AppointmentCancellationModal({ onClose, appointment }) {
  const formRef = useRef(null);
  const [appError, setAppError] = useState();

  const cancellationReasons = useCancellationReasons();

  const cancelPatientAppointment = useCancelPatientAppointment({
    onSuccess: onClose,
    onError: (error) => setAppError(error.message),
  });

  const deletePatientEncounter = useDeletePatientEncounter({
    onSuccess: onClose,
    onError: (error) => setAppError(error.message),
  });

  const isLoading =
    cancelPatientAppointment.isLoading ||
    deletePatientEncounter.isLoading ||
    cancellationReasons.isLoading;

  const handleCancel = () => {
    setAppError(null);
    formRef.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  };

  const handleFormSubmit = (values) => {
    if (isLoading) {
      return;
    }

    if (appointment.encounter) {
      deletePatientEncounter.mutate({
        patientId: appointment.patientId,
        encounterId: appointment.encounter.id,
        cancellationReasonId: values.cancellationReasonId,
      });
    } else {
      cancelPatientAppointment.mutate({
        patientId: appointment.patientId,
        appointmentId: appointment.id,
        cancellationReasonId: values.cancellationReasonId,
      });
    }
  };

  if (cancellationReasons.isLoading) {
    return null;
  }

  return (
    <Modal
      show
      backdrop="static"
      keyboard={false}
      centered
      onHide={onClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Cancel Appointment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Alert variant="danger" show={!!appError}>
              {appError}
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col>
            <Formik
              validationSchema={schema}
              initialValues={{ cancellationReasonId: '' }}
              onSubmit={handleFormSubmit}
            >
              {({ handleSubmit }) => (
                <Form
                  autoComplete="off"
                  onSubmit={handleSubmit}
                  onError={setAppError}
                  ref={formRef}
                >
                  <SelectField
                    label="Cancellation Reason"
                    name="cancellationReasonId"
                    defaultDisplay="Select Appointment Cancellation Reason"
                    options={cancellationReasons.data}
                    valueAccessor="id"
                    displayAccessor="name"
                  />
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button
            variant="secondary"
            className="me-1"
            onClick={onClose}
            disabled={isLoading}
          >
            Close
          </Button>
          <Button
            variant="danger"
            className="ms-1"
            onClick={handleCancel}
            disabled={isLoading}
          >
            Cancel Appointment
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

AppointmentCancellationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  appointment: PropTypes.shape({
    id: PropTypes.string,
    patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    encounter: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};
