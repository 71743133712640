import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

const createSiteIndication = async (siteIndication) => {
  const result = await axios.post(
    `/api/sites/${siteIndication.siteId}/indications`,
    siteIndication
  );
  return result.data;
};

export function useCreateSiteIndication(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createSiteIndication,
    onSuccess: (siteIndication) => {
      queryClient.invalidateQueries(['siteMetrics']);
      queryClient.invalidateQueries(['site-indications']);
      queryClient.invalidateQueries(['indicationMetrics']);
      queryClient.invalidateQueries(['sites']);
      queryClient.invalidateQueries(['site', siteIndication.siteId]);
      queryClient.invalidateQueries(['indications']);
      queryClient.invalidateQueries([
        'indication',
        siteIndication.indicationId,
      ]);
      queryClient.invalidateQueries([
        'site-indications',
        siteIndication.siteId,
      ]);

      options?.onSuccess?.(siteIndication);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const deleteSiteIndication = async (siteIndication) => {
  const result = await axios.delete(
    `/api/sites/${siteIndication.siteId}/indications/${siteIndication.indicationId}`
  );

  return result.data;
};

export function useDeleteSiteIndication(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteSiteIndication,
    onSuccess: (siteIndication) => {
      queryClient.invalidateQueries(['siteMetrics']);
      queryClient.invalidateQueries(['user-indication-options']);
      queryClient.invalidateQueries(['indicationMetrics']);
      queryClient.invalidateQueries(['sites']);
      queryClient.invalidateQueries(['site', siteIndication.siteId]);
      queryClient.invalidateQueries([
        'site-indications',
        siteIndication.siteId,
      ]);
      queryClient.invalidateQueries(['indications']);
      queryClient.invalidateQueries([
        'indication',
        siteIndication.indicationId,
      ]);

      options?.onSuccess?.(siteIndication);
    },
    onError: (error) => options?.onError?.(error),
  });
}

export function useSiteIndications(siteId) {
  return useQuery(['site-indications', siteId], () =>
    axios.get(`/api/sites/${siteId}/indications`).then((res) => res.data)
  );
}

export function useUserIndicationOptions() {
  return useQuery(['user-indication-options'], () =>
    axios.get('/api/user-indication-options').then((res) => res.data)
  );
}
