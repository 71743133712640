import PropTypes from 'prop-types';
import React from 'react';
import Field from '../BaseFields/Field';
import SiteMultiSelect from 'components/SiteMultiSelect';

function SiteMultiSelectField({ onChange, value }) {
  const handleOnChange = (selections) => {
    const selectedSiteIds = selections.map((s) => s.value);
    onChange('siteIds', selectedSiteIds);
  };

  return (
    <Field label="Sites">
      <SiteMultiSelect
        selectedSites={value}
        onChange={handleOnChange}
        placeholderText="All available sites"
      />
    </Field>
  );
}

SiteMultiSelectField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default SiteMultiSelectField;
