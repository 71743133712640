import { useQuery } from '@tanstack/react-query';
import axios from '../axios';

// eslint-disable-next-line import/prefer-default-export
export function useSiteAppointments(filters = {}, enabled = false) {
  return useQuery(
    ['patientsAppointments', filters],
    () =>
      axios
        .get(`/api/sites/${filters.siteId}/appointments`, {
          params: { ...filters },
        })
        .then((res) => res.data),
    { enabled }
  );
}

export function usePagedSiteAppointments(
  filters = {},
  pagination = { pageIndex: 0, pageSize: 25 },
  sorting = [{ id: null, desc: null }]
) {
  return useQuery(
    ['patientsAppointments', filters, pagination, sorting],
    () =>
      axios
        .get(`/api/patients/appointments`, {
          params: {
            ...filters,
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize,
            sortField: sorting?.[0]?.id,
            sortDirection: sorting?.[0]?.desc ? 'DESC' : 'ASC',
          },
        })
        .then((res) => res.data),
    {
      enabled: (() => {
        const { siteId, appointmentTypeId, startDate, endDate } = filters;
        return [siteId, appointmentTypeId, startDate, endDate].every(Boolean);
      })(),
    }
  );
}
