import { useQuery } from '@tanstack/react-query';
import axios from '../axios';

export function usePatientStudyHistory(patientId, sort) {
  return useQuery(patientId && ['patientStudyHistory', patientId], () =>
    axios
      .get(`/api/patients/${patientId}/study-history`, {
        params: { sort },
      })
      .then((res) => res.data)
  );
}

export default {
  usePatientStudyHistory,
};
