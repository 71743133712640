import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState, useCallback } from 'react';

import { Row, Col, Table, Button, Card } from 'react-bootstrap';
import { ShieldLock, Dash, Plus } from 'react-bootstrap-icons';

import ObservationButton from './ObservationButton';
import ObservationModal, { DEFAULT_OBSSERVATION } from './ObservationModal';
import {
  ACTION_BUTTON_TYPES,
  ActionButton,
} from 'components/Button/ActionButton';
import ConfirmationDialog from 'components/ConfirmationDialog';
import LoadingIndicator from 'components/LoadingIndicator';
import { UserContext, Roles } from 'components/Provider/UserProvider';
import ReadonlyIcon from 'components/ReadonlyIcon';
import ROLES from 'entities/Roles';
import {
  usePatientObservations,
  useDeletePatientObservation,
} from 'hooks/patientObservationHooks';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

const LIST_LENGTH = 10;
export const OBSERVATION_TYPES = {
  RESULT: 'Result',
  PROCEDURE_RESULT: 'Procedure Result',
  MEASUREMENT: 'Measurement',
};

const SECTION_TITLES = {
  [OBSERVATION_TYPES.RESULT]: 'Results',
  [OBSERVATION_TYPES.PROCEDURE_RESULT]: 'Procedure Results',
  [OBSERVATION_TYPES.MEASUREMENT]: 'Measurements',
};

export default function ObservationList({ type, patientId }) {
  const { data: observations, isLoading } = usePatientObservations({
    type,
    patientId,
  });
  const { userRole } = useContext(UserContext);
  const [selectedItemId, setSelectedItemId] = useState(0);
  const [showSection, setShowSection] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedObservation, setSelectedObservation] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [showLength, setShowLength] = useState(LIST_LENGTH);

  const deleteObservation = useDeletePatientObservation({
    onSuccess: () => {
      setSelectedItemId(0);
      setShowConfirm(false);
    },
  });

  const editObservation = (item) => {
    setSelectedObservation(item);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedObservation(null);
    setShowModal(false);
  };

  const confirmDelete = (itemId) => {
    setSelectedItemId(itemId);
    setShowConfirm(true);
  };

  const deleteConfirmed = () =>
    deleteObservation.mutate({ type, patientId, id: selectedItemId });

  const toggleShowAll = () => {
    setShowLength(showAll ? LIST_LENGTH : observations?.length);
    setShowAll(!showAll);
  };

  const isDisabled = useCallback(
    (role, item) =>
      !Roles.canModifyRecords(role) ||
      item.external ||
      !!item.patientEncounterId,
    []
  );

  useEffect(() => {
    if (!isLoading && observations.length) setShowSection(true);
  }, [isLoading, observations]);

  if (isLoading) return <LoadingIndicator />;

  return (
    <div className="border-bottom border-lighter mt-2">
      <Row className="align-items-center">
        <Col className="d-flex align-items-center">
          <div className="display-3 me-2">{SECTION_TITLES[type]}</div>
          <Button
            variant=""
            className="ms-1"
            onClick={() => setShowSection(!showSection)}
          >
            {showSection ? <Dash size={24} /> : <Plus size={24} />}
          </Button>
        </Col>
        {ROLES.canModifyRecords(userRole) && (
          <Col
            xs={3}
            className="align-items-center text-end"
            style={{ width: '175px' }}
          >
            <ObservationButton
              onClick={() => setShowModal(true)}
              className="w-100"
            />
          </Col>
        )}
      </Row>
      <Row className="mt-2">
        <Col>
          {showSection && observations.length > 0 && (
            <Table hover size="sm">
              <thead>
                <tr>
                  <th aria-label="External" className="text-center">
                    <ShieldLock className="ms-0" />
                  </th>
                  <th className="text-uppercase text-center">Date</th>
                  <th className="text-uppercase">Observation</th>
                  <th className="text-uppercase text-end">Value</th>
                  <th className="text-uppercase">Units</th>
                  <th className="text-uppercase text-center">Created by</th>
                  <th className="text-uppercase text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {observations.slice(0, showLength).map((item) => (
                  <tr key={item.id}>
                    <td style={{ width: '40px' }} className="text-center">
                      <ReadonlyIcon
                        readonly={item.external}
                        showDash
                        tooltip="External"
                      />
                    </td>
                    <td className="text-center">
                      {item.date ? formatDateDisplay(item.date) : <Dash />}
                    </td>
                    <td>{item.observationCode?.name}</td>
                    <td className="text-end">{item.value}</td>
                    <td>{item.units}</td>
                    <td className="text-center">
                      {item.createdBy?.fullName ?? <Dash />}
                    </td>
                    <td className="text-center" style={{ width: '100px' }}>
                      <ActionButton
                        disabled={isDisabled(userRole, item)}
                        onClick={() => editObservation(item)}
                        action={ACTION_BUTTON_TYPES.EDIT}
                      />
                      <ActionButton
                        disabled={isDisabled(userRole, item)}
                        onClick={() => confirmDelete(item.id)}
                        action={ACTION_BUTTON_TYPES.DELETE}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {showSection && (!observations || observations.length === 0) && (
            <Card body className="text-muted fst-italic mb-3">
              0 observations. Click Add Observation to add new records.
            </Card>
          )}

          {observations?.length > LIST_LENGTH && (
            <Button variant="link text-start" onClick={toggleShowAll}>
              {showAll ? 'Show less' : 'Show more'}
            </Button>
          )}
        </Col>

        <ConfirmationDialog
          show={showConfirm}
          title="Confirm Delete"
          bodyText="Are you sure you want to delete this observation?"
          confirmText="Delete"
          onConfirm={deleteConfirmed}
          onCancel={() => setShowConfirm(false)}
        />
        {showModal && (
          <ObservationModal
            type={type}
            patientId={patientId}
            observation={selectedObservation ?? DEFAULT_OBSSERVATION}
            onClose={handleCloseModal}
          />
        )}
      </Row>
    </div>
  );
}

ObservationList.propTypes = {
  type: PropTypes.string.isRequired,
  patientId: PropTypes.number.isRequired,
};
