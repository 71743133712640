import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col, FormGroup, Form } from 'react-bootstrap';

function Field({ label, children, labelAlginment = 'text-end' }) {
  return (
    <Row className="w-100">
      <Col xs={3} className={`align-self-center ${labelAlginment}`}>
        <Form.Label className="text-uppercase text-primary mb-0">
          {label}
        </Form.Label>
      </Col>
      <Col className="align-self-center" xs={8}>
        <FormGroup>{children}</FormGroup>
      </Col>
    </Row>
  );
}

Field.defaultProps = {
  styleOverrides: {},
  labelAlginment: 'text-end',
};

Field.propTypes = {
  labelAlginment: PropTypes.string,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  styleOverrides: PropTypes.shape({}),
};

export default Field;
