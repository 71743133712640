import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Stack, FormControl, Row, Col } from 'react-bootstrap';
import Field from '../BaseFields/Field';

function ObservationSection({ onChange, initialValue }) {
  const [observations, setObservations] = useState(initialValue);

  useEffect(() => {
    setObservations(initialValue);
  }, [initialValue]);

  const handleChange = (key, val) => {
    const updatedObservations = { ...observations, [key]: val };
    setObservations(updatedObservations);
    onChange('observations', updatedObservations);
  };

  return (
    <Row>
      <Col>
        <Stack direction="horizontal" className="my-3">
          <Field label="KPA">
            <FormControl
              size="sm"
              type="number"
              className="w-25 d-inline"
              defaultValue={observations?.kpa_minValue ?? ''}
              onChange={(event) =>
                handleChange('kpa_minValue', event.target.value || undefined)
              }
            />
            <span className="mx-3 d-inline">to</span>
            <FormControl
              size="sm"
              type="number"
              className="w-25 d-inline"
              defaultValue={observations?.kpa_maxValue ?? ''}
              onChange={(event) =>
                handleChange('kpa_maxValue', event.target.value || undefined)
              }
            />
          </Field>
          <Field label="AST">
            <FormControl
              size="sm"
              type="number"
              className="w-25 d-inline"
              defaultValue={observations?.ast_minValue ?? ''}
              onChange={(event) =>
                handleChange('ast_minValue', event.target.value || undefined)
              }
            />
            <span className="mx-3 d-inline">to</span>
            <FormControl
              size="sm"
              type="number"
              className="w-25 d-inline"
              defaultValue={observations?.ast_maxValue ?? ''}
              onChange={(event) =>
                handleChange('ast_maxValue', event.target.value || undefined)
              }
            />
          </Field>
        </Stack>
        <Stack direction="horizontal" className="my-3">
          <Field label="CAP">
            <FormControl
              size="sm"
              type="number"
              className="w-25 d-inline"
              defaultValue={observations?.cap_minValue ?? ''}
              onChange={(event) =>
                handleChange('cap_minValue', event.target.value || undefined)
              }
            />
            <span className="mx-3 d-inline">to</span>
            <FormControl
              size="sm"
              type="number"
              className="w-25 d-inline"
              defaultValue={observations?.cap_maxValue ?? ''}
              onChange={(event) =>
                handleChange('cap_maxValue', event.target.value || undefined)
              }
            />
          </Field>
          <Field label="ALT">
            <FormControl
              size="sm"
              type="number"
              className="w-25 d-inline"
              defaultValue={observations?.alt_minValue ?? ''}
              onChange={(event) =>
                handleChange('alt_minValue', event.target.value || undefined)
              }
            />
            <span className="mx-3 d-inline">to</span>
            <FormControl
              size="sm"
              type="number"
              className="w-25 d-inline"
              defaultValue={observations?.alt_maxValue ?? ''}
              onChange={(event) =>
                handleChange('alt_maxValue', event.target.value || undefined)
              }
            />
          </Field>
        </Stack>
        <Stack direction="horizontal" className="my-3">
          <Field label="HBA1C">
            <FormControl
              size="sm"
              type="number"
              className="w-25 d-inline"
              defaultValue={observations?.hba1c_minValue ?? ''}
              onChange={(event) =>
                handleChange('hba1c_minValue', event.target.value || undefined)
              }
            />
            <span className="mx-3 d-inline">to</span>
            <FormControl
              size="sm"
              type="number"
              className="w-25 d-inline"
              defaultValue={observations?.hba1c_maxValue ?? ''}
              onChange={(event) =>
                handleChange('hba1c_maxValue', event.target.value || undefined)
              }
            />
          </Field>
          <Field label="BMI">
            <FormControl
              size="sm"
              type="number"
              className="w-25 d-inline"
              defaultValue={observations?.bmi_minValue ?? ''}
              onChange={(event) =>
                handleChange('bmi_minValue', event.target.value || undefined)
              }
            />
            <span className="mx-3 d-inline">to</span>
            <FormControl
              size="sm"
              type="number"
              className="w-25 d-inline"
              defaultValue={observations?.bmi_maxValue ?? ''}
              onChange={(event) =>
                handleChange('bmi_maxValue', event.target.value || undefined)
              }
            />
          </Field>
        </Stack>
      </Col>
    </Row>
  );
}

ObservationSection.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.shape({
    observations: PropTypes.shape({
      kpa_minValue: PropTypes.number,
      kpa_maxValue: PropTypes.number,
      ast_minValue: PropTypes.number,
      ast_maxValue: PropTypes.number,
      cap_minValue: PropTypes.number,
      cap_maxValue: PropTypes.number,
      alt_minValue: PropTypes.number,
      alt_maxValue: PropTypes.number,
      hba1c_minValue: PropTypes.number,
      hba1c_maxValue: PropTypes.number,
      bmi_minValue: PropTypes.number,
      bmi_maxValue: PropTypes.number,
    }),
  }).isRequired,
};

export default ObservationSection;
