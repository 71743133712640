import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Dash, Telephone } from 'react-bootstrap-icons';
import { formatPhoneDisplayV2 } from 'pages/utils/phoneUtils';

const getDisplay = (number, asLink) => {
  const className = 'd-inline ms-2';

  if (!number) {
    return <Dash className={className} />;
  }

  const formattedNumber = formatPhoneDisplayV2(number);

  if (asLink) {
    return (
      <a href={`tel:${number}`} className={className}>
        {formattedNumber}
      </a>
    );
  }
  return <span className={className}>{formattedNumber}</span>;
};

export default function PhoneNumberDisplay({ label, number, icon, asLink }) {
  const display = useMemo(() => getDisplay(number, asLink), [number, asLink]);

  return (
    <div>
      <div className="mb-1">{label}</div>
      <div className="d-flex align-items-center">
        {icon}
        {display}
      </div>
    </div>
  );
}

PhoneNumberDisplay.defaultProps = {
  number: undefined,
};

PhoneNumberDisplay.propTypes = {
  label: PropTypes.string.isRequired,
  number: PropTypes.string,
  icon: PropTypes.element,
  asLink: PropTypes.bool.isRequired,
};

PhoneNumberDisplay.defaultProps = {
  icon: <Telephone />,
};
