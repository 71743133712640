import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

export default function AdminListLayout({ topRow, table, tableStyles, modal }) {
  return (
    <>
      {topRow}
      <Row className={`mx-1 bg-white rounded rounded-2 ${tableStyles}`}>
        <Col className="mt-3">{table}</Col>
        {modal}
      </Row>
    </>
  );
}

AdminListLayout.defaultProps = {
  modal: undefined,
  tableStyles: '',
};

AdminListLayout.propTypes = {
  topRow: PropTypes.node.isRequired,
  table: PropTypes.node.isRequired,
  tableStyles: PropTypes.string,
  modal: PropTypes.node,
};
