import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
  ToastContainer,
  Toast,
  Badge,
} from 'react-bootstrap';
import {
  Check2Circle,
  CheckCircleFill,
  XCircleFill,
} from 'react-bootstrap-icons';
import CrioModal from './CrioModal';
import { useCreatePatientResearchProfile } from 'hooks/patientResearchProfileHooks';

export default function PatientInfoHeader({
  name,
  patientId,
  researchProfiles,
  sites,
  doNotContact,
  isCrioButtonVisible,
}) {
  const [showCrioModal, setShowCrioModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showDanger, setShowDanger] = useState(false);

  const createPatientResearchProfile = useCreatePatientResearchProfile({
    onSuccess: () => {
      setShowSuccess(true);
    },
    onError: () => {
      setShowDanger(true);
    },
  });

  /* eslint-disable no-shadow */
  const handleAddResearchProfile = async (patientId, siteId) => {
    createPatientResearchProfile.mutate({ patientId, siteId });
  };

  return (
    <>
      <Row>
        <Col>
          <div className="d-flex">
            <div className="display-2">{name}</div>
            {doNotContact && (
              <div className="ms-3">
                <Badge bg="danger" className="mt-1 p-2">
                  <span className="ms-1 align-middle">Do Not Contact</span>
                </Badge>
              </div>
            )}
          </div>
        </Col>
        {isCrioButtonVisible && (
          <Col className="align-self-center">
            <Row className="text-end">
              <Col className="align-self-center text-nowrap">
                <OverlayTrigger
                  key="add-patient-to-crio-overlay"
                  placement="top"
                  overlay={
                    <Tooltip id="add-patient-to-crio-tooltip">
                      Add Patient to CRIO
                    </Tooltip>
                  }
                >
                  <Button
                    className="me-2"
                    variant="primary"
                    onClick={
                      sites.length > 1
                        ? () => setShowCrioModal(true)
                        : () => handleAddResearchProfile(patientId, sites[0].id)
                    }
                    disabled={
                      sites.length === 0 ||
                      (sites.length === 1 && researchProfiles.length > 0)
                    }
                  >
                    <Check2Circle /> CRIO
                  </Button>
                </OverlayTrigger>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <ToastContainer position="top-end" style={{ marginTop: '12px' }}>
        <Toast
          bg="success"
          show={showSuccess}
          delay={3000}
          onClose={() => setShowSuccess(false)}
          autohide
          className="shadow"
        >
          <Toast.Header>
            <CheckCircleFill fill="green" size={20} />
            <p className="ms-2 my-auto me-auto text-black">
              <strong>Success</strong>: Request sent to add patient to CRIO.
            </p>
          </Toast.Header>
        </Toast>

        <Toast
          bg="danger"
          show={showDanger}
          delay={3000}
          onClose={() => setShowDanger(false)}
          autohide
          className="shadow"
        >
          <Toast.Header>
            <XCircleFill fill="red" size={20} />
            <p className="ms-2 my-auto me-auto text-black">
              <strong>Error</strong>: Failure to add patient to CRIO.
            </p>
          </Toast.Header>
        </Toast>
      </ToastContainer>
      {showCrioModal && (
        <CrioModal
          show={showCrioModal}
          onHide={() => setShowCrioModal(false)}
          patient={{ id: patientId, researchProfiles, sites }}
        />
      )}
    </>
  );
}

PatientInfoHeader.propTypes = {
  name: PropTypes.string.isRequired,
  patientId: PropTypes.number.isRequired,
  researchProfiles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    })
  ).isRequired,
  doNotContact: PropTypes.bool.isRequired,
  isCrioButtonVisible: PropTypes.bool.isRequired,
};
