import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import CallPane from './CallPane';
import ChartReviewPane from './ChartReviewPane';
import SchedulePane from './SchedulePane';
import TextPane from './TextPane';
import { Roles, UserContext } from 'components/Provider/UserProvider';

const TAB_OPTIONS = {
  TEXT: 'text',
  CALL: 'call',
  SCHEDULE: 'schedule',
  CHART_REVIEW: 'chart-review',
};

export default function PatientCommunications({ patient }) {
  const { userRole } = useContext(UserContext);
  const isReadOnly = !Roles.canModifyRecords(userRole) || patient.doNotContact;

  return (
    <Tabs
      variant="tabs"
      id="communications-tabs"
      defaultActiveKey={TAB_OPTIONS.TEXT}
      className="bg-light-gray"
    >
      <Tab eventKey={TAB_OPTIONS.TEXT} title="TEXT">
        <TextPane patient={patient} isReadOnly={isReadOnly} />
      </Tab>
      <Tab eventKey={TAB_OPTIONS.CALL} title="CALL">
        <CallPane patient={patient} isReadOnly={isReadOnly} />
      </Tab>
      <Tab eventKey={TAB_OPTIONS.SCHEDULE} title="SCHEDULE">
        <SchedulePane patient={patient} isReadOnly={isReadOnly} />
      </Tab>
      <Tab eventKey={TAB_OPTIONS.CHART_REVIEW} title="REVIEW">
        <ChartReviewPane patient={patient} isReadOnly={isReadOnly} />
      </Tab>
    </Tabs>
  );
}

PatientCommunications.propTypes = {
  patient: PropTypes.shape({ doNotContact: PropTypes.bool }).isRequired,
};
