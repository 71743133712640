import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

export function usePatientNotes(patientId) {
  return useQuery(patientId && ['patientNotes', patientId], () =>
    axios.get(`/api/patients/${patientId}/notes`).then((res) => res.data)
  );
}

const createPatientNote = async (values) => {
  const { patientId, ...note } = values;
  const response = await axios.post(`/api/patients/${patientId}/notes`, note);
  return response.data;
};

export function useCreatePatientNote(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPatientNote,
    onSuccess: (note) => {
      queryClient.invalidateQueries(['latestPatientRemark', note.patientId]);
      queryClient.invalidateQueries(['patients']);
      queryClient.invalidateQueries(['patientNotes', note.patientId]);
      options?.onSuccess?.(note);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const updatePatientNote = async (values) => {
  const { patientId, noteId } = values;
  const res = await axios.put(
    `/api/patients/${patientId}/notes/${noteId}`,
    values
  );

  return res.data;
};

export function useUpdatePatientNote(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updatePatientNote,
    onSuccess: (note) => {
      queryClient.invalidateQueries(['latestPatientRemark', note.patientId]);
      queryClient.invalidateQueries(['patients']);
      queryClient.invalidateQueries(['patientNotes', note.patientId]);
      options?.onSuccess?.(note);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const deletePatientNote = async (values) => {
  const { patientId, noteId } = values;
  await axios.delete(`/api/patients/${patientId}/notes/${noteId}`);

  return { patientId };
};

export function useDeletePatientNote(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePatientNote,
    onSuccess: ({ patientId }) => {
      queryClient.invalidateQueries(['latestPatientRemark', Number(patientId)]);
      queryClient.invalidateQueries(['patients']);
      queryClient.invalidateQueries(['patientNotes', patientId]);
      options?.onSuccess?.({ patientId });
    },
    onError: (error) => options?.onError?.(error),
  });
}
