const Roles = Object.freeze({
  ADMINISTRATOR: 'Administrator',
  RECRUITER: 'Recruiter',
  VIEWER: 'Viewer',
  ORGANIZATION_OWNER: 'Organization Owner',
  canModifyRecords: (role) =>
    [Roles.ADMINISTRATOR, Roles.ORGANIZATION_OWNER, Roles.RECRUITER].includes(
      role
    ),
});

export default Roles;
