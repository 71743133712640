import PropTypes from 'prop-types';
import React, { useRef, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import TextMessageForm from './TextMessageForm';
import TextMessageList from './TextMessageList';
import LoadingIndicator from 'components/LoadingIndicator';
import PatientInteractionSource from 'entities/PatientInteractionSource';
import { usePatientInteractions } from 'hooks/patientInteractionHooks';
import { useMessagePatient } from 'hooks/patientMessageHooks';

export default function TextPane({ patient, isReadOnly }) {
  const { data: patientInteractions, isLoading } = usePatientInteractions(
    patient.id,
    PatientInteractionSource.TextMessage
  );
  const [error, setError] = useState();
  const messagePatient = useMessagePatient({
    onError: (e) => setError(e.response?.data?.message),
  });
  const scrollRef = useRef(null);
  const formRef = useRef();

  const scrollToBottom = () => {
    scrollRef.current.scrollTo({
      top: scrollRef.current.scrollHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollToBottom();
    }
  }, [patientInteractions]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const handleFormSubmit = (values, { resetForm }) => {
    setError('');

    const mutateVariables = {
      patientId: patient.id,
      messageBody: values.messageBody,
      mobilePhone: patient.mobilePhone,
    };

    messagePatient.mutate(mutateVariables, {
      onSuccess: () => {
        resetForm();
        scrollToBottom();
      },
    });
  };

  const handleClick = () => {
    formRef.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  };

  return (
    <div
      className="mt-3 ms-2 d-flex flex-column"
      style={{ height: 'calc(100vh - 125px)' }}
    >
      <Alert variant="danger" show={!!error}>
        {error}
      </Alert>
      <div
        ref={scrollRef}
        className="pb-3 flex-fill border-bottom"
        style={{ overflow: 'hidden auto' }}
      >
        <TextMessageList messages={patientInteractions} />
      </div>
      <div className="mt-3 mx-3">
        <TextMessageForm
          ref={formRef}
          handleFormSubmit={handleFormSubmit}
          handleSubmitClick={handleClick}
          disabled={
            isReadOnly || messagePatient.isLoading || !patient.mobilePhone
          }
          isBusy={messagePatient.isLoading}
          textPaneError={error}
        />
      </div>
    </div>
  );
}

TextPane.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.number.isRequired,
    mobilePhone: PropTypes.string,
  }).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};
