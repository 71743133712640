import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Button, Spinner, Form } from 'react-bootstrap';

export default function ModalForm({
  onClose,
  title,
  children,
  validationSchema,
  initialValues,
  onSubmit,
  formId,
  size = undefined,
  isSubmitting = false,
  modalHeadClassName = undefined,
  modalCloseVariant = undefined,
  submitButtonText = undefined,
}) {
  const buttonText = submitButtonText || 'Save';
  return (
    <Modal
      show
      backdrop="static"
      keyboard={false}
      centered
      onHide={onClose}
      animation={false}
      size={size}
    >
      <Modal.Header
        closeButton
        className={modalHeadClassName}
        closeVariant={modalCloseVariant}
      >
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={validationSchema}
          onSubmit={(v) => onSubmit(v)}
          initialValues={initialValues}
        >
          {({ handleSubmit, values, errors }) => (
            <Form
              id={formId}
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(values);
              }}
              onError={(e) => console.log(e, errors)}
            >
              {children}
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} disabled={isSubmitting}>
          Close
        </Button>
        <Button
          form={formId}
          type="submit"
          variant="primary"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <Spinner
              animation="border"
              role="status"
              size="sm"
              className="ms-2"
            />
          ) : (
            buttonText
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalForm.defaultProps = {
  size: undefined,
  isSubmitting: false,
  modalHeadClassName: undefined,
  modalCloseVariant: undefined,
  submitButtonText: undefined,
};

ModalForm.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  validationSchema: PropTypes.shape({}).isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['sm', 'lg', 'xl']),
  isSubmitting: PropTypes.bool,
  modalHeadClassName: PropTypes.string,
  modalCloseVariant: PropTypes.string,
  formId: PropTypes.string.isRequired,
  submitButtonText: PropTypes.string,
};
