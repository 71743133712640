import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

export function usePatientChartReviews(patientId, sort) {
  return useQuery(patientId && ['patientChartReviews', patientId], () =>
    axios
      .get(`/api/patients/${patientId}/chart-reviews`, { params: { sort } })
      .then((res) => res.data)
  );
}

const createPatientChartReview = async (values) => {
  const { patientId, ...data } = values;
  const response = await axios.post(
    `/api/patients/${patientId}/chart-reviews`,
    data
  );

  return response.data;
};

export function useCreatePatientChartReview(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPatientChartReview,
    onSuccess: (chartReview) => {
      queryClient.invalidateQueries(['patient', chartReview.patientId]);
      queryClient.invalidateQueries([
        'patientChartReviews',
        chartReview.patientId,
      ]);
      queryClient.invalidateQueries([
        'patientStudyHistory',
        chartReview.patientId,
      ]);
      queryClient.invalidateQueries(['status-group-metrics']);
      queryClient.invalidateQueries(['patients']);

      options?.onSuccess?.(chartReview);
    },
    onError: (error) => options?.onError?.(error),
  });
}
