import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CalendarView from './components/CalendarView';
import {
  ScheduleContext,
  ScheduleProvider,
} from './components/ScheduleProvider';
import Sidebar from './components/Sidebar';

export default function Schedule() {
  return (
    <ScheduleProvider>
      <Row id="schedule" className="pt-4 h-100 overflow-auto">
        <Col md={3}>
          <Sidebar context={ScheduleContext} />
        </Col>
        <Col md={9}>
          <Row>
            <Col style={{ minHeight: '800px' }}>
              <CalendarView context={ScheduleContext} />
            </Col>
          </Row>
        </Col>
      </Row>
    </ScheduleProvider>
  );
}
