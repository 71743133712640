import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';
import StatusGroups from 'entities/StatusGroups';

export function usePatientAppointments(patientId, appointmentTypeIds) {
  let ids = [];

  if (!Array.isArray(appointmentTypeIds)) {
    ids.push(appointmentTypeIds);
  } else {
    ids = appointmentTypeIds;
  }

  const queryParams = `?${ids
    .map((id) => `appointmentTypeId=${id}`)
    .join('&')}`;

  return useQuery(
    patientId && ['patientAppointments', { patientId, appointmentTypeIds }],
    () =>
      axios
        .get(
          `/api/patients/${patientId}/appointments${
            queryParams.length > 1 ? queryParams : ''
          }`
        )
        .then((res) => res.data),
    { enabled: Boolean(patientId) }
  );
}

const createPatientAppointment = async (values) => {
  const { patientId, ...appointment } = values;
  const response = await axios.post(
    `/api/patients/${patientId}/appointments`,
    appointment
  );
  return response.data;
};

export function useCreatePatientAppointment(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPatientAppointment,
    onSuccess: (appointment) => {
      queryClient.invalidateQueries([
        'patientAppointments',
        { patientId: appointment.patientId },
      ]);
      queryClient.invalidateQueries(['patientsAppointments']);
      queryClient.invalidateQueries(['patient', appointment.patientId]);
      queryClient.invalidateQueries(['status-group-metrics']);
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'patients' &&
          query.queryKey[1]?.statusGroup === StatusGroups.PRESCREEN,
      });

      options?.onSuccess?.(appointment);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const updatePatientAppointment = async (values) => {
  const { patientId } = values;
  const res = await axios.put(
    `/api/patients/${patientId}/appointments`,
    values
  );

  return res.data;
};

export function useUpdatePatientAppointment(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updatePatientAppointment,
    onSuccess: (appointment) => {
      queryClient.invalidateQueries([
        'patientAppointments',
        { patientId: appointment.patientId },
      ]);
      queryClient.invalidateQueries(['patientsAppointments']);
      queryClient.invalidateQueries(['patient', appointment.patientId]);
      queryClient.invalidateQueries(['status-group-metrics']);

      options?.onSuccess?.(appointment);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const cancelPatientAppointment = async (values) => {
  const { patientId, appointmentId, cancellationReasonId } = values;

  await axios.delete(
    `/api/patients/${patientId}/appointments/${appointmentId}`,
    {
      data: { cancellationReasonId },
    }
  );

  return { patientId, appointmentId, cancellationReasonId };
};

export function useCancelPatientAppointment(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: cancelPatientAppointment,
    onSuccess: ({ patientId, appointmentId }) => {
      queryClient.invalidateQueries(['patientAppointments', { patientId }]);
      queryClient.invalidateQueries(['patientsAppointments']);
      queryClient.invalidateQueries(['patient', patientId]);
      queryClient.invalidateQueries(['status-group-metrics']);
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'patients' &&
          query.queryKey[1]?.statusGroup === StatusGroups.PRESCREEN,
      });

      options?.onSuccess?.({ patientId, appointmentId });
    },
    onError: (error) => options?.onError?.(error),
  });
}
