import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Stack } from 'react-bootstrap';
import AppointmentTypeSelector from './AppointmentTypeSelector';
import ScheduleAppointmentButton from './ScheduleAppointmentButton';
import ScheduleFilters from './ScheduleFilters';
import SiteSelector from './SiteSelector';
import { SCHEDULE_VIEW } from '../constants';
import AppointmentTypes from 'entities/AppointmentTypes';

export default function SideBar({ context }) {
  const {
    allowChanges,
    sites,
    handleAddAppointment,
    handleSiteChange,
    handleApptTypeChange,
    stateValues: { view, siteId, appointmentTypeId },
  } = useContext(context);

  return (
    <Stack gap={4}>
      <p className="display-3 mb-n2">Sites</p>
      <SiteSelector
        sites={sites}
        selectedSiteId={siteId}
        onChange={handleSiteChange}
      />
      <AppointmentTypeSelector
        appointmentTypeId={appointmentTypeId}
        onClick={(value) => handleApptTypeChange(value)}
      />
      {allowChanges &&
        Number(appointmentTypeId) === AppointmentTypes.OBJECTIVE_SCREEN && (
          <ScheduleAppointmentButton onClick={handleAddAppointment} />
        )}
      {view === SCHEDULE_VIEW.LIST && <ScheduleFilters />}
    </Stack>
  );
}

SideBar.propTypes = {
  context: PropTypes.shape({}).isRequired,
};
