import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import UserSitesTable from './UserSitesTable';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { useDeleteUserSite } from 'hooks/userSiteHooks';

function ConfirmationBody(fullName, siteName) {
  return (
    <span>
      Are you sure you want to remove{' '}
      <span className="fw-bold">{fullName}</span> from the{' '}
      <span className="fw-bold">{siteName}</span> site?
    </span>
  );
}

export default function UserSiteList({ user }) {
  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const [selectedUserSite, setSelectedUserSite] = useState();
  const [confirmationBody, setConfirmationBody] = useState('');

  const deleteUserSite = useDeleteUserSite({
    onSuccess: () => {
      setShowConfirmRemove(false);
    },
  });

  const confirmRemove = (site) => {
    const newConfirmationBody = ConfirmationBody(user.fullName, site.name);

    setConfirmationBody(newConfirmationBody);

    setSelectedUserSite(site.UserSite);
    setShowConfirmRemove(true);
  };

  const handleRemove = () =>
    deleteUserSite.mutate({
      userId: selectedUserSite.userId,
      siteId: selectedUserSite.siteId,
    });

  return (
    <>
      <Row>
        <Col>
          <h5>Current Sites</h5>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          {user.sites.length ? (
            <UserSitesTable user={user} confirmRemove={confirmRemove} />
          ) : (
            <span className="fst-italic">
              User not assigned to any sites...
            </span>
          )}
        </Col>
      </Row>
      <ConfirmationDialog
        show={showConfirmRemove}
        title="Confirm Remove"
        bodyText={confirmationBody}
        confirmText="Remove"
        cancelText="Cancel"
        onConfirm={handleRemove}
        onCancel={() => setShowConfirmRemove(false)}
      />
    </>
  );
}

UserSiteList.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    fullName: PropTypes.string.isRequired,
    sites: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        UserSite: PropTypes.shape({
          userId: PropTypes.number.isRequired,
          siteId: PropTypes.number.isRequired,
        }).isRequired,
      })
    ).isRequired,
  }).isRequired,
};
