import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

const formatPhoneNumber = (value) => {
  if (!value) return value;

  const phoneNumber = value.replace(/[^\d]/g, '');

  if (phoneNumber.length < 4) return phoneNumber;

  if (phoneNumber.length < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

function PhoneNumber({ name, value, onChange, isValid, isInvalid }) {
  const [displayValue, setDisplayValue] = useState('');

  const handleChange = (e) => {
    const formattedValue = formatPhoneNumber(e.target.value);
    setDisplayValue(formattedValue);

    const formValue = formattedValue.replace(/[^\d]/g, '');
    onChange({ type: 'input', target: { name, value: formValue } });
  };

  return (
    <Form.Control
      type="text"
      id={`${name}`}
      name="phoneNumber"
      placeholder="(456) 456-7890"
      value={displayValue || formatPhoneNumber(value || '')}
      onChange={handleChange}
      isValid={isValid}
      isInvalid={isInvalid}
    />
  );
}

PhoneNumber.defaultProps = {
  value: null,
  isValid: null,
  isInvalid: null,
  onChange: null,
};

PhoneNumber.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  onChange: PropTypes.func,
};

export default PhoneNumber;
