import React from 'react';

import ReasonSection from 'components/Reasons/ReasonSection';
import {
  useCreateDocumentType,
  useDocumentTypes,
  useUpdateDocumentType,
} from 'hooks/documentTypeHooks';

function DocumentTypesSection() {
  return (
    <ReasonSection
      useOrgReasons={useDocumentTypes}
      useCreateReason={useCreateDocumentType}
      useUpdateReason={useUpdateDocumentType}
      reasonType="Document Type"
    />
  );
}

export default DocumentTypesSection;
