import axios from './axios';

const API = {
  /**
   * Set the bearer token for all future requests
   * @param {String} token - The bearer token
   * @returns {void}
   */
  setBearerToken(token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  },

  /**
   * Get the currently logged in user
   * @returns {Promise}
   * @resolves {Object} - The user object
   * @rejects {Error} - The error object
   */
  async getCurrentUser() {
    const { data } = await axios.get('/api/users/current');
    return data;
  },

  /**
   * Search for diagnosis concepts
   * @param {String} search - The search string
   * @returns {Promise}
   */
  async searchDiagnosisConcepts(search) {
    const { data } = await axios.get('/api/diagnoses/concepts/search', {
      params: { search },
    });
    return data;
  },

  /**
   * Get related codes for a diagnosis concept
   * @param {String} conceptId - The UMLS concept ID
   * @returns {Promise}
   */
  async getDiagnosisConceptRelatedCodes(conceptId) {
    const { data } = await axios.get(
      `/api/diagnoses/concepts/${conceptId}/related-codes`
    );
    return data;
  },

  async sendResetPasswordEmail(email) {
    const connection = `objective-screen-${process.env.REACT_APP_ENV}`;

    try {
      await axios.post(
        `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
        {
          client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
          email,
          connection,
        }
      );
    } catch (e) {
      console.error(e);
    }
  },
};

export default API;
