import React, { useState } from 'react';
import { Row, Col, FormCheck } from 'react-bootstrap';
import PatientMedicationsTable from './PatientMedicationsTable';

function PatientMedicationsView({
  medications,
  encounterId,
  onAdd,
  allowChanges,
}) {
  const [view, setView] = useState('active');

  const medicationNameSort = (a, b) => {
    const aName = a.customName || a.medicationCode?.name;
    const bName = b.customName || b.medicationCode?.name;
    if (aName > bName || a.startDate > b.startDate) return -1;
  };

  const activeMedications = medications
    .filter(
      (m) =>
        m.patientEncounterId?.toString() !== encounterId.toString() &&
        !m.stopDate
    )
    .map((m) => m)
    .sort(medicationNameSort);

  const historicalMedications = medications
    .filter(
      (m) =>
        m.patientEncounterId?.toString() !== encounterId.toString() &&
        m.stopDate
    )
    .map((m) => m)
    .sort(medicationNameSort);

  const handleToggleView = (e) => {
    setView(e.target.value);
  };

  return (
    <>
      <Row className="mt-2">
        <Col>
          <h6>Medication History</h6>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <FormCheck
            inline
            label="Active Medications"
            name="medication-view"
            type="radio"
            value="active"
            onChange={handleToggleView}
            id="medication-view-active"
            checked={view === 'active'}
          />
          <FormCheck
            inline
            label="Medication History"
            name="medication-view"
            type="radio"
            value="historical"
            onChange={handleToggleView}
            id="medication-view-historical"
            checked={view === 'historical'}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <PatientMedicationsTable
            medications={
              view === 'active' ? activeMedications : historicalMedications
            }
            showExternal
            showAdd={allowChanges}
            onAdd={onAdd}
          />
        </Col>
      </Row>
    </>
  );
}

export default PatientMedicationsView;
