import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

const createSiteStudy = async (siteStudy) => {
  const { organizationId, siteId, studyId } = siteStudy;
  const result = await axios.post(
    `/api/admin/organizations/${organizationId}/sites/${siteId}/studies`,
    { studyId }
  );
  return result.data;
};

export function useCreateSiteStudy(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createSiteStudy,
    onSuccess: (siteStudy) => {
      queryClient.invalidateQueries(['siteMetrics']);
      queryClient.invalidateQueries(['studyMetrics']);
      queryClient.invalidateQueries(['sites']);
      queryClient.invalidateQueries(['site', siteStudy.siteId]);
      queryClient.invalidateQueries(['siteStudies', siteStudy.siteId]);
      queryClient.invalidateQueries(['studies']);
      queryClient.invalidateQueries(['study', siteStudy.studyId]);

      options?.onSuccess?.(siteStudy);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const deleteSiteStudy = async (siteStudy) => {
  const result = await axios.delete(
    `/api/admin/sites/${siteStudy.siteId}/studies/${siteStudy.studyId}`
  );

  return result.data;
};

export function useDeleteSiteStudy(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteSiteStudy,
    onSuccess: (siteStudy) => {
      queryClient.invalidateQueries(['siteMetrics']);
      queryClient.invalidateQueries(['studyMetrics']);
      queryClient.invalidateQueries(['sites']);
      queryClient.invalidateQueries(['site', siteStudy.siteId]);
      queryClient.invalidateQueries(['siteStudies', siteStudy.siteId]);
      queryClient.invalidateQueries(['studies']);
      queryClient.invalidateQueries(['study', siteStudy.studyId]);

      options?.onSuccess?.(siteStudy);
    },
    onError: (error) => options?.onError?.(error),
  });
}

export function useSiteStudies(siteId, includeInactive = false) {
  const params = { includeInactive };
  return useQuery(
    ['siteStudies', siteId, includeInactive],
    () =>
      axios
        .get(`/api/sites/${siteId}/studies`, { params })
        .then((res) => res.data),
    { enabled: Boolean(siteId) }
  );
}
