import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Card } from 'react-bootstrap';
import PatientInteractionType from 'entities/PatientInteractionType';
import './TextMessage.scss';

export default function TextMessage({ message }) {
  const isInbound = message.type === PatientInteractionType.Inbound;
  const colConfig = { span: 11, offset: isInbound ? 0 : 1 };
  const messageDate = DateTime.fromISO(message.createdAt)
    .toLocaleString({
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    })
    .toUpperCase();

  const messageTime = DateTime.fromISO(message.createdAt).toLocaleString(
    DateTime.TIME_SIMPLE
  );

  const messageUser = isInbound ? '' : message.createdBy?.fullName;

  return (
    <Col xs={colConfig}>
      <p className="text-start mb-1">
        {messageUser}
        &nbsp;
        <small className="text-muted">
          {`${messageDate} @ ${messageTime}`}
        </small>
      </p>
      <Card className={isInbound ? 'recievedText' : 'sentText'}>
        <Card.Body className="p-2">{message.text}</Card.Body>
      </Card>
    </Col>
  );
}

TextMessage.propTypes = {
  message: PropTypes.shape({
    text: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    createdBy: PropTypes.shape({
      fullName: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
