import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CheckedIndicator from './CheckedIndicator';
import AppointmentTypes from 'entities/AppointmentTypes';
import './AppointmentTypeSelector.scss';

export default function AppointmentTypeSelector({
  appointmentTypeId,
  onClick,
}) {
  return (
    <div id="calendar-legend">
      <Row
        value={AppointmentTypes.OBJECTIVE_SCREEN}
        className="align-items-center clickable"
        onClick={() => onClick(AppointmentTypes.OBJECTIVE_SCREEN)}
      >
        <Col xs={1}>
          <CheckedIndicator
            checked={
              Number(appointmentTypeId) === AppointmentTypes.OBJECTIVE_SCREEN
            }
            variant="primary"
          />
        </Col>
        <Col className="ms-2 fw-bold display-5">
          Objective Screen Appointments
        </Col>
      </Row>
      <Row
        value={AppointmentTypes.FACILITY}
        className="mt-3 align-items-center clickable"
        onClick={() => onClick(AppointmentTypes.FACILITY)}
      >
        <Col xs={1}>
          <CheckedIndicator
            checked={Number(appointmentTypeId) === AppointmentTypes.FACILITY}
            variant="light-teal"
          />
        </Col>
        <Col className="ms-2 display-5">Facility Schedule</Col>
      </Row>
    </div>
  );
}

AppointmentTypeSelector.defaultProps = {
  appointmentTypeId: '',
};

AppointmentTypeSelector.propTypes = {
  appointmentTypeId: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};
