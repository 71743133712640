import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';
import PatientInteractionSource from 'entities/PatientInteractionSource';

export function useRecentPatientMessages(fromDate) {
  return useQuery(
    ['recent-messages', { fromDate }],
    () =>
      axios
        .get('/api/patients/messages', { params: { fromDate } })
        .then((res) => res.data),
    {
      refetchInterval: 1000 * 60 * 5,
      refetchIntervalInBackground: true,
    }
  );
}

const messagePatient = async ({ patientId, ...rest }) => {
  const result = await axios.post(`/api/patients/${patientId}/sms`, rest);
  return result.data;
};

export function useMessagePatient(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: messagePatient,
    onSuccess: (message) => {
      queryClient.invalidateQueries([
        'patientInteractions',
        {
          patientId: message.patientId,
          source: PatientInteractionSource.TextMessage,
        },
      ]);

      options?.onSuccess?.(message);
    },
    onError: (error) => options?.onError?.(error),
  });
}
