import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ConnectionInfo from './components/ConnectionInfo';
import {
  ConnectionContext,
  ConnectionProvider,
} from './components/ConnectionProvider';
import ConnectionSitesList from './components/ConnectionSitesList';
import SiteAdd from './components/SiteAdd';

function ConnectionDetails() {
  const params = useParams();
  const connectionId = Number(params.id);

  return (
    <ConnectionProvider connectionId={connectionId}>
      <Row className="mb-4 rounded">
        <Col>
          <ConnectionInfo context={ConnectionContext} />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <SiteAdd context={ConnectionContext} />
        </Col>
      </Row>
      <Row className="mb-4 pt-2 bg-white rounded mx-0">
        <Col>
          <ConnectionSitesList context={ConnectionContext} />
        </Col>
      </Row>
    </ConnectionProvider>
  );
}

export default ConnectionDetails;
