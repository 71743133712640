export function formatPhoneDisplay(phone) {
  if (phone) {
    const areaCode = phone.slice(0, 3);
    const exchangeCode = phone.slice(3, 6);
    const lineNumber = phone.slice(6);
    return `(${areaCode}) ${exchangeCode}-${lineNumber}`;
  }
  return '';
}

export function formatPhoneDisplayV2(phone) {
  if (phone) {
    const areaCode = phone.slice(0, 3);
    const exchangeCode = phone.slice(3, 6);
    const lineNumber = phone.slice(6);
    return `${areaCode}.${exchangeCode}.${lineNumber}`;
  }
  return '-';
}
