import React from 'react';

import ReasonSection from 'components/Reasons/ReasonSection';
import {
  useCreateCallOutcome,
  useCallOutcomes,
  useUpdateCallOutcome,
} from 'hooks/callOutcomeHooks';

function CallOutcomesSection() {
  return (
    <ReasonSection
      useOrgReasons={useCallOutcomes}
      useCreateReason={useCreateCallOutcome}
      useUpdateReason={useUpdateCallOutcome}
      reasonType="Call Outcome"
    />
  );
}

export default CallOutcomesSection;
