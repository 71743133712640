/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import PropTypes from 'prop-types';
import React, { useContext, useState, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { Dash, ShieldLock, PencilFill, TrashFill } from 'react-bootstrap-icons';

import { NoteContext } from './NoteProvider';
import ConfirmationDialog from 'components/ConfirmationDialog';
import TableLayout from 'components/Layouts/TableLayout';
import { UserContext, Roles } from 'components/Provider/UserProvider';
import { usePatientNotes, useDeletePatientNote } from 'hooks/patientNoteHooks';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

function NoteList({ patientId }) {
  const [noteId, setNoteId] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const { editNote } = useContext(NoteContext);
  const { userRole } = useContext(UserContext);
  const { data: notes, isLoading } = usePatientNotes(patientId);

  const deleteNote = useDeletePatientNote({
    onSuccess: () => {
      setNoteId(null);
      setShowConfirm(false);
    },
  });

  const confirmDelete = (id) => {
    setNoteId(id);
    setShowConfirm(true);
  };

  const deleteConfirmed = () => deleteNote.mutate({ patientId, noteId });

  const columns = useMemo(() => {
    const config = [
      {
        accessorKey: 'external',
        header: () => <ShieldLock className="ms-0" />,
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { className: 'text-center' },
        },
        cell: (props) =>
          props.getValue() ? <ShieldLock className="ms-0" /> : <Dash />,
      },

      {
        accessorKey: 'createdAt',
        header: 'DATE',
        meta: {
          headerProps: { className: 'text-center', style: { width: '220px' } },
          cellProps: { className: 'text-center' },
        },
        cell: (props) => formatDateDisplay(props.getValue()),
      },
      {
        accessorKey: 'text',
        header: 'NOTE',
      },
      {
        accessorFn: (row) => row.createdBy?.fullName,
        header: 'USER',
        meta: { headerProps: { style: { width: '220px' } } },
        cell: (props) => props.getValue() ?? <Dash />,
      },
      {
        header: 'ACTIONS',
        meta: {
          headerProps: { className: 'text-center', style: { width: '100px' } },
          cellProps: { className: 'text-center' },
        },
        cell: (props) => (
          <>
            {!props.row.original.external &&
              Roles.canModifyRecords(userRole) && (
                <Button
                  variant=""
                  onClick={() => editNote(props.row.original)}
                  size="sm"
                >
                  <PencilFill />
                </Button>
              )}
            {Roles.canModifyRecords(userRole) && (
              <Button
                variant=""
                onClick={() => confirmDelete(props.row.original.id)}
                size="sm"
              >
                <TrashFill />
              </Button>
            )}
          </>
        ),
      },
    ];

    return config;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes]);

  return (
    <>
      <TableLayout
        isLoading={isLoading}
        columns={columns}
        data={notes}
        noResultsText="0 notes. Click Add Note to add new records."
      />
      <ConfirmationDialog
        show={showConfirm}
        title="Confirm Delete"
        bodyText="Are you sure you want to delete this note?"
        confirmText="Delete"
        onConfirm={deleteConfirmed}
        onCancel={() => setShowConfirm(false)}
      />
    </>
  );
}

NoteList.propTypes = {
  patientId: PropTypes.number.isRequired,
};

export default NoteList;
