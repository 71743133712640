import { Formik, getIn } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import {
  Row,
  Col,
  Button,
  Alert,
  Modal,
  Form,
  Spinner,
  FormSelect,
} from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import * as yup from 'yup';
import axios from '../../../axios';
import { useMedicationFrequencies } from 'hooks/medicationHooks';

function EncounterMedicationModal({
  medication,
  handleSave,
  handleClose,
  showModal,
  serverError,
  isBusy,
}) {
  const medicationFrequencies = useMedicationFrequencies();
  const formRef = useRef();
  const [codeOptions, setCodeOptions] = useState([]);
  const [selectedCode, setSelectedCode] = useState(
    medication.medicationCode || null
  );

  useEffect(() => {
    setCodeOptions([]);
    setSelectedCode(medication.medicationCode);
  }, [medication]);

  const schema = yup.object({
    medicationCode: yup.object({ id: yup.number().integer().required() }),
    medicationFrequency: yup.object({ id: yup.number().integer().required() }),
    startDate: yup
      .date()
      .nullable()
      .max(new Date(), 'date cannot be in the future'),
    stopDate: yup.date().nullable(),
    dosage: yup.string(),
    units: yup.string(),
    route: yup.string(),
    reason: yup.string(),
  });

  const handleSearch = (substring) => {
    axios
      .get('/api/medications/codes', { params: { substring } })
      .then((res) => {
        setCodeOptions(res.data);
      });
  };

  const handleFormSubmit = (values) => {
    handleSave({
      ...medication,
      ...values,
    });
  };

  const handleFormSave = () => {
    formRef.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  };

  return (
    <Modal
      show={showModal}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
      onHide={handleClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Medication</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="danger" show={!!serverError}>
          {serverError}
        </Alert>
        <Formik
          validationSchema={schema}
          onSubmit={handleFormSubmit}
          initialValues={medication}
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit} ref={formRef}>
              <Row>
                <Col>
                  <Form.Group controlId="formMedicationCode">
                    <Form.Label>Medication Code</Form.Label>
                    <AsyncTypeahead
                      autoFocus
                      id="medicationCodeId"
                      name="medicationCode.id"
                      filterBy={() => true}
                      labelKey="name"
                      minLength={3}
                      isLoading={false}
                      onSearch={handleSearch}
                      options={codeOptions}
                      selected={selectedCode ? [selectedCode] : []}
                      placeholder="Start typing code or description..."
                      onChange={(code) => {
                        if (code.length === 0) {
                          setFieldValue('medicationCode.id', '');
                          setFieldValue('medicationCode.name', '');
                          setSelectedCode(null);
                        } else {
                          setFieldValue('medicationCode.id', code[0].id);
                          setFieldValue('medicationCode.name', code[0].name);
                          setSelectedCode(code[0]);
                        }
                      }}
                      isValid={
                        getIn(touched, 'medicationCode.id') &&
                        !getIn(errors, 'medicationCode.id')
                      }
                      isInvalid={!!getIn(errors, 'medicationCode.id')}
                      renderMenuItemChildren={(option) => (
                        <section className="d-flex flex-row">
                          <span className="text-primary me-2 w-10">
                            {option.code}
                          </span>
                          <span className="text-truncate w-90">
                            {option.name}
                          </span>
                        </section>
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formMedicationFrequency">
                    <Form.Label>Frequency</Form.Label>
                    <FormSelect
                      name="medicationFrequency.id"
                      value={values.medicationFrequency?.id || ''}
                      onChange={(event) => {
                        if (event.target.value) {
                          setFieldValue(
                            'medicationFrequency.id',
                            event.target.value
                          );
                          setFieldValue(
                            'medicationFrequency.name',
                            event.target.options[
                              event.target.options.selectedIndex
                            ].text
                          );
                        } else {
                          setFieldValue('medicationFrequency.id', '');
                          setFieldValue('medicationFrequency.name', '');
                        }
                      }}
                      isValid={
                        getIn(touched, 'medicationFrequency.id') &&
                        !getIn(errors, 'medicationFrequency.id')
                      }
                      isInvalid={!!getIn(errors, 'medicationFrequency.id')}
                    >
                      <option key="0" value="">
                        Select Frequency
                      </option>
                      {medicationFrequencies.data?.map((f) => (
                        <option key={f.id} value={f.id}>
                          {f.name}
                        </option>
                      ))}
                    </FormSelect>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formDosage">
                    <Form.Label>Dosage</Form.Label>
                    <Form.Control
                      type="text"
                      name="dosage"
                      value={values.dosage || ''}
                      onChange={handleChange}
                      isValid={touched.dosage && !errors.dosage}
                      isInvalid={!!errors.dosage}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formUnits">
                    <Form.Label>Units</Form.Label>
                    <Form.Control
                      type="text"
                      name="units"
                      value={values.units || ''}
                      onChange={handleChange}
                      isValid={touched.units && !errors.units}
                      isInvalid={!!errors.units}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formRoute">
                    <Form.Label>Route</Form.Label>
                    <Form.Control
                      type="text"
                      name="route"
                      value={values.route || ''}
                      onChange={handleChange}
                      isValid={touched.route && !errors.route}
                      isInvalid={!!errors.route}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formReason">
                    <Form.Label>Reason</Form.Label>
                    <Form.Control
                      type="text"
                      name="reason"
                      value={values.reason || ''}
                      onChange={handleChange}
                      isValid={touched.reason && !errors.reason}
                      isInvalid={!!errors.reason}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formStartDate">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="startDate"
                      value={values.startDate}
                      onChange={handleChange}
                      isValid={touched.startDate && !errors.startDate}
                      isInvalid={!!errors.startDate}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formStopDate">
                    <Form.Label>Stop Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="stopDate"
                      value={values.stopDate}
                      onChange={handleChange}
                      isValid={touched.stopDate && !errors.stopDate}
                      isInvalid={!!errors.stopDate}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleFormSave} disabled={isBusy}>
          Save
          <Spinner
            animation="border"
            role="status"
            size="sm"
            className={`ms-2 ${isBusy ? '' : 'd-none'}`}
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EncounterMedicationModal;
