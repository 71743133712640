import React, { useState } from 'react';
import { Form, InputGroup, Button, FormControl } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import paths from 'entities/SitePaths';

function PatientSearch() {
  const [search, setSearch] = useState('');

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (search) {
      navigate(`${paths.SEARCH}?query=${search}`);
    }
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputGroup className="mb-0 h-100 bg-white rounded flex-nowrap">
        <FormControl
          type="text"
          placeholder="Search by name, MRN or phone #"
          onChange={handleChange}
          style={{ width: '200px' }}
          size="sm"
          className="border border-end-0 shadow-none py-2"
        />
        <Button
          variant=""
          className="border-top border-bottom border-end border-start-0"
          size="sm"
          type="submit"
        >
          <Search size={18} />
        </Button>
      </InputGroup>
    </Form>
  );
}

export default PatientSearch;
