import PropTypes from 'prop-types';
import React from 'react';
import TextMessage from './TextMessage';

export default function TextMessageList({ messages }) {
  if (messages.length === 0) {
    return (
      <div className="m-3 text-muted">
        No text messages found for this patient.
      </div>
    );
  }

  return (
    <div className="d-grid gap-4 px-2">
      {messages.map((message) => (
        <TextMessage key={`text-message-${message.id}`} message={message} />
      ))}
    </div>
  );
}

TextMessageList.defaultProps = {
  messages: [],
};

TextMessageList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({})),
};
