import { Formik } from 'formik';
import React, { useContext, useRef } from 'react';

import {
  Alert,
  Button,
  Form,
  Row,
  Col,
  Modal,
  Spinner,
  Stack,
} from 'react-bootstrap';

import * as yup from 'yup';

import { DocumentContext } from './DocumentProvider';
import SelectField from 'components/FormikForm/SelectField';
import TextField from 'components/FormikForm/TextField';
import LoadingIndicator from 'components/LoadingIndicator';

const MAX_FILE_SIZE = 5 * 1024 * 1024;

const schema = yup.object({
  title: yup.string().required(),
  documentTypeId: yup.number().integer().required(),
  file: yup
    .mixed()
    .required('A file is required')
    .test(
      'fileSize',
      'File must be less than 5 MB',
      (value) => value && value.size <= MAX_FILE_SIZE
    ),
});

export default function DocumentModal() {
  const formRef = useRef();

  const { error, showModal, closeModal, saveDocument, documentTypes, isBusy } =
    useContext(DocumentContext);

  const handleFormSubmit = (values) => {
    const { title, documentTypeId, file } = values;

    const formData = new FormData();
    formData.append('title', title);
    formData.append('documentTypeId', documentTypeId);
    formData.append('data', file);

    saveDocument(formData);
  };

  const handleSave = () => {
    formRef.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  };

  if (isBusy) return <LoadingIndicator />;

  return (
    <Modal
      show={showModal}
      backdrop="static"
      keyboard={false}
      centered
      onHide={closeModal}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Document</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="danger" show={!!error}>
          {error}
        </Alert>

        <Formik
          validationSchema={schema}
          onSubmit={handleFormSubmit}
          initialValues={{ title: '', type: undefined, file: '' }}
        >
          {({ handleSubmit, setFieldValue, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit} ref={formRef}>
              <Stack gap={2}>
                <TextField
                  name="title"
                  label="Title"
                  placeholder="Document Title"
                />
                <SelectField
                  label="Type"
                  name="documentTypeId"
                  defaultDisplay="Select Document Type"
                  options={documentTypes ?? []}
                  valueAccessor="id"
                  displayAccessor="name"
                />
                <Row className="mt-2">
                  <Col>
                    <Form.Group controlId="formFile">
                      <Form.Label>File</Form.Label>
                      <Form.Control
                        type="file"
                        name="file"
                        label={values.file?.name || 'Select Document...'}
                        onChange={(e) => {
                          setFieldValue('file', e.currentTarget.files[0]);
                        }}
                        isValid={touched.file && !errors.file}
                        isInvalid={touched.file && !!errors.file}
                        feedback={errors.file}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Stack>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={isBusy}>
          Save
          <Spinner
            animation="border"
            role="status"
            size="sm"
            className={`ms-2 ${isBusy ? '' : 'd-none'}`}
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
