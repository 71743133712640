import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Dash } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import ConnectionModal from '../components/ConnectionModal';
import AdminListLayout from '../Layouts/AdminListLayout';
import AddButton from 'components/Button/AddButton';
import TableLayout from 'components/Layouts/TableLayout';
import LoadingIndicator from 'components/LoadingIndicator';
import { useConnections } from 'hooks/connectionHooks';
import { usePagination } from 'hooks/tableHooks';

export function CommonColumn({ name, id }) {
  return (
    <div>
      <div>{name}</div>
      <div className="text-muted">{id}</div>
    </div>
  );
}

CommonColumn.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export function renderSourceColumn(props) {
  return (
    <CommonColumn
      name={props.row.original.configuration.sourceName}
      id={props.row.original.configuration.sourceId}
    />
  );
}

export function renderDestinationColumn(props) {
  return (
    <CommonColumn
      name={props.row.original.configuration.destinationName}
      id={props.row.original.configuration.destinationId}
    />
  );
}

export function renderClinicalSummaryColumn(props) {
  return (
    <CommonColumn
      name={
        props.row.original.configuration.clinicalSummarySourceName ?? <Dash />
      }
      id={props.row.original.configuration.clinicalSummarySourceId ?? <Dash />}
    />
  );
}

const COLUMNS = [
  {
    header: 'Source',
    cell: (props) => renderSourceColumn(props),
  },
  {
    header: 'Destination',
    cell: (props) => renderDestinationColumn(props),
  },
  {
    accessorKey: 'configuration.medicalRecordNumberIdentifier',
    header: 'MRN Identifier',
  },
  {
    header: 'Clinical Summary Source',
    cell: (props) => renderClinicalSummaryColumn(props),
  },
];

export default function ConnectionsList() {
  const navigate = useNavigate();
  const [pagination, setPagination] = usePagination();
  const [showModal, setShowModal] = useState(false);

  const { data: connections, isLoading } = useConnections(
    pagination.pageIndex,
    pagination.pageSize
  );

  const handleAddConnectionClick = () => {
    setShowModal(true);
  };

  const getRowProps = (row) => ({
    className: 'hoverable-row',
    onClick: () => navigate(`/admin/connections/${row.original.id}`),
  });

  return (
    <AdminListLayout
      topRow={
        <AddButton onClick={handleAddConnectionClick} label="Connection" />
      }
      table={
        isLoading ? (
          <LoadingIndicator />
        ) : (
          <TableLayout
            columns={COLUMNS}
            data={connections}
            pagination={pagination}
            setPagination={setPagination}
            getRowProps={getRowProps}
          />
        )
      }
      modal={
        showModal && <ConnectionModal onClose={() => setShowModal(false)} />
      }
    />
  );
}
