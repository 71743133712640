import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import RegistryDropdown from './RegistryDropdown';

function RegistrySection({ searchCriteria, onChange }) {
  return (
    <Row xs={2} className="gy-3">
      <Col>
        <RegistryDropdown value={searchCriteria.registry} onChange={onChange} />
      </Col>
    </Row>
  );
}

RegistrySection.propTypes = {
  searchCriteria: PropTypes.shape({ registry: PropTypes.number }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RegistrySection;
