import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';

export default function LoginError({ logout }) {
  return (
    <div className="w-100 position-absolute" style={{ top: '25vh' }}>
      <Stack gap={4} direction="column" className="text-center w-50 mx-auto">
        <h1>
          Access denied for ObjectiveScreen. Please contact&nbsp;
          <a href="mailto: ossupport@objective.health">
            ossupport@objective.health
          </a>
          &nbsp;for help.
        </h1>
        <div>
          <Button
            size="lg"
            onClick={() =>
              logout({ logoutParams: { openUrl: window.location.pathname } })
            }
          >
            Back to login page
          </Button>
        </div>
      </Stack>
    </div>
  );
}

LoginError.propTypes = {
  logout: PropTypes.func.isRequired,
};
