import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import PhoneNumber from 'components/FormikForm/PhoneNumber';

export default function PhoneField({
  label,
  name,

  labelClassName = undefined,
}) {
  const { handleChange, values, touched, errors } = useFormikContext();

  return (
    <Form.Group>
      <Form.Label className={labelClassName}>{label}</Form.Label>
      <PhoneNumber
        name={name}
        value={values[name] || ''}
        onChange={handleChange}
        isValid={touched[name] && !errors[name]}
        isInvalid={!!errors[name]}
      />
    </Form.Group>
  );
}

PhoneField.defaultProps = {
  labelClassName: undefined,
};

PhoneField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,

  labelClassName: PropTypes.string,
};
