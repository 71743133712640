import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, Button, Spinner, InputGroup } from 'react-bootstrap';
import * as yup from 'yup';

const MAX_CHARACTERS = 500;
const sendIcon = (
  <svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1">
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.5"
    >
      <g
        id="Patient-Overview-1"
        transform="translate(-1405.000000, -1306.000000)"
      >
        <g id="Group-18" transform="translate(1085.000000, 1279.500000)">
          <g id="Group-17" transform="translate(20.602104, 20.625000)">
            <g id="send_black_24dp" transform="translate(300.022896, 6.375000)">
              <polygon id="Path" points="0 0 24 0 24 24 0 24" />
              <polygon
                id="Path"
                fill="#000000"
                fillRule="nonzero"
                points="2.01 21 23 12 2.01 3 2 10 17 12 2 14"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const schema = yup.object({
  messageBody: yup.string().required().max(MAX_CHARACTERS),
});

/** @type {React.ForwardRefExoticComponent<any, any>} */
const TextMessageForm = React.forwardRef(
  (
    { handleFormSubmit, handleSubmitClick, disabled, isBusy, textPaneError },
    ref
  ) => (
    <Formik
      validationSchema={schema}
      onSubmit={handleFormSubmit}
      initialValues={{ messageBody: '' }}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (
        <Form noValidate onSubmit={handleSubmit} ref={ref}>
          <InputGroup>
            <Form.Control
              as="textarea"
              autoComplete="off"
              disabled={disabled}
              rows={3}
              type="text"
              name="messageBody"
              placeholder="Send a text..."
              value={values.messageBody}
              onChange={handleChange}
              isValid={
                touched.messageBody && !errors.messageBody && !textPaneError
              }
              isInvalid={!!errors.messageBody || !!textPaneError}
            />
            <Button
              variant=""
              onClick={() => handleSubmitClick()}
              disabled={disabled || isBusy}
              className="pe-0 border-start-0 border-lighter"
            >
              {isBusy ? (
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  className="ms-2"
                />
              ) : (
                sendIcon
              )}
            </Button>
          </InputGroup>
        </Form>
      )}
    </Formik>
  )
);

export default TextMessageForm;

TextMessageForm.defaultProps = {
  textPaneError: undefined,
};

TextMessageForm.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  handleSubmitClick: PropTypes.func.isRequired,
  isBusy: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  textPaneError: PropTypes.string,
};
