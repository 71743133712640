import { useQuery } from '@tanstack/react-query';
import axios from '../axios';

export default function useStatusGroupMetrics(
  siteId,
  distanceInMiles,
  studyId
) {
  return useQuery(
    ['status-group-metrics', siteId, distanceInMiles, studyId],
    () =>
      axios
        .get('/api/status-groups/metrics', {
          params: { siteId, distanceInMiles, studyId },
        })
        .then((res) => res.data),
    {
      enabled: Boolean(siteId) && Boolean(studyId),
      keepPreviousData: true,
    }
  );
}
