import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

import TableLayout from 'components/Layouts/TableLayout';

export default function SiteIndicationsTable({
  indications,
  confirmRemoveIndication,
}) {
  const columns = useMemo(
    () => [
      {
        accessorKey: 'displayName',
        header: 'Name',
      },
      {
        header: 'Actions',
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { style: { width: '60px' }, className: 'text-center' },
        },
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row }) => (
          <Button
            size="sm"
            variant="dark"
            className="btn-lg btn-block"
            onClick={() => confirmRemoveIndication(row.original)}
          >
            <Trash />
          </Button>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <TableLayout columns={columns} data={indications} />;
}

SiteIndicationsTable.propTypes = {
  indications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  confirmRemoveIndication: PropTypes.func.isRequired,
};
