import React, { useState } from 'react';
import { Form, FormCheck, Stack } from 'react-bootstrap';
import { Dash } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import SiteModal from '../components/SiteModal';
import AdminListLayout from '../Layouts/AdminListLayout';
import AddButton from 'components/Button/AddButton';
import TableLayout from 'components/Layouts/TableLayout';
import LoadingIndicator from 'components/LoadingIndicator';
import { useSiteMetrics } from 'hooks/siteHooks';
import { usePagination } from 'hooks/tableHooks';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

const SITE_TEMPLATE = {
  id: null,
  name: '',
  createdAt: Date.now(),
  active: true,
};

const COLUMNS = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'userCount',
    header: 'Users',
    meta: {
      headerProps: { className: 'col-1 text-center' },
      cellProps: { className: 'text-center' },
    },
    cell: ({ getValue }) => getValue() || <Dash />,
  },
  {
    accessorKey: 'studyCount',
    header: 'Studies',
    meta: {
      headerProps: { className: 'col-1 text-center' },
      cellProps: { className: 'text-center' },
    },
    cell: ({ getValue }) => getValue() || <Dash />,
  },
  {
    accessorKey: 'createdAt',
    header: 'Date Created',
    meta: {
      headerProps: { className: 'col-1 text-center' },
      cellProps: { className: 'text-center' },
    },
    cell: ({ getValue }) => formatDateDisplay(getValue()),
  },
];

const paginationOptions = {
  pageIndex: 0,
  pageSize: 25,
};

export default function SitesTable() {
  const navigate = useNavigate();
  const [pagination, setPagination] = usePagination(paginationOptions);
  const [showActive, setShowActive] = useState(true);

  const [showModal, setShowModal] = useState(false);

  const { data, isLoading } = useSiteMetrics(
    pagination.pageIndex,
    pagination.pageSize,
    showActive
  );

  const handleShowActive = (value) => {
    setShowActive(value);
    setPagination(paginationOptions);
  };

  const addSite = () => {
    setShowModal(true);
  };

  const getRowProps = (row) => ({
    className: 'hoverable-row',
    onClick: () => navigate(`/admin/sites/${row.original.siteId}`),
  });

  return (
    <AdminListLayout
      topRow={<AddButton label="Site" onClick={addSite} />}
      table={
        isLoading ? (
          <LoadingIndicator />
        ) : (
          <Stack gap={2}>
            <Form direction="horizontal" gap={3} as={Stack}>
              <FormCheck
                type="radio"
                label="Active"
                checked={showActive}
                onChange={() => handleShowActive(true)}
              />
              <FormCheck
                className="ms-3"
                type="radio"
                label="Inactive"
                checked={!showActive}
                onChange={() => handleShowActive(false)}
              />
            </Form>
            <TableLayout
              columns={COLUMNS}
              data={data}
              pagination={pagination}
              setPagination={setPagination}
              getRowProps={getRowProps}
            />
          </Stack>
        )
      }
      modal={
        showModal && (
          <SiteModal site={SITE_TEMPLATE} setShowSiteModal={setShowModal} />
        )
      }
    />
  );
}
