import { useMutation } from '@tanstack/react-query';
import axios from '../axios';

const feasibilitySearch = async (query) => {
  const result = await axios.post('/api/feasibility-search', query);
  return result.data;
};

export function useFeasibilitySearch(options) {
  return useMutation({
    mutationFn: feasibilitySearch,
    onSuccess: (results) => {
      options?.onSuccess?.(results);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const querySearch = async (query) => {
  const result = await axios.post('/api/admin/query-search', query);
  return result.data;
};

export function useQuerySearch(options) {
  return useMutation({
    mutationFn: querySearch,
    onSuccess: (results) => {
      options?.onSuccess?.(results);
    },
    onError: (error) => options?.onError?.(error),
  });
}

export default {
  useFeasibilitySearch,
  useQuerySearch,
};
