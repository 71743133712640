import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';

const SettingsPopover = React.forwardRef(({ ...props }, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Popover {...props} ref={ref} className="patient-note">
    <Popover.Header>Settings - Read Only</Popover.Header>
    <Popover.Body>
      To change these settings, contact customer support.
    </Popover.Body>
  </Popover>
));

export default function InfoIconWithPopover() {
  return (
    <OverlayTrigger placement="right" overlay={<SettingsPopover />}>
      <InfoCircle />
    </OverlayTrigger>
  );
}
