import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';

export function usePatientDiagnoses(patientId) {
  return useQuery(patientId && ['patientDiagnoses', patientId], () =>
    axios.get(`/api/patients/${patientId}/diagnoses`).then((res) => res.data)
  );
}

const createPatientDiagnosis = async (values) => {
  const { patientId, ...diagnosis } = values;
  const response = await axios.post(
    `/api/patients/${patientId}/diagnoses`,
    diagnosis
  );
  return response.data;
};

export function useCreatePatientDiagnosis(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPatientDiagnosis,
    onSuccess: (diagnosis) => {
      queryClient.invalidateQueries(['patientDiagnoses', diagnosis.patientId]);
      queryClient.invalidateQueries(['patient', diagnosis.patientId]);

      options?.onSuccess?.(diagnosis);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const updatePatientDiagnosis = async (values) => {
  const { patientId, ...diagnosis } = values;
  const response = await axios.put(
    `/api/patients/${patientId}/diagnoses`,
    diagnosis
  );
  return response.data;
};

export function useUpdatePatientDiagnosis(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updatePatientDiagnosis,
    onSuccess: (diagnosis) => {
      queryClient.invalidateQueries(['patientDiagnoses', diagnosis.patientId]);
      queryClient.invalidateQueries(['patient', diagnosis.patientId]);

      options?.onSuccess?.(diagnosis);
    },
    onError: (error) => options?.onError?.(error),
  });
}

const deletePatientDiagnosis = async (values) => {
  const { patientId, id } = values;
  await axios.delete(`/api/patients/${patientId}/diagnoses`, {
    data: { id },
  });

  return { patientId };
};

export function useDeletePatientDiagnosis(options) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePatientDiagnosis,
    onSuccess: ({ patientId }) => {
      queryClient.invalidateQueries(['patientDiagnoses', patientId]);
      queryClient.invalidateQueries(['patient', patientId]);

      options?.onSuccess?.({ patientId });
    },
    onError: (error) => options?.onError?.(error),
  });
}
