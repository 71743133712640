import PropTypes from 'prop-types';
import React from 'react';
import { Alert } from 'react-bootstrap';
import { REASON_SCHEMA } from './constants';
import CheckField from 'components/FormikForm/CheckField';
import TextField from 'components/FormikForm/TextField';
import ModalForm from 'components/ModalForm';

function ReasonModal({
  title,
  onClose,
  onSubmit,
  isSubmitting,
  initialValues,
  errorMsg = '',
}) {
  return (
    <ModalForm
      title={title}
      onClose={onClose}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      validationSchema={REASON_SCHEMA}
      formId={`org-reason-${title}`}
    >
      <Alert variant="danger" show={!!errorMsg}>
        {errorMsg}
      </Alert>
      <TextField focus label="Reason Name" name="name" />
      <CheckField name="active" label="Active" />
    </ModalForm>
  );
}

ReasonModal.defaultProps = {
  errorMsg: '',
};

ReasonModal.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  errorMsg: PropTypes.string,
};

export default ReasonModal;
