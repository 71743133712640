/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import PropTypes from 'prop-types';

import React, { useContext, useMemo, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Dash, EyeFill, ShieldLock, TrashFill } from 'react-bootstrap-icons';
import axios from '../../../../../../../axios';

import ConfirmationDialog from 'components/ConfirmationDialog';
import TableLayout from 'components/Layouts/TableLayout';
import { UserContext, Roles } from 'components/Provider/UserProvider';
import ReadonlyIcon from 'components/ReadonlyIcon';
import { useDocumentTypes } from 'hooks/documentTypeHooks';
import {
  usePatientDocuments,
  useDeletePatientDocument,
} from 'hooks/patientDocumentHooks';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

function DocumentList({ patientId }) {
  const [deletedId, setDeletedId] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const { userRole } = useContext(UserContext);

  const { data: documents, isLoading } = usePatientDocuments(patientId);
  const { data: documentTypes, isLoading: isDocumentTypesLoading } =
    useDocumentTypes();

  const deleteDocument = useDeletePatientDocument({
    onSuccess: () => {
      setDeletedId(0);
      setShowConfirm(false);
    },
  });

  const confirmDelete = (itemId) => {
    setDeletedId(itemId);
    setShowConfirm(true);
  };

  const deleteConfirmed = () =>
    deleteDocument.mutate({ patientId, id: deletedId });

  const viewDocument = async (itemId) => {
    const document = await axios({
      url: `/api/patients/${patientId}/documents/${itemId}`,
      method: 'GET',
      responseType: 'blob',
    });

    const url = URL.createObjectURL(
      new Blob([document.data], { type: document.headers['content-type'] })
    );

    window.open(url);
    URL.revokeObjectURL(url);
  };

  const columns = useMemo(() => {
    const config = [
      {
        accessorKey: 'external',
        header: () => <ShieldLock className="ms-0" />,
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { className: 'text-center', style: { width: '40px' } },
        },
        cell: (props) => (
          <ReadonlyIcon
            readonly={props.row.original.external}
            tooltip="External"
          />
        ),
      },
      {
        accessorKey: 'createdAt',
        header: 'DATE',
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { className: 'text-center', style: { width: '150px' } },
        },
        cell: (props) => formatDateDisplay(props.getValue()),
      },
      {
        accessorKey: 'title',
        header: 'TITLE',
      },
      {
        accessorKey: 'filename',
        header: 'FILENAME',
        meta: {
          cellProps: { style: { width: '30%' } },
        },
      },
      {
        accessorKey: 'documentTypeId',
        header: 'TYPE',
        meta: {
          cellProps: { style: { width: '260px' } },
        },
        cell: (props) =>
          documentTypes.find(
            (docType) => docType.id === Number(props.getValue())
          )?.name ?? <Dash />,
      },
      {
        accessorFn: (row) => row.createdBy?.fullName,
        header: 'USER',
        meta: { headerProps: { style: { width: '200px' } } },
        cell: (props) => props.getValue() ?? <Dash />,
      },
      {
        header: 'ACTIONS',
        meta: {
          headerProps: { className: 'text-center', style: { width: '100px' } },
          cellProps: { className: 'text-center' },
        },
        cell: (props) => (
          <>
            <Button
              variant=""
              disabled={props.row.original.external}
              onClick={() => viewDocument(props.row.original.id)}
              size="sm"
            >
              <EyeFill />
            </Button>
            {Roles.canModifyRecords(userRole) && (
              <Button
                variant=""
                onClick={() => confirmDelete(props.row.original.id)}
                size="sm"
              >
                <TrashFill />
              </Button>
            )}
          </>
        ),
      },
    ];

    return config;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole, viewDocument, documents]);

  return (
    <Row>
      <Col>
        <TableLayout
          data={documents}
          isLoading={isLoading || isDocumentTypesLoading}
          columns={columns}
          noResultsText="0 documents. Click Add Document to add new records."
        />

        <ConfirmationDialog
          show={showConfirm}
          title="Confirm Delete"
          bodyText="Are you sure you want to delete this document?"
          confirmText="Delete"
          onConfirm={deleteConfirmed}
          onCancel={() => setShowConfirm(false)}
        />
      </Col>
    </Row>
  );
}

DocumentList.propTypes = {
  patientId: PropTypes.number.isRequired,
};

export default DocumentList;
