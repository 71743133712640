import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Row, Col, Form, FormSelect, Button, Spinner } from 'react-bootstrap';
import * as yup from 'yup';
import { useSearchStudies } from 'hooks/studyHooks';

const initialValues = {
  query: '',
  active: false,
  studyId: 0,
};

const CONFIGURATION_SCHEMA = yup.object({
  query: yup
    .object()
    .json()
    .required('Query is required and must be a valid JSON string')
    .typeError('Query must be a valid JSON string.'),
  active: yup.boolean().required(),
  studyId: yup.number().min(1, 'A study is required.').required(),
});

export default function SearchForm({
  configuration,
  onStudyChange,
  onSubmit,
  onSearch,
  disabled,
  isBusy,
}) {
  const formRef = useRef(null);
  const studies = useSearchStudies();

  const handleSave = () => {
    formRef.current.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={CONFIGURATION_SCHEMA}
      onSubmit={onSubmit}
      initialValues={{ ...initialValues, ...configuration }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <Form noValidate onSubmit={handleSubmit} ref={formRef}>
          <Row className="align-items-center">
            <Col>
              <Form.Check
                label="Active"
                type="switch"
                name="active"
                checked={values.active}
                onChange={handleChange}
                isInvalid={!!errors.active}
              />
            </Col>
            <Col>
              <FormSelect
                name="studyId"
                value={values.studyId || 0}
                onChange={(event) => onStudyChange(event, setFieldValue)}
                isInvalid={!!errors.studyId}
              >
                <option key="0" value="">
                  Select Status
                </option>
                {studies.data?.map((study) => (
                  <option key={`study-${study.id}`} value={study.id}>
                    {study.name}
                  </option>
                ))}
              </FormSelect>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Control
                id="formQuery"
                as="textarea"
                disabled={!values.studyId}
                rows={15}
                name="query"
                placeholder="Type your OpenSearch query body here..."
                value={values.query}
                onChange={handleChange}
                isInvalid={!!errors.query}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <ul className="text-danger">
                {Object.entries(errors).map((entry) => (
                  <li key={entry[0]}>{entry[1].toString()}</li>
                ))}
              </ul>
            </Col>
            <Col xs={2}>
              <Button
                variant="primary"
                onClick={handleSave}
                className="w-100"
                disabled={disabled}
              >
                Save
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  className={`ms-2 ${isBusy ? '' : 'd-none'}`}
                />
              </Button>
            </Col>
            <Col xs={2}>
              <Button
                variant="dark"
                onClick={onSearch}
                className="w-100"
                disabled={disabled}
              >
                Test Query
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
SearchForm.defaultProps = {
  configuration: {},
};

SearchForm.propTypes = {
  configuration: PropTypes.shape({
    active: PropTypes.bool,
    studyId: PropTypes.number,
    query: PropTypes.string,
  }),
  onStudyChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isBusy: PropTypes.bool.isRequired,
};
