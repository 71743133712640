import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, FormSelect } from 'react-bootstrap';

export default function SelectField({
  label,
  name,
  defaultDisplay,
  options = [],
  valueAccessor,
  displayAccessor,
  labelClassName = undefined,
  controlClassName = undefined,
  disabled = false,
  additionalOnChange,
}) {
  const { handleChange, values, touched, errors } = useFormikContext();

  const innerOnChange = (e) => {
    additionalOnChange?.(e);
    handleChange(e);
  };

  return (
    <Form.Group controlId={name}>
      {label && <Form.Label className={labelClassName}>{label}</Form.Label>}
      <FormSelect
        className={controlClassName}
        name={name}
        value={values[name] || ''}
        onChange={innerOnChange}
        isValid={touched[name] && !errors[name]}
        isInvalid={!!errors[name]}
        disabled={disabled}
      >
        <option value="">{defaultDisplay}</option>
        {options.map((x) => (
          <option key={x[valueAccessor]} value={x[valueAccessor]}>
            {x[displayAccessor]}
          </option>
        ))}
      </FormSelect>
    </Form.Group>
  );
}

SelectField.defaultProps = {
  labelClassName: undefined,
  controlClassName: undefined,
  disabled: false,
  label: undefined,
  additionalOnChange: undefined,
  options: [],
};

SelectField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  defaultDisplay: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  valueAccessor: PropTypes.string.isRequired,
  displayAccessor: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  controlClassName: PropTypes.string,
  disabled: PropTypes.bool,
  additionalOnChange: PropTypes.func,
};
