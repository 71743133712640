import PropTypes from 'prop-types';
import React from 'react';
import Field from './Field';
import MultiSelect from 'components/MultiSelect';

export default function MultiSelectField({
  name,
  onChange,
  selectedValues,
  className,
  placeholderText,
  options,
}) {
  return (
    <Field label={name}>
      <MultiSelect
        name={name}
        onChange={onChange}
        selectedValues={selectedValues}
        className={className}
        placeholderText={placeholderText}
        options={options}
      />
    </Field>
  );
}

MultiSelectField.defaultProps = {
  className: undefined,
};

MultiSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  className: PropTypes.string,
  placeholderText: PropTypes.string.isRequired,
};
