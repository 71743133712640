import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

const StudyAdminContext = createContext();

const studyTemplate = {
  id: 0,
  sponsor: '',
  protocol: '',
  name: '',
  marketName: '',
  indicationId: '',
  encounterTypeId: '',
  isRegistry: false,
  active: false,
  isObservational: false,
};

function StudyAdminProvider({ children }) {
  const [showStudyModal, setShowStudyModal] = useState(false);
  const [study, setStudy] = useState(studyTemplate);

  const addStudy = () => {
    setStudy(studyTemplate);
    setShowStudyModal(true);
  };

  return (
    <StudyAdminContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        study,
        addStudy,
        showStudyModal,
        setShowStudyModal,
      }}
    >
      {children}
    </StudyAdminContext.Provider>
  );
}

StudyAdminProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StudyAdminProvider;
export { StudyAdminContext, StudyAdminProvider };
