import PropTypes from 'prop-types';
import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import StudyStatuses from 'entities/StudyStatuses';
import { useStudyStatuses } from 'hooks/studyStatusHooks';
import './StudyStatusDropdown.scss';

const DISABLED_STATUSES = [
  StudyStatuses.NOT_ELIGIBLE,
  StudyStatuses.ELIGIBLE,
  StudyStatuses.RECOMMENDED,
  StudyStatuses.SCREEN_PENDING,
];
const RED_STATUSES = [StudyStatuses.SCREEN_FAIL, StudyStatuses.NOT_ELIGIBLE];

export default function StudyStatusDropdown({
  studyStatus,
  disabled,
  onChange,
}) {
  const studyStatuses = useStudyStatuses();

  const getVariant = () => {
    if (!studyStatus) {
      return 'outline-info';
    }

    return RED_STATUSES.includes(studyStatus.name) ? 'danger' : 'info';
  };

  if (!studyStatuses.data) return null;

  return (
    <DropdownButton
      className="study-status-toggle"
      title={studyStatus?.name ?? 'No Chart Review Completed'}
      align="end"
      size="sm"
      variant={getVariant()}
      onSelect={(id) => onChange(id)}
      disabled={disabled}
    >
      {studyStatuses.data.map((s) => (
        <Dropdown.Item
          key={s.id}
          disabled={DISABLED_STATUSES.includes(s.name)}
          as="button"
          eventKey={s.id}
        >
          {s.name}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
}

StudyStatusDropdown.defaultProps = {
  studyStatus: {},
};

StudyStatusDropdown.propTypes = {
  studyStatus: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

StudyStatusDropdown.defaultProps = {
  studyStatus: undefined,
};
