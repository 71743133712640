import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, ToastContainer, Toast, Modal } from 'react-bootstrap';
import { CheckCircleFill, XCircleFill } from 'react-bootstrap-icons';

import { useCreatePatientResearchProfile } from 'hooks/patientResearchProfileHooks';

export default function CrioModal({ show, onHide, patient }) {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showDanger, setShowDanger] = useState(false);

  const createPatientResearchProfile = useCreatePatientResearchProfile({
    onSuccess: () => {
      setShowSuccess(true);
    },
    onError: () => {
      setShowDanger(true);
    },
  });

  const handleAddResearchProfile = async (patientId, siteId) => {
    const values = { patientId, siteId };
    createPatientResearchProfile.mutate(values);
  };

  return (
    <>
      <Modal show={show} onHide={onHide} size="md" centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Patient to CRIO</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-grid gap-2">
            {patient.sites.map((site) => (
              <Button
                variant="primary"
                key={site.id}
                onClick={() => handleAddResearchProfile(patient.id, site.id)}
                disabled={
                  createPatientResearchProfile.isLoading ||
                  patient.researchProfiles.some((rp) => rp.siteId === site.id)
                }
              >
                {site.name}
              </Button>
            ))}
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-end" style={{ marginTop: '12px' }}>
        <Toast
          bg="success"
          show={showSuccess}
          delay={3000}
          onClose={() => setShowSuccess(false)}
          autohide
          className="shadow"
        >
          <Toast.Header>
            <CheckCircleFill fill="green" size={20} />
            <p className="ms-2 my-auto me-auto text-black">
              <strong>Success</strong>: Request sent to add patient to CRIO.
            </p>
          </Toast.Header>
        </Toast>

        <Toast
          bg="danger"
          show={showDanger}
          delay={3000}
          onClose={() => setShowDanger(false)}
          autohide
          className="shadow"
        >
          <Toast.Header>
            <XCircleFill fill="red" size={20} />
            <p className="ms-2 my-auto me-auto text-black">
              <strong>Error</strong>: Failure to add patient to CRIO.
            </p>
          </Toast.Header>
        </Toast>
      </ToastContainer>
    </>
  );
}

CrioModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  patient: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sites: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    researchProfiles: PropTypes.arrayOf(
      PropTypes.shape({
        siteId: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
};
