/* eslint-disable react/no-unstable-nested-components */
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Dash, ShieldLock } from 'react-bootstrap-icons';
import {
  ACTION_BUTTON_TYPES,
  ActionButton,
} from 'components/Button/ActionButton';
import TableLayout from 'components/Layouts/TableLayout';
import ReadonlyIcon from 'components/ReadonlyIcon';
import { formatDateDisplay } from 'pages/utils/dateTimeUtils';

const formatNameDisplay = (item) => {
  const fields = [
    item.customName || item.medicationCode?.name,
    item.dosage,
    item.units,
    item.route,
    item.medicationFrequency?.name,
  ];

  return fields.filter(Boolean).join(' ');
};

export default function PatientMedicationsTable({
  medications,
  onEdit,
  onDelete,
  editDisabled,
  deleteDisabled,
}) {
  const columns = useMemo(
    () => [
      {
        accessorKey: 'external',
        header: () => <ShieldLock className="ms-0" />,
        meta: {
          headerProps: { className: 'text-center', style: { width: '40px' } },
          cellProps: { className: 'text-center' },
        },
        cell: ({ row }) => (
          <ReadonlyIcon
            readonly={row.original.external}
            showDash
            tooltip="External"
          />
        ),
      },
      {
        accessorKey: 'name',
        header: 'MEDICATION',
        cell: ({ row: { original } }) => formatNameDisplay(original),
      },
      {
        accessorKey: 'reason',
        header: 'REASON',
        cell: ({ getValue }) => (getValue() ? getValue() : <Dash />),
      },

      {
        accessorKey: 'startDate',
        header: 'START DATE',
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { className: 'text-center' },
        },
        cell: ({ getValue }) =>
          getValue() ? formatDateDisplay(getValue()) : <Dash />,
      },
      {
        accessorKey: 'stopDate',
        header: 'STOP DATE',
        meta: {
          headerProps: { className: 'text-center' },
          cellProps: { className: 'text-center' },
        },
        cell: ({ getValue }) =>
          getValue() ? formatDateDisplay(getValue()) : <Dash />,
      },
      {
        accessorFn: (row) => row.createdBy?.fullName,
        header: 'CREATED BY',
        cell: ({ getValue }) => (getValue() ? getValue() : <Dash />),
      },
      {
        header: 'ACTIONS',
        meta: {
          headerProps: { className: 'text-center', style: { width: '100px' } },
          cellProps: { className: 'text-center small' },
        },
        cell: ({ row }) => (
          <>
            <ActionButton
              disabled={
                editDisabled ||
                row.original.external ||
                !!row.original.patientEncounterId
              }
              onClick={() => onEdit(row.original)}
              action={ACTION_BUTTON_TYPES.EDIT}
            />

            <ActionButton
              action={ACTION_BUTTON_TYPES.DELETE}
              disabled={
                deleteDisabled ||
                row.original.external ||
                !!row.original.patientEncounterId
              }
              onClick={() => onDelete(row.original.id)}
            />
          </>
        ),
      },
    ],
    [deleteDisabled, editDisabled, onDelete, onEdit]
  );

  return (
    medications && (
      <Row>
        <Col>
          <TableLayout
            columns={columns}
            data={medications}
            noResultsText="0 medications. Click Add Medication to add new records."
          />
        </Col>
      </Row>
    )
  );
}

PatientMedicationsTable.propTypes = {
  medications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  editDisabled: PropTypes.bool.isRequired,
  deleteDisabled: PropTypes.bool.isRequired,
};
