import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import SiteStudiesTable from './SiteStudiesTable';
import ConfirmationDialog from 'components/ConfirmationDialog';
import LoadingIndicator from 'components/LoadingIndicator';
import { useSiteStudies, useDeleteSiteStudy } from 'hooks/siteStudyHooks';

function ConfirmationBody(studyName) {
  return (
    <span>
      Are you sure you want to remove the{' '}
      <span className="fw-bold">{studyName}</span> study from the site?
    </span>
  );
}

export default function SiteStudyList({ siteId }) {
  const [showConfirmRemoveStudy, setShowConfirmRemoveStudy] = useState(false);
  const [selectedSiteStudy, setSelectedSiteStudy] = useState();
  const [confirmationBody, setConfirmationBody] = useState('');

  const studies = useSiteStudies(siteId, true);

  const deleteSiteStudy = useDeleteSiteStudy({
    onSuccess: () => {
      setShowConfirmRemoveStudy(false);
    },
  });

  const confirmRemoveStudy = (study) => {
    const newConfirmationBody = ConfirmationBody(study.name);

    setConfirmationBody(newConfirmationBody);

    setSelectedSiteStudy(study.SiteStudy);
    setShowConfirmRemoveStudy(true);
  };

  const handleRemove = () =>
    deleteSiteStudy.mutate({
      studyId: parseInt(selectedSiteStudy.studyId, 10),
      siteId: parseInt(selectedSiteStudy.siteId, 10),
    });

  if (studies.isLoading) return <LoadingIndicator />;

  return (
    <>
      <Row>
        <Col>
          <h5>Current Studies</h5>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          {studies.data.length ? (
            <SiteStudiesTable
              studies={studies.data}
              confirmRemoveStudy={confirmRemoveStudy}
            />
          ) : (
            <span className="fst-italic">Site has no assigned studies...</span>
          )}
        </Col>
      </Row>
      <ConfirmationDialog
        show={showConfirmRemoveStudy}
        title="Confirm Remove"
        bodyText={confirmationBody}
        confirmText="Remove"
        onConfirm={handleRemove}
        onCancel={() => setShowConfirmRemoveStudy(false)}
      />
    </>
  );
}

SiteStudyList.propTypes = {
  siteId: PropTypes.number.isRequired,
};
