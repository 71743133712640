import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button, FormCheck } from 'react-bootstrap';
import { Dash, Plus } from 'react-bootstrap-icons';
import MedicationButton from './MedicationButton';
import MedicationModal from './MedicationModal';
import { MedicationContext } from './MedicationProvider';
import PatientMedicationsTable from './PatientMedicationsTable';
import ConfirmationDialog from 'components/ConfirmationDialog';
import LoadingIndicator from 'components/LoadingIndicator';
import { UserContext, Roles } from 'components/Provider/UserProvider';
import ROLES from 'entities/Roles';
import {
  usePatientMedications,
  useDeletePatientMedication,
  useLatestEncounterMedications,
} from 'hooks/patientMedicationHooks';
import { partition } from 'pages/utils/helpers';

const LIST_LENGTH = 10;

function MedicationList({ patientId }) {
  const [show, setShow] = useState(false);
  const { editMedication } = useContext(MedicationContext);
  const { userRole } = useContext(UserContext);

  const [selectedItemId, setSelectedItemId] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [showLength, setShowLength] = useState(LIST_LENGTH);
  const [medicationView, setMedicationView] = useState('active');

  const { data: medications, isLoading: medicationsLoading } =
    usePatientMedications(patientId);
  const { data: encounterMedications, isLoading: encounterMedicationsLoading } =
    useLatestEncounterMedications(patientId);

  const deleteMedication = useDeletePatientMedication({
    onSuccess: () => {
      setSelectedItemId(0);
      setShowConfirm(false);
    },
  });

  const onToggleMedicationView = (event) => {
    setMedicationView(event.target.value);
  };

  const confirmDelete = (itemId) => {
    setSelectedItemId(itemId);
    setShowConfirm(true);
  };

  const deleteConfirmed = () =>
    deleteMedication.mutate({ patientId, id: selectedItemId });

  const [activeMedications, historicalMedications] = partition(
    medications?.filter((m) => !m.patientEncounterId), // only medications that are not in an encounter
    (x) => !x.stopDate || DateTime.fromISO(x.stopDate).toJSDate() > new Date() // split by active (no stop date or stop date in future) and historical (has stop date)
  );

  useEffect(() => {
    if (
      !encounterMedicationsLoading &&
      !medicationsLoading &&
      (activeMedications.length || encounterMedications.length)
    )
      setShow(true);
  }, [
    activeMedications?.length,
    encounterMedications?.length,
    encounterMedicationsLoading,
    medicationsLoading,
  ]);

  const toggleShowAll = () => {
    const updatedShowAll = !showAll;
    let updatedShowLength = LIST_LENGTH;

    if (updatedShowAll && medicationView === 'active') {
      updatedShowLength = activeMedications.length;
    } else if (updatedShowAll && medicationView === 'historical') {
      updatedShowLength = historicalMedications.length;
    } else if (updatedShowAll && medicationView === 'latestEncounter') {
      updatedShowLength = encounterMedications.length;
    }

    setShowAll(updatedShowAll);
    setShowLength(updatedShowLength);
  };

  const getMedicationsToShow = () => {
    switch (medicationView) {
      case 'active':
        return activeMedications.slice(0, showLength);
      case 'historical':
        return historicalMedications.slice(0, showLength);
      case 'latestEncounter':
        return encounterMedications.slice(0, showLength);
      default:
        return [];
    }
  };

  if (encounterMedicationsLoading || medicationsLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="border-bottom border-lighter mt-2">
      <Row className="align-items-center">
        <Col className="d-flex align-items-center">
          <div className="display-3">Medications</div>
          <Button variant="" className="ms-1" onClick={() => setShow(!show)}>
            {show ? <Dash size={24} /> : <Plus size={24} />}
          </Button>
        </Col>
        {ROLES.canModifyRecords(userRole) && (
          <Col
            xs={3}
            className="align-items-center text-end"
            style={{ width: '175px' }}
          >
            <MedicationButton className="w-100" />
          </Col>
        )}
      </Row>
      <Row className="mt-2">
        <Col>
          {show && (
            <div>
              <Row>
                <Col>
                  <FormCheck
                    className="align-middle"
                    inline
                    label="Active"
                    name="medication-view"
                    type="radio"
                    value="active"
                    onChange={onToggleMedicationView}
                    id="medication-view-active"
                    checked={medicationView === 'active'}
                  />
                  <FormCheck
                    className="align-middle"
                    inline
                    label="Historical"
                    name="medication-view"
                    type="radio"
                    value="historical"
                    onChange={onToggleMedicationView}
                    id="medication-view-historical"
                    checked={medicationView === 'historical'}
                  />
                  <FormCheck
                    className="align-middle"
                    inline
                    label="Last Visit"
                    name="medication-view"
                    type="radio"
                    value="latestEncounter"
                    onChange={onToggleMedicationView}
                    id="medication-view-lastest-encounter"
                    checked={medicationView === 'latestEncounter'}
                  />
                </Col>
              </Row>
              <Row className="mt-1">
                <Col>
                  <PatientMedicationsTable
                    medications={getMedicationsToShow()}
                    onEdit={editMedication}
                    onDelete={confirmDelete}
                    editDisabled={!Roles.canModifyRecords(userRole)}
                    deleteDisabled={!Roles.canModifyRecords(userRole)}
                  />
                  {((medicationView === 'active' &&
                    activeMedications.length > LIST_LENGTH) ||
                    (medicationView === 'historical' &&
                      historicalMedications.length > LIST_LENGTH) ||
                    (medicationView === 'latestEncounter' &&
                      encounterMedications.length > LIST_LENGTH)) && (
                    <Button variant="link" onClick={toggleShowAll}>
                      {showAll ? 'Show less' : 'Show more'}
                    </Button>
                  )}
                  <ConfirmationDialog
                    show={showConfirm}
                    title="Confirm Delete"
                    bodyText="Are you sure you want to delete this medication?"
                    confirmText="Delete"
                    onConfirm={deleteConfirmed}
                    onCancel={() => setShowConfirm(false)}
                  />
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>
      <MedicationModal />
    </div>
  );
}

MedicationList.propTypes = {
  patientId: PropTypes.number.isRequired,
};

export default MedicationList;
