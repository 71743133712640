import PropTypes from 'prop-types';
import React from 'react';
import { FormSelect, FormGroup, FormLabel } from 'react-bootstrap';
import './SchedulePane.scss';

export default function EncounterDurationSelect({
  value,
  onChange,
  disabled,
  isValid,
  isInvalid,
  label,
  name,
  className,
}) {
  return (
    <FormGroup controlId={`form-${name}`} className={className}>
      {label && <FormLabel>{label}</FormLabel>}
      <FormSelect
        name={name}
        value={value}
        onChange={onChange}
        isValid={isValid}
        isInvalid={isInvalid}
        disabled={disabled}
      >
        <option value={15}>15 minutes</option>
        <option value={30}>30 minutes</option>
        <option value={45}>45 minutes</option>
        <option value={60}>1 hour</option>
      </FormSelect>
    </FormGroup>
  );
}

EncounterDurationSelect.defaultProps = {
  className: undefined,
  label: undefined,
  isInvalid: undefined,
  isValid: undefined,
  disabled: undefined,
};

EncounterDurationSelect.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};
