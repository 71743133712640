import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';

export default function CheckField({
  label,
  name,
  disabled = false,
  type = 'checkbox',
  focus = false,
  className = undefined,
  additionalOnChange = undefined,
}) {
  const { handleChange, values } = useFormikContext();

  const innerOnChange = (e) => {
    additionalOnChange?.(e);
    handleChange(e);
  };

  return (
    <Form.Group controlId={name}>
      <Form.Check
        autoFocus={focus}
        className={className}
        type={type}
        name={name}
        label={label}
        checked={values[name]}
        disabled={disabled}
        onChange={innerOnChange}
      />
    </Form.Group>
  );
}

CheckField.defaultProps = {
  type: 'checkbox',
  focus: false,
  className: undefined,
  disabled: false,
  additionalOnChange: undefined,
};

CheckField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['checkbox', 'switch']),
  focus: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  additionalOnChange: PropTypes.func,
};
