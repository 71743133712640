import PropTypes from 'prop-types';
import React from 'react';
import SiteReasonsSection from './SiteReasonsSection';

import { useCancellationReasons } from 'hooks/cancellationReasonHooks';
import {
  useSiteCancellationReasons,
  useCreateSiteCancellationReason,
  useDeleteSiteCancellationReason,
} from 'hooks/siteCancellationReasonHooks';

function CancellationReasonsSection({ siteId }) {
  return (
    <SiteReasonsSection
      siteId={siteId}
      reasonType="Cancellation Reason"
      useSiteReasons={useSiteCancellationReasons}
      useOrgReasons={useCancellationReasons}
      useCreateSiteReason={useCreateSiteCancellationReason}
      useDeleteSiteReason={useDeleteSiteCancellationReason}
    />
  );
}

CancellationReasonsSection.propTypes = {
  siteId: PropTypes.number.isRequired,
};

export default CancellationReasonsSection;
