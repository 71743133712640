import PropTypes from 'prop-types';
import React from 'react';
import { Dash } from 'react-bootstrap-icons';
import TableLayout from 'components/Layouts/TableLayout';
import { useEncounterTypes } from 'hooks/encounterHooks';
import { formatDateTimeDisplay } from 'pages/utils/dateTimeUtils';

const getValue = (props) => props.getValue() ?? <Dash />;
const getDateTime = (props) =>
  props.getValue() ? formatDateTimeDisplay(props.getValue()) : <Dash />;

const getEncounterTypeName = (props, encounterTypes) => {
  const id = props.getValue();

  return encounterTypes?.find((type) => type.id === id)?.name;
};

function CancelledAppointmentsTable({ appointments }) {
  const { data: encounterTypes, isLoading } = useEncounterTypes();

  const COLUMNS = [
    {
      accessorFn: (row) => row.site?.name,
      header: 'LOCATION',
      cell: getValue,
    },
    {
      accessorKey: 'encounterTypeId',
      header: 'ENCOUNTER TYPE',
      cell: (props) => getEncounterTypeName(props, encounterTypes),
    },
    {
      accessorKey: 'appointmentDate',
      header: 'APPOINTMENT DATE',
      cell: getDateTime,
    },
    {
      accessorKey: 'cancellationDate',
      header: 'CANCELLATION DATE',
      cell: getDateTime,
    },
    {
      id: 'cancellationReason.name',
      accessorFn: (row) => row.cancellationReason?.name,
      header: 'REASON',
      cell: getValue,
    },
    {
      id: 'cancelledBy.fullName',
      accessorFn: (row) => row.cancelledBy?.fullName,
      header: 'CANCELLED BY',
      cell: getValue,
    },
  ];

  return (
    <TableLayout
      isLoading={isLoading}
      columns={COLUMNS}
      data={appointments}
      noResultsText="This patient has no cancelled visits."
    />
  );
}

CancelledAppointmentsTable.propTypes = {
  appointments: PropTypes.arrayOf(
    PropTypes.shape({
      patientId: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default CancelledAppointmentsTable;
